import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";

import { useNavigate } from "react-router";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
// import { query } from "express";

const ProfessionalWingForm = ({
    title,
    formData,
    setFormData,
    isCaptchaCorrect,
    setIsCaptchaCorrect,
    wingApiFlag,
    handleExportFormSubmit,
}) => {
  const navigate = useNavigate();
  const [selectedProfessional, setSelectedProfessional] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [summary, setSummary] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [captchaMatched, setCaptchaMatched] = useState(false);

  const typesWithTopicAndprofessionals = [
    {
      professionals: "Chartered Accountancy",
      Query: [
        {
          name: "Provide Financial Advisory Services",
          types: ["Accountancy", "Auditing"],
        },
        {
          name: "Direct Taxation",
          types: [
            "Income Tax",
            "Corporate Tax",
            "Securities Transaction Tax",
            "Capital Gains Tax",
            "Gift Tax",
            "Wealth Tax",
          ],
        },
        {
          name: "Indirect Taxation",
          types: [
            "Sales Tax",
            "Service Tax",
            "Octroi Duty",
            "Custom Duty",
            "Value Added Tax (VAT)",
            "Goods & Services Tax (GST)",
          ],
        },
        {
          name: "Other Taxes",
          types: ["Property Tax", "Registration Fees", "Professional Tax"],
        },
        {
          name: "IEC CODE",
          types: ["Import Export Code Registration"],
        },
      ],
    },
    {
      professionals: "Company Secretary",
      Query: [
        {
          name: "The Indian Contract Act, 1872",
          types: [
            "Executed contract",
            "Executory contract",
            "Unilateral contract",
            "Bilateral contract",
          ],
        },
        {
          name: "The Sale of Goods Act,1930",
          types: ["Existing Goods", "Future Goods", "Contingent Goods"],
        },
        {
          name: "The Indian Partnership Act,1932",
          types: [
            "Active/Managing Partner",
            "Sleeping Partner",
            "Nominal Partner",
            "Partner by Estoppel",
            "Partner in Profits only",
            "Minor Partner",
            "Secret Partner",
            "Outgoing partner",
            "Limited partner",
            "Sub-Partner",
          ],
        },
        {
          name: "The Limited Liability Partnership Act, 2008",
          types: ["Start-up LLP", "LLP incorporation document"],
        },
        {
          name: "Companies Act,2013",
          types: [
            "One Person Company",
            "Private Limited Company",
            "Public Limited Company",
            "Section 8 Company (NGO)",
            "MSME -Micro Companies",
            "MSME-Small Companies",
            "MSME-Medium Companies",
            "Limited By Shares",
            "Limited by Guarantee",
            "Unlimited Company",
            "Holding Company",
            "Subsidiary Company",
            "Listed Company",
            "Unlisted Company",
          ],
        },
      ],
    },
  ];

  

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleProfessionalChange = (event) => {
    setFormData({ ...formData, professionalType: event.target.value,query:"",topic:""})
  };

  const handleTypeChange = (event) => {
    setFormData({ ...formData, query: event.target.value,topic:""})
  };

  const handleCaptchaChange = (evt) => {
    // setIsVisible(true);
    setCaptchaMatched(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
        setIsCaptchaCorrect(true);
        return;
    }
    setIsCaptchaCorrect(false);
}

  const handleTopicChange = (event) => {
    setFormData({ ...formData, topic: event.target.value })
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({...formData, refDoc: file });
  };

  

  const MAX_CHARACTERS = 1000; // Define the maximum number of characters

  const handleSummaryChange = (event) => {
    const text = event.target.value;
        if (text.length <= MAX_CHARACTERS) {
            // Only update the state if the input is within the character limit
            setFormData({ ...formData, summary: event.target.value });
        }
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <div className="card mt-3">
          <div className="card-header pt-3 pb-5">
            <h5 className="card-title mb-2">
             {title}
            </h5>
            <p className="card-text">
              To schedule an online consultation, pose inquiries, or arrange a
              meeting with our expert, please provide the necessary information
              below:
            </p>
            <Button className="" onClick={() => navigate("/Professional-wing-token")}>
                                Track Queries
                            </Button>
          </div>
          <form className="card-body" onSubmit={handleExportFormSubmit}>
            <div className="d-flex justify-content-between mb-3 gap-5">
              <div style={{ width: "33%" }}>
                <label htmlFor="queryProfessional" className="form-label">
                  <h5>Select Professionals</h5>
                </label>
                <select
                  id="queryProfessional"
                  className="form-select"
                  onChange={handleProfessionalChange} // Make sure to define the handleProfessionalChange function
                  value={formData.professionalType}
                  required
                >
                  <option value="">Select Professionals</option>
                  {typesWithTopicAndprofessionals.map((professional, index) => (
                    <option key={index} value={professional.professionals}>
                      {professional.professionals}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <label htmlFor="queryType" className="form-label">
                  <h5>Type of Query</h5>
                </label>
                <select
                  id="queryType"
                  className="form-select"
                  onChange={handleTypeChange} // Make sure to define the handleTypeChange function
                  value={formData.query}
                  disabled={!formData.professionalType}
                  required
                >
                  <option value="">Select Type</option>
                  {formData.professionalType &&
                    typesWithTopicAndprofessionals
                      .find(
                        (professional) =>
                          professional.professionals === formData.professionalType
                      )
                      .Query.map((query, index) => (
                        <option key={index} value={query.name}>
                          {query.name}
                        </option>
                      ))}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <label htmlFor="queryTopic" className="form-label">
                  <h5>Type of Topic</h5>
                </label>
                <select
                  id="queryTopic"
                  className="form-select"
                  onChange={handleTopicChange} // Make sure to define the handleTopicChange function
                  value={formData.topic}
                  disabled={!formData.query} // Disable the topic select if type is not selected
                  required
                >
                  <option value="">Select Topic</option>
                  {formData.query &&
                    typesWithTopicAndprofessionals
                      .find(
                        (professional) =>
                          professional.professionals === formData.professionalType
                      )
                      .Query.find((query) => query.name === formData.query)
                      .types.map((subType, subIndex) => (
                        <option key={subIndex} value={subType}>
                          {subType}
                        </option>
                      ))}
                </select>
              </div>
            </div>

            <h5 className="mb-4 text-secondary">Brief of Summary </h5>
            <textarea
              className="form-control border-2 mb-4"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder={`Max Characters ${MAX_CHARACTERS}..`}
              required
              value={formData.summary} // Set the value of the textarea to the state variable
              onChange={handleSummaryChange} // Handle the change event
            ></textarea>
            <div className="d-flex justify-content-between mb-4">
              <span className="w-50">Reference Document (if any)</span>
              <label className="bg-color p-2 w-50 border-none text-center">
                UPLOAD
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </label>
            </div>
            <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex align-items-center">
                                    <LoadCanvasTemplate />
                                    {isCaptchaCorrect && (
                                        <AiFillCheckCircle className="icon-size primary-color" />
                                    )}{" "}
                                </div>
                                <div className="w-50">
                                    <input
                                        className="w-100 p-1 border-1"
                                        placeholder="Type the given captcha wordsssss"
                                        id="user_captcha_input"
                                        onChange={handleCaptchaChange}
                                        name="user_captcha_input"
                                        type="text"
                                    />
                                    {isCaptchaCorrect ? (
                                        <p className="text-success">CAPTCHA is valid</p>
                                    ) : (
                                        <p className="text-danger">CAPTCHA is invalid</p>
                                    )}
                                </div>
                            </div>
            <div className="d-flex justify-content-center gap-4 ">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
                required
              />
              <span className="w-75">
                I, hereby duly consent and acknowledge that all details filled
                herein are true, correct and any misrepresentation of facts,
                documents, or any other relevant information may deem result in
                disapproval of our Query.
              </span>
            </div>
            <hr />
            <Button
                                type="submit"
                                variant="success"
                                className="float-end w-25"
                                disabled={wingApiFlag}
                            >
                                {wingApiFlag ? "Processing..." : "SUBMIT"}
                            </Button>
          </form>
        </div>
      </Layout2>
    </Layout>
  );
};

export default ProfessionalWingForm;
