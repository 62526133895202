import React, { useState, useEffect } from "react";
import {
  Table,
  Pagination,
  Button,
  Modal,
  CloseButton,
  Form,
} from "react-bootstrap";
import { getURLbyEndPoint } from "../../store/api";
import "../../../src/App.css";

import axios from "axios";
import { getAuthUserlocal } from "../../store/services";
import { formatDateTime } from "../basic/SimpleFunctions";
import { PopUpModalButton } from "../basic/ModalComponent";
import { Document, Page, pdfjs } from "react-pdf";

// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const NonStandTrack = () => {
  const [nonStandData, setNonStandData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchNonStandData = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPoint("getNonStandDocUser") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setNonStandData(data.data);
      } else {
        alert("Failed to retrieve non Stand Data.");
      }
    } catch (error) {
      console.error("Error fetching non stand data:", error);
      // alert("Error fetching data:", error);
    }
  };


  const filteredData = nonStandData.filter((data) => {
    // Filter based on account status or any other criteria
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Reset to the first page whenever the search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchNonStandData();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-end mb-3">
        <Form.Control
          className="form-control w-40"
          type="search"
          placeholder="Search By Application No."
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                Sr No.
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                App No & Date
              </th>

              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Type Of Document & Country
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Download Documents
              </th>

              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <>
            <tbody>
              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className="pb-0">
                          {" "}
                          {filteredData.length - indexOfFirstItem - index}.
                        </td>
                        <td className="pb-0">
                          <pre>
                            {data.appNo} <br />
                            {formatDateTime(data.createdAt)}
                          </pre>
                        </td>
                        <td className="pb-0">
                          <span>Type of document: {data.typeOfDoc}</span>
                          <br />
                          <span>Country: {data.country}</span>
                        </td>

                      <td style={{ width: "max-content" }}>
                        <div
                          className="d-flex justify-content-between flex-wrap"
                          style={{ width: "230px", maxWidth: "max-content" }}
                        >
                          {data.nonStandDocs.map((docData, index) => {
                            return (
                              <div
                                className="col-md-6 mb-2 ps-0 text-start"
                                key={index}
                              >
                                <FileOpenPopUp
                                  userId={docData._id}
                                  title={docData.docName}
                                  fileLink={docData.docFile}
                                  isDownloaded={docData.isDownloaded}
                                  disabled={data.isApproved !== "Approved"}
                                />
                              </div>
                            );
                          })}
                        </div>

                          <div className="d-flex gap-2">
                            {data.reqLetterFlag && (
                              <PopUpModalButton
                                title="Request Letter"
                                fileLink={data.reqLetterFile}
                                disabled={data.isApproved !== "Approved"}
                              />
                            )}
                            <PopUpModalButton
                              title="Receipt"
                              fileLink={data.receipt}
                              disabled={data.isApproved !== "Approved"}
                            />
                          </div>
                        </td>
                        <td className="pb-0 text-center">
                          <h6
                            className={
                              data.isApproved === "Rejected"
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {data.isApproved}
                          </h6>
                          <span>{data.remark}</span>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </>
        </Table>
        {/* //pagination------------------------------------
            ------------------------------------------------ */}
        {totalPages > 1 && (
          <div className="d-flex justify-content-start">
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <Pagination.Item
                  key={index + startPage}
                  active={index + startPage === currentPage}
                  onClick={() => handlePageChange(index + startPage)}
                >
                  {index + startPage}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        )}
      </div>
    </div>
  );
};

export default NonStandTrack;

//popup for file download
export function FileOpenPopUp({ userId, title, fileLink, isDownloaded, disabled }) {
  const [modalShow, setModalShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [isDownloadedStatus, setIsDownloadedStatus] = useState(isDownloaded);


  const fetchDocumentData = async () => {
    const clientId = getAuthUserlocal()._id;
    try {
      const response = await axios.get(
        getURLbyEndPoint("getStamppedNonStandDocUser") +
          `${clientId}/${userId}`,
        {
          responseType: "blob",
        }
      );

      // Create a URL for the blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
        setPdfUrl(url);
    } catch (error) {
      console.error("Error fetching non-stand document Data:", error);
    }
  };

  const [downDocApi, setDownDocApi] = useState(false);
  const handleDownloadDoc = async () => {
    const clientId = getAuthUserlocal()._id;

    setDownDocApi(true)
    try {
      const res = await axios.get(
        getURLbyEndPoint("downDocNonStand") +
        `${clientId}/${userId}`,
        {
          responseType: "blob", // Set response type to blob
        }
      );
  
      // Create a blob URL for the downloaded file
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.pdf`); // Set the filename
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      alert("Document Downloaded Successfully");
      setIsDownloadedStatus(true)
      setDownDocApi(false)
  
  } catch (error) {
    console.error("Error fetching non-stand document Data:", error);
  }finally{
    setDownDocApi(false)
  }
  }

  useEffect(() => {
    if (modalShow) {
      fetchDocumentData();
    }
  }, [modalShow]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <Button
        className="m-0 text-nowrap text-white rounded small-font"
        onClick={() => setModalShow(true)}
        disabled={disabled}
      >
        {title.length > 10 ? title.slice(0, 10) + "..." : title}
      </Button>

      <Modal
  show={modalShow}
  onHide={() => setModalShow(false)}
  size="lg"
  centered
  dialogClassName="modal-90w" // Adjusted width of the modal
>
  <Modal.Header
    style={{ backgroundColor: "#0b3b5d", color: "white" }}
    className="text-white"
  >
    <Modal.Title>{title}</Modal.Title>
    <CloseButton variant="white" onClick={() => setModalShow(false)} />
  </Modal.Header>
  <Modal.Body className="pdf-view-modelBody pdf-container">
      {pdfUrl ? (
        <Document
          onContextMenu={(e) => e.preventDefault()}
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) =>
            console.error("Error while loading document:", error)
          }
          onSourceError={(error) =>
            console.error("Error with source:", error)
          }
          className="react-pdf__Document"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className="react-pdf__Page"
            />
          ))}
        </Document>
      ) : (
        <p>Loading...</p>
      )}
    </Modal.Body>
    {!isDownloadedStatus && (
  <>
    <Modal.Footer>
      <div className="d-flex justify-content-center">
        <Button
          onClick={handleDownloadDoc}
          disabled={downDocApi}
        >
         {downDocApi ? "DOWNLOADING..." : "DOWNLOAD"} 
        </Button>
      </div>
    </Modal.Footer>
  </>
)}
</Modal>

    </>
  );
}
