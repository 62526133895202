import React from 'react';
import Econs from "../../assets/sponsors/ECONS.png";
import EGC from "../../assets/sponsors/EGC india.png";
import Excellency from "../../assets/sponsors/Excellency.png";


const Sponsors = () => {
    return (
        <>
            <hr />
            <h4 className="text-center text-support my-1">OUR SPONSORS & STRATEGIC PARTNERS</h4>
            <hr />
            <div className="QuickLinks d-flex">
                <div className="sponsors">
                    <img src={Excellency} alt="" height={80} srcset="" />
                </div>
                <div className="sponsors">
                    <img src={EGC} alt="" height={80} srcset="" />
                </div>
                <div className="sponsors">
                    <img src={Econs} alt="" height={80} srcset="" />
                </div>
            </div>
        </>
    )
}

export default Sponsors