import { useState } from "react";

export function breakAddressLines(address, maxLength) {
  const words = address.split(" ");
  let currentLine = "";
  const lines = [];

  words.forEach((word) => {
    if (currentLine.length === 0) {
      currentLine = word;
    } else {
      currentLine += " " + word;
    }

    if (currentLine.length > maxLength) {
      lines.push(currentLine);
      currentLine = "";
    }
  });

  if (currentLine.length > 0) {
    lines.push(currentLine);
  }

  return lines;
}

export function breakAddressLinesForTextArea(text, maxLength) {
  const lines = text?.split("\n"); // Split text by existing newlines first
  const formattedLines = [];

  lines?.forEach((line) => {
    let currentLine = "";
    for (let i = 0; i < line.length; i++) {
      currentLine += line[i];
      if (currentLine.length >= maxLength) {
        formattedLines.push(currentLine);
        currentLine = "";
      }
    }
    if (currentLine.length > 0) {
      formattedLines.push(currentLine);
    }
  });

  return formattedLines;
}

export const TruncatedTextArea = ({ text, maxChars }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated
    ? text.length > maxChars
      ? text.slice(0, maxChars) + "..."
      : text
    : text;

  return (
    <div>
      <span
        className="text-secondary"
        onClick={toggleTruncate}
        style={{ cursor: "pointer" }}
      >
        {truncatedText}
      </span>
    </div>
  );
};
