function storeAuthUserlocal(userDatalocal) {
  localStorage.setItem("authUser", JSON.stringify(userDatalocal));
}

function getAuthUserlocal() {
  const userDetailslocal = JSON.parse(localStorage.getItem("authUser"));
  return userDetailslocal;
}

export { storeAuthUserlocal, getAuthUserlocal };
