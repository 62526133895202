import React from "react";
import Layout from "../components/Layouts/Layouts/Layout";
import Layout2 from "../components/Layouts/Layouts/Layout2";
import EPlatformHeader from "../components/basic/EPlatformHeader";
import EPlatformHeaderimg from "../assets/EplatformHeaderimg.jpg";
import interCollab1 from "../assets/internationalcollab/intercollab1.png";
import interCollab2 from "../assets/internationalcollab/intercollab2.png";
import interCollab3 from "../assets/internationalcollab/intercollab3.png";
import temp1 from "../assets/internationalcollab/temp1.png";

const InternationalCollabration = () => {
  return (
    <>
      <Layout>
        <div
          className="d-flex justify-content-center text-light"
          style={{
            backgroundImage: `url(${EPlatformHeaderimg})`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="main-width"
            style={{ marginBottom: "8vh", marginTop: "8vh" }}
          >
            <h1 className="font1">International Collaborations</h1>
            <span>
              Discover our strategic alliances with leading companies and
              exhibitions, driving growth and innovation in international trade.
              At AECCI, we believe in fostering synergies and creating
              opportunities for growth in the world of trade & Commerce.
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div
            className="main-width"
            style={{ marginBottom: "5vh", marginTop: "5vh" }}
          >
            <div className="d-flex justify-content-around mb-4">
              <img src={interCollab1} alt="" srcset="" />
              <img src={interCollab2} alt="" srcset="" />
              <img src={interCollab3} alt="" srcset="" />
            </div>

            {/* main content start here */}
            <div className="d-flex justify-content-between">
              <div
                className="pt-3 bg-color text-center"
                style={{ width: "30%" }}
              >
                <img
                  src={temp1}
                  className="px-4"
                  style={{ width: "100%" }}
                  alt=""
                  srcset=""
                />
                <h3 className="py-3">November 7-9, 2023</h3>
                <div className="bg-light mx-4 text-black px-2 py-2">
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h5>📌Dubai World Trade Centre</h5>
                </div>
                <div>
                  <div className="py-3">
                    <button type="button" class="btn btn-outline-light">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="pt-3 bg-color text-center"
                style={{ width: "30%" }}
              >
                <img
                  src={temp1}
                  className="px-4"
                  style={{ width: "100%" }}
                  alt=""
                  srcset=""
                />
                <h3 className="py-3">November 7-9, 2023</h3>
                <div className="bg-light mx-4 text-black px-2 py-2">
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h5>📌Dubai World Trade Centre</h5>
                </div>
                <div>
                  <div className="py-3">
                    <button type="button" class="btn btn-outline-light">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="pt-3 bg-color text-center"
                style={{ width: "30%" }}
              >
                <img
                  src={temp1}
                  className="px-4"
                  style={{ width: "100%" }}
                  alt=""
                  srcset=""
                />
                <h3 className="py-3">November 7-9, 2023</h3>
                <div className="bg-light mx-4 text-black px-2 py-2">
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h6>
                    ♦ Private Label Middle East, the leading international trade
                    show for private label and contract manufacturing in the
                    Middle East and Africa.
                  </h6>
                  <h5>📌Dubai World Trade Centre</h5>
                </div>
                <div>
                  <div className="py-3">
                    <button type="button" class="btn btn-outline-light">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default InternationalCollabration;
