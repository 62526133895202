import React, { createContext, useContext, useState } from 'react';

// Create Context
const PricingContext = createContext();

// Custom hook to use the Pricing Context
export const usePricing = () => {
  return useContext(PricingContext);
};

// Provider Component
export const PricingProvider = ({ initialData, children }) => {
  const [pricingData, setPricingData] = useState(initialData);

  const updatePricingData = (newData) => {
    setPricingData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <PricingContext.Provider value={{ pricingData, updatePricingData }}>
      {children}
    </PricingContext.Provider>
  );
};
