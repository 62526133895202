import React from "react";
import infoImg from "../../assets/infoimg.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const SmallPopUp = ({ text }) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <img src={infoImg} style={{ height: "6vh" }} alt="" srcset="" />
    </OverlayTrigger>
  );
};

export default SmallPopUp;
