import React from 'react'
import { Modal } from 'react-bootstrap'

const DemoModal = ({ show, handleCloseDemo, videoUrl, heading }) => {
  return (
    <Modal show={show} onHide={handleCloseDemo} size="lg" centered>
        <Modal.Header style={{ background: "rgb(11, 59, 93)", color: "#fff" }} closeButton>
      <Modal.Title>{heading}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <iframe
        width="100%"
        height="400"
        src={videoUrl}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="Dashboard Intro"
      ></iframe>
    </Modal.Body>
  </Modal>
  )
}

export default DemoModal