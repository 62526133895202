import React from "react";
import { getAuthUserlocal } from "../../store/services";

export function validateAmount(totalAmt) {
  const user = getAuthUserlocal();
  const hasWalletExceedAccess =
    user.specialControl?.accessList?.includes("walletExceed");
  const isTrialUser = user?.isTrial;
  const balanceAfterTransaction = user?.balance - totalAmt;

  if (!hasWalletExceedAccess && !isTrialUser && balanceAfterTransaction < 500) {
    return false;
  }
  return true;
}
