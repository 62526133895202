import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoImg from "../../assets/infoimg.png";

const authUserLocal = getAuthUserlocal();

const MarketingInformation = () => {
  const [miFromData, setMIFromData] = useState({
    emails: false,
    posts: false,
    calls: false,
    campaigns: false,
    awards: false,
    weeklyBullets: false,
    eventUpdates: false,
    magzine: false,
    newsLetters: false,
    sponsorship: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMIFromData((prevmiFromData) => ({
      ...prevmiFromData,
      [name]: value,
    }));
  };

  const handleMarketingFormSubmit = async (e) => {
    e.preventDefault();
    const userId = getAuthUserlocal()?._id;


    try {
      const res = await axios.post(
        getURLbyEndPoint("marketingDetails") + userId,
        {
          ...miFromData,
        }
      );
      if (res.status === 200) {
        console.log(res.data.message);
        alert("Request To Update Marketing Information Has Been Successfully Sent.");
      } else {
        alert("something went Wrong!!");
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went Wrong!!!");
    }
  };

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <form
            className="d-flex flex-column dashboardBlock"
            onSubmit={handleMarketingFormSubmit}
          >
            <div className="d-flex align-items-center">
              {" "}
              <h4 className="py-4">MARKETING INFORMATION</h4>{" "}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This section of the platform captures members's preferred
                    marketing services. Users should tick designated boxes to
                    get timely News, Weekly bulletins, Trade notifications,
                    etc., from the chamber.
                  </Tooltip>
                }
              >
                <img src={infoImg} style={{ height: "6vh" }} alt="" srcset="" />
              </OverlayTrigger>
            </div>
            <hr className="mt-1 mb-4" />
            <div>
              <div className="d-flex justify-content-between align-items-center w-75 mb-4">
                <h6 className="fw-normal text-secondary">
                  I AM HAPPY TO RECEIVE E-MAILS{" "}
                </h6>
                <div>
                  <input
                    style={{ height: "32px", width: "32px" }}
                    className="form-check-input border border-3"
                    type="checkbox"
                    id="checkboxNoLabel"
                    defaultValue
                    value={miFromData.emails}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setMIFromData({
                        ...miFromData,
                        emails: isChecked,
                      });
                    }}
                    aria-label="..."
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center w-75 mb-4">
                <h6 className="fw-normal text-secondary">
                  I AM HAPPY TO RECEIVE POST{" "}
                </h6>
                <div>
                  <input
                    style={{ height: "32px", width: "32px" }}
                    className="form-check-input border border-3"
                    type="checkbox"
                    id="checkboxNoLabel"
                    defaultValue
                    value={miFromData.posts}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setMIFromData({
                        ...miFromData,
                        posts: isChecked,
                      });
                    }}
                    aria-label="..."
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center w-75 mb-4">
                <h6 className="fw-normal text-secondary">
                  I AM HAPPY TO RECEIVE CALLS{" "}
                </h6>
                <div>
                  <input
                    style={{ height: "32px", width: "32px" }}
                    className="form-check-input border border-3"
                    type="checkbox"
                    id="checkboxNoLabel"
                    defaultValue
                    value={miFromData.calls}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setMIFromData({
                        ...miFromData,
                        calls: isChecked,
                      });
                    }}
                    aria-label="..."
                  />
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-4" />

            {/* second table starts here
            ===========================
            =========================== */}
            <table
              className="table table-bordered mb-3 table-striped"
              style={{ tableLayout: "fixed" }}
            >
              <colgroup>
                <col style={{ width: "80%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" className="py-3 ps-4">
                    Particulars
                  </th>
                  <th scope="col" className="text-center py-3">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-3 ps-4">AECCI Marketing Campaigns</td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.campaigns}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          campaigns: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">Chamber Awards</td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.awards}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          awards: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">Chamber weekly Bullets</td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.weeklyBullets}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          weeklyBullets: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">Event Updates</td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.eventUpdates}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          eventUpdates: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">Annual Magazines</td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.magzine}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          magzine: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">
                    Quarterly/ Weekly News Letter Distribution
                  </td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.newsLetters}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          newsLetters: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-3 ps-4">
                    Sponsorship & Advertising Opportunities
                  </td>
                  <td className="text-center py-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      value={miFromData.sponsorship}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setMIFromData({
                          ...miFromData,
                          sponsorship: isChecked,
                        });
                      }}
                      id="flexCheckDefault"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className=" d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary mt-4 p-2 w-25"
                style={{ backgroundColor: "#0b3b5d", border: "0" }}
              >
                UPDATE
              </button>
            </div>
          </form>
        </Layout2>
      </Layout>
    </>
  );
};

export default MarketingInformation;
