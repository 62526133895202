import React, { useEffect, useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import { getURLbyEndPointV2 } from "../../store/api";
import axios from "axios";
import { Form, Col, Row, Button, Table, Pagination } from "react-bootstrap";
import { getAuthUserlocal } from "../../store/services";
import { PopUpModalButton } from "../../components/basic/ModalComponent";
import jsPDF from "jspdf";
import Logo from "../../assets/footerLogo.png";
import { formatDateTime } from "../../components/basic/SimpleFunctions";

const userId = getAuthUserlocal() ? getAuthUserlocal()._id : null;

const AdBooking = () => {
  const [adBookingTrack, setAdBookingTrack] = useState([]);
  const [apiSubmitEvent, setApiSubmitEvent] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const initialFormData = {
    companyName: "",
    companyLogo: null,
    companyActivity: [[""]],
    adValidity: null,
    websiteUrl: "",
    phoneNo: "",
    emailId: "",
    adValue: 0,
  };

  const [adFormData, setAdFormData] = useState(initialFormData);

  const handleAdValidityChange = (e) => {
    const value = Number(e.target.value);
    let adValue = null;
    switch (value) {
      case 1:
        adValue = 2999;
        break;
      case 3:
        adValue = 4999;
        break;
      case 7:
        adValue = 8999;
        break;
      case 14:
        adValue = 14999;
        break;
      case 30:
        adValue = 300000;
        break;
      default:
        adValue = 0;
    }
    setAdFormData({
      ...adFormData,
      adValidity: value,
      adValue: adValue,
    });
  };

  const handleInputActivity = (value, docIndex, activityIndex) => {
    setAdFormData((prevData) => {
      const companyActivity = [...prevData.companyActivity];
      const activityArray = [...companyActivity[docIndex]];
      activityArray[activityIndex] = value;
      companyActivity[docIndex] = activityArray;
      return {
        ...prevData,
        companyActivity: companyActivity,
      };
    });
  };

  const addActivityField = () => {
    if (adFormData.companyActivity.length < 4) {
      setAdFormData((prevData) => ({
        ...prevData,
        companyActivity: [...prevData.companyActivity, [""]],
      }));
      console.log(adFormData);
    }
  };

  const removeActivityField = (docIndex) => {
    setAdFormData((prevData) => ({
      ...prevData,
      companyActivity: prevData.companyActivity.filter(
        (_, index) => index !== docIndex
      ),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //for company logo
  // =================
  const handleLogoChange = (e) => {
    const companyLogo = e.target.files[0];
    setAdFormData((prevFormData) => ({
      ...prevFormData,
      companyLogo: companyLogo,
    }));
  };

  const validateFormData = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberRegex = /^[0-9]{10}$/;

    const validationRules = {
      companyLogo: {
        message: "Please Upload a valid Company Logo (image).",
        isValid: () =>
          adFormData.companyLogo !== null &&
          adFormData.companyLogo instanceof File,
      },
      companyName: {
        message: "Please enter a valid Company Name (max 50 characters).",
        isValid: () => {
          const name = adFormData.companyName.trim();
          return name !== "" && name.length <= 50;
        },
      },
      emailId: {
        message: "Please enter a valid email address.",
        isValid: () => {
          const email = adFormData.emailId.trim();
          return email !== "" && emailRegex.test(email);
        },
      },
      phoneNo: {
        message: "Please enter a valid mobile number.",
        isValid: () => {
          const phoneNumber = adFormData.phoneNo;
          return mobileNumberRegex.test(phoneNumber);
        },
      },
      companyActivity: {
        message: "Please enter at least one valid company activity.",
        isValid: () => {
          return adFormData.companyActivity.some((activityArray) =>
            activityArray.some((activity) => activity.trim() !== "")
          );
        },
      },
    };

    for (const [field, rule] of Object.entries(validationRules)) {
      if (!rule.isValid()) {
        toast.error(rule.message);
        return false;
      }
    }
    return true;
  };

  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    //second line
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.line(15, 115, 195, 115); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];

    const data = [];
    data.push([
      "1",
      "AD BOOKING",
      `${adFormData.adValidity} Days`,
      // `Rs. ${adFormData.adValue}/-`,
      // `Rs. ${adFormData.adValue}/-`,
    ]);

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 1));
    // pdf.text(
    //   `Rs. ${adFormData.adValue}/-`,
    //   175,
    //   startY + lineHeight * (data.length + 1)
    // );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line
    // pdf.text(
    //   `Amount In words(in INR):
    //   ${numberToWords(adFormData.adValue)}
    //   `,
    //   15,
    //   startY + lineHeight * (data.length + 2)
    // );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 2),
      195,
      5 + startY + lineHeight * (data.length + 2)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 2),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 2),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 2)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in adFormData.receipt
    adFormData.receipt = pdfFile;
  };

  const handleSubmitAd = async (e) => {
    e.preventDefault();
    await handleCreateReceipt();

    const isValid = validateFormData();
    if (!isValid) {
      return;
    }

    const apiFormData = new FormData();
    Object.entries(adFormData).forEach(([key, value]) => {
      apiFormData.append(key, value);
    });

    try {
      setApiSubmitEvent(true);
      const res = await axios.post(
        getURLbyEndPointV2("createAdBooking") + userId,
        apiFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(res.data.message, { autoClose: 20000 });
      setApiSubmitEvent(true);
      setAdFormData(initialFormData);
      setApiSubmitEvent(false);
      // window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Internal server error");
      setApiSubmitEvent(false);
    }
  };

  const fetchAdBookingData = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPointV2("getAdBooking") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setAdBookingTrack([...data]);
      } else {
        console.error("Failed to retrieve ads Data.");
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  //for SearchBar
  const filteredData = adBookingTrack.filter((data) => {
    if (
      searchTerm !== "" &&
      !data.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  // Pagination logic-----------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4);
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }
    return { startPage, endPage };
  };
  const { startPage, endPage } = calculateRange(currentPage, totalPages);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchAdBookingData();
  }, []);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="d-flex flex-column dashboardBlock">
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">Book Your AD</h4>
              </div>
            </div>
            <hr className="mt-1 mb-4" />
            <form onSubmit={handleSubmitAd}>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">COMPANY NAME</label>
                <input
                  style={{ width: "60%" }}
                  className="form-control rounded-0 ms-5"
                  onChange={handleInputChange}
                  value={adFormData.companyName}
                  maxLength={50}
                  name="companyName"
                  type="text"
                  required
                />
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">COMPANY LOGO</label>
                <div className="input-group " style={{ width: "60%" }}>
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                    accept="image/*"
                    onChange={handleLogoChange}
                    required
                  />
                </div>
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">DESCRIBE ACTIVITY</label>
                <div
                  className="d-flex gap-3 flex-column"
                  style={{ width: "60%" }}
                >
                  {adFormData.companyActivity.map((activities, docIndex) => (
                    <div
                      key={docIndex}
                      className="d-flex gap-3 align-items-center"
                    >
                      {activities.map((activity, activityIndex) => (
                        <Form.Control
                          key={activityIndex}
                          type="text"
                          value={activity}
                          onChange={(e) =>
                            handleInputActivity(
                              e.target.value,
                              docIndex,
                              activityIndex
                            )
                          }
                        />
                      ))}
                      {adFormData.companyActivity.length > 1 &&
                        docIndex === adFormData.companyActivity.length - 1 && (
                          <Button
                            variant="danger"
                            onClick={() => removeActivityField(docIndex)}
                          >
                            -
                          </Button>
                        )}
                    </div>
                  ))}
                  {adFormData.companyActivity.length < 4 && (
                    <Button className="my-0 py-1" onClick={addActivityField}>
                      +
                    </Button>
                  )}
                </div>
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">VALIDITY OF AD</label>
                <div style={{ width: "60%" }}>
                  <Row>
                    <Col>
                      <Form.Select
                        value={adFormData.adValidity}
                        onChange={handleAdValidityChange}
                      >
                        <option value="">Select Duration</option>
                        <option value="1">1 day</option>
                        <option value="3">3 days</option>
                        <option value="7">7 days</option>
                        <option value="14">2 weeks</option>
                        <option value="30">1 month</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <input
                        className="form-control rounded-0"
                        placeholder="Website URL"
                        onChange={handleInputChange}
                        value={adFormData.websiteUrl}
                        maxLength={80}
                        name="websiteUrl"
                        type="text"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">CONTACT</label>
                <div style={{ width: "60%" }}>
                  <Row>
                    <Col>
                      <input
                        placeholder="Phone number"
                        className="form-control rounded-0 "
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (/^\d*$/.test(inputValue) || inputValue === "") {
                            if (
                              (inputValue.length === 0 ||
                                inputValue[0] !== "0") &&
                              inputValue.length <= 10
                            ) {
                              handleInputChange({
                                target: {
                                  name: "phoneNo",
                                  value: inputValue,
                                },
                              });
                            }
                          }
                        }}
                        value={adFormData.phoneNo}
                        name="phoneNo"
                        type="text"
                        title="Enter your number"
                        required
                      />
                    </Col>
                    <Col>
                      <input
                        placeholder="email"
                        className="form-control rounded-0"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          handleInputChange({
                            target: {
                              name: "emailId",
                              value: inputValue,
                            },
                          });
                        }}
                        value={adFormData.emailId}
                        name="email"
                        type="text"
                        title="Please enter a email"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-semibold fs-6">VALUE OF AD:</label>
                <div
                  className="d-flex gap-3 flex-column"
                  style={{ width: "60%" }}
                >
                  <h5> {adFormData.adValue}/-</h5>
                </div>
              </div>

              <div className=" d-flex gap-4 justify-content-end">
                <button
                  type="submit"
                  className="btn btn-success mt-4 p-2 w-25"
                  style={{ border: "0" }}
                  disabled={apiSubmitEvent}
                >
                  {apiSubmitEvent ? "Processing..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>

          <div className="mt-4">
            <h4>Track Your AD Bookings</h4>
            <Table responsive striped bordered hover w-auto>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sr
                    <br /> No.
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                    className="text-center"
                  >
                    Company Name
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                    className="text-center"
                  >
                    Logo
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                    className="text-center"
                  >
                    Contact Details
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                    className="text-center"
                  >
                    Validity
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                    className="text-center"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <>
                <tbody>
                  {currentFiltered.length > 0 ? (
                    <>
                      {currentFiltered.map((data, index) => {
                        return (
                          <tr>
                            <td className="pb-0">
                              {" "}
                              {filteredData.length - indexOfFirstItem - index}.
                            </td>
                            <td className="pb-0">{data.companyName}</td>
                            <td className="pb-0">
                              <img
                                src={data.companyLogo}
                                alt="logo"
                                style={{
                                  height: "50px",
                                  width: "auto",
                                  cursor: "pointer",
                                }}
                              />
                            </td>
                            <td className="pb-0">
                              <div
                                className="d-flex justify-content-between flex-wrap"
                                style={{
                                  width: "230px",
                                  maxWidth: "max-content",
                                }}
                              >
                                Phone Number: {data.phoneNo}
                                <br />
                                Email: {data.emailId}
                                <br />
                                Value Of Ad: {data.adValue}/-
                                <br />
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-flex justify-content-between flex-wrap"
                                style={{
                                  width: "230px",
                                  maxWidth: "max-content",
                                }}
                              >
                                {data.adValidity} Days
                              </div>
                              <div className="my-2">
                                <PopUpModalButton
                                  title="Receipt"
                                  fileLink={data.receipt}
                                  disabled={data.isApproved === "Processing"}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <span>{data.remark}</span>
                              <br />
                              <div
                                className={`pb-0 text-center ${
                                  data.isApproved === "Processing"
                                    ? "text-primary"
                                    : data.isApproved === "Rejected"
                                    ? "text-danger"
                                    : data.isApproved === "Approved"
                                    ? "text-success"
                                    : data.isApproved === "FullyApproved"
                                    ? "text-success"
                                    : ""
                                }`}
                              >
                                {data.isApproved === "FullyApproved"
                                  ? "Approved"
                                  : data.isApproved}
                                <div>{data.approvedDateTime}</div>
                              </div>
                              <div className="text-nowrap">
                                {data.isApproved === "FullyApproved" && (
                                  <>
                                    <div>
                                      AD start From:{" "}
                                      {formatDate(data.startsFrom)}
                                    </div>
                                    <div>
                                      <Button
                                        size="sm"
                                        onClick={() =>
                                          window.open(
                                            data.adBanner,
                                            "_blank",
                                            "noopener,noreferrer"
                                          )
                                        }
                                      >
                                        View AD
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            </Table>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default AdBooking;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); 
  const year = String(date.getFullYear()).slice(-2); 
  return `${day}-${month}-${year}`;
};
