import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { getURLbyEndPoint } from "../../store/api";
import { handleApiError } from "../../components/authentication/apiHandling";

const EnquiryComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //handing form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(getURLbyEndPoint("getNewsLetters"), {
        ...formData,
      });

      if (res.data.status) {
        setFormData({
          name: "",
          surname: "",
          email: "",
        });
        alert(res.data.message);
      } else {
        alert("Something went wrong!!!");
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-start font1"
      style={{ backgroundColor: "#0b3b5d" }}
    >
      <div
        className="py-4 align-items-center justify-content-center main-width"
        style={{ color: "white" }}
      >
        <h3>DON’T MISS OUT</h3>
        <p className="p-0">
          Sign up for alerts and updates on the latest news and opportunities
          across the Asian Exporters’ Chamber of Commerce And Industry and
          beyond
        </p>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-3">
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter first name"
                maxLength={20}
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-lg-4 col-md-12 mb-3">
              <Form.Control
                type="text"
                name="surname"
                placeholder="Enter surname"
                maxLength={20}
                value={formData.surname}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-lg-4 col-md-12 mb-3">
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                maxLength={40}
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-md-row flex-column py-3">
                <span>
                  By submitting your information you agree for Asian Exporters’
                  Chamber of Commerce And Industry to keep you up-to-date with
                  the latest news, upcoming events and other marketing
                  correspondence
                </span>
                <Button
                  className="mt-md-0 mt-3 ml-md-3"
                  style={{ backgroundColor: "#eb9b00" }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnquiryComponent;
