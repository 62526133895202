import React, { useState } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { Button, Col, Row } from "react-bootstrap";
import NonBindingDispute from "./NonBindingDispute/NonBindingDispute";
import NonBindingDisputeTrack from "./NonBindingDispute/NonBindingDisputeTrack";
import NonBindingCaseSummery from "./NonBindingDispute/NonBindingCaseSummery";
import BindingDispute from "./BindingDispute/BindingDispute";
import QuickLinks from "../../../../components/basic/QuickLinks";

const RaiseYourDispute = () => {
  const [activePage, setActivePage] = useState("homePage");

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        {activePage === "homePage" ? (
          <h4 className="pt-4 mb-3">Raise Your Dispute</h4>
        ) : activePage === "nonBindingDispute" ? (
          <h4 className="pt-4 mb-3">
            Raise Your Dispute / Non-Binding Dispute
          </h4>
        ) : activePage === "bindingDispute" ? (
          <h4 className="pt-4 mb-3">
            Raise Your Dispute / Binding Dispute
          </h4>
        ) : (
          <h4 className="pt-4 mb-3">Raise Your Dispute</h4>
        )}

        <hr />
        {activePage === "homePage" ? (
          <>
            <div>
              <div className="mb-4">
                <h5>
                  Encountering challenges in your overseas transactions? Submit
                  your dispute details below for prompt resolution. Our
                  dedicated team is ready to assist you. Please select either a
                  binding or non-binding dispute, and our experts will address
                  your query efficiently.
                </h5>
              </div>
              <div className="border p-4">
                <Row className="align-items-end">
                  <Col>
                    <div className="d-flex flex-column justify-content-end align-items-center">
                      <div className="w-75">
                        A party that has entered into a contract or agreement,
                        incorporating the model clause of AECCI-IAC, is eligible
                        to file a case under the{" "}
                        <strong>Binding Dispute</strong> mechanism.
                      </div>
                      <div className="my-4 fs-6 d-flex flex-column gap-2">
                        <Button 
                          onClick={() => setActivePage("bindingDispute")}
                          >BINDING DISPUTE</Button>
                        <Button 
                          onClick={() => setActivePage("trackBindingDispute")}
                        >TRACK YOUR DISPUTE</Button>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-end align-items-center">
                      <div className="w-75 m-auto">
                        A party that has not entered into any contract or
                        agreement and has chosen not to invoke the model clause
                        of AECCI-IAC may opt for the{" "}
                        <strong>Non Binding Dispute</strong> resolution process.
                      </div>
                      <div className="my-4 fs-6 d-flex flex-column gap-2">
                        <Button
                          onClick={() => setActivePage("nonBindingDispute")}
                        >
                          NON BINDING DISPUTE
                        </Button>
                        <Button
                          onClick={() => setActivePage("trackNonBindingDispute")}
                        >
                          TRACK YOUR DISPUTE
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : activePage === "nonBindingDispute" ? (
          <NonBindingDispute setActivePage={setActivePage} />
        ) : activePage === "bindingDispute" ? (
          <BindingDispute setActivePage={setActivePage} />
        ) : activePage === "trackNonBindingDispute" ? (
          <NonBindingDisputeTrack setActivePage={setActivePage} />
        ) : activePage === "caseSummery" ? (
          <NonBindingCaseSummery setActivePage={setActivePage} />
        ) : (
          <div>Something went Wrong!!!</div>
        )}
      <QuickLinks></QuickLinks>
      </Layout2>
    </Layout>
  );
};

export default RaiseYourDispute;
