import React from 'react'
import Prof1 from "../../assets/profile1.png";
import Prof2 from "../../assets/profile2.png";
import Prof3 from "../../assets/profile3.png";
import Prof4 from "../../assets/profile4.png";
import Prof5 from "../../assets/profile5.png";
import Prof6 from "../../assets/profile6.png";
import { Link } from 'react-router-dom';

const QuickLinks = () => {
  return (
    <>
      <hr />
          <h4 className="text-center text-support my-1">QUICK LINKS</h4>
          <hr />
          <div className="QuickLinks d-flex">
            <div className="QuickLinksitem">
                <Link to="/personal-details">
              <img src={Prof1} alt="" height={80} srcset="" />
                </Link>
              <span>Profile</span>
            </div>
            <div className="QuickLinksitem">
            <Link to="/trade-document">
              <img src={Prof2} alt="" height={80} srcset="" />
              </Link>
              <span>e-CO</span>
            </div>
            <div className="QuickLinksitem">
            <Link to="/trade-document">
              <img src={Prof3} alt="" height={80} srcset="" />
              </Link>
              <span>Track Your</span>
              <span> Document</span>
            </div>
            <div className="QuickLinksitem">
            <Link to="/membership-services">
              <img src={Prof4} alt="" height={80} srcset="" />
              </Link>
              <span>Membership</span>
              <span> Plans</span>
            </div>
            <div className="QuickLinksitem">
            <Link to="/chember-event-booking">
              <img src={Prof5} alt="" height={80} srcset="" />
              </Link>
              <span>AECCI</span>
              <span>Events</span>
            </div>
            <div className="QuickLinksitem">
            <Link to="#">
              <img src={Prof6} alt="" height={80} srcset="" />
              </Link>
              <span>Arbitration</span>
              <span> Center</span>
            </div>
          </div>
          </>
  )
}

export default QuickLinks