import React, { useState, useEffect } from "react";
import { getAuthUserlocal } from "../../store/services";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { Table, Pagination, Form } from "react-bootstrap";
import { formatDateTime } from "../basic/SimpleFunctions";
import { PopUpModalButton } from "../basic/ModalComponent";

const McoDataTrack = () => {
  const [mCoData, setMCoData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchMcoData = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPoint("getUserMcoData") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setMCoData(data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Manual data:", error);
    }
  };

  // Filtering MCO data
  const filteredData = mCoData.filter((data) => {
    // Filter based on account status or any other criteria
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Reset to the first page whenever the search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchMcoData();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-end mb-3">
        <Form.Control
          className="form-control w-40"
          type="search"
          placeholder="Search By Application No."
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        <Table responsive striped bordered hover w-auto>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                Sr
                <br /> No.
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                App No &
                <br />
                Date
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Exporter
                <br />
                Name
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Invoice
                <br />
                No.
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Ref
                <br />
                No
              </th>

              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Document
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Delivery Mode
              </th>

              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          {filteredData.length > 0 ? (
            <>
              {currentFiltered.map((data, index) => (
                <tbody>
                  <tr>
                    <td className="pb-0">
                      {filteredData.length - indexOfFirstItem - index}.
                    </td>
                    <td className="pb-0">
                      {data.appNo}<br/>
                      {formatDateTime(data.createdAt)}
                    </td>
                    <td className="pb-0">{data.forComRegion}</td>
                    <td className="pb-0">{data.noAndDateInvoice}</td>
                    <td className="pb-0">{data?.refNo ? data.refNo : "---"}</td>

                    <td>
                      <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                        <div className="d-flex gap-2">
                          <PopUpModalButton
                            title="eCo"
                            // fileLink={data.eCoFile}
                            disabled={true}
                          />
                          {data.comInvoiceFlag && (
                            <PopUpModalButton
                              title="Invoice"
                              // fileLink={data.comInvoice}
                              disabled={true}
                            />
                          )}

                          {data.addDoc1Flag && (
                            <PopUpModalButton
                              title="Additional Document 1"
                              // fileLink={data.addDoc1}
                              disabled={
                                // data.isApproved ===
                                // "Processing"
                                true
                              }
                            />
                          )}
                        </div>

                        <div className="d-flex gap-2">
                          {data.addDoc2Flag && (
                            <PopUpModalButton
                              title="Additional Document 2"
                              // fileLink={data.addDoc2}
                              disabled={
                                // data.isApproved ===
                                // "Processing"
                                true
                              }
                            />
                          )}

                          {data.packingListFlag && (
                            <PopUpModalButton
                              title="Packing List"
                              // fileLink={data.packingList}
                              disabled={
                                // data.isApproved ===
                                // "Processing"
                                true
                              }
                            />
                          )}

                          <PopUpModalButton
                            title="Receipt"
                            fileLink={data.receipt}
                            disabled={
                              data.isApproved === "Processing" ||
                              data.isApproved === "Rejected"
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "max-content" }}>
                    <div
                            className="d-flex justify-content-between flex-wrap"
                            style={{ width: "260px", maxWidth: "max-content" }}
                          >
                      <div>
                        {data.selectedDeliveryMode ===
                        "Collect From Chamber Desk" ? (
                          <>
                            <h6>{data.selectedDeliveryMode}</h6>
                            <div>{data.collectionTimeMessage}</div>
                          </>
                        ) : (
                          <>
                            <h6>Mode: {data.selectedDeliveryMode}</h6>
                            <div>
                              Address Type: {data.addressType}
                              <br />
                            </div>
                            {data.addressTypeAddress}
                            <br />
                            {data.addressTypePincode} {data.addressTypeState}
                          </>
                        )}
                      </div>
                      </div>
                    </td>

                    <td className="pb-0 text-center">
                      <h6
                        className={
                          data.isApproved === "Rejected"
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {data.isApproved === "Rejected" ? (
                          <>
                            <h6>{data.isApproved}</h6>
                            <h6>{data.remark}</h6>
                          </>
                        ) : (
                          <>
                            <h6>{data.isApproved}</h6>
                            {data.isApproved === "Processing" &&
                              data.selectedDeliveryMode ===
                                "Collect From Chamber Desk" && (
                                <h6 className="text-black"></h6>
                              )}

                            {data.isApproved === "Printing" &&
                              data.selectedDeliveryMode ===
                                "Collect From Chamber Desk" && (
                                <h6 className="text-black">
                                  Please Collect Your Documents From Chamber
                                </h6>
                              )}

                            {data.isApproved === "Approved" &&
                              data.selectedDeliveryMode ===
                                "Collect From Chamber Desk" && (
                                <h6 className="text-black">
                                  Please Collect Your Documents From Chamber
                                </h6>
                              )}

                            {data.isApproved === "Approved" &&
                            data.selectedDeliveryMode !==
                              "Collect From Chamber Desk" ? (
                              <div className="text-left text-nowrap mx-2">
                                <h6 className="text-black mb-0 pb-0">
                                  Courier Details
                                </h6>
                                <h6 className="text-black">
                                  Company: {data.currierCompanyName}
                                </h6>
                                <h6 className="text-black">
                                  Tracking No: {data.trackingNo}
                                </h6>
                                <h6 className="text-black">
                                  Date: {data.dateFieldName}
                                </h6>
                              </div>
                            ) : (
                              <>
                                <h6 className="text-black"></h6>
                              </>
                            )}

                            {data.isApproved === "Printing" &&
                              data.selectedDeliveryMode !==
                                "Collect From Chamber Desk" && (
                                <>
                                  <h6 className="text-black">
                                    Courier Details Provide Shortly
                                  </h6>
                                </>
                              )}

                            {data.isApproved === "Rejected" && (
                              <h6 className="text-black">
                                Remark: {data.remark}
                              </h6>
                            )}
                          </>
                        )}
                      </h6>
                    </td>
                  </tr>
                </tbody>
              ))}
            </>
          ) : (
            <tbody>
              <tr>
                <td colSpan={8} className="text-center">
                  No Eco Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        {totalPages > 1 && (
          <div className="d-flex justify-content-start my-0 py-0">
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <Pagination.Item
                  key={index + startPage}
                  active={index + startPage === currentPage}
                  onClick={() => handlePageChange(index + startPage)}
                >
                  {index + startPage}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        )}
      </div>
    </div>
  );
};

export default McoDataTrack;
