import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsEye } from "react-icons/bs";

import { useNavigate } from "react-router";
import { Button, Row, Col, Badge } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const WingForm = ({
  wing,
  title,
  formData,
  setFormData,
  isCaptchaCorrect,
  setIsCaptchaCorrect,
  wingApiFlag,
  handleSubmitForm,
}) => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState("");

  const captcha_length = 6;
  const listOfTypeQuery = {
    "Export Wing": [
      {
        name: "Cert.of origin",
        types: [
          "Preferential Cert. of Origin",
          "Non-preferential Cert. of Origin",
        ],
      },
      {
        name: "Shipping Documentation",
        types: [
          "Export Order or Purchase Order",
          "Proforma invoice",
          "Commercial invoice",
          "Packing list",
          "Inspection or Quality Check",
          "Phyto-Sanitary & Fumigation Certificates",
          "Shipping bill",
          "Bill of Exchange",
          "Shipper’s letter of instruction",
          "Bills of lading",
          "Dangerous goods forms",
          "Bank draft",
          "Free Sales certificate",
        ],
      },
      {
        name: "Letter of Credit",
        types: [
          "Setting the Terms of a Letter of Credit",
          "Credit on Sight/ Time Credit",
          "Standby Letter of Credit (SBLC)",
          "Revocable Credit/ Irrevocable Credit",
          "Process of Letter of Credit",
          "List of doc for opening LC",
        ],
      },
      {
        name: "Pharmaceutical Registration Documents",
        types: [
          "COPP",
          "COA",
          "WHO GMP",
          "MFG. Contracts",
          "Dossier Documents",
        ],
      },
    ],
    "Legal Wing": [
      {
        name: "Alternative Dispute Resolution (ADR)",
        types: [
          "Domestic arbitration",
          "International arbitration",
          "International commercial arbitration",
          "Ad-hoc Arbitration",
          "Fast track Arbitration",
          "Institutional Arbitration",
          "Mediation",
          "Conciliation",
        ],
      },
      {
        name: "Common types/causes of global trade disputes",
        types: [
          "Payment terms",
          "Letter of guarantee",
          "Foreign exchange rates",
          "Documentation errors",
        ],
      },
      {
        name: "Contract disputes",
        types: [
          "Confidentiality agreement",
          "Breach of contract",
          "Confidentiality and non-compete agreement",
        ],
      },
      {
        name: "Commercial/Business law",
        types: [
          "Contract Law",
          "Employment Law",
          "Labour Law",
          "Intellectual Property Law",
          "Securities Law",
          "Tax Law",
        ],
      },
      {
        name: "Company law",
        types: [
          "Private limited company",
          "Public limited company",
          "Sole Proprietorship",
          "One person company",
          "Partnership",
          "Limited liability partnership",
        ],
      },
      {
        name: "Director’s disputes",
        types: [
          "Negotiate and Mediate",
          "Shareholder Solutions",
          "Selling or Splitting the Business",
          "Negotiate a Share Sale and Resignation",
          "Company Buy-Back of Shares",
          "Resign or Sell Up",
        ],
      },
      {
        name: "Data protection",
        types: ["Data security", "Data availability", "Access Control"],
      },
    ],
    "HR Support Wing": [
      {
        name: "Employment Law",
        types: [
          "Employment discrimination",
          "Wages and benefits",
          "Noncompetition agreement",
        ],
      },
      {
        name: "Staff Grievances and Disputes",
        types: ["Handle Employee Grievances"],
      },
      {
        name: "Disciplinary Issues",
        types: [
          "Attendance Related Problems",
          "Job Behavior Problems",
          "Dishonesty Problems",
          "Outside Activities Problems",
        ],
      },
      {
        name: "Contracts of Employment",
        types: [
          "Full-time contract",
          "Part-time contract",
          "Zero-hour contract",
          "Casual contract",
          "Freelance contract",
          "Union contract",
          "Executive contract",
          "Fixed-term contract",
          "At-will agreement",
          "Non-compete and confidentiality contracts",
        ],
      },
      {
        name: "Managing Apprentices",
        types: [
          "Trade apprentice",
          "Graduate apprentice",
          "Technician apprentice",
          "Technician (vocational) apprentice",
          "Optional trade apprentice",
        ],
      },
      {
        name: "Calculating Settlement Agreements",
        types: ["Termination payment calculation", "Settlement agreement"],
        referenceLink: "Here",
      },
    ],
    "Professional Wing": [
      {
        name: "Chartered Accountancy",
        types: [
          {
            category: "Provide Financial Advisory Services",
            types: ["Accountancy", "Auditing"],
          },
          {
            category: "Direct Taxation",
            types: [
              "Income Tax",
              "Corporate Tax",
              "Securities Transaction Tax",
              "Capital Gains Tax",
              "Gift Tax",
              "Wealth Tax",
            ],
          },
          {
            category: "Indirect Taxation",
            types: [
              "Sales Tax",
              "Service Tax",
              "Octroi Duty",
              "Custom Duty",
              "Value Added Tax (VAT)",
              "Goods & Services Tax (GST)",
            ],
          },
          {
            category: "Other Taxes",
            types: ["Property Tax", "Registration Fees", "Professional Tax"],
          },
          {
            category: "IEC CODE",
            types: ["Import Export Code Registration"],
          },
        ],
      },
      {
        name: "Company Secretary",
        types: [
          {
            category: "The Indian Contract Act, 1872",
            types: [
              "Executed contract",
              "Executory contract",
              "Unilateral contract",
              "Bilateral contract",
            ],
          },
          {
            category: "The Sale of Goods Act, 1930",
            types: ["Existing Goods", "Future Goods", "Contingent Goods"],
          },
          {
            category: "The Indian Partnership Act, 1932",
            types: [
              "Active/Managing Partner",
              "Sleeping Partner",
              "Nominal Partner",
              "Partner by Estoppel",
              "Partner in Profits only",
              "Minor Partner",
              "Secret Partner",
              "Outgoing partner",
              "Limited partner",
              "Sub-Partner",
            ],
          },
          {
            category: "The Limited Liability Partnership Act, 2008",
            types: ["Start-up LLP", "LLP incorporation document"],
          },
          {
            category: "Companies Act, 2013",
            types: [
              "One Person Company",
              "Private Limited Company",
              "Public Limited Company",
              "Section 8 Company (NGO)",
              "MSME -Micro Companies",
              "MSME-Small Companies",
              "MSME-Medium Companies",
              "Limited By Shares",
              "Limited by Guarantee",
              "Unlimited Company",
              "Holding Company",
              "Subsidiary Company",
              "Listed Company",
              "Unlisted Company",
            ],
          },
        ],
      },
    ],
  };

  function getTypesByWingAndTopic(wing) {
    // Default to Export Wing if wing is not specified
    if (!wing) {
      wing = "Export Wing";
    }
    return listOfTypeQuery[wing] || [];
  }
  const typesWithTopic = getTypesByWingAndTopic(wing);

  useEffect(() => {
    loadCaptchaEnginge(captcha_length);
  }, []);

  const handleTypeChange = (event) => {
    setFormData({ ...formData, query: event.target.value, topic: "" });
  };

  const handleTopicChange = (event) => {
    setFormData({ ...formData, topic: event.target.value });
  };

  const handleSummaryChange = (event) => {
    setFormData({ ...formData, summary: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, refDoc: file });
  };

  const handleViewFile = () => {
    const fileURL = URL.createObjectURL(formData.refDoc);
    window.open(fileURL);
  };
  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <form className="card mt-3" onSubmit={handleSubmitForm}>
          <div className="card-header pt-3">
            <div className="d-flex justify-content-between">
              <h5 className="card-title mb-2">{title}</h5>
              <div>
                <Badge pill bg="success">
                  Online
                </Badge>
              </div>
            </div>
            <p className="card-text">
              To schedule an online consultation, pose inquiries, or arrange a
              meeting with our expert, please provide the necessary information
              below:
            </p>
            <Button className="" onClick={() => navigate("/export-wing-token")}>
              Track Queries
            </Button>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3 gap-5">
              <div className="w-50">
                <label htmlFor="query" className="form-label">
                  <h5>Type of Query</h5>
                </label>
                <select
                  id="query"
                  className="form-select"
                  onChange={handleTypeChange}
                  value={formData.query || ""}
                  required
                >
                  <option value="">Select Type</option>
                  {typesWithTopic.map((type, index) => (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-50">
                <label htmlFor="topic" className="form-label">
                  <h5>Type of Topic</h5>
                </label>
                <select
                  id="topic"
                  className="form-select"
                  onChange={handleTopicChange}
                  value={formData.topic || ""}
                  disabled={!formData.query}
                  required
                >
                  <option value="">Select Topic</option>
                  {formData.query &&
                    typesWithTopic
                      .find((type) => type.name === formData.query)
                      .types.map((subType, subIndex) => (
                        <option key={subIndex} value={subType}>
                          {subType}
                        </option>
                      ))}
                </select>
              </div>
            </div>

            <h5 className="mb-4 text-secondary">Brief of Summary </h5>
            <textarea
              className="form-control border-2 mb-4"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Max Characters 1000.."
              value={formData.summary || ""}
              onChange={handleSummaryChange}
              maxLength={1000}
              required
            ></textarea>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <span className="w-50">Reference Document (if any)</span>
              <Row className="w-50">
                <Col
                  xs={formData.refDoc ? 10 : 12}
                  className="d-flex align-items-center"
                >
                  <Button
                    variant="info"
                    onClick={() => document.getElementById("fileInput").click()}
                    disabled={!!formData.refDoc}
                    className="w-100"
                  >
                    UPLOAD
                  </Button>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Col>
                {formData.refDoc && (
                  <Col
                    xs={2}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button variant="link" onClick={handleViewFile}>
                      <BsEye className="icon-size" />
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <LoadCanvasTemplate />
                {isCaptchaCorrect && (
                  <AiFillCheckCircle className="icon-size primary-color" />
                )}{" "}
              </div>

              <div className="w-50">
                <input
                  className="w-100 p-1 border-1"
                  placeholder="Type the given captcha word"
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                  maxLength={captcha_length}
                  onChange={handleCaptchaChange}
                  value={captchaValue}
                />
                {isCaptchaCorrect ? (
                  <p className="text-success">CAPTCHA is valid</p>
                ) : (
                  <p className="text-danger">CAPTCHA is invalid</p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center gap-4 ">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
                required
              />
              <span className="w-75">
                I, hereby duly consent and acknowledge that all details filled
                herein are true, correct and any misrepresentation of facts,
                documents, or any other relevant information may deem result in
                disapproval of our Query.
              </span>
            </div>
            <hr />
            <Button
              type="submit"
              variant="success"
              className="float-end w-25"
              disabled={wingApiFlag}
            >
              {wingApiFlag ? "Processing..." : "SUBMIT"}
            </Button>
          </div>
        </form>
      </Layout2>
    </Layout>
  );
};

export default WingForm;
