import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Toast, ToastContainer } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router";
import B2bRoomWaterMark from '../../../src/assets/b2bImages/B2bRoomWaterMark.png';
import { getURLbyEndPointV2 } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";

const Room = () => {
    const { roomId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [isJoining, setIsJoining] = useState(false);
    const apiCallInProgress = useRef(false);
    const [showWarningToast, setShowWarningToast] = useState(false);
    const timerInterval = useRef(null);

    const { slotId, country, time, startTime, endTime, duration, date, companyName } = location.state || {};
    const [remainingTime, setRemainingTime] = useState(null);
    const [timerStarted, setTimerStarted] = useState(false);
    const zegoInstance = useRef(null);



    // Function to parse query parameters
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const username = queryParams.get("username");


    const calculateTimeRemaining = () => {
        // Parse the slot times
        const [startTimeOnly, startMeridian] = startTime.split(/(?=[AP]M)/);
        const [endTimeOnly, endMeridian] = endTime.split(/(?=[AP]M)/);
        const [startHours, startMinutes] = startTimeOnly.split(':').map(num => parseInt(num));
        const [endHours, endMinutes] = endTimeOnly.split(':').map(num => parseInt(num));

        // Convert to 24-hour format
        let start24Hour = startHours;
        if (startMeridian === 'PM' && startHours !== 12) start24Hour += 12;
        else if (startMeridian === 'AM' && startHours === 12) start24Hour = 0;

        let end24Hour = endHours;
        if (endMeridian === 'PM' && endHours !== 12) end24Hour += 12;
        else if (endMeridian === 'AM' && endHours === 12) end24Hour = 0;

        // Create Date objects for start and end times
        const slotDate = new Date(date);
        const startDateTime = new Date(slotDate);
        startDateTime.setHours(start24Hour, startMinutes, 0, 0);

        const endDateTime = new Date(slotDate);
        endDateTime.setHours(end24Hour, endMinutes, 0, 0);

        // Get current time in IST
        const istOffset = 5.5 * 60 * 60 * 1000;
        const now = new Date();
        const istTime = new Date(now.getTime() + (now.getTimezoneOffset() * 60000) + istOffset);

        // Calculate remaining time
        if (istTime < startDateTime) {
            // Meeting hasn't started yet
            return {
                remaining: Math.floor((endDateTime - startDateTime) / 1000),
                shouldStart: false
            };
        } else if (istTime > endDateTime) {
            // Meeting has ended
            return {
                remaining: 0,
                shouldStart: false
            };
        } else {
            // Meeting is ongoing
            return {
                remaining: Math.floor((endDateTime - istTime) / 1000),
                shouldStart: true
            };
        }
    };


    const showFiveMinuteWarning = () => {
        setShowWarningToast(true);
        // Keep the toast visible for 30 seconds
        setTimeout(() => {
            setShowWarningToast(false);
        }, 30000); // 30 seconds
    };


    const meetingData = {
        slotId: slotId,
        country: country,
        time: time,
        date: date,
        userName: username,
        companyName: getAuthUserlocal().companyName,
    };

    // const endMeeting = async () => {
    //     if (zegoInstance.current) {
    //         try {
    //             await zegoInstance.current.leaveRoom(); // Leave the room and stop camera/mic
    //             console.log("Successfully left the room.");
    //         } catch (error) {
    //             console.error("Error while leaving the room:", error);
    //         }
    //     }

    //     // Once the room is left, redirect to the desired page
    //     alert("The meeting has ended as scheduled.");

    //     navigate('/b2bSummery', { state: meetingData });
    // };


    const startTimer = () => {
        if (timerInterval.current) return;

        timerInterval.current = setInterval(() => {
            const timeInfo = calculateTimeRemaining();
            setRemainingTime(timeInfo.remaining);

            // Show warning toast 5 minutes before end
            if (Math.floor(timeInfo.remaining) === 300) {
                // showFiveMinuteWarning();
            }

            // End meeting when time is up
            if (timeInfo.remaining <= 0) {
                clearInterval(timerInterval.current);
                // endMeeting(); // Automatically end the meeting and leave the room
            }
        }, 1000);
    };


    useEffect(() => {
        // Initial time calculation
        const timeInfo = calculateTimeRemaining();
        setRemainingTime(timeInfo.remaining);

        if (timeInfo.shouldStart) {
            setTimerStarted(true);
            startTimer();  // Meeting is already ongoing
        } else if (timeInfo.remaining > 0) {
            // Check every second if the meeting should start
            const checkStartTime = setInterval(() => {
                const currentTimeInfo = calculateTimeRemaining();
                if (currentTimeInfo.shouldStart) {
                    setTimerStarted(true);
                    startTimer();
                    clearInterval(checkStartTime);  // Clear once meeting starts
                }
            }, 1000);

            // Cleanup interval when component unmounts or timer starts
            return () => clearInterval(checkStartTime);
        }

        // Cleanup on unmount (stops the active meeting timer)
        return () => {
            if (timerInterval.current) {
                clearInterval(timerInterval.current);
            }
        };
    }, []);




    const meetingContainer = useRef(null);
    const [showDetails, setShowDetails] = useState(true);

    const checkApiBeforeJoin = async () => {
        if (apiCallInProgress.current) {
            return false;
        }

        apiCallInProgress.current = true;

        try {
            const response = await axios.post(
                getURLbyEndPointV2("checkSlotBeforeJoin") + slotId,
                {
                    country,
                    date,
                    time,
                    startTime,
                    endTime,
                    duration
                }
            );

            if (response.data.success) {
                return true;
            } else {
                navigate('/aecci-virtual-b2b-forum');
                alert(response.data.message);
                return false;
            }
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert('Unable to connect to the server. Please try again later.');
            }
            console.error('Error checking API:', error);
            navigate('/aecci-virtual-b2b-forum');
            return false;
        } finally {
            apiCallInProgress.current = false;
        }
    };

    const myMeeting = async (element) => {
        const appID = 1429883440;
        const serverSecret = "5923e11cc03455e3dcefd5b55be22b3c";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            appID,
            serverSecret,
            roomId,
            Date.now().toString(),
            username
        );

        const zp = ZegoUIKitPrebuilt.create(kitToken);

        // Override the original joinRoom method
        const originalJoinRoom = zp.joinRoom.bind(zp);
        zp.joinRoom = async (params) => {
            if (isJoining) return; // Prevent multiple joins
            setIsJoining(true);

            try {
                // const canJoin = await checkApiBeforeJoin();
                // if (!canJoin) {
                //     setIsJoining(false);
                //     return;
                // }

                // If check passes, proceed with original join
                await originalJoinRoom({
                    ...params,
                    onJoinRoom: () => {
                        setShowDetails(false);
                        startCountdownTimer(zp);
                        if (params.onJoinRoom) params.onJoinRoom();
                    }
                });
            } catch (error) {
                console.error('Error during join process:', error);
                setIsJoining(false);
            }
        };

        // Call joinRoom with your configuration
        zp.joinRoom({
            container: element,
            scenario: {
                mode: ZegoUIKitPrebuilt.GroupCall,
                config: {
                    maxUsers: 2,
                },
            },
            showPreJoinView: true,
            preJoinViewConfig: {
                title: "AECCI Virtual B2B Forum 2024",
            },
            lowerLeftNotification: {
                showUserJoinAndLeave: true,
                showTextChat: true,
            },
            branding: {
                logoURL: "https://www.aecci.org.in/wp-content/uploads/2020/10/AECCI-24.png",
            },
            layout: "Grid",
            whiteboardConfig: {
                showAddImageButton: true,
                showCreateAndCloseButton: true,
            },
            showRoomDetailsButton: false,
            showRoomTimer: false,
            enableUserSearch: true,
            showMoreButton: true,
            onReturnToHomeScreenClicked: () => {
                navigate('/b2bSummery', { state: meetingData });
            },
        });
    };



    const formatTime = (seconds) => {
        if (seconds === null) return "--:--";
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    const startCountdownTimer = (zc) => {
        const interval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime === 300) { // When remaining time is 5 minutes (300 seconds)
                    // showFiveMinuteWarning();
                }

                if (prevTime <= 1) {
                    // endMeeting();
                    clearInterval(interval); // Clear the interval when the countdown ends
                    return 0; // Ensure the remaining time is set to 0
                }

                return prevTime - 1; // Decrement the remaining time by 1 second
            });
        }, 1000);
    };




    useEffect(() => {
        if (meetingContainer.current) {
            myMeeting(meetingContainer.current);
        }
    }, [meetingContainer]);




    return (
        <div>
            {/* Timer in top left corner */}
            {/* <div className="position-fixed top-0 mt-2 start-0 end-0 z-2">
                <div className="d-flex justify-content-center">
                    <div
                        className="alert alert-info rounded-5 mb-0"
                        style={{
                            background: '#313443',
                            color: '#fff',
                            padding: '4px 12px',
                            fontSize: '12px',
                            borderRadius: '25px',
                            border: 'none',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            margin: 0,
                            display: 'inline-block',
                            minWidth: 'auto'
                        }}
                    >
                        Remaining Time: {formatTime(remainingTime)}
                    </div>
                </div>
            </div> */}


            <ToastContainer position="top-end" className="p-3" style={{ zIndex: 3 }}>
                <Toast
                    show={showWarningToast}
                    onClose={() => setShowWarningToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header closeButton={false}>
                        <strong className="me-auto">Meeting Ending Soon</strong>
                    </Toast.Header>
                    <Toast.Body>
                        This meeting will end in 5 minutes. Please wrap up your discussion and save any important information.
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {/* Conditionally render the details section */}
            {/* {showDetails && (
                <div className="mx-auto">
                    <div className="d-flex position-fixed justify-content-center my-3" style={{ left: 0, right: 0 }}>
                        <div className="d-flex align-items-center flex-column z-2">
                            <img
                                src={b2bVirtualForum}
                                className="img-fluid mb-2"
                                alt="AECCI Virtual B2B Forum 2024"
                                style={{ width: "120px", height: "auto", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
                            />
                            <div className="text-center fs-2 text-primary fw-bold">
                                AECCI Virtual B2B Forum 2024
                            </div>
                        </div>
                    </div>
                </div>
            )} */}

            {/* Video call UI */}
            <div
                ref={meetingContainer}
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${B2bRoomWaterMark})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        </div>
    );
};

export default Room;
