import React from "react";
import SideBar from "../../Sidebar/SideBar";
import WelcomeBanner from "../../basic/WelcomeBanner";
import SideBar1 from "../../Sidebar/SideBar1";

const Layout2 = ({ children }) => {
  return (
    <>
      <WelcomeBanner />
      <div className="d-flex justify-content-center align-items-start">
        <div className="py-1 align-items-center main-width">
          <hr />
          <div className="d-flex gap-2 align-items-start py-4">
            {/* First div */}
            <div style={{ height: "auto" }}>
              <SideBar />
            </div>
            {/* Second div */}
            <div className="d-flex flex-column">
              <main
                className="Layout2-width flex-grow-1"
                style={{ minHeight: "70vh" }}
              >
                {children}
              </main>{" "}
            </div>
            {/* <div style={{ height: "auto" }}>
              <SideBar1 />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout2;
