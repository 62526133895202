import React, { useState, useEffect } from "react";
import { getAuthUserlocal } from "../../store/services";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { Table, Pagination } from "react-bootstrap";
import { formatDateTime } from "../basic/SimpleFunctions";
import { PopUpModalButton } from "../basic/ModalComponent";

const EventTrack = () => {
    const [eventTrack, setEventTrack] = useState([]);



    const fetchTrackData = async () => {
        try {
          const userId = getAuthUserlocal()._id;
    
          const response = await axios.get(
            getURLbyEndPoint("getRegisterEvent") + userId
          );
          if (response.status === 200) {
            const data = response.data;
            setEventTrack([...data]);
          } else {
          }
        } catch (error) {
          console.error("Error fetching Event data:", error);
        }
      };

  useEffect(() => {
    fetchTrackData();
  }, []);



  //filtering here
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = eventTrack.filter((data) =>
    searchTerm !== ""
      ? data.eventType &&
      data.eventType.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  //pagination is here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 2); // Adjusted to show 5 pages
    let endPage = Math.min(totalPages, currentPage + 2); // Adjusted to show 5 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(5, totalPages); // Adjusted to show 5 pages
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 4, 1); // Adjusted to show 5 pages
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };




  return (
    <div>
    <Table responsive striped bordered hover w-auto>
        <thead>
          <tr>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              Sr
              <br /> No.
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
             Perticular
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
            Details
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
              Document
            </th>
            <th
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
              className="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
      <>
        <tbody>
          {currentFiltered.length > 0 ? (
            <>
              {currentFiltered.map((data, index) => {
                return (
                <tr>
                    <td className="pb-0">{index + 1}.</td>
                    <td className="pb-0">{data.title}.<br/>{data.heading}</td>
                    <td className="pb-0">{data.heading}.</td>
                    <td className="pb-0">
                       App No: {data.appNo}<br/>
                        {/* {data.registeredDate}<br/> */}
                        Total Amt: {data.totalAmt}/-<br/>
                        Participate: {data.numberOfParticipant}<br/>
                        <div className="my-2">
                        <PopUpModalButton
                        title="Receipt"
                        fileLink={data.receipt}
                        disabled={
                          data.isApproved === "Processing" ||
                          data.isApproved === "Rejected"
                        }
                        />
                        </div>

                        </td>
                        <td className={`pb-0 text-center ${
  data.isApproved === "Processing" ? "text-primary" :
  data.isApproved === "Rejected" ? "text-danger" :
  data.isApproved === "Approved" ? "text-success" :
  ""
}`}>
  {data.isApproved}.<br/>
  <span className="text-dark text-center">{data.remark}</span>
</td>
                </tr>
                );
              })}
            </>
          ) : (
          <tr>
            <td colSpan={6} className="text-center">
              No Data Found
            </td>
          </tr>
          )}
        </tbody>
      </>
       
        </Table>
        {/* //pagination------------------------------------
            ------------------------------------------------ */}
  {
    totalPages > 1 && (
      <div className="d-flex justify-content-start">
        <Pagination>
          <Pagination.Prev
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          />
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <Pagination.Item
              key={index + startPage}
              active={index + startPage === currentPage}
              onClick={() => handlePageChange(index + startPage)}
            >
              {index + startPage}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    )
  }
    </div >
  );
};

export default EventTrack