export const menuItemsData = [
  {
    title: "About AECCI",
    url: "https://www.aecci.org.in/about/",
    submenu: [
      {
        title: "About Chamber",
        url: "https://www.aecci.org.in/about/about-chamber/",
      },
      {
        title: "Our History",
        url: "https://www.aecci.org.in/about/our-history/",
      },
      {
        title: "Chairman Message",
        url: "https://www.aecci.org.in/about/chairman-message/",
      },
      {
        title: "Chamber Policy",
        url: "https://www.aecci.org.in/about/chamber-policy/",
      },
      {
        title: "Office Bearers",
        url: "https://www.aecci.org.in/office-bearers-3/",
      },
      {
        title: "Roles & Responsibility",
        url: "https://www.aecci.org.in/about/roles-responsibility/",
      },
      {
        title: "Strategic Partners",
        url: "https://www.aecci.org.in/strategic-partners/",
      },
      {
        title: "Chamber Dynamics",
        url: "https://www.aecci.org.in/about/chamber-dynamics/",
      },
      {
        title: "Job Opportunities",
        url: "https://www.aecci.org.in/about/jobs-opportunities/",
      },
    ],
  },
  {
    title: "Services",
    url: "/https://www.aecci.org.in/our-services/",
    submenu: [
      {
        title: "The Wings",
        url: "https://www.aecci.org.in/our-services/the-wings/",
        submenu: [
          {
            title: "Exports Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/exports-wing/",
          },
          {
            title: "Legal Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/legal-wing/",
          },
          {
            title: "HR Support Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/hr-support-wing/",
          },
          {
            title: "Professional Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/professional-wing/",
          },
          {
            title: "Business Advice Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/business-advice-wing/",
          },
          {
            title: "Women Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/women-wing/",
          },
          {
            title: "Events and Seminars Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/event-and-seminar-wing/",
          },
        ],
      },

      {
        title: "Entrepreneur Hub",
        url: "https://www.aecci.org.in/our-services/entrepreneur-hub/",
        submenu: [
          {
            title: "Startup India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/startup-india/",
          },
          {
            title: "Investment India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/investment-india/",
          },
          {
            title: "Make in India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/make-in-india/",
          },
        ],
      },
      {
        title: "B2B Connect",
        url: "https://www.aecci.org.in/our-services/b2b-connect/",
        submenu: [
          {
            title: "consultations",
            url: "https://www.aecci.org.in/our-services/b2b-connect/consultations/",
          },
          {
            title: "Business Matches",
            url: "https://www.aecci.org.in/our-services/b2b-connect/business-matches/",
          },
          {
            title: "Market Studies",
            url: "https://www.aecci.org.in/our-services/b2b-connect/market-studies/",
          },
        ],
      },
      {
        title: "Membership",
        url: "https://www.aecci.org.in/our-services/membership/",
        submenu: [
          {
            title: "Membership & its Benefits",
            url: "https://www.aecci.org.in/membership-and-its-benefits/",
          },
          {
            title: "Fees, Forms & Guidelines",
            url: "https://www.aecci.org.in/our-services/membership/guidelines-and-form/",
          },
          {
            title: "Enrollment Offers",
            url: "https://www.aecci.org.in/our-services/membership/enrollment-offers/",
          },
          {
            title: "Visa Recommendations Letter",
            url: "https://www.aecci.org.in/our-services/membership/visa-recommendations-letter/",
          },
        ],
      },
      {
        title: "AECCI Affiliate Program",
        url: "https://www.aecci.org.in/aecci-affiliate-program/",
        submenu: [
          {
            title: "Meet Our Wings Experts",
            url: "https://www.aecci.org.in/meet-our-wings-experts/",
          },
          {
            title: "Join Our Affiliate Network",
            url: "https://www.aecci.org.in/join-our-affiliate-network/",
          },
        ],
      },
    ]
  },




  {
    title: "AECCI-IAC Center",
    url: "https://www.aecci.org.in/aecci-arbitrationcenter/",
    submenu: [
      {
        title: "Why AECCI-IAC?",
        url: "https://www.aecci.org.in/why-aecci/",
      },
      {
        title: "Schedule Fees",
        url: "https://www.aecci.org.in/schedule-fees/",
      },
      {
        title: "Rules and Policies",
        url: "https://www.aecci.org.in/rules-and-policies-2/",
      },
      {
        title: "AECCI-IAC Model Clause",
        url: "https://www.aecci.org.in/aecci-iac-model-clause/",
      },
      {
        title: "AECCI - IAC FAQ",
        url: "https://www.aecci.org.in/aecci-iac-faq/",
      },
      {
        title: "Panel Of Arbitrators",
        url: "https://www.aecci.org.in/aecci-iac-panel/",
      },

    ]
  },


  {
    title: "Ways & Means",
    url: "https://www.aecci.org.in/ways-means/",
    submenu: [
      {
        title: "Research and Information",
        url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/",
        submenu: [
          {
            title: "The Economic Analysis",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/the-economic-analysis/"
          },
          {
            title: "Business Opportunities Brief",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/business-opportunities-brief/"
          },
          {
            title: "Indian Economic Report",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/indian-economic-report/"
          },
          {
            title: "Operational Guides",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/operational-guides/"
          },

        ]
      },
      {
        title: "India Innovation Index",
        url: "https://www.aecci.org.in/ways-means/india-innovation-index/",
      },
      {
        title: "Annual report",
        url: "https://www.aecci.org.in/ways-means/aecci-annual-report/",
      },
      {
        title: "Commercial Directory",
        url: "https://www.aecci.org.in/ways-means/commercial-directory/",
      },
      {
        title: "Export Promotion Council",
        url: "https://www.aecci.org.in/ways-means/export-promotion-council/",
      },
    ]
  },












  {
    title: "Media",
    url: "https://www.aecci.org.in/media/",
    submenu: [
      {
        title: "e-Newsletters",
        url: "https://www.aecci.org.in/media/e-newsletters/",
     
      },
      {
        title: "Media Center",
        url: "https://www.aecci.org.in/media/media-center/"
      },
      {
        title: "Daily Viewpoints",
        url: "https://www.aecci.org.in/aecci-viewpoints/"
      },
      {
        title: "Gallery",
        url: "https://www.aecci.org.in/media/gallery/"
      },
      {
        title: "Publications",
        url: "https://www.aecci.org.in/media/publications/"
      },
    ]
  },







  {
    title: "Events",
    url: "https://www.aecci.org.in/events/",
    submenu: [
      {
        title: "Upcoming Events",
        url: "https://www.aecci.org.in/upcoming-events/",
     
      },
      {
        title: "Past Events",
        url: "https://www.aecci.org.in/events/past-events/"
      },
      {
        title: "Sponsorships",
        url: "https://www.aecci.org.in/events/sponsorship-2/"
      },
      {
        title: "Advertise With Us",
        url: "https://www.aecci.org.in/advertise-with-us/"
      },
      {
        title: "International collaborations",
        url: "https://www.aecci.org.in/international-collaboration/"
      },
      {
        title: "World Conference Information",
        url: "https://www.aecci.org.in/world-conference/"
      },
    ]
  },



  {
    title: "e-Platform",
    url: "https://www.aecci.org.in/e-platform/",
    submenu: [
      {
        title: "e-Platform  Information",
        url: "https://www.aecci.org.in/e-platform/e-platform-information/",
     
      },
      {
        title: "Members Login",
        url: "https://e-platform.aecci.org.in/login"
      },
      {
        title: "Request Trial Version",
        url: "/request-trial-version"
      },
      {
        title: "Formalities & Guidelines",
        url: "https://www.aecci.org.in/e-platform/formalities-guidelines/"
      },
      {
        title: "e-co verification",
        url: "https://e-platform.aecci.org.in/e-co-verification"
      },
    ]
  },


  {
    title: "AECCI-TAC",
    url: "https://www.aecci.org.in/trade-assistant-centre/",
    submenu: [
      {
        title: "About TAC",
        url: "https://www.aecci.org.in/aecci-trade-assistance-center/about-tac/",
     
      },
      {
        title: "TAC Operational Guide",
        url: "https://www.aecci.org.in/tac-process/"
      },
      {
        title: "TAC Location",
        url: "https://www.aecci.org.in/trade-assistant-centre/tac-location2/tac-location/"
      },
    ]
  },



  {
    title: "Contact Us",
    url: "https://www.aecci.org.in/contact-us/",
    submenu: [
      {
        title: "AECCI Head Office",
        url: "https://www.aecci.org.in/contact-us/aecci-head-office/",
     
      },
      {
        title: "AECCI International Hub",
        url: "https://www.aecci.org.in/contact-us/aecci-international-hub/"
      },
    ]
  },



]; 


