import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";

const BookCarousel = () => {
 const [allPublications, setAllPublications] = useState([]);

 useEffect(() => {
    const fetchAllPublications = async () => {
      try {
        const response = await axios.get(getURLbyEndPoint("getPublication"));
        const publications = response.data;
        setAllPublications(publications);
      } catch (error) {
        console.error("Failed to fetch all publications:", error);
      }
    };

    fetchAllPublications();
 }, []);

 return (
    <div>
      <Marquee>
        {allPublications.map((publication, index) => (
          <Card key={index} style={{ width: "7rem", marginRight: "4rem" }}>
            <Card.Img variant="top" src={publication.
bannerImg} />
          </Card>
        ))}
      </Marquee>
    </div>
 );
};

export default BookCarousel;
