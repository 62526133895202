import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { getAuthUserlocal } from "../../store/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoImg from "../../assets/infoimg.png";
import AccordionSection from "../../components/basic/Accordion";
import { emailRegex, mobileNumberRegex } from "../../components/basic/Regex";

const userId = getAuthUserlocal() ? getAuthUserlocal()._id : null;

const CommercialDirectory = () => {
  const [ComDFormData, setComDFormData] = useState({
    companyLogo: null,
    companyName: "",
    ownersName: "",
    email: "",
    establishmentYear: "",
    companyAdd: "",
    mobileNo: "",
    companyProduct: "",
    companyActivity: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setComDFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //for company logo
  // =================
  // =================

  const handleLogoChange = (e) => {
    const logoFile = e.target.files[0]; // Get the selected file
    setComDFormData((prevFormData) => ({
      ...prevFormData,
      companyLogo: logoFile, // Set companyLogo to the selected file
    }));
  };

  const handleComDformsubmission = async (e) => {
    e.preventDefault();

    const apiFormData = new FormData();

    Object.entries(ComDFormData).forEach(([key, value]) => {
      // Convert establishmentYear and mobileNo to numbers
      apiFormData.append(
        key,
        key === "establishmentYear" || key === "mobileNo"
          ? Number(value)
          : value
      );
    });

    const validationRulesProceedPayment = {
      companyLogo: {
        message: "Please Upload a valid Company Logo (image).",
        isValid: () =>
          ComDFormData.companyLogo !== null &&
          ComDFormData.companyLogo instanceof File,
      },
      companyName: {
        message: "Please enter a valid Company Name (max 80 characters).",
        isValid: () => {
          const name = ComDFormData.companyName.trim();
          return name !== "" && name.length <= 80;
        },
      },
      ownersName: {
        message: "Please enter a valid owner Name (max 40 characters).",
        isValid: () => {
          const name = ComDFormData.ownersName.trim();
          return name !== "" && name.length <= 40;
        },
      },
      email: {
        message: "Please enter a valid email address.",
        isValid: () => {
          const email = ComDFormData.email.trim();
          return email !== "" && emailRegex.test(email);
        },
      },
      establishmentYear: {
        message: "Please enter a valid 4-digit establishment year.",
        isValid: () => {
          const year = ComDFormData.establishmentYear.trim();
          return (
            !isNaN(year) && Number.isInteger(Number(year)) && year.length === 4
          );
        },
      },

      companyAdd: {
        message: "Please enter a valid company address.",
        isValid: () => {
          const address = ComDFormData.companyAdd.trim();
          return address !== "";
        },
      },
      companyAdd: {
        message: "Please enter a valid company address.",
        isValid: () => {
          const address = ComDFormData.companyAdd.trim();
          return address !== "";
        },
      },
      mobileNo: {
        message: "Please enter a valid mobile number.",
        isValid: () => {
          const phoneNumber = ComDFormData.mobileNo;
          return mobileNumberRegex.test(phoneNumber);
        },
      },
      companyProduct: {
        message: "Please enter a valid company product.",
        isValid: () => {
          const comProd = ComDFormData.companyProduct.trim();
          return comProd !== "";
        },
      },
      companyActivity: {
        message: "Please enter a valid company activity.",
        isValid: () => {
          const comAct = ComDFormData.companyActivity.trim();
          return comAct !== "";
        },
      },
    };

    for (const field in validationRulesProceedPayment) {
      const { message, isValid } = validationRulesProceedPayment[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    try {
    const res = await axios.post(
        getURLbyEndPoint("commercialDir") + userId,
        apiFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        toast.success(res.data.message, { autoClose: 20000 });
      } else {
        toast.error("something went Wrong", { autoClose: 20000 });
      }
    } catch (error) {
      console.error(error);
      toast.error("Internal server error");
    }
  };

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="d-flex flex-column dashboardBlock">
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">COMMERCIAL DIRECTORY</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Complete the form below to have your company featured in
                      our commercial directory. This directory is frequented by
                      global exporters, importers, entrepreneurs, and
                      professionals. Enhance your business prospects by
                      connecting with a vast network of potential partners.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
            </div>
            <hr className="mt-1 mb-4" />
            {getAuthUserlocal().selectMembership === "Digital User" ? (
              <AccordionSection />
            ) : (
              <form onSubmit={handleComDformsubmission}>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY LOGO</label>
                  <div className="input-group " style={{ width: "60%" }}>
                    <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile02"
                      accept="image/*"
                      onChange={handleLogoChange}
                      required
                    />
                  </div>
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY NAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.companyName}
                    maxLength={80}
                    name="companyName"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">OWNER’S NAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.ownersName}
                    name="ownersName"
                    type="text"
                    maxLength={40}
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">EMAIL-ID</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.email}
                    name="email"
                    type="email"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">
                    YEAR OF ESTABLISHMENT
                  </label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (/^\d*$/.test(inputValue) || inputValue === "") {
                        if (
                          (inputValue.length === 0 || inputValue[0] !== "0") &&
                          inputValue.length <= 4
                        ) {
                          handleInputChange({
                            target: {
                              name: "establishmentYear",
                              value: inputValue,
                            },
                          });
                        }
                      }
                    }}
                    value={ComDFormData.establishmentYear}
                    name="establishmentYear"
                    type="text"
                    pattern="\d{4}"
                    title="Please enter a 4-digit year."
                    required
                  />
                </div>

                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY ADDRESS</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.companyAdd}
                    name="companyAdd"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">MOBILE NO</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (/^\d*$/.test(inputValue) || inputValue === "") {
                        if (
                          (inputValue.length === 0 || inputValue[0] !== "0") &&
                          inputValue.length <= 10
                        ) {
                          handleInputChange({
                            target: {
                              name: "mobileNo",
                              value: inputValue,
                            },
                          });
                        }
                      }
                    }}
                    value={ComDFormData.mobileNo}
                    name="mobileNo"
                    pattern="\d{10}"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY PRODUCTS</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.companyProduct}
                    name="companyProduct"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COMPANY ACTIVITY</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={ComDFormData.companyActivity}
                    name="companyActivity"
                    type="text"
                    required
                  />
                </div>
                <div className=" d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 p-2 w-25"
                    style={{ backgroundColor: "#0b3b5d", border: "0" }}

                    // className="bg-color mt-4 p-2 w-25 border-none"
                  >
                    UPDATE
                  </button>
                </div>
              </form>
            )}
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default CommercialDirectory;