import React, { useEffect, useState } from "react";
import {
  countriesList,
  nonStandDoc,
  nonStandDocForDigitalUser,
} from "../../store/AllListData";
import { getAuthUserlocal } from "../../store/services";
import Logo from "../../assets/footerLogo.png";
import axios from "axios";
import jsPDF from "jspdf";

import { FaPen } from "react-icons/fa";
import { BsEye } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";

import {
  Table,
  Form,
  Button,
  Modal,
  CloseButton,
  Card,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { numberToWords } from "../basic/numberToWords";
import { getURLbyEndPoint } from "../../store/api";
import SmallPopUp from "../basic/SmallPopUp.js";
import ModalComponent from "../basic/ModalComponent.jsx";

const priceControl = getAuthUserlocal()?.priceControl;

const NonStandDoc = () => {
  const priceControl = getAuthUserlocal()?.priceControl;
  const nonStandDocFees = [
    {
      "A. Commercial Document": getAuthUserlocal()?.priceControl?.A_ComDoc,
      "B. MCA Document": getAuthUserlocal()?.priceControl?.B_McaDoc,
      "C. Export Permission related Document":
        getAuthUserlocal()?.priceControl?.C_ExpDoc,
      "D. Pharma Reg. Document": getAuthUserlocal()?.priceControl?.D_PharmDoc,
      "E. Agro Chemical": getAuthUserlocal()?.priceControl?.E_AgroDoc,
      "F. Other document": getAuthUserlocal()?.priceControl?.F_OtherDoc,
      "Non Stand PC":
        getAuthUserlocal()?.selectMembership === "Digital User"
          ? 75
          : priceControl?.nonStandPc,

      //FOR DIGITAL USER-----------------------------------------
      "Export Permission related Document": 100,
      "Pharma Reg. Document": 100,
      "Agro Chemical": 100,
      "Other document": 100,
    },
    {
      "A. Commercial Document": "A_ComDoc",
      "B. MCA Document": "B_McaDoc",
      "C. Export Permission related Document": "C_ExpDoc",
      "D. Pharma Reg. Document": "D_PharmDoc",
      "E. Agro Chemical": "E_AgroDoc",
      "F. Other document": "F_OtherDoc",
      "Non Stand PC": "nonStandPc",
    },
  ];

  const docFormData = {
    docName: "",
    noOfPages: null,
    stampPages: [null],
    fees: null,
    docFile: null,
  };

  const initialFormData = {
    typeOfDoc: "",
    country: "",
    totalAmt: 0,
    reqLetterFlag: false,
    reqLetterFile: null,
    receipt: null,
    docs: [docFormData],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiSubmitNonStand, setApiSubmitNonStand] = useState(false);

  //resettting form data

  const handleResetFormData = () => {
    setFormData({
      typeOfDoc: "",
      country: "",
      totalAmt: 0,
      reqLetterFlag: false,
      reqLetterFile: null,
      receipt: null,
      docs: [
        {
          docName: "",
          noOfPages: null,
          stampPages: [null],
          fees: null,
          docFile: null,
        },
      ],
    });
  };

  //handle Input field
  const handleInputField = (selectedDocument, docIndex, updateField) => {
    setFormData((prevData) => {
      const updatedDocs = [...prevData.docs];
      updatedDocs[docIndex] = {
        ...updatedDocs[docIndex],
        [updateField]: selectedDocument,
      };
      return {
        ...prevData,
        docs: updatedDocs,
      };
    });
  };

  const handleDocumentSelect = (selectedDocument, docIndex) => {
    setFormData((prevFormData) => {
      const updatedDocs = [...prevFormData.docs];
      if (selectedDocument) {
        updatedDocs[docIndex].fees = nonStandDocFees[0][formData.typeOfDoc];
      } else {
        updatedDocs[docIndex].fees = null;
      }
      return { ...prevFormData, docs: updatedDocs };
    });
  };

  const handleInputFieldPageNo = (value, docIndex, pageNoIndex) => {
    setFormData((prevData) => {
      const updatedDocs = [...prevData.docs];
      const stampPages = updatedDocs[docIndex].stampPages.slice();
      stampPages[pageNoIndex] = value; // Update the value at pageNoIndex
      updatedDocs[docIndex] = {
        ...updatedDocs[docIndex],
        stampPages: stampPages,
      };
      return {
        ...prevData,
        docs: updatedDocs,
      };
    });
  };

  //simple file saving
  const handleFileChange = (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          setFormData({ ...formData, [fileName]: file });
        } else {
          alert("File size exceeds 5MB. Please select a smaller file.");
        }
      } else {
        alert("Please select a PDF file.");
      }
    }
  };

  // Function to handle viewing a document
  const viewDocument = (documentId) => {
    const documentFile = formData[documentId];
    if (documentFile instanceof File || documentFile instanceof Blob) {
      const documentUrl = URL.createObjectURL(documentFile);
      window.open(documentUrl, "_blank");
    } else {
      console.log("Document not found or invalid");
    }
  };
  //handle file upload
  const handleFileUpload = (e, docIndex) => {
    const file = e.target.files[0];
    const updatedDocs = [...formData.docs];
    updatedDocs[docIndex] = {
      ...updatedDocs[docIndex],
      docFile: file,
    };
    setFormData({
      ...formData,
      docs: updatedDocs,
    });
  };
  const editDocument = (documentId) => {
    document.getElementById(documentId).click();
  };

  // //actions functions--------------------------------
  // ---------------------------------------------------
  // ---------------------------------------------------
  const handlePenClick = (docIndex) => {
    const fileInput = document.getElementById(`docFile_${docIndex}`);
    fileInput.click();
  };

  const handleDelFile = (docIndex) => {
    const updatedDocs = [...formData.docs];
    updatedDocs.splice(docIndex, 1); // Remove the document at docIndex
    setFormData({
      ...formData,
      docs: updatedDocs,
    });
  };

  //calculating total amount
  useEffect(() => {
    const calculateTotalAmt = () => {
      let total = 0;

      //for documents
      {
        formData.docs.map((data) => {
          total +=
            nonStandDocFees[0][formData.typeOfDoc] * data.stampPages.length;
        });
      }

      //for per set
      total += priceControl?.nonStandPc * formData.docs.length;

      setFormData((prevFormData) => ({
        ...prevFormData,
        totalAmt: total,
      }));
    };

    calculateTotalAmt();
  }, [formData.totalAmt, formData.docs]);

  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    // pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    const startPosY = 20;
    pdf.setFontSize(20);
    pdf.text(
      "Asian Exporters' Chamber of Commerce And Industry",
      22,
      startPosY
    ); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      startPosY + 7
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, startPosY + 11);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, startPosY + 29);
    pdf.line(15, startPosY + 33, 195, startPosY + 33); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, startPosY + 37);
    pdf.text("Document No:", 15, startPosY + 42);
    pdf.text(
      `Received From: ${getAuthUserlocal().companyName}`,
      15,
      startPosY + 47
    );
    pdf.text("Address: ", 15, startPosY + 52);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, startPosY + 52 + index * addLineHeight);
    });

    //second line
    pdf.text("Date:", 130, startPosY + 37);
    pdf.text("Time:", 130, startPosY + 42);
    pdf.text(
      `AECCI Code: ${getAuthUserlocal().memberShipNo}`,
      130,
      startPosY + 47
    );

    // Table
    const headers = [
      "Sr no.",
      "Document Name",
      "Stamp Count",
      "Rate",
      "Amount",
    ];

    const data = [];
    formData.docs.forEach((item, index) => {
      data.push([
        `${index + 1}`,
        item.docName,
        item.stampPages.length.toString(),
        `Rs. ${nonStandDocFees[0][formData.typeOfDoc].toString()}/-`,
        `Rs. ${(
          item.stampPages.length * nonStandDocFees[0][formData.typeOfDoc]
        ).toString()}/-`,
      ]);
    });

    if (formData.reqLetterFlag) {
      data.push([
        `${formData.docs.length + 1}`,
        "Request Letter",
        "--",
        "--",
        "--",
      ]);
    }

    const startY = startPosY + 75; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [15, 80, 30, 30, 30]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    const headerLineYForHeader = startY - 5;
    headers.forEach((header, index) => {
      pdf.text(header, currentX + 2, startY);
      const rectWidth =
        index === 4 ? columnWidths[index] - 5 : columnWidths[index];
      pdf.rect(currentX, headerLineYForHeader, rectWidth, lineHeight, "S");
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const lineHeightMain = 13;
    let finalYPos = startY;
    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      let yPos = startY + lineHeightMain * (rowIndex + 1) - 3;
      finalYPos = yPos;
      row.forEach((cell, cellIndex) => {
        if (cellIndex === 2) {
          pdf.text(cell, currentX + 12, yPos);
        } else {
          pdf.text(cell, currentX + 2, yPos);
        }
        const rectWidth =
          cellIndex === 4
            ? columnWidths[cellIndex] - 5
            : columnWidths[cellIndex];
        pdf.rect(currentX, yPos - 5, rectWidth, lineHeightMain, "S");
        currentX += columnWidths[cellIndex];
      });
    });

    const yOffset = 8;
    pdf.text("Processing Fee:", 17, finalYPos + yOffset + 5);
    pdf.text(
      `Rs. ${formData.docs.length * priceControl?.nonStandPc}/-`,
      172,
      finalYPos + yOffset + 5
    );

    pdf.text("Total:", 17, finalYPos + yOffset + 15);
    pdf.text(`Rs. ${formData.totalAmt}/-`, 172, finalYPos + yOffset + 15);

    pdf.text(
      `Amount In words (In Rupees): ${numberToWords(formData.totalAmt)}`,
      17,
      finalYPos + yOffset + 25
    );

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      finalYPos + yOffset + 35,
      { align: "right" }
    );
    pdf.text("Authorised Signatory", 195, finalYPos + yOffset + 50, {
      align: "right",
    });

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      +finalYPos + yOffset + 45
    );

    //REXTANGLES ARE HERE
    pdf.rect(startX, finalYPos + yOffset, 180, lineHeight, "S");
    pdf.rect(startX, finalYPos + yOffset + 10, 180, lineHeight, "S");
    pdf.rect(startX, finalYPos + yOffset + 20, 180, lineHeight, "S");

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  // Function to check if all docFiles and stampPages are null
  const areAllFilesNull = () => {
    for (const doc of formData.docs) {
      if (
        doc.docFile !== null &&
        doc.stampPages.every((page) => page !== null)
      ) {
        return false;
      }
    }
    return true;
  };

  //submit non stand document
  const handleSubmitNonStand = async () => {
    await handleCreateReceipt();
    const validationRules = {
      typeOfDoc: {
        message: "Please Select Type of Document",
        isValid: () => {
          const typeOfDoc = formData.typeOfDoc;
          return typeOfDoc !== null && typeOfDoc.trim() !== "";
        },
      },

      country: {
        message: "Please Select Country",
        isValid: () => {
          const country = formData.country;
          return country !== null && country !== "";
        },
      },

      totalAmt: {
        message: "Please fill Some Information",
        isValid: () => {
          const comInvoice = formData.totalAmt;
          return comInvoice !== null && comInvoice !== 0;
        },
      },

      reqLetterFile: {
        message: "Please Upload request Letter",
        isValid: () => {
          const reqLetterFlag = formData.reqLetterFlag;
          if (reqLetterFlag) {
            const reqLetterFile = formData.reqLetterFile;
            return reqLetterFile !== null;
          }
          return true;
        },
      },

      receipt: {
        message: "Error in receipt",
        isValid: () => {
          const receipt = formData.receipt;
          return receipt !== null;
        },
      },
    };
    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }
    //validation for documents
    let validationForNonStand = false;
    {
      formData.docs.map((data, index) => {
        console.log("docs log is here", data.stampPages);
        const validationRulesForNonStand = {
          docName: {
            message: `Please Enter ${index + 1} document name`,
            isValid: () => {
              const docName = data.docName.trim();
              return docName !== "";
            },
          },

          noOfPages: {
            message: `Please Enter ${index + 1} document No. of page Contains`,
            isValid: () => {
              const noOfPages = data.noOfPages;
              return noOfPages !== null && noOfPages !== 0;
            },
          },

          stampPages: {
            message: `Please provide place of stamp for ${index + 1} documents`,
            isValid: () => {
              return data.stampPages.every(
                (page) => page !== null && page !== 0
              );
            },
          },
          //validation for page no. can not be more that legth
          stampPagesLength: {
            message: `Place of stamp and no. of stamp can not be more than document length for ${
              index + 1
            } documents`,
            isValid: () => {
              return (
                data.stampPages.length <= data.noOfPages &&
                data.stampPages.every((page) => page <= data.noOfPages)
              );
            },
          },

          fees: {
            message: `Please ${index + 1} Document Name`,
            isValid: () => {
              const fees = data.fees;
              return fees !== "" || fees !== null;
            },
          },
          docFile: {
            message: `Please Enter page ${
              index + 1
            } Origin Criteria field and should be 40 letters max.`,
            isValid: () => {
              const docFile = data.docFile;
              return docFile !== "" || docFile !== "";
            },
          },
        };

        // Check each validation rule
        for (const field in validationRulesForNonStand) {
          const { message, isValid } = validationRulesForNonStand[field];
          if (!isValid()) {
            alert(message);
            validationForNonStand = true;
            return;
          }
        }
      });
    }
    if (validationForNonStand) {
      return;
    }

    if (
      !getAuthUserlocal().specialControl?.accessList?.includes(
        "walletExceed"
      ) &&
      getAuthUserlocal().balance - formData.totalAmt < 500
    ) {
      alert("Low balance");
      return;
    }

    //api setup started
    const userId = getAuthUserlocal()._id;
    const formDataForApi = new FormData();

    formDataForApi.append("formData", JSON.stringify(formData));
    // Append reqLetterFile if reqLetterFlag is true
    if (formData.reqLetterFlag) {
      formDataForApi.append("reqLetterFile", formData.reqLetterFile);
    }

    //for receipt
    formDataForApi.append("receipt", formData.receipt);

    formData.docs.forEach((doc, index) => {
      const docKeyPrefix = `docs[${index}]`;
      formDataForApi.append(`${docKeyPrefix}[docFile]`, doc.docFile);
    });

    try {
      setApiSubmitNonStand(true);

      const res = await axios.post(
        getURLbyEndPoint("createNonStandard") + userId,
        formDataForApi
      );

      if (res.data.status) {
        alert(
          "Non-Standard created successfully, you can check in track your document"
        );
        setFormData({ ...initialFormData });
        window.location.reload();
        setApiSubmitNonStand(true);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      setApiSubmitNonStand(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h5>Select Document Type : </h5>
        <div className="w-25 ms-2">
          <Form.Select
            value={formData.typeOfDoc}
            onChange={(e) => {
              setFormData({
                ...formData,
                typeOfDoc: e.target.value,
              });
            }}
          >
            <option value="">Select Document Type</option>
            {getAuthUserlocal()?.selectMembership === "Digital User"
              ? Object.keys(nonStandDocForDigitalUser[0]).map(
                  (heading, index) => (
                    <option key={index} value={heading}>
                      {heading}
                    </option>
                  )
                )
              : Object.keys(nonStandDoc[0]).map((heading, index) => (
                  <option key={index} value={heading}>
                    {heading}
                  </option>
                ))}
          </Form.Select>
        </div>
        <SmallPopUp
          text=" Carefully select the “Type of Document” you will be adding below.
              Incorrect selection of type may lead to delay in processing.
              Please make the selection from drag down."
        />
      </div>
      <div className="d-flex align-items-center">
        <h5>Select Country : </h5>
        <div className="w-25 ms-2">
          <Form.Select
            value={formData.country}
            onChange={(e) => {
              setFormData({
                ...formData,
                country: e.target.value,
              });
            }}
          >
            <option value="">Select Country</option>
            {countriesList.map((c, index) => (
              <option key={index} value={c}>
                {c}
              </option>
            ))}
          </Form.Select>
        </div>
        <ModalComponent body={<CoutryTips />} />
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th className="align-middle">Sr No.</th>
            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              DOCUMENTS <ModalComponent body={<DocTips />} />
            </th>
            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              PLACE OF STAMP <ModalComponent body={<PlaceOfStamp />} />
            </th>
            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              FEES <ModalComponent body={<Fees />} />
            </th>

            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              STAMP COUNT <ModalComponent body={<StampCount />} />
            </th>
            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              UPLOADS <ModalComponent body={<Uploads />} />
            </th>
            <th className="align-middle" style={{ whiteSpace: "nowrap" }}>
              ACTION <ModalComponent body={<Action />} />
            </th>
          </tr>
        </thead>
        <tbody>
          {/* 
          //mapping files here------------------------------------------
          -------------------------------------------------------------- */}
          {formData.docs.map((data, docIndex) => {
            return (
              <tr key={docIndex}>
                <td>{docIndex + 1}.</td>
                <td style={{ width: "30%" }}>
                  {formData.typeOfDoc === "F. Other document" ||
                  formData.typeOfDoc === "Other document" ? (
                    <Form.Control
                      disabled={!formData.typeOfDoc}
                      value={data.docName}
                      maxLength="40"
                      onChange={(e) => {
                        handleInputField(e.target.value, docIndex, "docName");
                        const selectedDocument = "Other Document";
                        handleDocumentSelect(selectedDocument, docIndex);
                      }}
                    />
                  ) : (
                    <Form.Select
                      disabled={!formData.typeOfDoc}
                      value={data.docName}
                      onChange={(e) => {
                        handleInputField(e.target.value, docIndex, "docName");
                        // Call the function to update fees based on the selected document
                        const selectedDocument = "Other Document";
                        handleDocumentSelect(selectedDocument, docIndex);
                      }}
                    >
                      <option value="">Select Document</option>
                      {getAuthUserlocal()?.selectMembership ===
                        "Digital User" &&
                        formData.typeOfDoc &&
                        nonStandDocForDigitalUser[0][formData.typeOfDoc]?.map(
                          (document, index) => (
                            <option key={index} value={document.name}>
                              {document.name}
                            </option>
                          )
                        )}
                      {getAuthUserlocal()?.selectMembership !==
                        "Digital User" &&
                        formData.typeOfDoc &&
                        nonStandDoc[0][formData.typeOfDoc]?.map(
                          (document, index) => (
                            <option key={index} value={document.name}>
                              {document.name}
                            </option>
                          )
                        )}
                    </Form.Select>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <span className="text-info">
                      Contain No. of Pages: {"  "}
                    </span>
                    <Form.Control
                      type="text"
                      inputMode="numeric"
                      onInput={(e) => {
                        const value = e.target.value;
                        if (value === "0") {
                          e.target.value = "";
                        } else if (!isNaN(value)) {
                          e.target.value = Math.max(0, parseInt(value) || 0)
                            .toString()
                            .slice(0, 2);
                        } else {
                          e.target.value = "";
                        }
                      }}
                      value={data.noOfPages}
                      onChange={(e) => {
                        handleInputField(
                          parseInt(e.target.value),
                          docIndex,
                          "noOfPages"
                        );
                      }}
                      style={{ width: "35%", MozAppearance: "textfield" }}
                      pattern="[0-9]*"
                    />
                  </div>
                </td>

                <td style={{ width: "25%" }}>
                  {" "}
                  <div>
                    {data.stampPages.map((pageNo, pageNoIndex) => (
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-info text-nowrap me-2">
                          Page No.:
                        </span>
                        <Form.Control
                          type="text"
                          inputmode="numeric"
                          style={{ minWidth: "50px" }}
                          onInput={(e) => {
                            const value = e.target.value;
                            if (value === "0") {
                              e.target.value = "";
                            } else if (!isNaN(value)) {
                              e.target.value = Math.max(0, parseInt(value) || 0)
                                .toString()
                                .slice(0, 2);
                            } else {
                              e.target.value = "";
                            }
                          }}
                          onChange={(e) => {
                            handleInputFieldPageNo(
                              parseInt(e.target.value),
                              docIndex,
                              pageNoIndex
                            );
                          }}
                        />{" "}
                        {data.stampPages.length - 1 === pageNoIndex &&
                          data.stampPages.length < 3 && (
                            <>
                              <Button
                                className="my-0 ms-1 py-1 w-25"
                                onClick={() => {
                                  setFormData((prevData) => {
                                    const updatedDocs = [...prevData.docs];
                                    updatedDocs[docIndex] = {
                                      ...updatedDocs[docIndex],
                                      stampPages: [
                                        ...updatedDocs[docIndex].stampPages,
                                        null,
                                      ],
                                    };
                                    return {
                                      ...prevData,
                                      docs: updatedDocs,
                                    };
                                  });
                                }}
                              >
                                +
                              </Button>
                            </>
                          )}
                        {data.stampPages.length - 1 === pageNoIndex &&
                          data.stampPages.length > 1 && (
                            <>
                              <Button
                                variant="danger"
                                className="my-0 ms-1 py-1 w-25"
                                onClick={() => {
                                  setFormData((prevData) => {
                                    const updatedDocs = [...prevData.docs];
                                    // Remove the last element from the stampPages array
                                    updatedDocs[docIndex] = {
                                      ...updatedDocs[docIndex],
                                      stampPages: updatedDocs[
                                        docIndex
                                      ].stampPages.slice(0, -1),
                                    };
                                    return {
                                      ...prevData,
                                      docs: updatedDocs,
                                    };
                                  });
                                }}
                              >
                                <MdDelete />
                              </Button>
                            </>
                          )}
                      </div>
                    ))}
                  </div>
                </td>
                <td style={{ width: "10%" }}>
                  {formData.typeOfDoc
                    ? `Rs. ${nonStandDocFees[0][formData.typeOfDoc]}/-`
                    : "---"}
                </td>
                <td className="text-center">{data.stampPages.length}</td>

                <td style={{ width: "10%" }}>
                  <div>
                    {/* Hidden file input for commercial invoice */}
                    <input
                      type="file"
                      id={`docFile_${docIndex}`} // Use a unique id for each file input
                      accept=".pdf" // Add accepted file types if needed
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e, docIndex)}
                    />

                    {/* Button and associated label for commercial invoice */}
                    <Button
                      className="setup-bg-color w-100 mb-2"
                      disabled={data.docFile}
                    >
                      <label htmlFor={`docFile_${docIndex}`} className="mb-0">
                        {" "}
                        {/* Use corresponding id for the label */}
                        Upload
                      </label>
                    </Button>
                  </div>
                </td>
                <td style={{ width: "15%" }}>
                  <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                    <a
                      href={
                        data.docFile ? URL.createObjectURL(data.docFile) : "#"
                      }
                      onClick={(e) => {
                        if (!data.docFile) {
                          e.preventDefault();
                        }
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={!data.docFile}
                    >
                      <BsEye className="fs-5 cursor-pointer" />
                    </a>
                    <FaPen
                      className="fs-6 cursor-pointer"
                      onClick={() => handlePenClick(docIndex)}
                    />

                    {docIndex !== 0 && (
                      <MdDelete
                        className="fs-5 cursor-pointer"
                        onClick={() => handleDelFile(docIndex)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}

          {/* 
          //table row for requesting Letter here------------------------------------------
          -------------------------------------------------------------- */}

          {formData.reqLetterFlag && (
            <tr>
              <td>{formData.docs.length + 1}.</td>

              <td>
                <h6>
                  <div className="d-flex align-items-center">
                    <h6>Requesting Letter</h6>
                    <div>{/* <PopUpModal body={<AddDocTips />} /> */}</div>
                  </div>
                </h6>
              </td>
              <td>--</td>
              <td>--</td>
              <td>--</td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  {/* Hidden file input for commercial invoice */}
                  <input
                    type="file"
                    id="reqLetterFile"
                    accept=".pdf"
                    onChange={(event) =>
                      handleFileChange(event, "reqLetterFile")
                    }
                    style={{ display: "none" }}
                  />

                  {/* Button and associated label for commercial invoice */}
                  <Button
                    className="setup-bg-color w-100 mb-2"
                    disabled={formData.reqLetterFile}
                  >
                    <label htmlFor="reqLetterFile" className="mb-0">
                      Upload
                    </label>
                  </Button>
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("reqLetterFile")}
                    disabled={!formData.reqLetterFile}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>

                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() => editDocument("reqLetterFile")}
                  />

                  <MdDelete
                    className="fs-5 cursor-pointer"
                    onClick={() => {
                      setFormData((prevData) => ({
                        ...prevData,
                        reqLetterFlag: false,
                        reqLetterFile: null,
                      }));
                    }}
                  />
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td colSpan={7} className="text-end">
              {!formData.reqLetterFlag && (
                <Button
                  className="small-font my-1 fw-semibold px-3"
                  variant="info"
                  onClick={() => {
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        reqLetterFlag: true,
                      };
                    });
                  }}
                >
                  {" "}
                  Request Letter?
                </Button>
              )}
              {formData.docs.length < 10 && (
                <Button
                  className="small-font my-1 fw-semibold px-3 ms-2"
                  onClick={() => {
                    setFormData((prevData) => {
                      return {
                        ...prevData,
                        docs: [...prevData.docs, docFormData],
                      };
                    });
                  }}
                >
                  Add More Documents
                </Button>
              )}
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <h6>
                <div className="d-flex align-items-center">
                  <h6>Processing Fee</h6>
                </div>
              </h6>
            </td>
            <td colSpan={4}>
              Rs. {priceControl?.nonStandPc * formData.docs.length}/-
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h6>Total</h6>
            </td>

            <td>
              <span>Rs.{formData?.totalAmt ? formData.totalAmt : "--"}/-</span>
            </td>
            <td colSpan={3} className="text-end">
              <PreviewReceipt
                formData={formData}
                areAllFilesNull={areAllFilesNull}
                nonStandDocFees={nonStandDocFees}
              />

              {/* <Button
    style={{ backgroundColor: "#ed6c02" }}
    className="text-white small-font my-1 fw-semibold me-1 border-none"
  >
    Add More E-Co(NP)
  </Button> */}
              <ProcessedPayment
                formData={formData}
                setIsSubmit={setIsSubmit}
                areAllFilesNull={areAllFilesNull}
                nonStandDocFees={nonStandDocFees}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <div>
        <div className="d-flex justify-content-center gap-3">
          <Button
            variant="success"
            // disabled={!isSubmit || areAllFilesNull()}
            disabled={!isSubmit || apiSubmitNonStand}
            onClick={handleSubmitNonStand}
          >
            {apiSubmitNonStand ? "Processing..." : "SUBMIT"}
          </Button>
          <Button onClick={() => handleResetFormData()}>RESET</Button>
          {/*<Button
            variant="success"
            onClick={() => {
              handleCreateReceipt();
            }}
          >
            CHECK
          </Button>*/}
        </div>
      </div>

      {/* <Button
        onClick={() => {
          console.log(formData);
        }}
      >
        check
      </Button> */}
    </>
  );
};

export default NonStandDoc;

// ------------------------preview receipt modals----------------------------------
// -----------------------------------------------------------------------------
export function PreviewReceipt({ formData, areAllFilesNull, nonStandDocFees }) {
  const [modalShow, setModalShow] = useState(false);
  const priceControl = getAuthUserlocal()?.priceControl;

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <Button
        className="small-font my-1 fw-semibold me-1 setup-bg-color"
        onClick={handleShow}
        disabled={areAllFilesNull()}
      >
        Preview Receipt
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="d-flex justify-content-cente position-relative font1">
          <div className="text-center w-100 d-flex flex-column justify-content-center">
            <div>
              <img
                src={Logo}
                width="15%"
                height="auto"
                className="img-fluid mb-2"
                alt="logo"
                style={{
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <h3>Asian Exporters' Chamber of Commerce And Industry</h3>
            <h6 className="mb-0">
              Regd Office:604, 6th Floor, Hilton Centre, Plot No.66,
              Sector-11,CBD Belapur, Navi Mumbai,
            </h6>
            <h6>Maharashtra, E-mail: info@aecci.org.in</h6>
            <h4 className="fw-semibold">RECEIPT PREVIEW</h4>
            <hr className="bg-dark mb-0" />

            {/* ----------------receipt table start here-------------------------------- */}
            <div className="text-start mb-3">
              <span className="text-small d-block mb-0">Receipt No:</span>
              <span className="text-small d-block mb-0">Document No:</span>
              <span className="text-small d-block mb-0">
                Received From: {getAuthUserlocal().companyName}
              </span>
              <span className="text-small d-block mb-0">
                Address: {getAuthUserlocal().address1}
              </span>
              <span className="text-small d-block mb-0">
                GSTIN: {getAuthUserlocal().gstNo}
              </span>
            </div>

            <Table className="text-start" hover>
              <thead>
                <tr>
                  <th>Srno</th>
                  <th>Document Name</th>
                  <th className="text-center">STAMP COUNT</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {formData.docs.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.docName}</td>
                    <td className="text-center">{data.stampPages.length}</td>
                    <td>Rs. {nonStandDocFees[0][formData.typeOfDoc]}/-</td>
                    <td>
                      Rs.{" "}
                      {data.docName && data.stampPages.length
                        ? data.stampPages.length *
                          nonStandDocFees[0][formData.typeOfDoc]
                        : 0}
                      /-
                    </td>
                  </tr>
                ))}
                {formData.reqLetterFlag && (
                  <tr>
                    <td>{formData.docs.length + 1}</td>
                    <td>Request Letter</td>
                    <td className="text-center">--</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={3}>Processing charge (Per Document)</td>
                  <td>Rs. {priceControl?.nonStandPc}/-</td>
                  <td>
                    Rs. {priceControl?.nonStandPc * formData.docs.length}/-
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>Total</td>
                  <td>Rs. {formData.totalAmt}/-</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    Amount In words (In Rupees):{" "}
                    {numberToWords(formData.totalAmt)}
                  </td>
                </tr>

                {/* <tr>
                  <td colSpan={5} className="text-center">
                    <Button variant="success" >
                      Processed Payment
                    </Button>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

// ------------------------preview receipt modals----------------------------------
// -----------------------------------------------------------------------------
export function ProcessedPayment({
  formData,
  setIsSubmit,
  areAllFilesNull,
  nonStandDocFees,
}) {
  const priceControl = getAuthUserlocal()?.priceControl;

  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <Button
        variant="success"
        className="small-font my-1 fw-semibold"
        onClick={handleShow}
        disabled={areAllFilesNull()}
      >
        Processed Payment
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        {" "}
        <Modal.Body>
          <div>
            <h5>Payment Process</h5>
            <hr />
            <Card>
              <Card.Header>
                <Row className="d-flex">
                  <Col md={7}>
                    <h6 className="text-medium">
                      {getAuthUserlocal().companyName}
                    </h6>
                    <h6 className="text-medium">
                      Membership No: {getAuthUserlocal().memberShipNo}
                    </h6>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h6 className="text-medium">
                      Wallet Balance: ₹{getAuthUserlocal().balance}
                    </h6>
                    <h6 className="text-medium">
                      Valid Upto: {getAuthUserlocal().validUpTo}
                    </h6>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="ms-5 mb-2">
                  <h6 className="text-medium">
                    Debit Amount: {formData.totalAmt}
                  </h6>
                  <h6 className="text-medium">Payment Towards: Non-Standard</h6>
                </div>

                <Card className="p-2 mb-3">
                  <Card.Text className="fs-5 mb-0">Informative Note:</Card.Text>

                  <ul>
                    <li>
                      Charges for the issuance of Non- standard Documents will
                      be deducted from the wallet.
                    </li>
                    <li>
                      Maintain a minimum balance of Rs. 3000/- in your wallet.
                    </li>
                    <li>
                      Initiate wallet payments only from the company&#39;s
                      registered and authorized bank account.
                    </li>
                    <li>
                      The beneficiary Account displayed on this platform is
                      unique to you only and is not be shared with anyone else.
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        In Case of Rejection, Amount will be Credited to your
                        wallet.
                      </Card.Text>
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        You can monitor Transaction entires in your wallet.
                      </Card.Text>
                    </li>{" "}
                    <li>
                      <Card.Text className="text-medium">
                        For Any Assistant, contact Chamber Desk.
                      </Card.Text>
                    </li>
                  </ul>
                </Card>

                <div className="text-center">
                  <Button
                    variant="success"
                    className="rounded-5 px-5 text-medium"
                    onClick={() => {
                      setIsSubmit(true);
                      setModalShow(false);
                    }}
                  >
                    CONFIRM PAYMENT
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function DocTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for Documents:</h5>
        </div>
        <ul className="text-small">
          <li>
            Users have the option to select the document type from the provided
            list.
          </li>
          <li>
            If the desired document is not listed, users can manually add it.
          </li>
          <li>
            It&#39;s important to input the total number of pages in the
            designated field.
          </li>
        </ul>
      </div>
    </>
  );
}
function CoutryTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for COUNTRY:</h5>
        </div>
        <ul className="text-small">
          <li>Specify the country where the document will be used.</li>
          <li>Upload the selected country document only for attestation.</li>
          <li>
            Uploading a document of a different country other than selection may
            result in request rejection.
          </li>
        </ul>
      </div>
    </>
  );
}
function PlaceOfStamp() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Tips for PLACE OF STAMP:
          </h5>
        </div>
        <ul className="text-small">
          <li>Here Chamber attested Stamps will be affixed to the document.</li>
          <li>
            Users should specify the page number(s) where attestation is
            required. Up to 3 pages can be selected for attestation.
          </li>
          <li>
            For example: If the total number of pages is 10 and attestation is
            required on pages 2 or 6, indicate page 2 &gt; click on the
            &quot;+&quot; button &gt; and write 6. Each field should contain
            only one page number. Incorrect input may incur additional charges.
          </li>
        </ul>
      </div>
    </>
  );
}
function Fees() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for FEES:</h5>
        </div>
        <span>
          The fee is calculated on a &quot;per page&quot; basis of document
          attested. Any additional pages will result in Added costs.
        </span>
      </div>
    </>
  );
}
function StampCount() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for STAMP COUNT:</h5>
        </div>
        <span>
          Based on your input of page numbers in the &quot;Place of Stamp&quot;
          column, the total count of stamps will be displayed here.
        </span>
      </div>
    </>
  );
}
function Uploads() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for UPLOADS:</h5>
        </div>
        <ul className="text-small">
          <li>Must Upload a PDF document only.</li>
          <li>The document size should not exceed 5 MB.</li>
          <li>
            Ensure that the uploaded document corresponds to the selected
            document type.
          </li>
          <li>
            Verify that the total number of pages in the PDF matches the entry
            in the &quot;Documents&quot; column.
          </li>
          <li>
            Any discrepancy in the total number of pages or document name may
            result in rejection.
          </li>
        </ul>
      </div>
    </>
  );
}
function Action() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Tips for ACTION:</h5>
        </div>
        <span>
          Users have the option to view the uploaded document here or make any
          necessary edits.
        </span>
      </div>
    </>
  );
}
