import React, { useState, useEffect } from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import infoImg from "../../assets/infoimg.png";
import eCo_info from "../../assets/eCo_info.png";
import { FcAnswers } from "react-icons/fc";
import { useNavigate } from "react-router";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { getAuthUserlocal } from "../../store/services";
import congratsGif from "../../assets/gifs/congrats.gif"

export default function ModalComponent({ body, disabled }) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleButtonClick = () => {
    if (!disabled) {
      setModalShow(true);
    }
  };

  return (
    <>
      <Button
        className={`bg-white border-0 ${disabled ? "disabled" : ""}`}
        onClick={handleButtonClick}
      >
        <img src={infoImg} style={{ height: "4vh" }} alt="" />
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Body className="d-flex justify-content-center position-relative font1">
          {body}
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="d-flex justify-content-center">
          <img
            className="img-fluid"
            style={{ width: "80%" }}
            src={popUpImg}
            alt="eco"
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
}

// for i box image
export function PopUpModal({ body }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <FcAnswers
        className="ms-2 icon-size cursor-pointer"
        onClick={() => setModalShow(true)}
      />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="d-flex justify-content-center position-relative font1">
          {body}
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}


export const MembExpModal = ({ setShowMemModal: setShowMemModalProp }) => {
  const [showMemModal, setShowMemModal] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    let userValidity = getAuthUserlocal()?.validUpTo;

    // If userValidity is a string, parse it into a Date object
    if (typeof userValidity === "string") {
      userValidity = new Date(userValidity);
    }
    const daysDifference = (userValidity - currentDate) / (1000 * 60 * 60 * 24);
    //for trial user 
    const isTrial = getAuthUserlocal()?.isTrial ? getAuthUserlocal()?.isTrial : null;

    if ((daysDifference <= 30) && !isTrial) {
      if (
        window.location.pathname === "/e-co" ||
        window.location.pathname === "/recommendation-letters"
      ) {
        setShowMemModal(localStorage.getItem("MemmodalShown") === "true");
      }
    }
  }, []);

  const handleMemClose = () => setShowMemModal(false);
  const handleMemShow = () => setShowMemModal(true);

  const navigate = useNavigate();

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showMemModal}
        onHide={handleMemClose}
      >
        <Modal.Body className="mb-3">
          <div className="text-center">
            <div className="text-end">
              <IoClose
                className="icon-size text-danger"
                onClick={handleMemClose}
              />
            </div>
            <div>
              <RiErrorWarningFill
                className="text-danger"
                style={{ fontSize: "8rem" }}
              />
            </div>
            <h4>Your Membership Has Expired!</h4>
            <h6 className="text-secondary">
              *To continue enjoying all the benefits of our services, please
              renew your membership.
            </h6>
            <Button
              className="setup-bg-color"
              onClick={() => {
                navigate("/membership-services");
                localStorage.setItem("MemmodalShown", "false");
              }}
            >
              Renew Membership Now
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export function PopUpModalButton({ title, fileLink, disabled }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="m-0 text-nowrap text-white rounded small-font"
        onClick={() => setModalShow(true)}
        disabled={disabled}
      >
        {title.length > 10 ? title.slice(0, 10) + "..." : title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#0b3b5d", color: "white" }}
          className="text-white"
        >
          <Modal.Title>{title}</Modal.Title>

          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>{" "}
        <Modal.Body>
          <div>
            <iframe
              src={fileLink}
              title={title}
              width="100%"
              height="700px"
              frameBorder="0"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function TrialStartModel() {
  const [modalShow, setModalShow] = useState(true);

  return (
    <>
      <FcAnswers
        className="ms-2 icon-size cursor-pointer"
        onClick={() => setModalShow(true)}
      />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="d-flex justify-content-center position-relative font1">
        <img src={congratsGif} alt="Congrats GIF" />
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}


export function CollaborationPopUpModalButton({ title, body, disabled }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="m-2 text-nowrap text-white rounded small-font"
        onClick={() => setModalShow(true)}
        size="sm"
        disabled={disabled}
      >
        {title.length > 10 ? title.slice(0, 10) + "..." : title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        {/* <Modal.Header
          style={{ backgroundColor: "#0b3b5d", color: "white" }}
          className="text-white"
        >
          <Modal.Title>{title}</Modal.Title>

          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>{" "} */}
        <Modal.Body className="d-flex justify-content-center position-relative font1">
          <img src={body} alt={title} className="img-fluid" width={700}/>
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}


export function PopupModalText({ show, title, onHide, body }) {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        centered
      >
          <Modal.Header className="bg-info text-white" closeButton>
        <Modal.Title className="fw-bold text-white">{title}</Modal.Title>
      </Modal.Header>
        <Modal.Body className="">
          <div className="border rounded p-3">{body}</div>
          {/* <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={onHide}
          /> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

