import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import newIcon from "../../assets/new-icon.svg";
import ecoIcon from "../../assets/sidebarImgs/ECo.svg";
import { getAuthUserlocal } from "../../store/services";

// import newIcon from "../../assets/new-icon.svg"
import { IoMdAlert } from "react-icons/io";
import { Link } from "react-router-dom";
import B2bAds from "../../../src/assets/sidebarImgs/B2bAds.png";

const SideBar = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const [daysLeft, setDaysLeft] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [adData, setAdData] = useState(null);
  // const [adBanner, setAdBanner] = useState("");
  // const [adUrl, setAdUrl] = useState("");
  const defaultBanner = B2bAds
    // "https://aecci-bucket-new.s3.amazonaws.com/aecci+assets/excellency-services-logo+bannerNew.png";
  const defaultUrl = "/aecci-virtual-b2b-forum";

  // useEffect(() => {
  //   const fetchAdsData = async () => {
  //     try {
  //       const response = await axios.get(getURLbyEndPointV2("getAllApprovedAdsData"));
  //       if (response.data.status) {
  //         const ads = response.data.data;
  //         const currentDateTime = new Date();

  //         let activeAd = null;

  //         for (const ad of ads) {
  //           const startDate = new Date(ad.startsFrom);
  //           const expiryDate = new Date(startDate);
  //           expiryDate.setDate(expiryDate.getDate() + parseInt(ad.adValidity, 10));

  //           if (startDate <= currentDateTime && currentDateTime <= expiryDate) {
  //             activeAd = ad;
  //             break;
  //           }
  //         }

  //         if (activeAd) {
  //           setAdBanner(activeAd.adBanner);
  //           setAdUrl(activeAd.websiteUrl);
  //         } else {
  //           setAdBanner(defaultBanner);
  //           setAdUrl(defaultUrl);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching ads data:", error);
  //       setAdBanner(defaultBanner);
  //       setAdUrl(defaultUrl);
  //     }
  //   };

  //   fetchAdsData();
  // }, []);

  const accessList = getAuthUserlocal().approveUserData;
  const validUpto = getAuthUserlocal().validUpTo;

  useEffect(() => {
    if (validUpto) {
      try {
        const currentDate = new Date();
        const validUptoDate = new Date(validUpto.replace(/-/g, "/"));

        if (!isNaN(validUptoDate.getTime())) {
          const differenceInTime =
            validUptoDate.getTime() - currentDate.getTime();
          const differenceInDays = Math.ceil(
            differenceInTime / (1000 * 3600 * 24)
          );

          setDaysLeft(differenceInDays);
          if (differenceInDays <= 30) {
            setShowWarning(true);
          } else {
            setShowWarning(false);
          }
        } else {
          console.error("Invalid date format for validUpto:", validUpto);
        }
      } catch (error) {
        console.error("Error parsing date:", error);
      }
    } else {
      console.error("validUpto is undefined");
    }
  }, [validUpto]);

  const nameWithRoutes = [
    {
      name: "Profile",
      options: [
        { name: "Personal Details", route: "/personal-details" },
        { name: "Company Details", route: "/company-details" },
        { name: "Marketing Information", route: "/marketing-information" },
        { name: "Change Password", route: "/change-password" },
      ],
    },
    {
      name: "Services",
      options: [
        { name: "e-CO", route: "/trade-document" },
        {
          name: "Membership Services",
          route: "/membership-services",
          icon: (
            <IoMdAlert
              className="fs-5 cursor-pointer text-danger pulse"
              style={{ color: "red" }}
            />
          ),
        },
        { name: "Recommendation Letters", route: "/recommendation-letters" },
        { name: "Collaboration Portal", route: "/collaboration-portal" },
        { name: "Commercial Directory", route: "/commercial-directory" },
        { name: "Wallet", route: "/wallet" },
      ],
    },
    {
      name: "The Wings",
      options: [
        { name: "Export Wing", route: "/export-wing" },
        { name: "Legal Wing", route: "/legal-wing" },
        { name: "HR Support Wings", route: "/hr-support-wing" },
        { name: "Business Advice Wing", route: "/buissness-advice-wing" },
        { name: "Professional Wing", route: "/professional-wing" },
        { name: "Event & Seminar Wing", route: "/event-seminar-wing" },
      ],
    },
    {
      name: "Arbitration Center",
      options: [
        { name: "Rules, Forms & Fees", route: "/rules-policies" },
        { name: "Panel List", route: "/panel-name" },
        { name: "Raise your Dispute", route: "/raise-your-dispute" },
        { name: "List Of Agreement", route: "/list-of-agreement" },
      ],
    },
    {
      name: "Events",
      options: [
        {
          name: "Chamber Event Bookings",
          route: "/chember-event-booking",
          className: "pulse",
        },
        {
          name: "AECCI B2B Connect 2024",
          route: "/b2b-connect",
        },
        {
          name: "Virtual B2B forum",
          route: "/aecci-virtual-b2b-forum",
          icon: (
            <IoMdAlert
              className="fs-5 cursor-pointer text-danger pulse"
              style={{ color: "red" }}
            />
          ),
        },
        ...(getAuthUserlocal().selectMembership === "Collaboration"
          ? [
            {
              name: "Interested Participate",
              route: "/interested-participate",
            },
          ]
          : []),
      ],
    },
    {
      name: "Publications",
      options: [
        {
          name: "Digital Library",
          route: "/digital-library",
          // , icon:<IoMdAlert className="fs-5 cursor-pointer text-danger pulse" style={{color: "red"}}/>
        },
      ],
    },
  ];

  //function for sidebar as per superadmin
  const filteredNameWithRoutes = nameWithRoutes.filter((category) => {
    // Check if the category is present in the accessList and if it's truthy
    if (accessList[category.name] !== false) {
      if (category.options) {
        // Check if the options array is present in the accessList
        category.options = category.options.filter((option) => {
          // Adjust the property access to handle spaces

          return (
            accessList[category.name] &&
            accessList[category.name][option.name] !== false
          );
        });
      }
      return true;
    }
    return false;
  });

  const handleItemClick = (item) => {
    setActiveItem(item.name);
    navigate(item.route);
  };

  // const shouldRenderHeading = (options) => {
  //   return Object.values(options).some((value) => value === true);
  // };

  return (
    <>
      <div className="cursor-pointer h-100">
        <div
          className="bg-grey my-0 rounded sidebar-width"
          style={{ minHeight: "100%" }}
        >
          <div className="p-4 ">
            {filteredNameWithRoutes.map((nameAndOption, index) => (
              <div key={index}>
                {nameAndOption.name === "Services" ||
                  nameAndOption.name === "The Wings" ? (
                  nameAndOption.options &&
                  nameAndOption.options.some(
                    (option) =>
                      accessList[nameAndOption.name] &&
                      accessList[nameAndOption.name][option.name] === true
                  ) && <h4>{nameAndOption.name}</h4>
                ) : (
                  <h4>{nameAndOption.name}</h4>
                )}
                <ul className="profileLinks p-0 nonestyle fs-5">
                  {nameAndOption.options.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(item)}
                      style={{
                        fontWeight:
                          window.location.pathname === item.route
                            ? "bold"
                            : "normal",
                        marginLeft:
                          window.location.pathname === item.route
                            ? "1rem"
                            : "0px",
                      }}
                      className={`
                       ${item.name === "B2B Connect 2024" ? "fw-bolder" : ""}`}
                    >
                      <div>
                        <div className="d-flex flex-nowrap align-items-center">
                          <div>
                            {item.name === "e-CO"
                              ? "Trade Documentation"
                              : item.name === "Membership Services" &&
                                getAuthUserlocal().selectMembership !==
                                "Digital User"
                                ? getAuthUserlocal().selectMembership ===
                                  "Collaboration"
                                  ? "Certificate of Collaboration"
                                  : "Certificate of Membership"
                                : item.name}
                          </div>
                          {item.name === "Virtual B2B forum" && (
                            <>
                              {" "}
                              <img
                                className="img-responsive h-auto pulse ml-2"
                                style={{ width: "25px" }}
                                src={newIcon}
                              />
                            </>
                          )}
                          {getAuthUserlocal().isTrial &&
                            item.name === "e-CO" && (
                              <img
                                className="img-responsive h-auto pulse ml-2"
                                style={{ width: "25px" }}
                                src={ecoIcon}
                              />
                            )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {getAuthUserlocal().selectMembership === "Digital User" && (
              <div>
                <h4>Membership</h4>
                <ul className="profileLinks p-0 nonestyle fs-5">
                  <li
                    onClick={() => navigate("/upgrade-membership")}
                    style={{
                      fontWeight:
                        window.location.pathname === "/upgrade-membership"
                          ? "bold"
                          : "normal",
                      marginLeft:
                        window.location.pathname === "/upgrade-membership"
                          ? "1rem"
                          : "0px",
                    }}
                  >
                    Upgrade your membership
                  </li>
                </ul>
              </div>
            )}
          </div>
          <hr />
          <div className="cursor-pointer h-100">
            <div>
              <Link to={defaultUrl} rel="noopener noreferrer">
                <img
                  src={defaultBanner}
                  alt="Ad Banner"
                  width="100%"
                  height="auto"
                />
              </Link>
            </div>
          </div>
          <hr />
          <div
            className="px-4 py-3 profileLinks p-0 nonestyle fs-5 cursive-font"
            onClick={() => navigate("/book-your-ad")}
            style={{
              fontWeight:
                window.location.pathname === "/book-your-ad"
                  ? "bold"
                  : "normal",
              marginLeft:
                window.location.pathname === "/book-your-ad" ? "1rem" : "0px",
            }}
          >
            You can be Next! <br /> Book Your AD{" "}
            <span style={{ color: "#5495ff" }}>here</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
