import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Toaster } from "react-hot-toast";
import SocialBar from "./SocialBar";
import { IoMdAlert } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import {Row,Modal,Col,Container,Button }from "react-bootstrap";
import { useNavigate } from "react-router";
import { MembExpModal } from "../../basic/ModalComponent";


const Layout = ({ children }) => {
  
  return (
    <div>
      <Header />
      <main style={{ minHeight: "70vh" }}>
        <Toaster />
        {children}
      </main>
      <Footer />
      {/* <SocialBar /> */}



{/* //setup for expiration */}
<MembExpModal />
    </div>
  );
};

export default Layout;
