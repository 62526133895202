export const titleOptions = [
  "Mr.",
  "Mrs.",
  "Ms.",
  "Dr.",
  "Prof.",
  "Shri.",
  "Smt.",
  "Hon.",
  "Gov.",
  "Rep.",
  "Amb.",
];

export const RulesAndPolicyAccordian = [
  {
    heading: "PART I: INTRODUCTION",
    options: [
      {
        title: "Article 1 Introduction",
        content: [
          "1.1 To frame, amend such Arbitration Rules to hereby govern the conduct of Arbitration Proceeding held at AECCI- International Arbitration Centre, India relating to International Commercial Arbitration and International Maritime Arbitration and for matters connected therewith or incidental thereto.",
          "1.2 By consenting to use the above Arbitration Rules to hereby govern the arbitration proceedings, to be conducted at the AECCI- IAC, Navi Mumbai, India the parties hereby further agree, acknowledge, and accept these rules or any revised rules framed thereafter approved by Council AECCI- IAC Navi Mumbai, India. And in the event of any dispute arising between a relevant rule and an express provision of applicable law hereby governing the Conduct of Arbitration Proceedings between the Parties to Arbitration, then the express provision of applicable/ governing law shall prevail over said relevant rule.",
          "1.3 The Arbitration Rules shall hereby deem to commence from such date as unanimously agreed upon by the Council AECCI- IAC.",
          "1.4 These Arbitration Rules shall govern any and all arbitration proceedings initiated on or after April, 2023, unless otherwise agreed upon by the Council AECCI- IAC, India to fix different of commencement of said Rules.",
        ],
      },
      {
        title: "Article 2 Definition",
        content: [
          "a. “Arbitration” means any arbitration whether or not administered by permanent arbitral institution; any arbitration arising out of International Disputes.",
          "b. “Arbitration agreement” means an arbitration agreement may be in the form of an arbitration clause in a contract or in the form of a separate agreement.",
          "c. “Award” includes any final decision made by the arbitrator(s), including emergency arbitrators.",
          "d. “Tribunal” means a single arbitrator or all arbitrators when there is more than one and includes any tribunal formed under these Rules.",
          "e. “Council” is a group of members appointed by the Chairman of the Centre and governing the AECCI-International Arbitration Centre (IAC), India.",
          "f. “Claimant” is an aggrieved person seeking relief from the other party.",
          "g. “Parties” means a party to an Arbitration Agreement and parties to Proceedings.",
          "h. “Secretary” means the Secretary for the time being appointed by the Committee and includes such other persons as the Council may nominate for carrying out the duties of the, Secretariat under these rules.",
        ],
      },
      {
        title: "Article 3 Applicable Law",
        content: [
          "Amendments:",
          "1. The Tribunal shall apply the Law of Arbitration and Conciliation Act, 1996 and/or if the parties determine and agree that the arbitrator is relying on morally fair, just & commercially accepted rules and principles shall apply for the arbitration proceedings. Failing which the Tribunal shall apply the law and/or rules of law which it determines to be appropriate for an unprejudiced dictum.",
          "2. The Tribunal shall decide as amiable compositeur or ex aequo et bono only if the parties have expressly authorised the Tribunal to do so. (“Amiable compositeur or ex aequo et bono” means dispute settlement where parties expressly agree that the Arbitrator is not bound by strict rules of law but based on fair, just and moral dictum and commercially accepted principles only if the parties have expressly authorised the Tribunal to do so.)",
          "3. In all cases, the Tribunal shall decide in accordance with the terms of the contract, if any, and shall take into account any usage of trade applicable to the transaction to the extent that the Tribunal considers it relevant to the arbitration proceedings.",
        ],
      },
    ],
  },
  {
    heading: "PART II: ARBITRATION INVOCATION: INITIATING THE PROCESS",
    options: [
      {
        title: "Article 4 Commencement of Arbitration",
        content: [
          "4.1. Arbitration Agreement-",
          "In this Part, “arbitration agreement” means an agreement by the parties to submit to arbitration all or certain disputes which have arisen or which may arise between them in respect of a defined legal relationship, whether contractual or not.",
          "An arbitration agreement may be in the form of an arbitration clause in a contract or in the form of a separate agreement.",
          "a. An arbitration agreement shall be in writing.",
          "b. An arbitration agreement is in writing if it is contained in—",
          "(i) a document signed by the parties;",
          "(ii) an exchange of letters, telex, telegrams or other means of telecommunication [including communication through electronic means] which provide a record of the agreement; or",
          "c. The reference in a contract to a document containing an arbitration clause constitutes an arbitration agreement if the contract is in writing and the reference is such as to make that arbitration clause part of the contract.",
          "4.2. Invocation of Arbitration Proceedings",
          "a. Any participant seeking to initiate arbitration (“Claimant”) must first send a written request for Arbitration to the registrar/council.",
          "b. The date on which the Request is received by the Council shall, for all purposes, be deemed to be the date of the commencement of the arbitration.",
          "c. The council will notify all involved parties of the arbitration’s scheduled commencement date.",
          "d. The Request for arbitration shall contain a) the name in full, description, address and other contact details of each of the parties; b) the name in full, address and other contact details of any person(s) representing the claimant in the arbitration; c) a description of the nature and circumstances of the dispute giving rise to the claims.",
          "e. The Request for Arbitration should also include the Statement of Claim of the Claimant.",
          "f. Within 14 days of getting the claimant’s request for arbitration, Defendant must submit his reply to the Claimant. Confirmation or denial of the claims, contact information, a description of the conflict and defense, nomination of a judge, service of copies of the answer and documents on all parties, and evidence of payment, of the filing fee for any complaint are all required in the response. Include a statement of defense, and a statement of counterclaim in your answer, and feel free to revise it as needed within the parameters of the adjudication agreement.",
          "4.3 Receipt of written communications",
          "a. Unless otherwise agreed by the parties,—",
          "• Any written communication is deemed to have been received if it is delivered to the addressee personally or at his place of business, habitual residence or mailing address, and",
          "• If none of the places referred to in clause (a) can be found after making a reasonable inquiry, a written communication is deemed to have been received if it is sent to the addressee’s last known place of business, habitual residence or mailing address by registered letter or by any other means which provides a record of the attempt to deliver it",
          "• The communication is deemed to have been received on the day it is so delivered.",
          "• This Article does not apply to written communications in respect of proceedings of any judicial authority.",
        ],
      },

      {
        title: "Article 5 Interim measures, etc., by Council",
        content: [
          "5.1A party may apply before the arbitral tribunal for interim relief:-",
          "a. By submitting an application to the arbitral tribunal, with reasons stating therein.",
          "b. The Tribunal may, at the request of a party, issue an order granting an interim edict, injunction or any other interim relief it deems appropriate. The tribunal may order the party requesting interim relief to provide appropriate security in connection with the relief sought.",
          "c. Any such measure shall take the form of an order, giving reasons, or of an award, as the arbitral tribunal considers appropriate.",
          "d. Before the file is transmitted to the arbitral tribunal, and in appropriate circumstances even thereafter, the parties may apply to any competent judicial authority for interim or conservatory measures. The application of a party to a judicial authority for such measures or for the implementation of any such measures ordered by an arbitral tribunal shall not be deemed to be an infringement or a waiver of the arbitration agreement and shall not affect the relevant powers reserved to the arbitral tribunal. Any such application and any measures taken by the judicial authority must be notified without delay to the Registrar and the Registrar shall inform the Arbitral Tribunal thereof.",
        ],
      },
    ],
  },
  {
    heading: "PART III: THE ARBITRAL TRIBUNAL",
    options: [
      {
        title: "Article 6 Appointment of Arbitrator",
        content: [
          "6.1 Appointment of Arbitrators-",
          "a. A person of any nationality, location, and language may be an arbitrator, unless otherwise agreed by the parties.",
          "b. The parties are free to agree on a procedure for appointing the arbitrator or arbitrators.",
          "c. Failing any agreement referred to in sub-clause b, or unless it appears to the Council of AECCI-IAC, giving due regard to any proposals by the parties, the complications, the proportion connected with or the relevant circumstances of the dispute that the dispute warrants the appointment of three arbitrators, a sole-arbitrator shall be appointed.",
          "In appointing an arbitrator under these Rules, the Council at AECCI-IAC shall have due consideration and appraisal to the nature of the transaction, the nature, substance, and circumstances of the dispute, the nationality, location and languages of the parties and (if more than two) the number of parties. Due consideration will further be given to any qualifications required by the arbitrator for the subject matter pertaining to the dispute at hand. The Council at AECCI-IAC shall also consider whether the arbitrator has sufficient time, availability and ability to conduct the case in an instantaneous and coherent manner appropriate for arbitration.",
          "6.2 Sole Arbitrator-",
          "In the event that a sole arbitrator needs to be appointed, either party holds the right to propose one or more individuals to the other party. From these proposed names, one will be selected to act as the sole arbitrator. Should the parties be unable to reach an agreement on this matter, the Council of AECCI-IAC, India, will step in to constitute the Arbitral Tribunal, appointing a sole arbitrator.",
          "In situations where the parties are unable to mutually elect an arbitrator, the Council will assume responsibility for selecting a sole arbitrator within a maximum period of 7 days. The entire process of arbitrator selection will be completed within a total timeframe not exceeding 15 days.",
          "6.3 Appointment of Three Arbitrators-",
          "If there are three arbitrators to be appointed in arbitration, each party shall nominate one arbitrator, and the two appointed arbitrators shall appoint the third arbitrator who shall act as the presiding arbitrator.",
          "a. If the appointment procedure in sub-clause 6.3 applies and—",
          "i. A party fails to appoint an arbitrator within thirty days from the receipt of a request to do so from the other party; or",
          "ii. If the two appointed arbitrators fail to agree on the third arbitrator within Thirty days from the date of their appointment; then the appointment shall be hereby made subject to the Council of AECCI-IAC exercising its discretionary powers.",
          "b. Where, under an appointment procedure agreed upon by the parties,—",
          "i. A party fails to act as required under that procedure; or",
          "ii. The parties, or the two appointed arbitrators, fail to reach an agreement expected of them under that procedure; or",
          "iii. A person, including an institution, fails to perform any function entrusted to him or it under that procedure,",
          "The council AECCI-IAC subject to exercising its discretionary powers shall take the necessary steps in this regard. Centre",
        ],
      },
      {
        title: "Article 7 Ground for Challenge of Arbitrators",
        content: [
          "Arbitrator Challenge Rules",
          "7.1 Basis for Challenge: An arbitrator may be challenged if there are doubts about their impartiality, independence, and qualifications agreed upon by the parties, or if they cannot fulfill their duties as required by the rules or within specified timeframes.",
          "7.2 Challenger Limited: Only the party who nominated an arbitrator can challenge them based on new information arising after the appointment.",
          "7.3 Notification Period: Challengers must notify the Secretariat within 14 days of learning about the grounds for the challenge or of the arbitrator’s appointment.",
          "7.4 Notice of Challenge: The challenge notice must be in writing, submitted to the Registrar, and shared with the other party, the challenged arbitrator, and other Tribunal members.",
          "7.5 Consensual Challenge Resolution: If one party challenges an arbitrator and the other agrees, or if the challenged arbitrator voluntarily withdraws, it does not imply agreement on the validity of the challenge grounds.",
          "7.6 Substitute Arbitrator: In cases covered by Rule 7.5, a substitute arbitrator follows the procedure in Rule 8, even if one party failed to nominate an arbitrator during the challenged arbitrator’s appointment. Time limits in Rule 8 start from the date of agreement or withdrawal.",
          "7.7 Council Decision: If the other party does not agree with the challenge within seven days, and the challenged arbitrator doesn’t voluntarily withdraw, the Council decides. The Registrar/Council may request comments/submissions, setting a schedule.",
          "7.8 Successful Challenge: When the Council upholds a challenge, a substitute arbitrator is appointed, even if one party failed to nominate an arbitrator during the initial appointment. Time limits in Rule 8 start from the Registrar’s notification of the Council’s decision.",
          "7.9 Rejected Challenge: If the Council rejects the challenge, the challenged arbitrator continues with arbitration. The challenged arbitrator can proceed.",
          "7.10 Cost Determination: The Council decides the challenge costs, part of AECCI IAC’s fees. The Registrar may request cost deposits under Rule 35 setting a deadline; failure results in withdrawal of the challenge.",
          "7.11 Final Council Decision: The Council’s decision under this rule is final and binding on the parties.",
        ],
      },
      {
        title: "Article 8 – Replacement of Arbitrators",
        content: [
          "This article outlines the circumstances for replacing an arbitrator:",
          "8.1An arbitrator can be replaced due to death, resignation, a valid challenge accepted by the Council, or a joint written request by all parties.",
          "8.2 The Council can also replace an arbitrator if they are legally or practically unable to fulfill their role or if they are not meeting their obligations under the Rules or within set timeframes.",
          "8.3If the Council considers replacing an arbitrator based on certain information, all relevant parties have a chance to provide written comments before a decision is made.",
          "8.4When an arbitrator is replaced, a new one is appointed following the same rules as the original appointment.",
          "8.5The Council may waive a party’s right to nominate a replacement arbitrator if they don’t do so within a specified time. The reconstituted Tribunal will decide if previous proceedings need to be repeated.",
          "8.6If the sole or presiding arbitrator is replaced, previous hearings are usually held again unless the parties agree otherwise. In a three-member tribunal, the decision to redo prior hearings depends on the Tribunal’s discretion and consultation with the parties. However, if an interim or partial award has been issued, hearings related solely to that award are not repeated, and the award remains in effect.",
        ],
      },
      {
        title: "Article 9 Appointment of Emergency Arbitrator",
        content: [
          "9.1A party that needs urgent interim or conservatory measures that cannot await the constitution of an arbitral tribunal (“Emergency Measures”) may make an application for such measures pursuant to the Application made by the either party pursuant to Article 4. Any such application shall be accepted only if it is received by the Registrar prior to the transmission of the file to the arbitral tribunal irrespective of whether the party making the application has already submitted its Request for Arbitration.",
          "9.2The Application for Emergency Measures shall contain the following information with all relevant documentation:",
          "a. The name in full, description, address and other contact details of each of the parties;",
          "b. The name in full, address and other contact details of any person(s) representing the applicant;",
          "c. A description of the circumstances giving rise to the Application and of the underlying dispute referred or to be referred to arbitration;",
          "d. A statement of the Emergency Measures sought;",
          "e. The reasons why the applicant needs urgent interim or conservatory measures that cannot await the constitution of an arbitral tribunal;",
          "f. Any relevant agreements and, in particular, the arbitration agreement containing provisions for appointment of Emergency Arbitrator to seek interim or conservatory measures;",
          "g. Proof of payment of the Emergency Arbitrator Fees referred to in Schedule of Fees annexed to said Rules and any Request for Arbitration and any other submissions in connection with the underlying dispute, which have been filed with the Registrar by any of the parties to the emergency arbitrator proceedings prior to the making of the said Application.",
          "h. The Council at AECCI-IAC shall determine the application as soon as possible in the circumstances and if granted shall seek to appoint an Emergency Arbitrator within one business day of receipt by the Registrar.",
          "The emergency arbitrator’s decision shall take the form of an order. The parties undertake to comply with any order made by the emergency arbitrator.",
          "9.3The emergency arbitrator’s order shall not bind the arbitral tribunal with respect to any question, issue or dispute determined in the order. The arbitral tribunal may modify, terminate or annul the order or any modification thereto made by the emergency arbitrator.",
          "9.4The arbitral tribunal shall decide upon any party’s requests or claims related to the emergency arbitrator proceedings, including the reallocation of the costs of such proceedings and any claims arising out of or in connection with the compliance or noncompliance with the order.",
          "9.5The Emergency Arbitrator Provisions shall not apply if:",
          "a. The arbitration agreement under the Rules was concluded prior to date of commencement of said Arbitration Rules of AECCI-IAC Navi Mumbai, India",
          "b. The parties have agreed to opt out of the Emergency Arbitrator Provisions; or",
          "c. The arbitration agreement upon which the application is based arises from an International treaty to which India is not a signatory.",
          "9.6 The Emergency Arbitrator Provisions are not intended to prevent any party from obtaining urgent interim or conservatory measures from a competent judicial authority at any time prior to making an application for such measures, under the said Rules and in appropriate circumstances even thereafter, pursuant to the Rules",
          "Any application to obtain any interim or conservatory measures from a competent judicial authority shall not be deemed to be an infringement or a waiver of the arbitration agreement. Any such application and any measures taken by the judicial authority must be notified without delay to the Council AECCI-IAC.",
        ],
      },
      {
        title: "Article 10 Conduct of the Arbitral Tribunal",
        content: [
          "10.1 Independence, Impartiality, and Availability –",
          "a. Any arbitrator chosen by either the parties or the council must maintain an impartial and independent stance in relation to the parties engaged in the arbitration and provide his disclosure in writing to the parties.",
          "b. Prior to their appointment or confirmation, a prospective arbitrator is required to sign a statement confirming their acceptance, availability, impartiality, and independence. The prospective arbitrator must also disclose in writing to the Secretariat any facts or circumstances that could potentially raise doubts about their independence in the eyes of the involved parties or give rise to reasonable concerns about their impartiality.",
          "c. If, at any point during the arbitration proceedings, the arbitrator becomes aware of information that could reasonably cast doubt on their neutrality or independence, they must promptly inform the parties, fellow arbitrators, and the registry about such information.",
        ],
      },
    ],
  },
  {
    heading: "PART IV: ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW",
    options: [
      {
        title: "Article 11 Seat and Venue of Arbitration",
        content: [
          "11.1 Seat of Arbitration",
          "a. The designated arbitration venue shall be India.",
          "b. In the absence of any pre-established agreement, the choice of arbitration location shall be determined by the AECCI-IAC council, taking into consideration the circumstances of the case and the convenience of the involved parties.",
          "c. Irrespective of the conditions outlined in sub-Article (a), the AECCI-IAC council located in Navi Mumbai, India, unless otherwise agreed upon by the parties, reserves the right to convene at any location it deems appropriate for member consultations, witness testimonies, expert examinations, parties’ interactions, or the examination of documents, merchandise, or other assets.",
          "11.2 Venue Arbitration-",
          "a. The Venue of Arbitration shall be in Navi Mumbai, India, since one of the party is of India origin.",
        ],
      },
      {
        title: "Article 12 Consolidation Mechanism",
        content: [
          "12.1 The AECCI-IAC Council in India has the authority, upon request from either party involved in arbitration proceedings, submitted in writing through one of the specified methods in Article 2, to combine two or more ongoing arbitration cases into a single arbitration proceeding when:",
          "a. All parties have provided written consent for consolidation.",
          "b. All claims in the arbitrations stem from the same contract or contracts.",
          "c. The claims in the arbitrations do not originate from the same contract or contracts, but the arbitrations involve the same parties, the disputes are related to the same legal relationship, and the AECCI-IAC Council in Navi Mumbai, India, upon reviewing the arbitration agreements, deems them compatible.",
          "12.2 Following the receipt of a written consolidation request from either party, the AECCI-IAC Council in Navi Mumbai, India, during a duly convened meeting, shall either approve or reject the consolidation request while documenting detailed reasons in writing. This decision takes into account:",
          "a. The specific facts and circumstances necessitating consolidation, which may vary from case to case.",
          "b. Whether the interests of all parties involved are adequately protected through consolidation.",
          "c. Whether consolidation is essential to ensure justice for all parties in the proceedings.",
          "d. Whether the subject matter of the arbitration proceedings allows for consolidation.",
          "e. Whether the initiation of consolidation proceedings will not adversely affect the rights of any parties, whether contractual or otherwise.",
          "12.3 The AECCI-IAC Council, exercising its discretionary powers, will also determine whether the same arbitrator will continue or if a new arbitrator should be appointed for the consolidated hearing.",
          "12.4 Additionally, the AECCI-IAC Council, using its discretionary authority, will decide whether all arbitration proceedings should be consolidated into the arbitration proceeding that commenced first.",
          "12.5 The decision made by the AECCI-IAC Council is final, conclusive, and binding upon all parties involved in the arbitration regarding the conduct of consolidation proceedings, subject to the parties’ payment of the specified fees as outlined in the Schedule of Fees (Article 34).",
        ],
      },
      {
        title: "Article 13 Conduct of the Arbitration",
        content: [
          "13.1 Considering the intricacy and significance of the dispute, the arbitration process should prioritize efficiency and cost-effectiveness, involving both the arbitral tribunal and the involved parties.",
          "13.2 The arbitral tribunal must, in consultation with the parties, implement any necessary procedural measures for effective case management, as long as they do not contradict any prior agreements made by the parties. These measures may involve various case management strategies.",
          "13.3 Upon request from any party, the arbitral tribunal may issue orders regarding the confidentiality of the arbitration proceedings or any related matters, taking steps to protect trade secrets and confidential information.",
          "13.4 In all instances, the arbitral tribunal must operate in accordance with the law and maintain impartiality, ensuring that both parties are treated equitably and have ample opportunity to present their respective cases.",
          "13.5 Parties are obligated to adhere to any orders issued by the arbitral tribunal.",
          "13.6 If the original Agreement is in a language other than English, it must be translated into English, and the duly translated copy must be submitted to the centre by the parties.",
          "13.7 The presiding arbitrator may issue procedural rulings independently, with the possibility of review by the Tribunal.",
          "13.8 The Tribunal may proceed with the arbitration process even in cases where any party fails or refuses to comply with these Rules, the Tribunal’s orders or directions, any interim or partial Award, or fails to attend meetings or hearings. The Tribunal may impose appropriate sanctions in such circumstances.",
          "13.9 If any applicable law requires it, the Tribunal shall strive to deliver its final Award within 12 months from the date it receives written notice of its formation. If the Tribunal determines that it cannot reasonably issue the final Award within this 12-month period, it must promptly inform the parties in writing, providing brief reasons and estimating the additional time needed. By agreeing to arbitrate under these Rules, the parties expressly consent to extending the time prescribed by applicable law for rendering the Award by up to 6 months if the Tribunal deems such an extension necessary.",
          "13.10 For matters not explicitly addressed in these Rules, the Council and the Tribunal will operate in accordance with the underlying principles and intent of these Rules. They will make every effort to ensure that the Award complies with the law of the arbitration seat and is legally enforceable.",
        ],
      },
      {
        title: "Article 14 Language",
        content: [
          "14.1 The parties have the liberty to mutually decide on the language or languages to be employed in the Arbitral Proceedings, unless the agreement specifies otherwise.",
          "14.2 If any document is composed in a language different from the arbitration’s designated language, the Tribunal reserves the right to instruct that party to provide a translation, following a format determined by the Tribunal.",
          "14.3 In cases where the parties have not reached an agreement regarding the language(s) of the arbitration, English will serve as the default language or languages, taking into careful consideration all pertinent factors, including the language used in the contract.",
        ],
      },
      {
        title: "Article 15 Statement of Claims & Defences",
        content: [
          "15.1 The Claimant must, within the timeline agreed upon by the parties or determined by the arbitral tribunal, provide a detailed account of the facts supporting their claim, the issues in contention, and the specific relief or remedy sought. The Respondent, on the other hand, should present their defence concerning these particulars, unless the parties have mutually agreed upon different requirements for these statements.",
          "15.2 Both parties are permitted to include any documents they deem relevant with their statements or make references to documents and other evidence they intend to submit later.",
          "15.3 Additionally, the Respondent has the option to introduce a counterclaim or assert a set-off in support of their position. The arbitral tribunal will adjudicate on these matters if they fall within the scope of the arbitration agreement.",
          "15.4 Unless otherwise stipulated by the parties, either party has the right to modify or augment their claim or Defence as the arbitral proceedings progress, unless such amendments or supplements are deemed inappropriate by the arbitral tribunal due to undue delay.",
          "15.5 The submission of the statement of claim and defence, as outlined in this Article, must be completed within a six-month period from the date when the arbitrator or the entire panel of arbitrators, as applicable, formally received written notice of their appointment.",
        ],
      },
      {
        title: "Article 16 Statement of Claim",
        content: [
          "16.1 The Claimant must file its claim in compliance with the rules of AECCI- IAC.",
          "16.2 If the Claimant does not submit its Statement of Claim within the specified timeframe, the Tribunal may issue an order to conclude the arbitration proceedings or provide other appropriate instructions unless a Respondent has filed a counterclaim and desires to continue the arbitration.",
        ],
      },
      {
        title: "Article 17 Further Pleadings",
        content: [
          "17.1 All statements, documents, or other information supplied to the Tribunal and the Registrar by both the parties and the final decision shall lie with the tribunal.",
        ],
      },
      {
        title:
          "Article 18 Submission of Statement of Defence and Counter Claims",
        content: [
          "18.1 Unless previously presented as per the relevant Rule, the Respondent must, within a timeline established by the Tribunal during the initial procedural meeting in accordance with the Rule, provide the Claimant and the Tribunal with a comprehensive Statement of Defence. This statement should outline the Respondent’s complete defence against the Statement of Claim, encompassing both factual and legal arguments. Additionally, the Statement of Defence should indicate any counterclaim, adhering to Rule requirements.",
          "18.2 If, as determined during the initial procedural meeting, the Tribunal deems it necessary; the Respondent should also append witness statements supporting its defence and any counterclaim to the Statement of Defence.",
          "18.3 In the event of a counterclaim, the Claimant must, within a timeline set by the Tribunal during the initial procedural meeting as per the Rule, furnish a Statement of Defence against the Counterclaim. This statement should encompass the Claimant’s comprehensive defence against the counterclaim, including factual details and legal arguments.",
          "18.4 Should the Respondent fail to submit a Statement of Defence, or if, at any point, any party neglects the opportunity to present its case as instructed by the Tribunal, the Tribunal retains the authority to proceed with the arbitration nonetheless.",
        ],
      },
      {
        title: "Article 19 Evidence",
        content: [
          "19.1 The arbitral Tribunal may possess the authority to mandate that any documentary evidence must be accompanied by translations into languages that have been mutually agreed upon by the involved parties or designated by the Tribunal itself.",
          "19.2 Furthermore, the Tribunal’s authority extends beyond what is explicitly articulated within these Rules, and it does not undermine the imperative legal principles governing the arbitration process. The Tribunal is empowered to:",
          "a. Undertake inquiries deemed necessary for prudent by the Tribunal.",
          "b. Issue directives to the parties, compelling them to make any property or item accessible for inspection.",
          "c. Instruct any party to submit to the Tribunal and share with the other parties, documents within their possession, custody, or control that the Tribunal deems pertinent to the case and integral to its resolution. Copies of these documents must also be supplied.",
          "d. Adjudicate on matters pertaining to legal privilege or any other privileges asserted.",
        ],
      },
      {
        title: "Article 20 Witnesses",
        content: [
          "20.1 Prior to the commencement of the hearing, the arbitral tribunal holds the authority to request that the parties furnish a notice identifying the witnesses, which may include expert witnesses, along with a description of their intended testimonies and how they relate to the pertinent issues.",
          "20.2 The Tribunal retains the discretion to determine whether witnesses should be allowed to provide oral testimonies during a hearing and may impose limitations on the number of witnesses to streamline proceedings.",
          "20.3 The Tribunal possesses the authority to decide the manner in which witnesses will be examined, which may include the acceptance of written testimonies.",
          "20.4 Parties, or their duly authorized representatives may, with the Tribunal’s prior approval, conduct questioning of their own potential witnesses before their appearance at the hearing.",
          "20.5 In the event that the parties involved formally request, in writing, to solely base the arbitration proceedings on documentary evidence, the tribunal may dispense with the necessity of examining witnesses before the hearing commences.",
          "20. 6 It is important to note that the arbitral tribunal is bound by the procedures outlined in the Indian Evidence Act of 1872 or any Evidence Act applicable, concerning witness examinations or documentary evidence. Instead, the Tribunal is entitled to establish its procedures, provided they align with the principles of natural justice.",
        ],
      },
      {
        title: "Article 21 Tribunal appointed Expert",
        content: [
          "21.1 The arbitral tribunal, considering the unique circumstances of a particular case and unless otherwise stipulated by the parties, has the authority to:",
          "a. Nominate one or more experts to provide insights on specific matters under the tribunal’s consideration,",
          "b. Call upon a party to furnish the expert with pertinent information or grant access to relevant documents, goods, or the other assets for examination.",
          "21.2 The arbitral tribunal must instruct the appointed expert to submit a written report within a specified timeframe. In cases where it deems it necessary, the tribunal may also require the expert’s presence at an oral hearing, during which the parties have the opportunity to cross-examine the expert and seek clarifications on the issues in question.",
          "21.3 With prior approval from the Arbitral Tribunal, the parties can direct the expert to allow the arbitration parties to examine documents, goods, or other assets that were in the expert’s possession and contributed to the conclusions presented in his written report, which was submitted to the Tribunal.",
        ],
      },
    ],
  },
  {
    heading: "PART V: ARBITRAL AWARD",
    options: [
      {
        title: "Article 22 Time frame to determine Arbitral Award",
        content: [
          "22.1 The Arbitral tribunal is expected to deliver the final award within a span of six months from the date of the last signature, either by the tribunal itself or by the parties, on the Terms of reference governing the arbitration. However, the tribunal retains the flexibility to establish a different time limit in accordance with the procedural schedule it has established. Additionally, if deemed necessary, the tribunal can extend the procedural time limit, either at its own initiative or in response to a formal written request submitted by the parties involved in the Arbitration.",
          "22.2 In the event that the parties reach a settlement subsequent to the transmission of the case file to the arbitral tribunal, the tribunal, after recording the underlying reasons in writing, is empowered to issue a decision in the form of an award confirming the settlement. This is contingent upon the Arbitral Tribunal’s agreement and the prior request for such action, either orally or in writing, by the parties engaged in the arbitration process.",
        ],
      },
      {
        title: "Article 23 Form and Contents of Arbitral Award",
        content: [
          "23.1 An Arbitral Award must be composed in a written format and bear the signatures of all the members of the arbitral tribunal.",
          "23.2 In cases involving multiple arbitrators, it is acceptable for the signatures of the majority of the arbitral tribunal’s members to suffice, provided that any omitted signatures are accompanied by a stated reason.",
          "23.3 The Arbitral Award is required to elucidate the grounds on which it is based, unless either:",
          "A. the parties have mutually agreed to forego the requirement of providing reasons, or",
          "B. the award is a result of arbitral proceedings settled on agreed terms in accordance with Article 32.",
          "23.4 The Arbitral Award should specify the date and the place of arbitration. This designation establishes that the award is considered to have been rendered at that specified location.",
          "23.5 Subsequent to the completion of the Arbitral Award, a signed copy must be delivered to each of the involved parties.",
          "23.6 The Arbitral Award has the authority, at any point during the arbitral proceedings, to issue an interim arbitral award regarding any matter over which it retains the power to make a final arbitral award.",
          "a. Unless otherwise agreed upon by the parties, if the Arbitral Award entails a monetary payment, the arbitral tribunal is entitled to include interest, at a reasonable rate determined by its discretion, on the entirety or any portion of the monetary award. This interest accrues from the date on which the cause of action originated until the date the award is officially issued.",
          "b. Any monetary sum directed to be paid by an Arbitral Award shall, unless explicitly directed otherwise by the award, accrue interest at a rate two percent higher than the prevailing rate of interest as of the date of the award, from the date of the award until the date of payment.",
        ],
      },
      {
        title: "Article 24 Relevant Factors in Determining Final Award",
        content: [
          "24.1 In cases involving multiple arbitrators, the award is contingent upon a majority decision. In the absence of multiple arbitrators, the president of the tribunal is tasked with rendering the award individually, accompanied by a written explanation of this decision.",
          "24.2 The award issued by the arbitral tribunal must be comprehensive, providing detailed reasons for arriving at the decision. It should also include information about the date and location of the arbitration proceedings.",
          "24.3 If both parties unanimously agree that no further relevant evidence or pleadings are to be presented, the tribunal is entitled to close the proceedings.",
          "24.4 Within 30 days of concluding the proceedings, the tribunal must submit all draft awards to the Registrar, except in exceptional circumstances. While the Registrar can propose changes to the format and content of the draft award, the tribunal has the final say.",
          "24.5 The final award is expected to be delivered within 30 days of the tribunal submitting the draft award to the Registrar, with the possibility of an extension under exceptional circumstances, not exceeding a total period of 60 days.",
          "24.6 The tribunal has the discretion to issue separate awards on different issues and at different times.",
          "24.7 In cases where an arbitrator fails to cooperate, the remaining arbitrators can proceed to make the award without their participation.",
          "24.8 An award can exist in multiple counterparts, with each considered as an original. If an arbitrator fails to sign, the reason for their absence must be documented. If the majority of the tribunal signs, the award is deemed final and binding, provided all arbitrators had the opportunity to sign.",
          "24.9 The Registrar is responsible for delivering certified copies of the award upon the full payment of arbitration costs.",
          "24.10 The tribunal holds the authority to grant simple or compound interest at rates agreed upon or determined.",
          "24.11 In the event of a settlement, the tribunal may issue a consent award either with or without reasons upon request by the parties, or the parties can inform the Registrar of the settlement.",
          "24.12 By opting for arbitration under these Rules, the parties commit to promptly executing the award and relinquish their rights to appeal or seek judicial remedies. The award becomes final and binding upon its issuance.",
          "24.13 The AECCI – IAC has the option to publish an award with redacted names and identifying information and maintains it in its registry.",
        ],
      },
      {
        title: "Article 25 Correction of Awards and Additional Awards",
        content: [
          "25.1 Within a period of 30 days following the receipt of an Award, any party may submit a written notice to the Registrar and all other involved parties, requesting the Tribunal to rectify any computational, clerical, typographical, or similar errors present in the Award. Other parties are entitled to provide their comments within 15 days upon receiving such a request. If the Tribunal deems the request justified, it must effect the necessary correction within 30 days from the date of receiving the request. Any corrections, whether included in the original Award or a separate memorandum, shall be considered integral components of the Award.",
          "25.2 The Tribunal retains the authority to rectify any errors of the type mentioned in Rule 23.1, independently and without external request, within a timeframe of 30 days from the date of issuing the Award.",
          "25.3 Within 30 days of receiving an Award, a party may, via written notice to the Registrar and all other parties involved, request the Tribunal to render an additional Award concerning claims presented during the arbitral proceedings but not addressed in the initial Award. In such cases, notwithstanding Rule 23.1, the Tribunal may reopen the proceedings solely for the purpose of adjudicating requests made under this Rule. Other parties have the right to provide their comments within 15 days upon receiving such a request. If the Tribunal deems the request justified, it must issue the additional award within 60 days from the date of receiving the request.",
          "25.4 Within 30 days of receiving an Award, a party may submit a written notice to the Registrar and all other parties, seeking an interpretation of the Award. Other parties are permitted to comment on this request within 15 days upon receipt. If the Tribunal finds the request warranted, it must provide a written interpretation within 30 days of receiving the request. This interpretation will become an integral part of the Award.",
          "25.5 The Registrar holds the authority to extend the time limits established by this Rule.",
          "25.6 The provisions outlined in Article 23 shall be applied, with necessary or appropriate adjustments, in the context of corrections to an Award, interpretations of an Award, and the issuance of additional Awards.",
        ],
      },
    ],
  },
  {
    heading: "PART VI: MISCELLANEOUS PROVISIONS",
    options: [
      {
        title: "Article 26 Jurisdiction",
        content: [
          "26.1 Unless otherwise specified in the agreement, the Tribunal’s primary jurisdiction should be in Navi Mumbai, India.",
          "26.2 In the event of a dispute regarding jurisdiction, the determination of Seat of Arbitration, the location of Arbitration proceedings and the conclusion of such proceedings shall be communicated through written correspondence by the parties who have entered into the agreement.",
        ],
      },
      {
        title: "Article 27 Secrecy and Confidentiality",
        content: [
          "In addition to any provisions explicitly outlined in any other currently applicable laws governing arbitration proceedings between the parties involved, it is required that the arbitrator, the AECCI – IAC, and the parties who are signatories to the arbitration agreement maintain rigorous confidentiality throughout all phases of the arbitration process until its conclusion. The only exception to this rule is in the case of the final arbitral award issued by the Arbitral Tribunal, where disclosure becomes necessary for the purpose of implementing and enforcing the said final arbitral award.",
        ],
      },
      {
        title: "Article 28 Waiver and Limited Liability",
        content: [
          "28.1 If either party involved in the arbitration proceedings proceeds with the arbitration without raising objections to non-compliance with any mandatory provisions within the AECCI – IAC Rules, or any other explicit provisions of the law relevant to the arbitration proceedings, any directives issued by the Arbitral Tribunal, or any requirements outlined in the arbitration agreement pertaining to the composition of the Arbitral Tribunal or the course of the proceedings, it shall be presumed that this party has willingly waived its right to object.",
          "28.2 The arbitrators, individuals appointed by the Arbitral Tribunal, the emergency arbitrator, the AECCI- IAC, and any of its members, employees, as well as members of the AECCI IAC Council, including its Chairman, shall not be held liable to any party for any actions or omissions carried out in Good Faith during the arbitration process. This limitation of liability shall apply, except in cases where such limitation is prohibited by the applicable laws governing the arbitration proceedings conducted between the parties.",
        ],
      },
      {
        title: "Article 29 Termination of Proceedings",
        content: [
          "29.1 The arbitral proceedings may conclude either through the issuance of a final arbitral award or by an order from the arbitral tribunal as specified in sub-Article (ii).",
          "29.2 The arbitral tribunal is responsible for issuing an order to terminate the arbitral proceedings in the following circumstances:",
          "a. If the claimant withdraws their claim, unless the respondent raises an objection and the arbitral tribunal acknowledges a legitimate interest on the respondent’s part in achieving a final resolution of the dispute.",
          "b. When the parties mutually agree to terminate the proceedings.",
          "c. If the arbitral tribunal determines that the continuation of the proceedings has become unnecessary or impossible for any other valid reason.",
          "29.3 The authority of the arbitral tribunal ends concurrently with the termination of the arbitral proceedings.",
        ],
      },
      {
        title: "Article 30 Default of a party",
        content: [
          "Unless otherwise stipulated by the parties, in cases where, without presenting sufficient justification:",
          "a. The claimant fails to submit their statement of claim as prescribed; the arbitral tribunal shall bring the proceedings to a close.",
          "b. The respondent neglects to furnish their statement of defense as required, the arbitral tribunal shall proceed with the proceedings without interpreting this failure as an admission of the claimant’s allegations [and may exercise discretion in considering the forfeiture of the respondent’s right to submit such a statement of defense].",
          "c. Either party fails to attend an oral hearing or provide documentary evidence; the arbitral tribunal may continue with the proceedings and render an arbitral award based on the available evidence.",
        ],
      },
      {
        title: "Article 31 Settlement",
        content: [
          "31.1 An arbitral tribunal is not precluded from actively promoting the settlement of the dispute under an arbitration agreement. With the consent of the parties, the arbitral tribunal may utilize mediation, conciliation, or other procedures at any stage of the arbitration proceedings to facilitate a settlement.",
          "31.2 Should the parties reach a settlement during the course of the arbitral proceedings, the arbitral tribunal shall terminate the proceedings and, upon request from the parties and without objection from the arbitral tribunal, memorialize the settlement in the form of an arbitral award reflecting the agreed-upon terms.",
          "31.3 An arbitral award reflecting agreed terms shall adhere to the principles outlined in and explicitly state its nature as an arbitral award.",
          "31.4 An arbitral award on agreed terms shall carry the same legal status and impact as any other arbitral award addressing the merits of the dispute.",
        ],
      },
      {
        title:
          "Article 32 Arbitration Agreement not to be discharged by death of Parties",
        content: [
          "The death of a party to an arbitration agreement does not result in the agreement being terminated. Instead, it remains valid and can be enforced by or against the deceased party’s legal representative.",
          "If an arbitrator has been appointed by a party who subsequently passes away, the arbitrator’s role and authority are not terminated as a result of the party’s death.",
          "It’s important to note that this section does not affect any laws that may exist, which could extinguish a person’s right to take legal action upon their death.",
        ],
      },
    ],
  },
  {
    heading: "PART VII: ARBITRATION PROCESS: A COMPREHENSIVE OVERVIEW",
    options: [
      {
        title: "Article 33 Payment of Arbitration Cost",
        content: [
          "33.1 In relation to any arbitration proceeding under the said Rules the Arbitral Tribunal, shall have the discretion to determine—",
          "a. Whether costs are payable by one party to another;",
          "b. The amount of such costs; and",
          "c. When such costs are to be paid.",
          "Explanation.—For the purpose of this sub-Article, “costs” means reasonable costs relating to—",
          "i. The fees and expenses of the Arbitrators, Expert and Witnesses;",
          "ii. Legal fees and expenses;",
          "iii. Administration fees of the AECCI International Arbitration Centre supervising the arbitration; and",
          "iv. Any other expenses incurred in connection with the arbitral award.",
          "33.2 If the arbitral tribunal decides to make an order as to payment of costs,—",
          "a. The general rule is that the unsuccessful party shall be ordered to pay the costs of the successful party; or",
          "b. The Arbitral Tribunal may make a different order for reasons to be recorded in writing.",
          "33.3 In determining the costs, the Arbitral Tribunal shall have regard to all the circumstances, including—",
          "a. The conduct of all the parties;",
          "b. Whether a party has succeeded partly in the case;",
          "c. Whether the party had made a frivolous counterclaim leading to delay in the disposal of the arbitral proceedings; and",
          "d. Whether any reasonable offer to settle the dispute is made by a party and refused by the other party.",
          "33.4 The Arbitral Tribunal may make any order under this Article including the order that a party shall pay—",
          "a. A proportion of another party’s costs;",
          "b. A stated amount in respect of another party’s costs;",
          "c. Costs from or until a certain date only;",
          "d. Costs incurred before proceedings have begun;",
          "e. Costs relating to particular steps taken in the proceedings;",
          "f. Costs relating only to a distinct part of the proceedings; and",
          "g. Interest on costs from or until a certain date.",
          "33.5 An agreement which has the effect that a party is to pay the whole or part of the costs of the arbitration in any event shall be only valid if such agreement is made after the dispute in question has arisen.",
        ],
      },
      {
        title: "Article 34 Schedule of Fee",
        content: [
          "34.1 To know about schedule of fees, Kindly Click on the above link [SCHEDULE FEE](<insert link here>)",
        ],
      },
    ],
  },
];

export const countriesList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const countriesAsRegion = {
  Asian: [
    "Armenia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Bhutan",
    "Brunei",
    "Cambodia",
    "China",
    "Georgia",
    "Hong Kong",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Lebanon",
    "Macau",
    "Malaysia",
    "Maldives",
    "Mongolia",
    "Myanmar (Burma)",
    "Nepal",
    "North Korea",
    "Oman",
    "Pakistan",
    "Palestine",
    "Philippines",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "South Korea",
    "Sri Lanka",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Vietnam",
    "Yemen",
  ],
  Schengen: [
    "Austria",
    "Belgium",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
  ],
  Others: [
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Australia",
    "Bahamas",
    "Barbados",
    "Belarus",
    "Belize",
    "Benin",
    "Bermuda",
    "Bolivia",
    "Bosnia & Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Burkina Faso",
    "Burundi",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    // Removed countries from "Others" that are in "Asian" or "Schengen"
    "China",
    "Georgia",
    "Hong Kong",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Lebanon",
    "Macau",
    "Malaysia",
    "Maldives",
    "Mongolia",
    "Myanmar (Burma)",
    "Nepal",
    "North Korea",
    "Oman",
    "Pakistan",
    "Palestine",
    "Philippines",
    "Qatar",
    "Saudi Arabia",
    "Singapore",
    "South Korea",
    "Sri Lanka",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Thailand",
    "Timor-Leste",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Vietnam",
    "Yemen",
    // Removed countries from "Others" that are in "Schengen"
    "Austria",
    "Belgium",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    // ...
    "Zambia",
    "Zimbabwe",
  ],
};
export const navBarData = [
  {
    label: "About AECCI",
    url: "https://www.aecci.org.in/about/",
    submenu: [
      {
        label: "About Chamber",
        url: "https://www.aecci.org.in/about/about-chamber/",
      },
      {
        label: "Our History",
        url: "https://www.aecci.org.in/about/our-history/",
      },
      {
        label: "Chairman Message",
        url: "https://www.aecci.org.in/about/chairman-message/",
      },
      {
        label: "Chamber Policy",
        url: "https://www.aecci.org.in/about/chamber-policy/",
      },
      {
        label: "Office Bearers",
        url: "https://www.aecci.org.in/office-bearers-3/",
      },
      {
        label: "Roles & Responsibility",
        url: "https://www.aecci.org.in/about/roles-responsibility/",
      },
      {
        label: "Strategic Partners",
        url: "https://www.aecci.org.in/strategic-partners/",
      },
      {
        label: "Chamber Dynamics",
        url: "https://www.aecci.org.in/about/chamber-dynamics/",
      },
      {
        label: "Job Opportunities",
        url: "https://www.aecci.org.in/about/jobs-opportunities/",
      },
    ],
  },
  {
    label: "Services",
    url: "/https://www.aecci.org.in/our-services/",
    submenu: [
      {
        label: "The Wings",
        url: "https://www.aecci.org.in/our-services/the-wings/",
        submenu: [
          {
            label: "Exports Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/exports-wing/",
          },
          {
            label: "Legal Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/legal-wing/",
          },
          {
            label: "HR Support Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/hr-support-wing/",
          },
          {
            label: "Professional Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/professional-wing/",
          },
          {
            label: "Business Advice Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/business-advice-wing/",
          },
          {
            label: "Women Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/women-wing/",
          },
          {
            label: "Events and Seminars Wing",
            url: "https://www.aecci.org.in/our-services/the-wings/event-and-seminar-wing/",
          },
        ],
      },

      {
        label: "Entrepreneur Hub",
        url: "https://www.aecci.org.in/our-services/entrepreneur-hub/",
        submenu: [
          {
            label: "Startup India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/startup-india/",
          },
          {
            label: "Investment India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/investment-india/",
          },
          {
            label: "Make in India",
            url: "https://www.aecci.org.in/our-services/entrepreneur-hub/make-in-india/",
          },
        ],
      },
      {
        label: "B2B Connect",
        url: "https://www.aecci.org.in/our-services/b2b-connect/",
        submenu: [
          {
            label: "consultations",
            url: "https://www.aecci.org.in/our-services/b2b-connect/consultations/",
          },
          {
            label: "Business Matches",
            url: "https://www.aecci.org.in/our-services/b2b-connect/business-matches/",
          },
          {
            label: "Market Studies",
            url: "https://www.aecci.org.in/our-services/b2b-connect/market-studies/",
          },
        ],
      },
      {
        label: "Membership",
        url: "https://www.aecci.org.in/our-services/membership/",
        submenu: [
          {
            label: "Membership & its Benefits",
            url: "https://www.aecci.org.in/membership-and-its-benefits/",
          },
          {
            label: "Fees, Forms & Guidelines",
            url: "https://www.aecci.org.in/our-services/membership/guidelines-and-form/",
          },
          {
            label: "Enrollment Offers",
            url: "https://www.aecci.org.in/our-services/membership/enrollment-offers/",
          },
          {
            label: "Visa Recommendations Letter",
            url: "https://www.aecci.org.in/our-services/membership/visa-recommendations-letter/",
          },
        ],
      },
      {
        label: "AECCI Affiliate Program",
        url: "https://www.aecci.org.in/aecci-affiliate-program/",
        submenu: [
          {
            label: "Meet Our Wings Experts",
            url: "https://www.aecci.org.in/meet-our-wings-experts/",
          },
          {
            label: "Join Our Affiliate Network",
            url: "https://www.aecci.org.in/join-our-affiliate-network/",
          },
        ],
      },
    ],
  },

  {
    label: "AECCI-IAC Center",
    url: "https://www.aecci.org.in/our-services/aecci-arbitration-center/",
    submenu: [
      {
        label: "Why AECCI-IAC?",
        url: "https://www.aecci.org.in/why-aecci/",
      },
      {
        label: "Schedule Fees",
        url: "https://www.aecci.org.in/schedule-fees/",
      },
      {
        label: "Rules and Policies",
        url: "https://www.aecci.org.in/rules-and-policies-2/",
      },
      {
        label: "AECCI-IAC Model Clause",
        url: "https://www.aecci.org.in/aecci-iac-model-clause/",
      },
      {
        label: "AECCI - IAC FAQ",
        url: "https://www.aecci.org.in/aecci-iac-faq/",
      },
      {
        label: "Panel Of Arbitrators",
        url: "https://www.aecci.org.in/aecci-iac-panel/",
      },
    ],
  },

  {
    label: "Ways & Means",
    url: "https://www.aecci.org.in/ways-means/",
    submenu: [
      {
        label: "Research and Information",
        url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/",
        submenu: [
          {
            label: "The Economic Analysis",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/the-economic-analysis/",
          },
          {
            label: "Business Opportunities Brief",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/business-opportunities-brief/",
          },
          {
            label: "Indian Economic Report",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/indian-economic-report/",
          },
          {
            label: "Operational Guides",
            url: "https://www.aecci.org.in/ways-means/aecci-research-and-information-2/operational-guides/",
          },
        ],
      },
      {
        label: "India Innovation Index",
        url: "https://www.aecci.org.in/ways-means/india-innovation-index/",
      },
      {
        label: "Annual report",
        url: "https://www.aecci.org.in/ways-means/aecci-annual-report/",
      },
      {
        label: "Commercial Directory",
        url: "https://www.aecci.org.in/ways-means/commercial-directory/",
      },
      {
        label: "Export Promotion Council",
        url: "https://www.aecci.org.in/ways-means/export-promotion-council/",
      },
    ],
  },

  {
    label: "Media",
    url: "https://www.aecci.org.in/media/",
    submenu: [
      {
        label: "e-Newsletters",
        url: "https://www.aecci.org.in/media/e-newsletters/",
      },
      {
        label: "Media Center",
        url: "https://www.aecci.org.in/media/media-center/",
      },
      {
        label: "Daily Viewpoints",
        url: "https://www.aecci.org.in/aecci-viewpoints/",
      },
      {
        label: "Gallery",
        url: "https://www.aecci.org.in/media/gallery/",
      },
      {
        label: "Publications",
        url: "https://www.aecci.org.in/media/publications/",
      },
    ],
  },

  {
    label: "Events",
    url: "https://www.aecci.org.in/events/",
    submenu: [
      {
        label: "Upcoming Events",
        url: "https://www.aecci.org.in/upcoming-events/",
      },
      {
        label: "Past Events",
        url: "https://www.aecci.org.in/events/past-events/",
      },
      {
        label: "Sponsorships",
        url: "https://www.aecci.org.in/events/sponsorship-2/",
      },
      {
        label: "Advertise With Us",
        url: "https://www.aecci.org.in/advertise-with-us/",
      },
      {
        label: "Internation collabrations",
        url: "https://www.aecci.org.in/international-collaboration/",
      },
      {
        label: "World Conference Information",
        url: "https://www.aecci.org.in/world-conference/",
      },
    ],
  },

  {
    label: "e-Platform",
    url: "https://www.aecci.org.in/e-platform/",
    submenu: [
      {
        label: "e-Platform  Information",
        url: "https://www.aecci.org.in/e-platform/e-platform-information/",
      },
      {
        label: "Members Login",
        url: "https://eplatform.aecci.org.in/",
      },
      {
        label: "Formalities & Guidelines",
        url: "https://www.aecci.org.in/e-platform/formalities-guidelines/",
      },
      {
        label: "e-co verification",
        url: "https://e-platform.aecci.org.in/e-co-verification",
      },
    ],
  },

  {
    label: "AECCI-TAC",
    url: "https://www.aecci.org.in/trade-assistant-centre/",
    submenu: [
      {
        label: "About TAC",
        url: "https://www.aecci.org.in/aecci-trade-assistance-center/about-tac/",
      },
      {
        label: "TAC Operational Guide",
        url: "https://www.aecci.org.in/tac-process/",
      },
      {
        label: "TAC Location",
        url: "https://www.aecci.org.in/trade-assistant-centre/tac-location2/tac-location/",
      },
    ],
  },

  {
    label: "Contact Us",
    url: "https://www.aecci.org.in/contact-us/",
    submenu: [
      {
        label: "AECCI Head Office",
        url: "https://www.aecci.org.in/contact-us/aecci-head-office/",
      },
      {
        label: "AECCI International Hub",
        url: "https://www.aecci.org.in/contact-us/aecci-international-hub/",
      },
    ],
  },
];

export const nonStandDoc = [
  {
    "A. Commercial Document": [
      { name: "Commercial Power of Attorney" },
      { name: "Letter of Authorisation" },
      { name: "Commercial Distribution Agreement" },
      { name: "Exclusive Distribution Agreement" },
      { name: "Non Exclusive Distribution of Agreement" },
      { name: "Letter of Access" },
      { name: "Agency Agreement" },
      { name: "Exclusive Agency Agreement" },
      { name: "Non-Exclusive Agency Agreement" },
      { name: "Declaration of import permit" },
      { name: "To whom it may concern" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "B. MCA Document": [
      { name: "Certificate of Incorporation" },
      { name: "Article of Association" },
      { name: "Memorandum of Association" },
      { name: "Memorandum of Understanding" },
      { name: "Good standing Certificate" },
      { name: "MCA Director List" },
      { name: "Partnership Deed" },
      { name: "Share Certificate" },
      { name: "Company profile" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "C. Export Permission related Document": [
      { name: "Free Sale Certificate" },
      { name: "Free Marketing Certificate" },
      { name: "Export Declaration" },
      { name: "EDI (Shipping Bill)" },
      { name: "Certificate of Analysis" },
      { name: "Test Report" },
      { name: "Export Registry Form Turkey" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "D. Pharma Reg. Document": [
      { name: "Brand POA (Nigeria)" },
      { name: "Invitation Letter (Nigeria)" },
      { name: "Trademark Letter (Nigeria)" },
      { name: "Inspection Letter (Nigeria)" },
      { name: "COPP (Nigeria)" },
      { name: "Product Permission (Nigeria)" },
      { name: "MFG License (Nigeria)" },
      { name: "WHO- GMP (To whom it may concern)" },
      { name: "Excise certificate" },
      { name: "Form 25" },
      { name: "Form 28" },
      { name: "WHO- GMP Extension letter Validity Certificate" },
      { name: "Dossier Document" },
      { name: "Manufacturing Validity extension letter" },
      { name: "Retention License" },
      { name: "Contract Manufacturing Agreement" },
      { name: "Loan License agreement" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "E. Agro Chemical": [
      { name: "CIB Certificate (Nigeria)" },
      { name: "Plant Manufacturing License" },
      { name: "COO (To whom it may concern)" },
      { name: "Authorisation Letter" },
      { name: "Composition Certificate" },
      { name: "Analysis Report" },
      { name: "QNQ Certificate" },
      { name: "Guarantee Certificate" },
      { name: "End user certificate" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "F. Other document": [{ name: "Other Document" }],
  },
];

export const nonStandDocForDigitalUser = [
  {
    "Export Permission related Document": [
      { name: "Free Sale Certificate" },
      { name: "Free Marketing Certificate" },
      { name: "EDI (Shipping Bill)" },
      { name: "Certificate of Analysis" },
      { name: "Test Report" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "Pharma Reg. Document": [
      { name: "COPP (Nigeria)" },
      { name: "Product Permission (Nigeria)" },
      { name: "MFG License (Nigeria)" },
      { name: "WHO- GMP (To whom it may concern)" },
      { name: "Excise certificate" },
      { name: "Form 25" },
      { name: "Form 28" },
      { name: "WHO- GMP Extension letter Validity Certificate" },
      { name: "Manufacturing Validity extension letter" },
      { name: "Retention License" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "Agro Chemical": [
      { name: "CIB Certificate (Nigeria)" },
      { name: "Plant Manufacturing License" },
      { name: "Composition Certificate" },
      { name: "miscellaneous Document" },
      { name: "Other Document" },
    ],
    "Other document": [{ name: "Other Document" }],
  },
];

// export const nonStandDocFees = [
//   {
//     "A. Commercial Document": getAuthUserlocal()?.priceControl?.A_ComDoc,
//     "B. MCA Document": getAuthUserlocal()?.priceControl?.B_McaDoc,
//     "C. Export Permission related Document":
//       getAuthUserlocal()?.priceControl?.C_ExpDoc,
//     "D. Pharma Reg. Document": getAuthUserlocal()?.priceControl?.D_PharmDoc,
//     "E. Agro Chemical": getAuthUserlocal()?.priceControl?.E_AgroDoc,
//     "F. Other document": getAuthUserlocal()?.priceControl?.F_OtherDoc,
//     "Non Stand PC": getAuthUserlocal()?.priceControl?.nonStandPc,
//   },
//   {
//     "A. Commercial Document": "A_ComDoc",
//     "B. MCA Document": "B_McaDoc",
//     "C. Export Permission related Document": "C_ExpDoc",
//     "D. Pharma Reg. Document": "D_PharmDoc",
//     "E. Agro Chemical": "E_AgroDoc",
//     "F. Other document": "F_OtherDoc",
//     "Non Stand PC": "nonStandPc",
//   },
// ];



export const industries = [
  "Export Consultant",
  "Organic products",
  "Agriculture and forestry",
  "Livestock and fish",
  "Food",
  "Agricultural and forestry machinery and equipment",
  "Beverages",
  "Food, drink, tobacco and catering industry machinery and equipment",
  "Chemical base materials",
  "Plastic products",
  "Chemical products",
  "Chemical industry plant and equipment",
  "Rubber and plastic industry plant and equipment",
  "Health, medical and pharmaceutical",
  "Rubber products",
  "Furniture and linen",
  "Metal constructions for the building industry",
  "Heating, ventilation, air conditioning (HVAC) and refrigeration equipment",
  "Metal pipework, valves and containers",
  "Security equipment",
  "Hardware, ironmongery, cutlery and tools",
  "Timber, wooden products, machinery and equipment for the woodworking industry",
  "Building industry",
  "Civil engineering and building machinery and equipment",
  "Civil and marine engineering contractors",
  "Energy, fuel and water",
  "Environmental services, renewable energies",
  "Oil and gas industry plant and equipment",
  "Education and training",
  "Banking Sector",
  "International organisations, administrations and associations",
  "Social care, personal services",
  "Research and testing",
  "Information technology (IT) and Internet",
  "Technical offices and engineering consultancies, architects",
  "Courier services, telecommunications, radio and television",
  "Hospitality, tourism, hotel and catering industries",
  "Leisure, culture and entertainment",
  "Sports and leisure equipment",
  "Measuring and testing equipment",
  "Services to businesses",
  "Sourcing Agent",
  "Intending Agent",
  "Merchant Exporters",
  "Wholesale & Retail Traders",
  "Hire and rental services",
  "Hygiene and cleaning",
  "Financial and insurance services",
  "Charitable/Social Organisations",
  "Textile, clothing, leather, and shoemaking machinery and equipment",
  "Leathers, furs, and their products",
  "Clothing and footwear",
  "Textiles",
  "Precious stoneworking, watchmaking, and jewellery",
  "Transportation and logistics services",
  "Handling and storage plant and equipment",
  "Packaging machinery, equipment",
  "Electronic equipment",
  "Telecommunications equipment",
  "Electrical equipment",
  "Nuclear equipment",
  "Optical, photographic, and cinematographic equipment",
  "Industrial subcontractors",
  "Basic metal products",
  "Engines and mechanical parts",
  "Machinery and equipment for metalworking",
  "General traders, department and retail stores",
  "Paper and board making plant and equipment",
  "Printing and publishing",
  "Printing equipment",
  "Office and shop equipment",
  "Paper and board",
  "Glass, cement, and ceramics",
  "Quarried stone",
  "Mining, quarrying, and stoneworking plant and equipment",
  "Building industry",
  "Ores and minerals",
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",
  "Other",
];

export const countryCurrencyWithFlags = [{
	"symbol": "$",
	"name": "US Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "USD",
	"name_plural": "US dollars"
}, {
	"symbol": "CA$",
	"name": "Canadian Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "CAD",
	"name_plural": "Canadian dollars"
}, {
	"symbol": "€",
	"name": "Euro",
	"symbol_native": "€",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "EUR",
	"name_plural": "euros"
}, {
	"symbol": "AED",
	"name": "United Arab Emirates Dirham",
	"symbol_native": "د.إ.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "AED",
	"name_plural": "UAE dirhams"
}, {
	"symbol": "Af",
	"name": "Afghan Afghani",
	"symbol_native": "؋",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "AFN",
	"name_plural": "Afghan Afghanis"
}, {
	"symbol": "ALL",
	"name": "Albanian Lek",
	"symbol_native": "Lek",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "ALL",
	"name_plural": "Albanian lekë"
}, {
	"symbol": "AMD",
	"name": "Armenian Dram",
	"symbol_native": "դր.",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "AMD",
	"name_plural": "Armenian drams"
}, {
	"symbol": "AR$",
	"name": "Argentine Peso",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "ARS",
	"name_plural": "Argentine pesos"
}, {
	"symbol": "AU$",
	"name": "Australian Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "AUD",
	"name_plural": "Australian dollars"
}, {
	"symbol": "man.",
	"name": "Azerbaijani Manat",
	"symbol_native": "ман.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "AZN",
	"name_plural": "Azerbaijani manats"
}, {
	"symbol": "KM",
	"name": "Bosnia-Herzegovina Convertible Mark",
	"symbol_native": "KM",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BAM",
	"name_plural": "Bosnia-Herzegovina convertible marks"
}, {
	"symbol": "Tk",
	"name": "Bangladeshi Taka",
	"symbol_native": "৳",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BDT",
	"name_plural": "Bangladeshi takas"
}, {
	"symbol": "BGN",
	"name": "Bulgarian Lev",
	"symbol_native": "лв.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BGN",
	"name_plural": "Bulgarian leva"
}, {
	"symbol": "BD",
	"name": "Bahraini Dinar",
	"symbol_native": "د.ب.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "BHD",
	"name_plural": "Bahraini dinars"
}, {
	"symbol": "FBu",
	"name": "Burundian Franc",
	"symbol_native": "FBu",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "BIF",
	"name_plural": "Burundian francs"
}, {
	"symbol": "BN$",
	"name": "Brunei Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BND",
	"name_plural": "Brunei dollars"
}, {
	"symbol": "Bs",
	"name": "Bolivian Boliviano",
	"symbol_native": "Bs",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BOB",
	"name_plural": "Bolivian bolivianos"
}, {
	"symbol": "R$",
	"name": "Brazilian Real",
	"symbol_native": "R$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BRL",
	"name_plural": "Brazilian reals"
}, {
	"symbol": "BWP",
	"name": "Botswanan Pula",
	"symbol_native": "P",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BWP",
	"name_plural": "Botswanan pulas"
}, {
	"symbol": "BYR",
	"name": "Belarusian Ruble",
	"symbol_native": "BYR",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "BYR",
	"name_plural": "Belarusian rubles"
}, {
	"symbol": "BZ$",
	"name": "Belize Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "BZD",
	"name_plural": "Belize dollars"
}, {
	"symbol": "CDF",
	"name": "Congolese Franc",
	"symbol_native": "FrCD",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "CDF",
	"name_plural": "Congolese francs"
}, {
	"symbol": "CHF",
	"name": "Swiss Franc",
	"symbol_native": "CHF",
	"decimal_digits": 2,
	"rounding": 0.05,
	"code": "CHF",
	"name_plural": "Swiss francs"
}, {
	"symbol": "CL$",
	"name": "Chilean Peso",
	"symbol_native": "$",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "CLP",
	"name_plural": "Chilean pesos"
}, {
	"symbol": "CN¥",
	"name": "Chinese Yuan",
	"symbol_native": "CN¥",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "CNY",
	"name_plural": "Chinese yuan"
}, {
	"symbol": "CO$",
	"name": "Colombian Peso",
	"symbol_native": "$",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "COP",
	"name_plural": "Colombian pesos"
}, {
	"symbol": "₡",
	"name": "Costa Rican Colón",
	"symbol_native": "₡",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "CRC",
	"name_plural": "Costa Rican colóns"
}, {
	"symbol": "CV$",
	"name": "Cape Verdean Escudo",
	"symbol_native": "CV$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "CVE",
	"name_plural": "Cape Verdean escudos"
}, {
	"symbol": "Kč",
	"name": "Czech Republic Koruna",
	"symbol_native": "Kč",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "CZK",
	"name_plural": "Czech Republic korunas"
}, {
	"symbol": "Fdj",
	"name": "Djiboutian Franc",
	"symbol_native": "Fdj",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "DJF",
	"name_plural": "Djiboutian francs"
}, {
	"symbol": "Dkr",
	"name": "Danish Krone",
	"symbol_native": "kr",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "DKK",
	"name_plural": "Danish kroner"
}, {
	"symbol": "RD$",
	"name": "Dominican Peso",
	"symbol_native": "RD$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "DOP",
	"name_plural": "Dominican pesos"
}, {
	"symbol": "DA",
	"name": "Algerian Dinar",
	"symbol_native": "د.ج.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "DZD",
	"name_plural": "Algerian dinars"
}, {
	"symbol": "Ekr",
	"name": "Estonian Kroon",
	"symbol_native": "kr",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "EEK",
	"name_plural": "Estonian kroons"
}, {
	"symbol": "EGP",
	"name": "Egyptian Pound",
	"symbol_native": "ج.م.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "EGP",
	"name_plural": "Egyptian pounds"
}, {
	"symbol": "Nfk",
	"name": "Eritrean Nakfa",
	"symbol_native": "Nfk",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "ERN",
	"name_plural": "Eritrean nakfas"
}, {
	"symbol": "Br",
	"name": "Ethiopian Birr",
	"symbol_native": "Br",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "ETB",
	"name_plural": "Ethiopian birrs"
}, {
	"symbol": "£",
	"name": "British Pound Sterling",
	"symbol_native": "£",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "GBP",
	"name_plural": "British pounds sterling"
}, {
	"symbol": "GEL",
	"name": "Georgian Lari",
	"symbol_native": "GEL",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "GEL",
	"name_plural": "Georgian laris"
}, {
	"symbol": "GH₵",
	"name": "Ghanaian Cedi",
	"symbol_native": "GH₵",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "GHS",
	"name_plural": "Ghanaian cedis"
}, {
	"symbol": "FG",
	"name": "Guinean Franc",
	"symbol_native": "FG",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "GNF",
	"name_plural": "Guinean francs"
}, {
	"symbol": "GTQ",
	"name": "Guatemalan Quetzal",
	"symbol_native": "Q",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "GTQ",
	"name_plural": "Guatemalan quetzals"
}, {
	"symbol": "HK$",
	"name": "Hong Kong Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "HKD",
	"name_plural": "Hong Kong dollars"
}, {
	"symbol": "HNL",
	"name": "Honduran Lempira",
	"symbol_native": "L",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "HNL",
	"name_plural": "Honduran lempiras"
}, {
	"symbol": "kn",
	"name": "Croatian Kuna",
	"symbol_native": "kn",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "HRK",
	"name_plural": "Croatian kunas"
}, {
	"symbol": "Ft",
	"name": "Hungarian Forint",
	"symbol_native": "Ft",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "HUF",
	"name_plural": "Hungarian forints"
}, {
	"symbol": "Rp",
	"name": "Indonesian Rupiah",
	"symbol_native": "Rp",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "IDR",
	"name_plural": "Indonesian rupiahs"
}, {
	"symbol": "₪",
	"name": "Israeli New Sheqel",
	"symbol_native": "₪",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "ILS",
	"name_plural": "Israeli new sheqels"
}, {
	"symbol": "Rs",
	"name": "Indian Rupee",
	"symbol_native": "টকা",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "INR",
	"name_plural": "Indian rupees"
}, {
	"symbol": "IQD",
	"name": "Iraqi Dinar",
	"symbol_native": "د.ع.‏",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "IQD",
	"name_plural": "Iraqi dinars"
}, {
	"symbol": "IRR",
	"name": "Iranian Rial",
	"symbol_native": "﷼",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "IRR",
	"name_plural": "Iranian rials"
}, {
	"symbol": "Ikr",
	"name": "Icelandic Króna",
	"symbol_native": "kr",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "ISK",
	"name_plural": "Icelandic krónur"
}, {
	"symbol": "J$",
	"name": "Jamaican Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "JMD",
	"name_plural": "Jamaican dollars"
}, {
	"symbol": "JD",
	"name": "Jordanian Dinar",
	"symbol_native": "د.أ.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "JOD",
	"name_plural": "Jordanian dinars"
}, {
	"symbol": "¥",
	"name": "Japanese Yen",
	"symbol_native": "￥",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "JPY",
	"name_plural": "Japanese yen"
}, {
	"symbol": "Ksh",
	"name": "Kenyan Shilling",
	"symbol_native": "Ksh",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "KES",
	"name_plural": "Kenyan shillings"
}, {
	"symbol": "KHR",
	"name": "Cambodian Riel",
	"symbol_native": "៛",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "KHR",
	"name_plural": "Cambodian riels"
}, {
	"symbol": "CF",
	"name": "Comorian Franc",
	"symbol_native": "FC",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "KMF",
	"name_plural": "Comorian francs"
}, {
	"symbol": "₩",
	"name": "South Korean Won",
	"symbol_native": "₩",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "KRW",
	"name_plural": "South Korean won"
}, {
	"symbol": "KD",
	"name": "Kuwaiti Dinar",
	"symbol_native": "د.ك.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "KWD",
	"name_plural": "Kuwaiti dinars"
}, {
	"symbol": "KZT",
	"name": "Kazakhstani Tenge",
	"symbol_native": "тңг.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "KZT",
	"name_plural": "Kazakhstani tenges"
}, {
	"symbol": "LB£",
	"name": "Lebanese Pound",
	"symbol_native": "ل.ل.‏",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "LBP",
	"name_plural": "Lebanese pounds"
}, {
	"symbol": "SLRs",
	"name": "Sri Lankan Rupee",
	"symbol_native": "SL Re",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "LKR",
	"name_plural": "Sri Lankan rupees"
}, {
	"symbol": "Lt",
	"name": "Lithuanian Litas",
	"symbol_native": "Lt",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "LTL",
	"name_plural": "Lithuanian litai"
}, {
	"symbol": "Ls",
	"name": "Latvian Lats",
	"symbol_native": "Ls",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "LVL",
	"name_plural": "Latvian lati"
}, {
	"symbol": "LD",
	"name": "Libyan Dinar",
	"symbol_native": "د.ل.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "LYD",
	"name_plural": "Libyan dinars"
}, {
	"symbol": "MAD",
	"name": "Moroccan Dirham",
	"symbol_native": "د.م.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MAD",
	"name_plural": "Moroccan dirhams"
}, {
	"symbol": "MDL",
	"name": "Moldovan Leu",
	"symbol_native": "MDL",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MDL",
	"name_plural": "Moldovan lei"
}, {
	"symbol": "MGA",
	"name": "Malagasy Ariary",
	"symbol_native": "MGA",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "MGA",
	"name_plural": "Malagasy Ariaries"
}, {
	"symbol": "MKD",
	"name": "Macedonian Denar",
	"symbol_native": "MKD",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MKD",
	"name_plural": "Macedonian denari"
}, {
	"symbol": "MMK",
	"name": "Myanma Kyat",
	"symbol_native": "K",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "MMK",
	"name_plural": "Myanma kyats"
}, {
	"symbol": "MOP$",
	"name": "Macanese Pataca",
	"symbol_native": "MOP$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MOP",
	"name_plural": "Macanese patacas"
}, {
	"symbol": "MURs",
	"name": "Mauritian Rupee",
	"symbol_native": "MURs",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "MUR",
	"name_plural": "Mauritian rupees"
}, {
	"symbol": "MX$",
	"name": "Mexican Peso",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MXN",
	"name_plural": "Mexican pesos"
}, {
	"symbol": "RM",
	"name": "Malaysian Ringgit",
	"symbol_native": "RM",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MYR",
	"name_plural": "Malaysian ringgits"
}, {
	"symbol": "MTn",
	"name": "Mozambican Metical",
	"symbol_native": "MTn",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "MZN",
	"name_plural": "Mozambican meticals"
}, {
	"symbol": "N$",
	"name": "Namibian Dollar",
	"symbol_native": "N$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NAD",
	"name_plural": "Namibian dollars"
}, {
	"symbol": "₦",
	"name": "Nigerian Naira",
	"symbol_native": "₦",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NGN",
	"name_plural": "Nigerian nairas"
}, {
	"symbol": "C$",
	"name": "Nicaraguan Córdoba",
	"symbol_native": "C$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NIO",
	"name_plural": "Nicaraguan córdobas"
}, {
	"symbol": "Nkr",
	"name": "Norwegian Krone",
	"symbol_native": "kr",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NOK",
	"name_plural": "Norwegian kroner"
}, {
	"symbol": "NPRs",
	"name": "Nepalese Rupee",
	"symbol_native": "नेरू",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NPR",
	"name_plural": "Nepalese rupees"
}, {
	"symbol": "NZ$",
	"name": "New Zealand Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "NZD",
	"name_plural": "New Zealand dollars"
}, {
	"symbol": "OMR",
	"name": "Omani Rial",
	"symbol_native": "ر.ع.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "OMR",
	"name_plural": "Omani rials"
}, {
	"symbol": "B/.",
	"name": "Panamanian Balboa",
	"symbol_native": "B/.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "PAB",
	"name_plural": "Panamanian balboas"
}, {
	"symbol": "S/.",
	"name": "Peruvian Nuevo Sol",
	"symbol_native": "S/.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "PEN",
	"name_plural": "Peruvian nuevos soles"
}, {
	"symbol": "₱",
	"name": "Philippine Peso",
	"symbol_native": "₱",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "PHP",
	"name_plural": "Philippine pesos"
}, {
	"symbol": "PKRs",
	"name": "Pakistani Rupee",
	"symbol_native": "₨",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "PKR",
	"name_plural": "Pakistani rupees"
}, {
	"symbol": "zł",
	"name": "Polish Zloty",
	"symbol_native": "zł",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "PLN",
	"name_plural": "Polish zlotys"
}, {
	"symbol": "₲",
	"name": "Paraguayan Guarani",
	"symbol_native": "₲",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "PYG",
	"name_plural": "Paraguayan guaranis"
}, {
	"symbol": "QR",
	"name": "Qatari Rial",
	"symbol_native": "ر.ق.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "QAR",
	"name_plural": "Qatari rials"
}, {
	"symbol": "RON",
	"name": "Romanian Leu",
	"symbol_native": "RON",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "RON",
	"name_plural": "Romanian lei"
}, {
	"symbol": "din.",
	"name": "Serbian Dinar",
	"symbol_native": "дин.",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "RSD",
	"name_plural": "Serbian dinars"
}, {
	"symbol": "RUB",
	"name": "Russian Ruble",
	"symbol_native": "руб.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "RUB",
	"name_plural": "Russian rubles"
}, {
	"symbol": "RWF",
	"name": "Rwandan Franc",
	"symbol_native": "FR",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "RWF",
	"name_plural": "Rwandan francs"
}, {
	"symbol": "SR",
	"name": "Saudi Riyal",
	"symbol_native": "ر.س.‏",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "SAR",
	"name_plural": "Saudi riyals"
}, {
	"symbol": "SDG",
	"name": "Sudanese Pound",
	"symbol_native": "SDG",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "SDG",
	"name_plural": "Sudanese pounds"
}, {
	"symbol": "Skr",
	"name": "Swedish Krona",
	"symbol_native": "kr",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "SEK",
	"name_plural": "Swedish kronor"
}, {
	"symbol": "S$",
	"name": "Singapore Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "SGD",
	"name_plural": "Singapore dollars"
}, {
	"symbol": "Ssh",
	"name": "Somali Shilling",
	"symbol_native": "Ssh",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "SOS",
	"name_plural": "Somali shillings"
}, {
	"symbol": "SY£",
	"name": "Syrian Pound",
	"symbol_native": "ل.س.‏",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "SYP",
	"name_plural": "Syrian pounds"
}, {
	"symbol": "฿",
	"name": "Thai Baht",
	"symbol_native": "฿",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "THB",
	"name_plural": "Thai baht"
}, {
	"symbol": "DT",
	"name": "Tunisian Dinar",
	"symbol_native": "د.ت.‏",
	"decimal_digits": 3,
	"rounding": 0,
	"code": "TND",
	"name_plural": "Tunisian dinars"
}, {
	"symbol": "T$",
	"name": "Tongan Paʻanga",
	"symbol_native": "T$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "TOP",
	"name_plural": "Tongan paʻanga"
}, {
	"symbol": "TL",
	"name": "Turkish Lira",
	"symbol_native": "TL",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "TRY",
	"name_plural": "Turkish Lira"
}, {
	"symbol": "TT$",
	"name": "Trinidad and Tobago Dollar",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "TTD",
	"name_plural": "Trinidad and Tobago dollars"
}, {
	"symbol": "NT$",
	"name": "New Taiwan Dollar",
	"symbol_native": "NT$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "TWD",
	"name_plural": "New Taiwan dollars"
}, {
	"symbol": "TSh",
	"name": "Tanzanian Shilling",
	"symbol_native": "TSh",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "TZS",
	"name_plural": "Tanzanian shillings"
}, {
	"symbol": "₴",
	"name": "Ukrainian Hryvnia",
	"symbol_native": "₴",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "UAH",
	"name_plural": "Ukrainian hryvnias"
}, {
	"symbol": "USh",
	"name": "Ugandan Shilling",
	"symbol_native": "USh",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "UGX",
	"name_plural": "Ugandan shillings"
}, {
	"symbol": "$U",
	"name": "Uruguayan Peso",
	"symbol_native": "$",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "UYU",
	"name_plural": "Uruguayan pesos"
}, {
	"symbol": "UZS",
	"name": "Uzbekistan Som",
	"symbol_native": "UZS",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "UZS",
	"name_plural": "Uzbekistan som"
}, {
	"symbol": "Bs.F.",
	"name": "Venezuelan Bolívar",
	"symbol_native": "Bs.F.",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "VEF",
	"name_plural": "Venezuelan bolívars"
}, {
	"symbol": "₫",
	"name": "Vietnamese Dong",
	"symbol_native": "₫",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "VND",
	"name_plural": "Vietnamese dong"
}, {
	"symbol": "FCFA",
	"name": "CFA Franc BEAC",
	"symbol_native": "FCFA",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "XAF",
	"name_plural": "CFA francs BEAC"
}, {
	"symbol": "CFA",
	"name": "CFA Franc BCEAO",
	"symbol_native": "CFA",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "XOF",
	"name_plural": "CFA francs BCEAO"
}, {
	"symbol": "YR",
	"name": "Yemeni Rial",
	"symbol_native": "ر.ي.‏",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "YER",
	"name_plural": "Yemeni rials"
}, {
	"symbol": "R",
	"name": "South African Rand",
	"symbol_native": "R",
	"decimal_digits": 2,
	"rounding": 0,
	"code": "ZAR",
	"name_plural": "South African rand"
}, {
	"symbol": "ZK",
	"name": "Zambian Kwacha",
	"symbol_native": "ZK",
	"decimal_digits": 0,
	"rounding": 0,
	"code": "ZMK",
	"name_plural": "Zambian kwachas"
}]