import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import AccordionSection from "../../components/basic/Accordion";
import DashboardHeader from "../../components/basic/DashboardHeader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";
import Alert from "react-bootstrap/Alert";

const UpgradeMembership = () => {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [error, setError] = useState("");

  const userId = getAuthUserlocal()._id;

  const [selectedMembership, setSelectedMembership] = useState("");
  const [upgradeRequest, setUpgradeRequest] = useState(false);

  const handleUpgradeMembership = async (e) => {
    e.preventDefault();
    if (!selectedMembership) {
      setError("Please select a membership option");
      return;
    }

    try {
      const res = await axios.put(
        getURLbyEndPoint("upgradeMembership") + userId,
        { membershipType: selectedMembership }
      );
      if (res.status === 200) {
        setError(res.data.message);
        setUpgradeRequest(true);
      } else {
        setError("Failed to send Request.");
      }
    } catch (e) {
      console.log("error is here", e);
      if (e.response && e.response.data && e.response.data.message) {
        setError(e.response.data.message);
      } else {
        // alert("Something went wrong");
        setError("Something went wrong");
      }
    }
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <h4 className="mt-4 mb-3">UPGRADE YOUR MEMBERSHIP</h4>
        <hr />

        <AccordionSection />
        <hr />
        <Card>
          <Card.Header as="h5">Upgrade Membership</Card.Header>
          <Card.Body>
            {!upgradeRequest ? (
              <div>
                <Card.Title>
                  {" "}
                  <h5> Select a membership which you want to upgrade to</h5>
                </Card.Title>

                <Form onSubmit={handleUpgradeMembership}>
                  <div className="w-50 mt-3">
                    <Form.Select
                      aria-label="Default select example"
                      required
                      value={selectedMembership}
                      onChange={(e) => setSelectedMembership(e.target.value)}
                    >
                      <option value="">Open this select menu</option>
                      <option value="Small Business (Associate)">
                        Small Business (Associate)
                      </option>
                      <option value="Start-Up">Start-Up</option>
                      <option value="Corporate">Corporate</option>
                      <option value="Corporate +">Corporate +</option>
                      <option value="Overseas">Overseas</option>
                      <option value="Non-Profit Org">
                        Non-Profit Organization
                      </option>
                    </Form.Select>
                  </div>
                  <span className="text-danger">
                    *Note : Save Your all data of e-platform When Your
                    application is underprocess.
                  </span>
                  <div className="mt-3">
                    <Button
                      type="submit"
                      className="border-0 w-25"
                      style={{ backgroundColor: "#0b3b5d" }}
                    >
                      Upgrade
                    </Button>{" "}
                  </div>
                </Form>
              </div>
            ) : (
              <Card.Text>
                <h4>Thank You!!!</h4>
                <span>
                  Your Request has been sent successfully. It will take 2-3
                  buisness days to review your request.
                </span>
              </Card.Text>
            )}
          </Card.Body>
        </Card>

        {error && (
          <Alert variant="success" className="mt-2">
            {error}
          </Alert>
        )}

        {/* //modal is here ------------------------ */}
        {/* <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request For Upgrade Membership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6> Select a membership which you want to upgrade to</h6>

            <Form.Select aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="Small Business">Small Business</option>
              <option value="Start-Up">Start-Up</option>
              <option value="Corporate">Corporate</option>
              <option value="Corporate +">Corporate +</option>
              <option value="Overseas">Overseas</option>
              <option value="Non Profit Org">Non-Profit Organization</option>
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal> */}
      </Layout2>
    </Layout>
  );
};

export default UpgradeMembership;
