import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  FaEnvelope,
  FaExternalLinkAlt,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import b2bConnectQR from "../../../assets/b2bConnectQR.png";
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { collaboration_countrys } from "../../../pages/auth/Register/AllRegiPageLists";
import { industries } from "../../../store/AllListData";
import { getURLbyEndPointV2 } from "../../../store/api";
import "../../auth/Register/Register.css";

export const Profession = [
  "Mentor",
  "Lawyer",
  "Accountant",
  "Arbitrator Center",
  "New Market consultant",
  "Financial Adviser",
  'ADR PROFESSIONAL',
  "Chartered Accountant",
  "Company Secretary",
  "Export Adviser",
  "Commercial Adviser",
  "Innovation Adviser",
  "Digital Service Specialist",
  "Marketing Specialist",
  "Other",
];

const B2bEventRequest = () => {
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [dropdownCount, setDropdownCount] = useState(1);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const captcha_length = 6;
  const initialFormData = {
    companyName: "",
    email: "",
    name: "",
    entity: "Business",
    profession: "",
    industry: "",
    country: [],
    phoneNo: "",
    contactTimeFrom: "",
    contactTimeTo: "",
    cinNumber: "",
    reqPath: "B2BInterest",
    userMessage: "",
  };

  const [formData, setFormData] = useState(initialFormData);

 

  const timeSlots = [
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
  ];

  const [availableTimesTo, setAvailableTimesTo] = useState(timeSlots);

  const handleCountryChange = (index, event) => {
    const newSelectedCountry = event.target.value;

    if (selectedCountries.includes(newSelectedCountry)) {
      alert("Country already selected. Please choose a different country!");
      return;
    }
    const newSelectedCountries = [...selectedCountries];
    newSelectedCountries[index] = newSelectedCountry;
    setSelectedCountries(newSelectedCountries);
    setFormData({
      ...formData,
      country: newSelectedCountries,
    });
  };

  const addDropdown = () => {
    if (dropdownCount < 6) {
      setDropdownCount(dropdownCount + 1);
      setSelectedCountries([...selectedCountries, ""]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };

  const handleInputChangeCin = (event) => {
    const value = event.target.value.toUpperCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      cinNumber: value,
    }));
    const iecRegex = /^[a-zA-Z0-9]{10}$/;
    const cinRegex = /^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/;
    const isIECValid = iecRegex.test(value);
    const isCINValid = cinRegex.test(value);
    const isValid = isIECValid || isCINValid;
    setIsInputValid(isValid);
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedCountries([]);
    setDropdownCount(1);
    setCaptchaValue("");
    setIsCaptchaCorrect(false);
    setIsInputValid(true);
    setRegisterFormSuccess(false);
    setFormSuccessful(false);
  };

  const handleRegiFormSubmit = async (e) => {
    e.preventDefault();
    setRegisterFormSuccess(true);
    try {
      formData.phoneNo = parseInt(formData.phoneNo);
      if (isFormValid) {
        // Proceed
      } else {
        alert("CAPTCHA is invalid");
        return false;
      }

      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };
      // Validate common fields
      if (!validateField(formData.name, "Name")) return;
      if (!validateField(formData.email, "Email")) return;
      if (!validateField(formData.entity, "Entity")) return;
      if (!validateField(formData.reqPath, "Req path")) return;
      if (
        !validateField(
          formData.phoneNo.toString(),
          "Phone No.",
          /^(?:\d{10,15})$/
        )
      )
        return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;

      // Additional validation for Business entity
      if (formData.entity === "Business") {
        if (!validateField(formData.companyName, "Company Name")) return;
        if (!validateField(formData.cinNumber, "IEC CODE / CIN NO")) return;
        if (!validateField(formData.industry, "Industry")) return;
      }

      // Additional validation for individual entity
      if (formData.entity === "Individual") {
        if (!validateField(formData.profession, "Profession")) return;
      }

      const res = await axios.post(
        getURLbyEndPointV2("createB2BEventRequest"),
        {
          ...formData,
        }
      );

      if (res && res.data && res.data.status) {
        toast.success(res.data.message);
        setFormSuccessful(true);
        setRegisterFormSuccess(false);
        setFormData(initialFormData);
      } else {
        console.log("Response data:", res);
        toast.error(res?.data?.message || "An error occurred");
      }
    } catch (e) {
      console.log("Error caught:", e);
      toast.error(e?.response?.data?.message || "An error occurred");
    } finally {
      setRegisterFormSuccess(false);
    }
  };

  useEffect(() => {
    const filterTimes = () => {
      const selectedFromIndex = timeSlots.indexOf(formData.contactTimeFrom);
      if (selectedFromIndex === -1) {
        setAvailableTimesTo(timeSlots);
      } else {
        const newAvailableTimes = timeSlots.slice(selectedFromIndex + 1); // Get times after the selected 'From' time
        setAvailableTimesTo(newAvailableTimes);
      }
    };

    filterTimes();
  }, [formData.contactTimeFrom]);

  useEffect(() => {
    const isValid = isCaptchaCorrect;
    setIsFormValid(isValid);
  }, [isCaptchaCorrect]);

  useEffect(() => {
    loadCaptchaEnginge(captcha_length);
  }, []);
  return (
    <>
      <Layout>
        <EPlatformHeader
          // heading="REQUEST FORM - AECCI B2B CONNECT 2024"
          heading="SUBMIT YOUR REQUEST - AECCI Virtual B2B Forum"
          // text="AECCI ePlatform Simplifying Trade Documentation"
        />
        <div className="custom-width mx-auto my-4">
          <Row className="mt-2">
            <Col sm={12} lg={6}>
              <h3 className="montserratAlternatesFont fw-bold">
              Do you find this important but ‘too-costly’ to fly abroad for exploring business opportunities?
              </h3>
              <h5 className="my-3 fw-bold">
              AECCI ‘Virtual B2B Forum’ bringing the opportunity to meet global-experts at the comfort of your business.
              </h5>

              <h6 className="my-4 text-justify fw-semibold">
              Being your trade association, we understand the complexities of business, necessities to expand and limitations to spend. To help you overcome such challenges we have collaborated with 25+ expert partners within Asia and Africa region from the prominent trade partner countries: Nigeria, Vietnam, Cambodia, Oman, Brazin, Egypt, and many more.
              </h6>

              <h6 className="my-4 text-justify fw-semibold">
              Yes!!! AECCI marks its presence in 25+ countries and to make your global business journey, an enriching experience, we are bringing-up with “Virtual Business Forum”, wherein you will have the opportunity to have “one-to-one interaction session”, 30 minutes each with our collaborative expert partners.
              </h6>

              <h6 className="my-4 text-justify fw-semibold">
              Virtual B2B forum will also present an excellent opportunity for law professionals by facilitating the interaction with various law firms, enabling attendees to expand their international networks. This is a chance to build valuable relationships, share insights, and explore potential collaborations in the legal field.
              </h6>


              <h5 className="fw-bold mt-4">
              AECCI Virtual Business Forum is scheduled for October 25th, 26th, and 27th, 2024..
              </h5>

              <h6 className="fw-bold mt-3">
                {" "}
                Instead of spending hugely on global visits let’s explore the opportunity to meet our trade experts virtually and take advise on all unanswered queries, like:
              </h6>
              <ul>
                <li className="my-2">
                  <span className="fw-semibold">Reaching to new customers</span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">Business Expansion</span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Credibility check of the trade partners.{" "}
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Compliances and regulation at foreign land.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Product or brand registrations in Chosen country.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Resolving the commercial disputes.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">
                    Seeking Investment partners.
                  </span>
                </li>
                <li className="my-2">
                  <span className="fw-semibold">Fulfilling IPR needs.</span>
                </li>
              </ul>
              <h6>and all such other issues.</h6>
              <h6>
              Simply “click” to join us for the big-event where-in you can have every possible solution.
              </h6>
              <h6>
                <a
                  href="https://www.aecci.org.in/b2b-about/"
                  target="_blank"
                >
                  {" "}
                  Click to explore{" "}
                </a>{" "}
                our AECCI Virtual B2B Forum at the comfort of your workplace.
              </h6>
              <h4 className="fw-bolder my-4">Get Started Now!</h4>
              <h5 className="fw-bold my-2">Register to enquire more</h5>
              <h6 className="my-2">
                For any assistance or queries, please don't hesitate to contact
                us.
              </h6>
              <Row className="mt-3">
                <Col md={3} xs={12}>
                  <img src={b2bConnectQR} className="img-fluid w-100" />
                </Col>
                <Col md={9} xs={12}>
                  <div>
                    <p>
                      <FaPhone /> <strong>Board Lines:</strong> +91-22-412 711
                      45 | 46 | 47
                    </p>
                    <p>
                      <FaWhatsapp /> <strong>Mobile/WhatsApp:</strong>{" "}
                      <a
                        href="https://api.whatsapp.com/send?phone=918433720996"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +91-8433720996
                      </a>
                    </p>
                    <p>
                      <FaEnvelope /> <strong>Email ID:</strong> ed@aecci.org.in
                    </p>
                    <p>
                      <FaExternalLinkAlt /> <strong>Website:</strong>{" "}
                      <a
                        href="http://www.aecci.org.in"
                        className="text-primary"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                      >
                        www.aecci.org.in
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} lg={6}>
              {!formSuccessful ? (
                <div
                  className="text-white montserratFont"
                  style={{
                    background: "#021C43",
                    paddingBlock: "30px",
                    paddingInline: "50px",
                  }}
                >
                  <form onSubmit={handleRegiFormSubmit}>
                    <p className="mt-4" style={{ fontSize: "0.9em" }}>
                      * indicates required fields
                    </p>
                    <div className="">
                      <div>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Entity Type *
                        </label>
                      </div>
                      <div className="d-flex gap-3">
                        <div className="d-flex flex-column">
                          <div>
                            <Form.Check
                              inline
                              label="I'm Business"
                              name="entity"
                              value="Business"
                              type="radio"
                              id="inline-radio-1"
                              checked={formData.entity === "Business"}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="text-small">
                            Corporate Private Limited Non-Profit Partnership
                            Joint Venture
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div>
                            <Form.Check
                              inline
                              label="I'm Individual"
                              name="entity"
                              value="Individual"
                              type="radio"
                              id="inline-radio-2"
                              checked={formData.entity === "Individual"}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="text-small">
                            Sole Proprietorship, Freelancer, Consultant,
                            Contractor, Self-Employed
                          </div>
                        </div>
                      </div>
                    </div>
                    {formData.entity === "Business" && (
                      <Row className="my-4">
                        <Col md={6}>
                          <div>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "0.9rem" }}
                            >
                              Company Name *
                            </label>
                            <div className="d-flex flex-column">
                              <input
                                style={{ width: "auto", height: "auto" }}
                                className="form-control rounded-0"
                                onChange={handleInputChange}
                                value={formData.companyName}
                                name="companyName"
                                type="text"
                                maxLength={50}
                                required
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "0.9rem" }}
                            >
                              Select Your Industry *
                            </label>
                            <div className="d-flex flex-column">
                              <select
                                className="form-control rounded-0"
                                required
                                value={formData.industry}
                                onChange={handleInputChange}
                                name="industry"
                              >
                                <option value="">Select Industry...</option>
                                {industries.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {formData.entity === "Individual" && (
                      <div className="my-4">
                        <Row>
                          <Col>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "0.9rem" }}
                            >
                              Select Your Profession *
                            </label>
                            <div className="d-flex flex-column">
                              <select
                                className="form-control rounded-0"
                                required
                                value={formData.profession}
                                onChange={handleInputChange}
                                name="profession"
                              >
                                <option value="">Select a Profession...</option>
                                {Profession.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <div className="my-4">
                      <Row>
                        <Col xs={12} sm={12} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Contact Person Name *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.name}
                              name="name"
                              type="text"
                              maxLength={50}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {formData.entity === "Business"
                              ? "Company Email Address *"
                              : "Email Address *"}
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.email}
                              name="email"
                              type="email"
                              maxLength={40}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="my-4">
                      <Row>
                        <Col md={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Phone Number *
                          </label>
                          <input
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.phoneNo}
                            name="phoneNo"
                            type="text"
                            required
                          />
                        </Col>
                        <Col md={6}>
                          <label
                            className="fw-bold"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Contact Preferred Time *
                          </label>
                          <Row>
                            <Col xs={6} sm={6} md={6}>
                              <select
                                className="form-control rounded-0"
                                onChange={handleInputChange}
                                value={formData.contactTimeFrom}
                                name="contactTimeFrom"
                                required
                              >
                                <option value="">Select..</option>
                                {timeSlots
                                  .filter((time) => time !== "06:00PM")
                                  .map((time, index) => (
                                    <option key={index} value={time}>
                                      {time}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                            <Col xs={6} sm={6} md={6}>
                              <select
                                className="form-control rounded-0"
                                onChange={handleInputChange}
                                value={formData.contactTimeTo}
                                name="contactTimeTo"
                                required
                                disabled={!formData.contactTimeFrom}
                              >
                                <option value="">Select..</option>
                                {availableTimesTo.map((time, index) => (
                                  <option key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div className="my-4">
                      <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                        Select Country *
                      </label>
                      {[...Array(Math.ceil(dropdownCount / 3))].map(
                        (_, rowIndex) => (
                          <Row key={rowIndex} className="mb-3">
                            {[...Array(3)].map((_, colIndex) => {
                              const index = rowIndex * 3 + colIndex;
                              return (
                                index < dropdownCount && (
                                  <Col key={index}>
                                    <select
                                      className="form-control rounded-0"
                                      required
                                      value={selectedCountries[index]}
                                      onChange={(e) =>
                                        handleCountryChange(index, e)
                                      }
                                      name={`country-${index}`}
                                    >
                                      <option value="">
                                        Select a country...
                                      </option>
                                      {collaboration_countrys.map(
                                        (country, i) => (
                                          <option key={i} value={country}>
                                            {country}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        )
                      )}
                      {dropdownCount < 6 && (
                        <Button
                          className="mt-3"
                          size="sm"
                          onClick={addDropdown}
                        >
                          Do you want to add more countries?
                        </Button>
                      )}
                      {dropdownCount === 6 && (
                        <p className="mt-3 text-danger">
                          more than 6 countries? Contact to Chamber Desk
                        </p>
                      )}
                    </div>

                    {formData.entity === "Business" && (
                      <div className="my-4">
                        <Row>
                          <Col>
                            <label
                              className="fw-bold"
                              style={{ fontSize: "0.9rem" }}
                            >
                              IEC CODE / CIN NO *
                            </label>
                            <input
                              style={{ height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChangeCin}
                              value={formData.cinNumber}
                              name="cinNumber"
                              type="text"
                              minLength={10}
                              maxLength={10}
                              required
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    <div className="my-4">
                      <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                        Purpose of participation *
                      </label>
                      <Form.Control
                        as="textarea"
                        style={{
                          height: "auto",
                          resize: "none",
                        }}
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.userMessage}
                        name="userMessage"
                        maxLength={140}
                        rows={2}
                        required
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-between align-items-start mb-3">
                      <div className="d-flex align-items-start gap-2 text-white">
                        <LoadCanvasTemplate className="text-white" />
                        {isCaptchaCorrect && (
                          <AiFillCheckCircle className="icon-size text-white" />
                        )}{" "}
                      </div>

                      <div className="w-50">
                        <input
                          className="w-100 px-2 py-1 border-1"
                          placeholder="Type the given captcha word"
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          maxLength={captcha_length}
                          onChange={handleCaptchaChange}
                          value={captchaValue}
                        />
                        {isCaptchaCorrect ? (
                          <p className="text-success">CAPTCHA is valid</p>
                        ) : (
                          <p className="text-danger">CAPTCHA is invalid</p>
                        )}
                      </div>
                    </div>
                    <Row>
                      <Col xs={12} sm={12} lg={6}>
                        <Button
                          variant="danger"
                          className="m-1 px-5 py-2 rounded-1 border-0 fw-medium"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col xs={12} sm={12} lg={6}>
                        <Button
                          type="submit"
                          className="m-1 px-5 py-2 rounded-1 border-0 fw-medium "
                          disabled={registerFormSuccess}
                        >
                          {!registerFormSuccess ? "Submit" : "Processing..."}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    background: "#021C43",
                    paddingInline: "50px",
                    paddingBlock: "50px",
                  }}
                >
                  <div style={{ marginTop: "10px", color: "#fff" }}>
                    <h2>Thank You for Your Request!</h2>
                    <p>
                      We have received your request and will contact you soon.
                    </p>
                    <p>
                      Meanwhile, feel free to explore our{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                        href="https://www.aecci.org.in/e-platform/e-platform-information/"
                        rel="noreferrer"
                      >
                        e-platform
                      </a>{" "}
                      and{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                        href="https://www.aecci.org.in/our-services/"
                        rel="noreferrer"
                      >
                        services
                      </a>{" "}
                      to discover more about what we offer.
                    </p>
                    <div className="mt-5 mb-3">
                      <h2 className="mb-2">AECCI e-Platform 2.0</h2>
                      <p>
                        The AECCI e-Platform, launched in 2020 and upgraded to
                        Version 2.0 on April 1, 2024, offers digital services
                        tailored for exporters. Exclusively available to
                        registered members, it provides comprehensive support
                        including brand awareness, business opportunities,
                        network expansion, personnel training, and access to
                        international markets.
                      </p>
                    </div>

                    <div className="text-white my-4">
                      <div className="text-white mb-2">
                        <h4 className="card-title">AECCI B2B Connect 2024</h4>
                        <p className="card-text">
                          Join our big event in October 2024 to connect with 25+
                          foreign partners, addressing your business needs
                          through consultations, market studies, business
                          matches, and more.
                        </p>
                        <p className="card-text">
                          Get ready to meet foreign experts and receive support
                          from the comfort of your business.
                        </p>
                      </div>

                      <div className="text-white my-4">
                        <div className="text-white mb-2">
                          <h4>OUR Publications</h4>
                          <p>
                            Access all chamber publications in our Digital
                            Library:
                          </p>
                          <ul style={{ color: "#35afd3" }}>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/aecci-viewpoints/"
                                rel="noreferrer"
                              >
                                Daily Viewpoints
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/e-newsletters/"
                                rel="noreferrer"
                              >
                                Newsletters
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/publications/"
                                rel="noreferrer"
                              >
                                Annual Magazine
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default B2bEventRequest;
