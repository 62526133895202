import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import { getURLbyEndPointV2 } from "../../../store/api";
import { getAuthUserlocal } from "../../../store/services";
import "./b2b.css";

const B2bInterestedClient = () => {
  const [participantList, setParticipateList] = useState([]);

  const colors = [
    "#FFF9F9", // Light pink
    "#F5FFFF", // Light cyan
    "#FFF5E1", // Light peach
    "#F0FFF0", // Honeydew (very light green)
    "#FFF0F5", // Lavender blush
    "#F0F8FF", // Alice blue
    "#F5F5DC", // Beige
    "#F5FFFA", // Mint cream
    "#FAFAD2", // Light goldenrod yellow
    "#FFFFF0", // Ivory
    "#FDF5E6", // Old lace (light beige)
    "#F0FFFF", // Azure (very light cyan)
  ];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const fetchParticipateList = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPointV2("getParticipateList") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setParticipateList(data.data);
      } else {
        console.error("Failed to fetch participant list");
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  useEffect(() => {
    fetchParticipateList();
  }, []);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="">
            <div>
              <div className="d-flex align-items-center">
                <h4 className="py-4">INTERESTED PARTICIPANTS LIST</h4>
              </div>
              <hr className="mt-1 mb-1" />
            </div>
            <div style={{ backgroundColor: "#F4F4F9" }} className="p-3 rounded">
              <h3 className="text-center text-bold">AECCI Virtual B2B FORUM</h3>
              <hr className="mx-auto w-75" />
              <div>
                <p className="mt-3">
                  We are pleased to provide a list of registered participants: individuals,
                  manufacturers, exporters, and importers. This list is based on
                  the registrations received and approved by our membership desk.
                  Please note that the list is realistic but tentative and may
                  not reflect the exact number of interested participants from
                  your country.
                </p>
                <p>
                  <strong>Daily Meeting Slots Available:</strong> <br />
                  Minimum: 5 business slots <br />
                  Maximum: 6 business slots<br />
                  (Maximum 5 hours a day-each delegate)<br />
                </p>
                <p>
                  This allocation ensures that each country has a fair opportunity
                  to engage in business meetings.
                </p>
                <p className="text-muted">
                  Note: The number of available slots may vary based on daily
                  registrations and approvals.
                </p>
              </div>
              <div className="mt-3">
                <div className="masonry-grid">
                  {participantList.map((item, index) => (
                    <div key={index} className="masonry-item">
                      <Card
                        style={{
                          backgroundColor: getRandomColor(),
                          borderRadius: "15px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                          cursor: "pointer",
                          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                        }}
                        className="h-100"
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow = "0 12px 24px rgba(0, 0, 0, 0.3)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
                        }}
                      >
                        <Card.Body className="d-flex flex-column justify-content-between">
                          <Card.Title
                            className="fs-6 fw-bold text-center mb-2"
                            style={{
                              backgroundImage: "linear-gradient(90deg, #6a11cb, #2575fc)",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            {item.companyName
                              ? item.companyName
                              : `${item.name} (${item.profession})`}
                          </Card.Title>

                          <Card.Text className="text-center">
                            <Badge
                              style={{
                                backgroundColor: "#007bff",
                                borderRadius: "20px",
                                padding: "8px 16px",
                                fontSize: "0.85rem",
                              }}
                            >
                              {item.entity}
                            </Badge>
                          </Card.Text>

                          <Card.Text className="text-center">
                            <span className="fw-semibold">Industry: </span>
                            {item.industry}
                          </Card.Text>

                          <Card.Text className="text-center">
                            <span className="fw-semibold">Purpose: </span>
                            {item.eventMessage || "No details available"}
                          </Card.Text>
                          <div className="text-center text-muted">
                            <div
                              className='fw-bold'
                              style={{
                                fontSize: "0.9rem",
                                fontStyle: "italic",
                              }}
                            >
                              {item.date}
                            </div>
                            <div
                              style={{
                                fontSize: "0.9rem",
                                fontStyle: "italic",
                              }}
                            >
                              {item.time} (IST)
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default B2bInterestedClient;
