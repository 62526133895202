import React, { useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import "./Register.css";
import { useNavigate } from "react-router";
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import { storeEmail } from "../../../store/userStore";
import infoImg from "../../../assets/infoimg.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import EnquiryComponent from "../../../components/basic/EnquiryComponent";
import DemoModal from "../../../components/basic/DemoModal";

const Registerpage1 = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showDemo, setShowDemo] = useState(false);
  const handleCloseDemo = () => setShowDemo(false);
  const handleShowDemo = () => setShowDemo(true);


  const handleMemberBenClick = () => {
    // Check if the click event originated from the button
    window.open(
      "https://www.aecci.org.in/membership-and-its-benefits/",
      "_blank"
    );
  };
  //functions
  const handleregisterclick1 = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.get(getURLbyEndPoint("checkEmail"), {
        params: { email },
      });

      console.log(res.data.exists);

      if (res.data.exists) {
        console.log("Email already exists. Please try a different email.");
        alert("Email already exists. Please try a different email.");
      } else {
        // Email doesn't exist, proceed with registration
        storeEmail(email);
        navigate("/register-2", { state: { email } });
      }
      return res.data.exists;
    } catch (error) {
      console.error("Error checking email:", error);
      return false; // Assume email check failure
    }
  };
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center">
          <EPlatformHeader
            text="Embark on a new era of seamless business solutions! AECCI proudly unveils its upgraded e-Platform, now featuring digital
services, expert consultancy, shipping document attestation, visa recommendation letter issuance, and robust support for
Trade disputes &amp; Arbitration matters—all under one roof. Elevate your experience and streamline success with AECCI&#39;s
enhanced capabilities!"
          />
          <div className="d-flex my-4 flex-column  align-items-center">
            {/* <div>
              <button type="button">Back</button>
            </div> */}
            <div className="align-items-start main-width">
              <h1>JOIN NOW</h1>
              <p>
                Joining the Chamber is easy, simply start by entering your email
                address and follow the step-by-step instructions. Fill in the
                form below and once the Membership is allotted you will be
                eligible to access the e-services of the chamber. If at any
                point you need assistance, you can call our membership’s desk
                on:
                <br /> Board Line 022-41271145/46 or 8433720996
              </p>
            </div>
            <div className="align-items-start main-width"> 
            <h6 className="text-left" style={{ fontSize: "1.3rem", cursor: "pointer", color: "#5495ff" }}  onClick={handleShowDemo}> How to Register on AECCI ePlatform ?
      </h6>
      <DemoModal show={showDemo} handleCloseDemo={handleCloseDemo} heading="How to Register on AECCI ePlatform?" videoUrl="https://aecci-bucket-new.s3.amazonaws.com/aecci+assets/signUpDraft.mp4" />
            </div>
            <div className="align-items-start main-width">
              <div className="d-flex align-items-center">
                {" "}
                <h4> ENTER YOUR EMAIL ADDRESS</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Enter your company's official and valid email address
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
              <form
                className="mt-4 d-flex"
                style={{ width: "30vw" }}
                onSubmit={handleregisterclick1}
              >
                <input
                  className="p-1 rounded-1 w-100"
                  type="email"
                  placeholder="EMAIL ADDRESS"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  required
                  maxLength={50}
                />
                <button
                  className="px-2 py-1 ml-1 bg-color-2 rounded-1"
                  type="submit"
                >
                  NEXT
                </button>
              </form>
            </div>
            <div className="mt-4 mb-2 align-items-start main-width">
              <button
                className="p-2 px-4 bg-color rounded-1 border-0"
                onClick={() => handleMemberBenClick()}
              >
                <span>EXPLORE THE MEMBERSHIP BENEFITS</span>
              </button>
            </div>
          </div>

          <EnquiryComponent />
        </div>
      </Layout>
    </>
  );
};

export default Registerpage1;
