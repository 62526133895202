import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getURLbyEndPoint } from "../../../store/api";
import axios from "axios";
import { FaLocationDot } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import { getAuthUserlocal } from "../../../store/services";
import EventTrack from "../../../components/trackDoc/eventTrack";
import { FaVideo } from "react-icons/fa";
import jsPDF from "jspdf";
import Logo from "../../../assets/logo.jpg";
import { numberToWords } from "../../../components/basic/numberToWords";
import SideBar1 from "../../../components/Sidebar/SideBar1";
import EventBanner from "../../../assets/popupBanners/WebinarOnEgypt.png";

const ChamberEventBooking = () => {
  const [selectedTab, setSelectedTab] = useState("webinar");
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);
  const [activePage, setActivePage] = useState("eventBooking");

  const handleTabSelect = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const handlePastEventClick = () => {
    window.open("https://www.aecci.org.in/events/past-events/", "_blank");
  };

  const getEvents = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getEvents") + selectedTab
      );
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching Events:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, [selectedTab]);

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <h4 className="mt-4 mb-3">CHAMBER EVENT BOOKINGS</h4>

        <hr className="mb-2" />
        <>
          {activePage === "eventBooking" ? (
            <>
              <div className="d-flex gap-2 mb-2">
                <button
                  className="btn btn-outline-primary w-50"
                  onClick={() => setActivePage("eventBookingTrack")}
                >
                  Track Your Booking
                </button>
                <button
                  className="btn btn-outline-success w-50"
                  onClick={handlePastEventClick}
                >
                  View Past Events
                </button>
              </div>
              <Tabs
                defaultActiveKey="webinar"
                activeKey={selectedTab}
                onSelect={handleTabSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab
                  eventKey="webinar"
                  title={<h6 className="tab-title">WEBINAR</h6>}
                  style={{
                    // backgroundColor: selectedTab === "webinar" ? "blue" : "inherit",
                    color: selectedTab === "webinar" ? "white" : "inherit",
                  }}
                >
                  <div className="mt-5" />
                  {selectedTab === "webinar" && (
                    <WebinarTabContent events={events} />
                  )}
                </Tab>
                <Tab
                  eventKey="workshop"
                  title={<h6 className="tab-title">SEMINAR / WORKSHOP</h6>}
                  style={{
                    // backgroundColor: selectedTab === "workshop" ? "blue" : "inherit",
                    color: selectedTab === "workshop" ? "white" : "inherit",
                  }}
                >
                  <div className="mt-5" />
                  {selectedTab === "workshop" && (
                    <WorkshopsTabContent events={events} />
                  )}
                </Tab>
                <Tab
                  eventKey="livesession"
                  title={<h6 className="tab-title">LIVE SESSIONS</h6>}
                  style={{
                    // backgroundColor: selectedTab === "livesession" ? "blue" : "inherit",
                    color: selectedTab === "livesession" ? "white" : "inherit",
                  }}
                >
                  <div className="mt-5" />
                  {selectedTab === "livesession" && (
                    <LiveSessionsTabContent events={events} />
                  )}
                </Tab>
              </Tabs>
            </>
          ) : (
            <>
              <div className="d-flex mb-2 justify-content-end">
                <button
                  className="btn btn-outline-primary w-50"
                  onClick={() => setActivePage("eventBooking")}
                >
                  Back
                </button>
              </div>
              <EventTrack />
            </>
          )}
        </>
      </Layout2>
    </Layout>
  );
};

export default ChamberEventBooking;

const WebinarTabContent = ({ events }) => {
  const [showModal, setShowModal] = useState({});
  const [selectedEvent, setSelectedEvent] = useState();
  const [apiSubmitEvent, setApiSubmitEvent] = useState();
  const [formData, setFormData] = useState({
    name: "",
    numberOfParticipant: "1",
    email: "",
    totalAmt: "",
    receipt: null,
  });

  const handleCreateReceipt = async (selectedEvent) => {
    const pdf = new jsPDF();

    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45);
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);

    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 70);
    const addLineHeight = 5;
    let addLinePos;
    addressLines.forEach((line, index) => {
      addLinePos = 97 + index * addLineHeight;
      pdf.text(line, 30, addLinePos);
    });

    const detailStartY = addLinePos + 10;

    // Second column
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);

    pdf.line(15, detailStartY, 195, detailStartY); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];
    const data = [];
    const capEventType = selectedEvent.eventType.toUpperCase();
    const smallTitleLines = pdf.splitTextToSize(
      selectedEvent.title.toLowerCase(),
      50
    );

    data.push([
      "1",
      capEventType, // Event type in uppercase
      `${formData.numberOfParticipant}`,
      `Rs. ${selectedEvent.fee}/-`,
      `Rs. ${formData.totalAmt}/-`,
    ]);

    const startY = detailStartY + 10;
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40];
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    let currentY = headerLineY + lineHeight;
    data.forEach((row, rowIndex) => {
      currentX = startX;

      row.forEach((cell, cellIndex) => {
        if (cellIndex === 1) {
          pdf.text(cell, currentX, currentY);
          smallTitleLines.forEach((line, lineIndex) => {
            pdf.text(line, currentX, currentY + (lineIndex + 1) * 5);
          });
        } else {
          pdf.text(cell, currentX, currentY);
        }
        currentX += columnWidths[cellIndex];
      });

      const lineY = currentY + 10 + (smallTitleLines.length - 1) * 5;
      pdf.line(startX, lineY, 195, lineY);
      currentY = lineY + lineHeight;
    });

    const totalY = currentY;
    pdf.text("Total:", 15, totalY);
    pdf.text(`Rs. ${formData.totalAmt}/-`, 175, totalY);

    pdf.line(15, totalY + 5, 195, totalY + 5); // horizontal line

    const amountInWordsY = totalY + 10;
    pdf.text(
      `Amount In words(in INR): ${numberToWords(formData.totalAmt)} rupee`,
      15,
      amountInWordsY
    );

    pdf.line(15, amountInWordsY + 5, 195, amountInWordsY + 5); // horizontal line

    const signatoryY = amountInWordsY + 15;
    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      signatoryY,
      { align: "right" }
    );
    pdf.text("Authorised Signatory", 195, signatoryY + 10, { align: "right" });

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      signatoryY + 10
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  useEffect(() => {
    if (selectedEvent) {
      const initialTotalAmt =
        selectedEvent.fee * parseInt(formData.numberOfParticipant);
      setFormData((prevState) => ({ ...prevState, totalAmt: initialTotalAmt }));
    }
  }, [selectedEvent, formData.numberOfParticipant]);

  const handleNumParticipantsChange = (e) => {
    const numParticipants = parseInt(e.target.value);
    const totalAmt = selectedEvent.fee * numParticipants;
    setFormData({
      ...formData,
      numberOfParticipant: numParticipants,
      totalAmt: totalAmt,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegisterEvent = async () => {
    const userId = getAuthUserlocal()._id;
    await handleCreateReceipt(selectedEvent);
    const formDataEventRegistration = new FormData();
    try {
      formDataEventRegistration.append("heading", selectedEvent.heading);
      formDataEventRegistration.append("title", selectedEvent.title);
      formDataEventRegistration.append("fee", selectedEvent.fee);
      formDataEventRegistration.append("eventType", selectedEvent.eventType);
      formDataEventRegistration.append("eventDate", selectedEvent.eventDate);
      formDataEventRegistration.append("eventTime", selectedEvent.eventTime);
      formDataEventRegistration.append(
        "description",
        selectedEvent.description
      );
      formDataEventRegistration.append("eventUrl", selectedEvent.eventUrl);
      formDataEventRegistration.append("location", selectedEvent.location);
      formDataEventRegistration.append("name", formData.name);
      formDataEventRegistration.append(
        "numberOfParticipant",
        formData.numberOfParticipant
      );
      formDataEventRegistration.append("email", formData.email);
      formDataEventRegistration.append("totalAmt", formData.totalAmt);

      formDataEventRegistration.append("receipt", formData.receipt);

      setApiSubmitEvent(true);
      const response = await axios.post(
        getURLbyEndPoint("registerEvent") + userId,
        formDataEventRegistration
      );
      if (response.data.status) {
        alert("Registered successfully!!");
        window.location.reload();
        setApiSubmitEvent(true);
      } else {
        alert(response.data.message);
        window.location.reload();
        setApiSubmitEvent(false);
      }
    } catch (error) {
      console.error("Error while submitting:", error);
      alert("Error occurred! Please try again.");
      setApiSubmitEvent(false);
    }
  };

  const handleShow = (eventId) => {
    setSelectedEvent(events.find((event) => event._id === eventId));
    setShowModal((prevState) => ({ ...prevState, [eventId]: true }));
  };

  const handleClose = (eventId) => {
    setShowModal((prevState) => ({ ...prevState, [eventId]: false }));
  };

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const timeObj = new Date();
    timeObj.setHours(hours, minutes);
    const formattedTime = timeObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  }

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <div className="w-75">
      {events.map((event, index) => {
        const eventDate = new Date(event.eventDate);
        const month = monthNames[eventDate.getMonth()];
        const day = eventDate.getDate();

        return (
          <>
            <Card className="my-3" key={index}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{event.heading}</h4>
                  <p>
                    <span className="text-primary fs-5">
                      <IoTime />
                      {convertTo12HourFormat(event.eventTime)}
                    </span>
                  </p>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="my-2 d-flex gap-4">
                  <div
                    className="d-flex align-items-center justify-content-center bg-primary p-2 rounded"
                    style={{ width: "16%", height: "100%" }}
                  >
                    <h4 className="text-center text-white">
                      {day}
                      <br />
                      {month}
                      <br />
                      <h6 className="mt-2">2024</h6>
                    </h4>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div>
                      <Card.Title>{event.title}</Card.Title>
                      <p>{event.description}</p>
                      <Card.Text className="text-success">
                        <FaVideo /> {event.location}
                      </Card.Text>
                      <div className="d-flex justify-content-between mt-4 align-items-center">
                        <a
                          className="btn btn-outline-primary"
                          href={event.eventUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>

                        <Button
                          className="mr-4"
                          variant="outline-success"
                          onClick={() => handleShow(event._id)}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Modal
              show={showModal[event._id]}
              onHide={() => handleClose(event._id)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Webinar registration</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="text-left">
                  {event.title}
                  <br /> ({event.heading})
                </h5>
                <h6 className="text-right">Participants fee: {event.fee}/-</h6>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      placeholder="Enter Your Name"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Label>No Of Participants</Form.Label>
                  <Form.Select
                    onChange={handleNumParticipantsChange}
                    aria-label="Default select example"
                    value={formData.numberOfParticipant}
                  >
                    {[...Array(4).keys()].map((num) => (
                      <option key={num} value={num + 1}>
                        {num + 1}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="name@example.com"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <h6 className="text-right">
                    Total Amount: {formData.totalAmt}
                  </h6>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  disabled={apiSubmitEvent}
                  onClick={() => handleRegisterEvent(event)}
                >
                  {apiSubmitEvent ? "Processing..." : "SUBMIT"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      })}
    </div>
  );
};

const WorkshopsTabContent = ({ events }) => {
  const [showModal, setShowModal] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [apiSubmitEvent, setApiSubmitEvent] = useState();
  const [formData, setFormData] = useState({
    name: "",
    numberOfParticipant: "1",
    email: "",
    totalAmt: "",
    receipt: null,
  });

  const handleCreateReceipt = async (selectedEvent) => {
    const pdf = new jsPDF();

    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45);
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);

    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 70);
    const addLineHeight = 5;
    let addLinePos;
    addressLines.forEach((line, index) => {
      addLinePos = 97 + index * addLineHeight;
      pdf.text(line, 30, addLinePos);
    });

    const detailStartY = addLinePos + 10;

    // Second column
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);

    pdf.line(15, detailStartY, 195, detailStartY); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];
    const data = [];
    const capEventType = selectedEvent.eventType.toUpperCase();
    const smallTitleLines = pdf.splitTextToSize(
      selectedEvent.title.toLowerCase(),
      50
    );

    data.push([
      "1",
      capEventType, // Event type in uppercase
      `${formData.numberOfParticipant}`,
      `Rs. ${selectedEvent.fee}/-`,
      `Rs. ${formData.totalAmt}/-`,
    ]);

    const startY = detailStartY + 10;
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40];
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    let currentY = headerLineY + lineHeight;
    data.forEach((row, rowIndex) => {
      currentX = startX;

      row.forEach((cell, cellIndex) => {
        if (cellIndex === 1) {
          pdf.text(cell, currentX, currentY);
          smallTitleLines.forEach((line, lineIndex) => {
            pdf.text(line, currentX, currentY + (lineIndex + 1) * 5);
          });
        } else {
          pdf.text(cell, currentX, currentY);
        }
        currentX += columnWidths[cellIndex];
      });

      const lineY = currentY + 10 + (smallTitleLines.length - 1) * 5;
      pdf.line(startX, lineY, 195, lineY);
      currentY = lineY + lineHeight;
    });

    const totalY = currentY;
    pdf.text("Total:", 15, totalY);
    pdf.text(`Rs. ${formData.totalAmt}/-`, 175, totalY);

    pdf.line(15, totalY + 5, 195, totalY + 5); // horizontal line

    const amountInWordsY = totalY + 10;
    pdf.text(
      `Amount In words(in INR): ${numberToWords(formData.totalAmt)} rupee`,
      15,
      amountInWordsY
    );

    pdf.line(15, amountInWordsY + 5, 195, amountInWordsY + 5); // horizontal line

    const signatoryY = amountInWordsY + 15;
    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      signatoryY,
      { align: "right" }
    );
    pdf.text("Authorised Signatory", 195, signatoryY + 10, { align: "right" });

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      signatoryY + 10
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  useEffect(() => {
    if (selectedEvent) {
      const initialTotalAmt =
        selectedEvent.fee * parseInt(formData.numberOfParticipant);
      setFormData((prevState) => ({ ...prevState, totalAmt: initialTotalAmt }));
    }
  }, [selectedEvent, formData.numberOfParticipant]);

  const handleNumParticipantsChange = (e) => {
    const numParticipants = parseInt(e.target.value);
    const totalAmt = selectedEvent.fee * numParticipants;
    setFormData({
      ...formData,
      numberOfParticipant: numParticipants,
      totalAmt: totalAmt,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegisterEvent = async () => {
    const userId = getAuthUserlocal()._id;
    await handleCreateReceipt(selectedEvent);
    const formDataEventRegistration = new FormData();
    try {
      formDataEventRegistration.append("heading", selectedEvent.heading);
      formDataEventRegistration.append("title", selectedEvent.title);
      formDataEventRegistration.append("fee", selectedEvent.fee);
      formDataEventRegistration.append("eventType", selectedEvent.eventType);
      formDataEventRegistration.append("eventDate", selectedEvent.eventDate);
      formDataEventRegistration.append("eventTime", selectedEvent.eventTime);
      formDataEventRegistration.append(
        "description",
        selectedEvent.description
      );
      formDataEventRegistration.append("eventUrl", selectedEvent.eventUrl);
      formDataEventRegistration.append("location", selectedEvent.location);
      formDataEventRegistration.append("name", formData.name);
      formDataEventRegistration.append(
        "numberOfParticipant",
        formData.numberOfParticipant
      );
      formDataEventRegistration.append("email", formData.email);
      formDataEventRegistration.append("totalAmt", formData.totalAmt);

      formDataEventRegistration.append("receipt", formData.receipt);

      setApiSubmitEvent(true);
      const response = await axios.post(
        getURLbyEndPoint("registerEvent") + userId,
        formDataEventRegistration
      );
      if (response.data.status) {
        alert("Registered successfully!!");
        window.location.reload();
        setApiSubmitEvent(true);
      } else {
        alert(response.data.message);
        window.location.reload();
        setApiSubmitEvent(false);
      }
    } catch (error) {
      console.error("Error while submitting:", error);
      alert("Error occurred! Please try again.");
      setApiSubmitEvent(false);
    }
  };

  const handleShow = (eventId) => {
    setSelectedEvent(events.find((event) => event._id === eventId)); // Set the selected event
    setShowModal((prevState) => ({ ...prevState, [eventId]: true })); // Show the modal for the selected event
  };

  const handleClose = (eventId) => {
    setShowModal((prevState) => ({ ...prevState, [eventId]: false })); // Hide the modal for the selected event
  };

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const timeObj = new Date();
    timeObj.setHours(hours, minutes);
    const formattedTime = timeObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  }

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <div className="w-75">
      {events.map((event, index) => {
        const eventDate = new Date(event.eventDate);
        const month = monthNames[eventDate.getMonth()];
        const day = eventDate.getDate();

        return (
          <>
            <Card className="my-3" key={index}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{event.heading}</h4>
                  <p>
                    <span className="text-primary fs-5">
                      <IoTime />
                      {convertTo12HourFormat(event.eventTime)}
                    </span>
                  </p>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="my-2 d-flex gap-4">
                  <div
                    className="d-flex align-items-center justify-content-center bg-primary p-2 rounded"
                    style={{ width: "16%", height: "100%" }}
                  >
                    <h4 className="text-center text-white">
                      {day}
                      <br />
                      {month}
                      <br />
                      <h6 className="mt-2">2024</h6>
                    </h4>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div>
                      <Card.Title>{event.title}</Card.Title>
                      <p>{event.description}</p>
                      <Card.Text className="text-success">
                        <FaVideo /> {event.location}
                      </Card.Text>
                      <div className="d-flex justify-content-between mt-4 align-items-center">
                        <a
                          className="btn btn-outline-primary"
                          href={event.eventUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>

                        <Button
                          className="mr-4"
                          variant="outline-success"
                          onClick={() => handleShow(event._id)}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Modal
              show={showModal[event._id]}
              onHide={() => handleClose(event._id)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Seminar / Workshop registration</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="text-left">
                  {event.title}
                  <br /> ({event.heading})
                </h5>
                <h6 className="text-right">Participants fee: {event.fee}/-</h6>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      placeholder="Enter Your Name"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Label>No Of Participants</Form.Label>
                  <Form.Select
                    onChange={handleNumParticipantsChange}
                    aria-label="Default select example"
                    value={formData.numberOfParticipant}
                  >
                    {[...Array(4).keys()].map((num) => (
                      <option key={num} value={num + 1}>
                        {num + 1}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="name@example.com"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <h6 className="text-right">
                    Total Amount: {formData.totalAmt}
                  </h6>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  disabled={apiSubmitEvent}
                  onClick={() => handleRegisterEvent(event)}
                >
                  {apiSubmitEvent ? "Processing..." : "SUBMIT"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      })}
    </div>
  );
};

const LiveSessionsTabContent = ({ events }) => {
  const [showModal, setShowModal] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [apiSubmitEvent, setApiSubmitEvent] = useState();
  const [formData, setFormData] = useState({
    name: "",
    numberOfParticipant: "1",
    email: "",
    totalAmt: "",
    receipt: null,
  });

  const handleCreateReceipt = async (selectedEvent) => {
    const pdf = new jsPDF();

    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45);
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);

    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 70);
    const addLineHeight = 5;
    let addLinePos;
    addressLines.forEach((line, index) => {
      addLinePos = 97 + index * addLineHeight;
      pdf.text(line, 30, addLinePos);
    });

    const detailStartY = addLinePos + 10;

    // Second column
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);

    pdf.line(15, detailStartY, 195, detailStartY); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];
    const data = [];
    const capEventType = selectedEvent.eventType.toUpperCase();
    const smallTitleLines = pdf.splitTextToSize(
      selectedEvent.title.toLowerCase(),
      50
    );

    data.push([
      "1",
      capEventType, // Event type in uppercase
      `${formData.numberOfParticipant}`,
      `Rs. ${selectedEvent.fee}/-`,
      `Rs. ${formData.totalAmt}/-`,
    ]);

    const startY = detailStartY + 10;
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40];
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    let currentY = headerLineY + lineHeight;
    data.forEach((row, rowIndex) => {
      currentX = startX;

      row.forEach((cell, cellIndex) => {
        if (cellIndex === 1) {
          pdf.text(cell, currentX, currentY);
          smallTitleLines.forEach((line, lineIndex) => {
            pdf.text(line, currentX, currentY + (lineIndex + 1) * 5);
          });
        } else {
          pdf.text(cell, currentX, currentY);
        }
        currentX += columnWidths[cellIndex];
      });

      const lineY = currentY + 10 + (smallTitleLines.length - 1) * 5;
      pdf.line(startX, lineY, 195, lineY);
      currentY = lineY + lineHeight;
    });

    const totalY = currentY;
    pdf.text("Total:", 15, totalY);
    pdf.text(`Rs. ${formData.totalAmt}/-`, 175, totalY);

    pdf.line(15, totalY + 5, 195, totalY + 5); // horizontal line

    const amountInWordsY = totalY + 10;
    pdf.text(
      `Amount In words(in INR): ${numberToWords(formData.totalAmt)} rupee`,
      15,
      amountInWordsY
    );

    pdf.line(15, amountInWordsY + 5, 195, amountInWordsY + 5); // horizontal line

    const signatoryY = amountInWordsY + 15;
    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      signatoryY,
      { align: "right" }
    );
    pdf.text("Authorised Signatory", 195, signatoryY + 10, { align: "right" });

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      signatoryY + 10
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  useEffect(() => {
    if (selectedEvent) {
      const initialTotalAmt =
        selectedEvent.fee * parseInt(formData.numberOfParticipant);
      setFormData((prevState) => ({ ...prevState, totalAmt: initialTotalAmt }));
    }
  }, [selectedEvent, formData.numberOfParticipant]);

  const handleNumParticipantsChange = (e) => {
    const numParticipants = parseInt(e.target.value);
    const totalAmt = selectedEvent.fee * numParticipants;
    setFormData({
      ...formData,
      numberOfParticipant: numParticipants,
      totalAmt: totalAmt,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegisterEvent = async () => {
    const userId = getAuthUserlocal()._id;
    await handleCreateReceipt(selectedEvent);
    const formDataEventRegistration = new FormData();
    try {
      formDataEventRegistration.append("heading", selectedEvent.heading);
      formDataEventRegistration.append("title", selectedEvent.title);
      formDataEventRegistration.append("fee", selectedEvent.fee);
      formDataEventRegistration.append("eventType", selectedEvent.eventType);
      formDataEventRegistration.append("eventDate", selectedEvent.eventDate);
      formDataEventRegistration.append("eventTime", selectedEvent.eventTime);
      formDataEventRegistration.append(
        "description",
        selectedEvent.description
      );
      formDataEventRegistration.append("eventUrl", selectedEvent.eventUrl);
      formDataEventRegistration.append("location", selectedEvent.location);
      formDataEventRegistration.append("name", formData.name);
      formDataEventRegistration.append(
        "numberOfParticipant",
        formData.numberOfParticipant
      );
      formDataEventRegistration.append("email", formData.email);
      formDataEventRegistration.append("totalAmt", formData.totalAmt);

      formDataEventRegistration.append("receipt", formData.receipt);

      setApiSubmitEvent(true);
      const response = await axios.post(
        getURLbyEndPoint("registerEvent") + userId,
        formDataEventRegistration
      );
      if (response.data.status) {
        alert("Registered successfully!!");
        window.location.reload();
        setApiSubmitEvent(true);
      } else {
        alert(response.data.message);
        window.location.reload();
        setApiSubmitEvent(false);
      }
    } catch (error) {
      console.error("Error while submitting:", error);
      alert("Error occurred! Please try again.");
      setApiSubmitEvent(false);
    }
  };

  const handleShow = (eventId) => {
    setSelectedEvent(events.find((event) => event._id === eventId)); // Set the selected event
    setShowModal((prevState) => ({ ...prevState, [eventId]: true })); // Show the modal for the selected event
  };

  const handleClose = (eventId) => {
    setShowModal((prevState) => ({ ...prevState, [eventId]: false })); // Hide the modal for the selected event
  };

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const timeObj = new Date();
    timeObj.setHours(hours, minutes);
    const formattedTime = timeObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  }

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <div className="w-75">
      {events.map((event, index) => {
        const eventDate = new Date(event.eventDate);
        const month = monthNames[eventDate.getMonth()];
        const day = eventDate.getDate();

        return (
          <>
            <Card className="my-3" key={index}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{event.heading}</h4>
                  <p>
                    <span className="text-primary fs-5">
                      <IoTime />
                      {convertTo12HourFormat(event.eventTime)}
                    </span>
                  </p>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="my-2 d-flex gap-4">
                  <div
                    className="d-flex align-items-center justify-content-center bg-primary p-2 rounded"
                    style={{ width: "16%", height: "100%" }}
                  >
                    <h4 className="text-center text-white">
                      {day}
                      <br />
                      {month}
                      <br />
                      <h6 className="mt-2">2024</h6>
                    </h4>
                  </div>
                  <div style={{ width: "80%" }}>
                    <div>
                      <Card.Title>{event.title}</Card.Title>
                      <p>{event.description}</p>
                      <Card.Text className="text-success">
                        <FaVideo /> {event.location}
                      </Card.Text>
                      <div className="d-flex justify-content-between mt-4 align-items-center">
                        <a
                          className="btn btn-outline-primary"
                          href={event.eventUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>

                        <Button
                          className="mr-4"
                          variant="outline-success"
                          onClick={() => handleShow(event._id)}
                        >
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Modal
              show={showModal[event._id]}
              onHide={() => handleClose(event._id)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Live Session registration</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="text-left">
                  {event.title}
                  <br /> ({event.heading})
                </h5>
                <h6 className="text-right">Participants fee: {event.fee}/-</h6>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      placeholder="Enter Your Name"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Label>No Of Participants</Form.Label>
                  <Form.Select
                    onChange={handleNumParticipantsChange}
                    aria-label="Default select example"
                    value={formData.numberOfParticipant}
                  >
                    {[...Array(4).keys()].map((num) => (
                      <option key={num} value={num + 1}>
                        {num + 1}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="name@example.com"
                      onChange={handleInputChange}
                      autoFocus
                    />
                  </Form.Group>
                  <h6 className="text-right">
                    Total Amount: {formData.totalAmt}
                  </h6>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  disabled={apiSubmitEvent}
                  onClick={() => handleRegisterEvent(event)}
                >
                  {apiSubmitEvent ? "Processing..." : "SUBMIT"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      })}
    </div>
  );
};
