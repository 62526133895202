import React, { useState } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoImg from "../../assets/infoimg.png";

const ChangePassword = () => {
  const [cpFromData, setCPFromData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCPFromData((setCPFromData) => ({
      ...setCPFromData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    // Reset form fields
    setCPFromData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleChangepassFormSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex =
      /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z]?)(?=.*[!@#$%^&*()_+[\]{}|;:',.<>?~\\/-]).{8,15}$/;

    const { currentPassword, newPassword, confirmPassword } = cpFromData;

    if (!passwordRegex.test(currentPassword)) {
      toast.error(
        "Current Password must be 8-15 characters, contain at least one lowercase letter, one number, and may include one uppercase letter and one special character."
      );
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "New Password must be 8-15 characters, contain at least one lowercase letter, one number, and may include one uppercase letter and one special character."
      );
      return;
    }

    if (newPassword === currentPassword) {
      toast.error("New password cannot be the same as the current password.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.post(
        getURLbyEndPoint("changePassword") + getAuthUserlocal()._id,
        {
          ...cpFromData,
        }
      );

      // Check if response.data is defined
      if (response.data) {
        // Assuming the API returns a success status (e.g., 200)
        if (response.status === 200) {
          // Handle success
          console.log("Password change successful");
          handleReset();
          console.log(response.data.message);
          toast.success(response.data.message);
        } else {
          // Handle other response statuses
          console.error("Password change failed with status", response.status);
          // You might want to show an error message to the user
          toast.error("Password change failed. Please try again.");
        }
      } else {
        // Handle the case where response.data is undefined
        console.error("Invalid response format. Data property is undefined.");
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error during password change", error);
      toast.error("Internal Server error");
    }
  };

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="d-flex flex-column dashboardBlock">
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">CHANGE PASSWORD</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      If you have forgotten your current password or suspect any
                      unauthorized access, you can initiate with Change
                      password. Remember to keep your password confidential and
                      avoid sharing it with anyone.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
            </div>
            <hr className="mt-1 mb-4" />
            <form onSubmit={handleChangepassFormSubmit}>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-normal fs-6 w-25">
                  ENTER <b className="text-secondary">CURRENT</b> PASSWORD{" "}
                </label>
                <input
                  style={{ width: "60%" }}
                  className="form-control rounded-0 ms-5"
                  onChange={handleInputChange}
                  value={cpFromData.currentPassword}
                  name="currentPassword"
                  type="text"
                  required
                />
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-normal fs-6 w-25">
                  ENTER <b className="text-secondary">NEW</b> PASSWORD{" "}
                </label>
                <input
                  style={{ width: "60%" }}
                  className="form-control rounded-0 ms-5"
                  onChange={handleInputChange}
                  value={cpFromData.newPassword}
                  name="newPassword"
                  type="password"
                  required
                />
              </div>
              <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                <label className="fw-normal fs-6 w-25">
                  CONFIRM <b className="text-secondary">NEW</b> PASSWORD{" "}
                </label>
                <input
                  style={{ width: "60%" }}
                  className="form-control rounded-0 ms-5"
                  onChange={handleInputChange}
                  value={cpFromData.confirmPassword}
                  name="confirmPassword"
                  type="text"
                  required
                />
              </div>
              <div className=" d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary mt-4 p-2 w-25"
                  style={{ backgroundColor: "#0b3b5d", border: "0" }}
                >
                  UPDATE
                </button>
              </div>
            </form>
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default ChangePassword;
