import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Img1 from "../../assets/membership images/Smallbuissnessimg.png";
import Img2 from "../../assets/membership images/Start-Up.png";
import Img3 from "../../assets/membership images/Corporate.png";
import Img4 from "../../assets/membership images/Corporate-1.png";
import Img5 from "../../assets/membership images/Overseas.png";
import Img6 from "../../assets/membership images/Non-profit.png";
// import Button from "react-bootstrap/Button";

import { Row, Col } from "react-bootstrap";

const AccordionSection = () => {
  const ContentWithImg = [
    {
      "SMALL BUSINESS": Img1,
      "PERFECT FOR":
        "Any individual or organization including proprietorship, partnership, Professional, LLP, LLC or other SME’s with 15 or fewer employees, and no other offices worldwide engaged in trade, commerce or economic welfare of the community can be our Small Business (Associate) Member. Members receive a wide range of benefits providing every company a platform for networking and brand exposure; with valuable information and insight; to any assistance in matters pertaining to Legal advocacy.",
      BENEFITS: [
        "Certificate of Membership",
        "Certificate of Origin (Non-Preferential)",
        "Digital Platform access for e-CO service",
        "VISA Recommendation",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ discounted rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Assistance in Handling International Payment Disputes (Arbitration)",
      ],
    },
    {
      "START UP": Img2,
      "PERFECT FOR":
        "For start-up companies such as IT sector, real estate sector, Healthcare tech, delivery services, Artificial Intelligence, e-commerce, B2B Software, Supply chain management & logistics etc. which is less than a year old and have no other offices worldwide. A copy of the organization’s Certificate of Incorporation must be submitted upon application. For the company’s first 2 years of membership, they can enjoy Start-up Membership fees wherein Members receive a wide range of benefits providing every company a platform for networking and brand exposure; with valuable information and insight; to any assistance in matters pertaining to Legal advocacy. After the 2 years, Start-up Members must upgrade to Small Business or Corporate Membership to remain a Chamber member.",
      BENEFITS: [
        "Certificate of Membership",
        "VISA Recommendation",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ discounted rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Focus on HR Solutions for members to help them grow their business and manage Human Resource and labor law needs",
        "Assistance to deal with day-to-day issues in new/existing business, their expansion, and diversification plans",
        "Use of Chamber Logo",
        "Networking opportunities with other Members that help you to develop the partnership within the business community",
        "Legal assistance in matters pertaining to Legal advocacy",
      ],
    },
    {
      CORPORATE: Img3, // Replace ImgCorporate with the actual image for the Corporate category
      "PERFECT FOR":
        "AECCI creates an environment for the members to build mutually beneficial and lasting business relationships through various events and thus AECCI’s Corporate Membership can be acquired by Private Limited Companies. Members receive a wide range of benefits providing organizations a platform for networking and brand exposure; information and insight; and representation and advocacy.",
      BENEFITS: [
        "Certificate of Membership",
        "Certificate of Origin (Non-Preferential)",
        "Digital Platform access for e-CO service",
        "VISA Recommendation",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ discounted rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Assistance in Handling International Payment Disputes (Arbitration)",
        "Focus on HR Solutions for members who help them to grow their business and manage Human Resource and labor law needs",
        "Use of Chamber Logo",
        "Legal assistance in matters pertaining to legal advocacy",
        "Advocacy charges applicable",
      ],
    },
    {
      "CORPORATE+": Img4, // Replace ImgCorporatePlus with the actual image for the Corporate+ category
      "PERFECT FOR":
        "AECCI provides an environment for the members, benefits both the parties and increase business relationships through various events and thus AECCI’s Corporate+ Membership can be acquired by Banks or Limited companies or any Public listed Companies. Members receive a wide range of benefits providing every company a platform for networking and brand exposure; with valuable information and insight; to any assistance in matters pertaining to Legal advocacy.",
      BENEFITS: [
        "Certificate of Membership",
        "Certificate of Origin (Non-Preferential)",
        "Digital Platform access for e-CO service",
        "VISA Recommendation",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ discounted rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Assistance in Handling International Payment Disputes (Arbitration)",
        "Focus on HR Solutions for members who help them to grow their business and manage Human Resource and labor law needs",
        "Preferential advertising rates on the website, Daily Viewpoint, Newsletter, Annual Magazines and direct marketing tools",
        "Use of Chamber Logo",
        "Legal assistance in matters pertaining to legal advocacy",
        "ADD ON SERVICES",
        "Complimentary advertisement of joining company – viewpoint – full page; Quaterly Newsletter – Half",
        "Exposure of Business to Potential Audience – Your business is entitled to a link and a short profile in a special section on the AECCI website which attracts more than 10000 unique visitors a month; therefore we expose your business to a potential new audience.",
        "Opportunity to submit an article to be published in the Chamber magazine",
        "Volunteer/Sponsorship Opportunities",
        "Networking opportunities with other Members that help you to develop the partnership with others in your business community",
        "The Chamber magazine announces all new joining members alongside other joining companies",
      ],
    },
    {
      OVERSEAS: Img5, // Replace ImgOverseas with the actual image for the Overseas category
      "PERFECT FOR":
        "Companies who are based outside India of corporate and commercial field are eligible for Overseas Membership in AECCI. It provides direct contact with AECCI and its services such as assistance for Commercial Arbitration matters or to establish a new venture in India or fulfillment of compliance for every business need. Members receive a wide range of benefits providing every company a platform for networking with valuable information and insight; to any assistance in matters pertaining to Legal advocacy.",
      BENEFITS: [
        "Certificate of Membership",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Exposure of Business to Potential Audience – Your Company’s hallmark is entitled to the section of our prestigious Member on the AECCI website which attracts a daily visitor base of 500 + per day more than 10000 unique visitors a month.",
        "Assistance in Handling International Payment Disputes (Arbitration)",
        "Networking opportunities with other Members that help you to develop the partnership within the business community",
        "Volunteer/Sponsorship Opportunities",
      ],
    },
    {
      "NON-PROFIT ORGANIZATION": Img6, // Replace ImgNonProfit with the actual image for the Non-Profit Organization category
      "PERFECT FOR":
        "For Non-Profit organization such as Trust, Institutions, Foundations, centre’s etc. can be registered in AECCI as a Non-profit Organization Member. Proof of non-profit status is required upon application. Non-Profit Organization Members receive a wide range of benefits providing every company a platform for networking and brand exposure; with valuable information and insight; Of regular government update.",
      BENEFITS: [
        "Certificate of Membership",
        "Company Listing on Chamber’s digital Membership Directory",
        "Members’ discounted rates for all Chamber events",
        "Regular updates for the important circular/notifications from GOI",
        "Preferential advertising rates on the website, Daily Viewpoints, Newsletter, Annual Magazines and direct marketing tools",
        "Networking opportunities with other Members that help you to develop the partnership within the business community",
      ],
    },
  ];

  return (
    <Row xs={2} md={3} className="g-4 justify-content-center">
      {ContentWithImg.map((data, index) => (
        <Col key={index}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{Object.keys(data)[0]}</Accordion.Header>
              <Accordion.Body>
                {" "}
                <img
                  src={Object.values(data)[0]}
                  alt="Small Business"
                  width="100%"
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{Object.keys(data)[1]}</Accordion.Header>
              <Accordion.Body>{Object.values(data)[1]}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{Object.keys(data)[2]}</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {data["BENEFITS"].map((benefit, idx) => (
                    <li key={idx}>{benefit}</li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div className="text-center mt-3">
            <Button className="border-0" style={{ backgroundColor: "#0b3b5d" }}>
              Upgrade
            </Button>{" "}
          </div> */}
        </Col>
      ))}
    </Row>
  );
};

export default AccordionSection;
