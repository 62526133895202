import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoReaderOutline } from 'react-icons/io5';

const SideBar1 = () => {
 const [showModal, setShowModal] = useState(false);

 const handleCloseModal = () => setShowModal(false);
 const handleShowModal = () => setShowModal(true);

 return (
    <>
      <div
        className="d-flex align-item-center"
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className="p-4 my-0 py-20 rounded"
          style={{
            background: "rgb(0 22 67 / 92%)",
            position: "absolute",
            width: "17%",
            top: "740px",
            right: "0px",
          }}
        >
          <div style={{ marginBlock: "40px" }}>
            <img
              className="img-fluid"
              src="http://www.aecci.org.in/wp-content/uploads/2024/03/country-book.png"
              alt="country book"
            />
            <p className="text-weight-bold text-white mt-4 text-center">
            INDIA AND SAUDI
ARABIA ECONOMIC
AND COMMERCIAL
RELATIONS

            </p>
            <p
              className="text-weight-bold text-white text-center"
              style={{ color: "#ccc", fontSize: "10px" }}
            >
              India-Saudi Arabia Bilateral Relations: Explore the dynamic economic partnership between India and Saudi Arabia, emphasizing strategic alliances, investment opportunities, and trade policies. Discover the role of the Indian diaspora in strengthening cultural ties. Additionally, delve into energy cooperation, digital transformation, and sustainable development goals. {" "}
            </p>

            <div className="d-flex justify-content-center">
              <Button variant="primary" onClick={handleShowModal}>
                view Book <IoReaderOutline />
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://www.aecci.org.in/wp-content/uploads/2024/03/India-Saudi-Arabia-Book-4.pdf"
            style={{ width: '100%', height: '600px' }}
            title="Example Book PDF"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
 );
};

export default SideBar1;
