import React from "react";
import Table from "react-bootstrap/Table";

const Contactinfo = () => {
  return (
    <>
      <hr />
      <h4 className="text-center text-support my-1 text-color-heading">
        {" "}
        CONTACT OUR TEAM FOR MORE INFORMATION
      </h4>
      <hr />
      <div className="text-center d-flex justify-content-evenly mb-4">
        <b>Chamber desk no: 022-41271145/46/47 </b>
        <b>Mobile & WhatsApp No: 8433720996 </b>
        <b>E-mail id: info@aecci.org.in </b>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan={3} className="text-center fs-5 text-danger">
              Submission & Collection of Approved COOs:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">
              <div className="fs-6 fw-bold text-success">
                <span>For Electronic Certificate</span>
                <br />
                <span>of Origin (eCO)</span>
                <br />
                <span>(Printed by User): </span>
                <br />
              </div>
              <div className="fs-6 fw-bold text-success mt-2">
                <p>
                  24X7<span className="text-danger">*</span>
                </p>
              </div>
              <div
                className="fw-bold text-secondary mt-3"
                style={{ fontSize: "0.8rem" }}
              >
                <p>
                  <span className="text-danger">*</span>For perishable/
                  Agricultural Goods <br />
                  Exporters Only!
                </p>
              </div>
            </td>

            <td className="text-center">
              <div className="fs-6 fw-bold text-success">
                <span>For Semi Electronic Certificate of Origin (SeCO)</span>
                <br />
                <span>(Printed at Chamber desk):</span>
                <br />
              </div>

              <div className="fw-bold text-secondary mt-2">
                <p>
                  If, submit between 5:30 PM - 9:30 AM, Collect at
                  <br />
                  11:30 AM - 1:30 PM.
                </p>
              </div>
              <div className="fw-bold text-secondary mt-2">
                <p>
                  If, submit between 9:30 AM - 2 PM, Collect at <br />
                  4:00 PM - 6:00 PM.
                </p>
              </div>
            </td>

            <td className="text-center">
              <div className="fs-6 fw-bold text-success">
                <span>For Non-Standard </span>
                <br />
                <span>Documents </span>
                <br />
                <span>(Printed by User): </span>
                <br />
              </div>
              <div className="fs-6 fw-bold text-success mt-2">
                <p>
                  10 AM TO 5.30 PM<span className="text-danger">*</span>
                </p>
              </div>

              <div
                className="fw-bold text-secondary mt-3"
                style={{ fontSize: "0.8rem" }}
              >
                <p>
                  <span className="text-danger">*</span>Approval time varies
                  depending <br />
                  upon the time of submission
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="text-center">
    <h5 className="text-success fw-bold">

      Query Notes & Wing Consultation: 2 PM – 5.30 PM
    </h5>
      </div>
    </>
  );
};

export default Contactinfo;
