export function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    return `${formattedDate} ${formattedTime}`;
  }
  
  function padZero(num) {
    return num.toString().padStart(2, '0');
  }
  