import { getAuthUserlocal } from '../../store/services';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getURLbyEndPoint } from '../../store/api';
import { Form, Table, Pagination } from "react-bootstrap";
import { formatDateTime } from '../basic/SimpleFunctions';
import { PopUpModalButton } from '../basic/ModalComponent';

const ECoDataTrack = () => {

    const [eCoData, setECoData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")

    const fetchEcoData = async () => {
        try {
            const userId = getAuthUserlocal()._id;

            const response = await axios.get(
                getURLbyEndPoint("getUserEcoData") + userId
            );
            if (response.status === 200) {
                const data = response.data;
                setECoData(data.data);
                // alert("e-Co Data Retrived.");
            } else {
                console.error("Failed to fetch eCo data.");
            }
        } catch (error) {
            console.error("Error fetching eco data:", error);
            // alert("Error fetching data:", error);
        }
    };

   


    //filtering data
    const filteredData = eCoData.filter((data) => {
        // Filter based on account status
        if (
            searchTerm !== "" &&
            !data.appNo.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
            return false;
        }
        return true;
    });

    //filter data here
  // Pagination logic-----------------------------------------------------------
  // ------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Reset to the first page whenever the search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);


     useEffect(() => {
        fetchEcoData();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-end align-items-end mb-3">
                <Form.Control
                    className="form-control w-40"
                    type="search"
                    placeholder="Search By Application No."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div>
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Sr
                                <br /> No.
                            </th>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                App No &
                                <br />
                                Date
                            </th>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Exporter
                                <br />
                                Name
                            </th>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Invoice
                                <br />
                                No.
                            </th>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Reference
                                <br />
                                No
                            </th>

                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Download Document
                            </th>
                            <th
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                                className="text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    {filteredData.length > 0 ? (
                        <>
                            {currentFiltered.map((data, index) => (
                                <tbody>
                                    <tr>
                                        <td className="pb-0">{filteredData.length - indexOfFirstItem - index}.</td>
                                        <td className="pb-0">
                                            {data.appNo}{" "}<br/>
                                            {formatDateTime(data.createdAt)}
                                        </td>
                                        <td className="pb-0">
                                            {data.forComRegion}
                                        </td>
                                        <td className="pb-0">
                                            {data.noAndDateInvoice}
                                        </td>
                                        <td className="pb-0">
                                            {data?.refNo ? data.refNo : "--"}
                                        </td>

                                        <td>
                                            <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                                                <div className="d-flex gap-2">
                                                    <PopUpModalButton
                                                        title="eCo"
                                                        fileLink={data.eCoFile}
                                                        disabled={
                                                            data.isApproved ===
                                                            "Processing"
                                                        }
                                                    />

                                                    {data.comInvoiceFlag && (
                                                        <PopUpModalButton
                                                            title="Invoice"
                                                            fileLink={data.comInvoice}
                                                            disabled={
                                                                data.isApproved ===
                                                                "Processing"
                                                            }
                                                        />
                                                    )}

                                                    {data.addDoc1Flag && (
                                                        <PopUpModalButton
                                                            title="Additional Document 1"
                                                            fileLink={data.addDoc1}
                                                            disabled={
                                                                data.isApproved ===
                                                                "Processing"
                                                            }
                                                        />
                                                    )}
                                                </div>

                                                <div className="d-flex gap-2">
                                                    {data.addDoc2Flag && (
                                                        <PopUpModalButton
                                                            title="Additional Document 2"
                                                            fileLink={data.addDoc2}
                                                            disabled={
                                                                data.isApproved ===
                                                                "Processing"
                                                            }
                                                        />
                                                    )}

                                                    {data.packingListFlag && (
                                                        <PopUpModalButton
                                                            title="Packing List"
                                                            fileLink={data.packingList}
                                                            disabled={
                                                                data.isApproved ===
                                                                "Processing"
                                                            }
                                                        />
                                                    )}

                                                    <PopUpModalButton
                                                        title="Receipt"
                                                        fileLink={data.receipt}
                                                        disabled={
                                                            data.isApproved ===
                                                            "Processing"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="pb-0 text-center">
                                            <h6
                                                className={
                                                    data.isApproved === "Rejected"
                                                        ? "text-danger"
                                                        : "text-success"
                                                }
                                            >
                                                {data.isApproved}
                                            </h6>
                                            <span>{data.remark}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={7} className="text-center">
                                    No Eco Found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>

                {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <Pagination.Item
                    key={index + startPage}
                    active={index + startPage === currentPage}
                    onClick={() => handlePageChange(index + startPage)}
                  >
                    {index + startPage}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ECoDataTrack