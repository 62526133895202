import React from 'react'

const NonBindingCaseSummery = () => {
  return (
    <div>NonBindingCaseSummery

        this is case summery
    </div>
  )
}

export default NonBindingCaseSummery