import React, { useState, useEffect } from "react";
import {
  Table,
  Pagination,
  Button,
  Modal,
  Card,
  Col,
  Row,
  Form,
  InputGroup,
  Badge,
  FormControl,
} from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../../../store/api";
import { getAuthUserlocal } from "../../../../../store/services";
import { PopUpModalButton } from "../../../../../components/basic/ModalComponent";
import Logo from "../../../../../assets/footerLogo.png";
import { FaBackward, FaCreditCard, FaImage, FaPaperclip } from "react-icons/fa";
import jsPDF from "jspdf";
import { numberToWords } from "../../../../../components/basic/numberToWords";
import { GrDocumentPdf } from "react-icons/gr";
// import { IoMdSend } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { CiStar } from "react-icons/ci";

const NonBindingDisputeTrack = ({ setActivePage }) => {
  const [nonBindingTrack, setNonBindingTrack] = useState([]);
  const [comments, setComments] = useState({});
  const [chatShow, setChatShow] = useState(false);

  // Handle input change
  const handleInputChange = (e, docId) => {
    const { value } = e.target;
    setComments((prevComments) => ({
      ...prevComments,
      [docId]: value,
    }));
  };

  const handleMessageSend = async (docId) => {
    const message = comments[docId];
    if (!message) {
      console.error("No message to send.");
      return;
    }
    try {
      const response = await axios.put(
        getURLbyEndPointV2("updateDisputeDocMessage") + docId,
        {
          message: message,
        }
      );
      if (response.status === 200) {
        setNonBindingTrack((prevNonBindingTrack) =>
          prevNonBindingTrack.map((item) => ({
            ...item,
            raiseDisputeDocs: item.raiseDisputeDocs.map((doc) =>
              doc._id === docId ? { ...doc, message: message } : doc
            ),
          }))
        );
      } else {
        console.error("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const fetchNonBindingDisputeTrack = async () => {
    try {
      const userId = getAuthUserlocal()._id;

      const response = await axios.get(
        getURLbyEndPointV2("getNonBindingDispute") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setNonBindingTrack([...data]);
      } else {
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  //filtering here
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = nonBindingTrack.filter((data) => {
    // Filter based on account status or any other criteria
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //pagination is here
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 2); // Adjusted to show 5 pages
    let endPage = Math.min(totalPages, currentPage + 2); // Adjusted to show 5 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(5, totalPages); // Adjusted to show 5 pages
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 4, 1); // Adjusted to show 5 pages
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchNonBindingDisputeTrack();
  }, []);

  return (
    <div>
      <div className="d-flex gap-2 justify-content-end align-items-center">
        <Form.Control
          className="form-control w-40"
          type="search"
          placeholder="Search By Application No."
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="secondary" onClick={() => setActivePage("homePage")}>
          <FaBackward /> Back
        </Button>
      </div>
      <div className="mt-3">
        <Table responsive striped bordered hover w-auto>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                Sr
                <br /> No.
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Application No
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
              Details
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Documents
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                AECCI Administration Fee
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
                Status
              </th>
              <th
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
                className="text-center"
              >
               Action
              </th>
            </tr>
          </thead>
          <>
            <tbody>
              {currentFiltered.length > 0 ? (
                <>
                  {currentFiltered.map((data, index) => {
                    return (
                      <tr>
                        <td>{index + 1}.</td>
                        <td>
                          <div className="text-nowrap">{data.appNo}</div>
                        </td>
                        <td className="">
                            <div className="text-nowrap">
                            <div className="text-nowrap">Name of parties: {data.consignorName} V/S {data.consigneeName}</div>
                            <div>Year of dispute: {data.disputeYear}</div>
                            <div>Amount in Dispute:{data.amountInDisputeCurrency}{" "}
                            {data.amountInDispute}/-</div>
                            <div>Shipment Form: {data.shipmentFrom} To {data.shipmentTo}</div>
                            <div>Product: {data.productName}</div>
                          </div>
                        </td>
                        <td className="">
                          <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <PopUpModalButton
                              title="Application Form"
                              fileLink={data.applicationForm}
                            />
                            <PopUpModalButton
                              title="Request letter"
                              fileLink={data.requestLetter}
                            />
                            <PopUpModalButton
                              title="Other Doc"
                              fileLink={data.otherDoc}
                            />
                          </div>
                        </td>                      
                        <td>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-nowrap mb-2">
                              Rs {data.administrationFee}/- per consignee.
                            </div>
                            <div className="my-1 text-center">
                              {!data.receipt ? (
                                <>
                                  {data.refNo ? (
                                    <div>Ref No: {data.refNo}</div>
                                  ) : (
                                    <div>Ref No: Not Available</div>
                                  )}

                                  <div className="my-2">
                                    {data.isPaymentReqSubmit ? (
                                      <ProcessedPayment apiData={data} />
                                    ) : (
                                      <Button
                                        size="sm"
                                        disabled
                                        variant="secondary"
                                      >
                                        Process Payment
                                      </Button>
                                    )}
                                  </div>
                                  <PopUpModalButton
                                    title="Receipt"
                                    disabled={data.receipt ? false : true}
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="my-2">
                                    Ref No: {data.refNo}
                                  </div>
                                  <PopUpModalButton
                                    title="Receipt"
                                    fileLink={data.receipt}
                                    disabled={data.receipt ? false : true}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="text-primary">
                          <div className="d-flex flex-column gap-2 align-items-center">
                          <div>
                          {data.isApproved}
                          </div>
                          <div>
                          <Read data={data} />
                          </div>
                          </div>
                          </td>
                        <td className="text-primary">
                          {data.isApproved !== "Approved" && data.raiseDisputeDocs.length > 0 ? (
                            // <>
                            //   {data.raiseDisputeDocs &&
                            //     data.raiseDisputeDocs.length > 0 && (
                            //       <ul className="list-group">
                            //         {data.raiseDisputeDocs.map(
                            //           (doc, docIndex) => (
                            //             <li
                            //               key={docIndex}
                            //               className="list-group-item"
                            //             >
                            //               <div className="d-flex justify-content-between align-items-center gap-2">
                            //                 <div className="fw-semibold" style={{width: "220px"}}>
                            //                   {doc.caption}
                            //                 </div>
                            //                 <a
                            //                   className="btn btn-info btn-sm text-nowrap"
                            //                   href={doc.fileUrl}
                            //                   target="_blank"
                            //                   rel="noopener noreferrer"
                            //                 >
                            //                   <GrDocumentPdf />
                            //                 </a>
                            //               </div>
                            //               {doc.message ? (
                            //                 <div style={{width: "220px"}}>Message: {doc.message}</div>
                            //               ) : (
                            //                 <div className="mt-2 d-flex gap-2 align-items-center">
                            //                   <Form.Control
                            //                     type="text"
                            //                     placeholder="Comment"
                            //                     maxLength={70}
                            //                     value={comments[doc._id] || ""}
                            //                     onChange={(e) =>
                            //                       handleInputChange(e, doc._id)
                            //                     }
                            //                   />
                            //                   <Button
                            //                     size="sm"
                            //                     variant="success"
                            //                     onClick={() =>
                            //                       handleMessageSend(doc._id)
                            //                     }
                            //                   >
                            //                     <IoMdSend />
                            //                   </Button>
                            //                 </div>
                            //               )}
                            //             </li>
                            //           )
                            //         )}
                            //       </ul>
                            //     )}
                            // </>
                            <>
                            <div className="d-flex justify-content-center flex-column">

                             {/* <Button variant="primary" size="sm" onClick={() => setChatShow(true)}>
        Chats
      </Button> */}


      <div class="container">
      <Button variant="success" className="me-2" onClick={handleShow}>
        {" "}
        Chat
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "30vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Modal.Title className="h5">
                Reference No: {data.refNo}
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="d-flex flex-column h-100 w-100">
                <div>
                  <AecciMessage text="Your Ticket has been processed and our expert will connect you shortly." />
                </div>
                <div className="d-flex flex-column mt-auto">
                  <Message
                    align="Received"
                    text="Hello, Thank you for choosing our services."
                    time="18/06/2023 4.00pm"
                  />
                  <Message
                    align="sent"
                    text="Hello, Thank you for choosing our services."
                    time="18/06/2023 4.00pm"
                  />
                  <hr />

                  <InputGroup>
                    <FormControl
                      placeholder="Type your message"
                      aria-label="Type your message"
                    />
                    <InputGroup.Text id="btnGroupAddon">Send</InputGroup.Text>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
  <div class="row">
    <div class="col-lg-12">
      <div class="star-rating d-flex fs-3">
        <span data-rating="1"><CiStar /></span>
        <span data-rating="2"><CiStar /></span>
        <span data-rating="3"><CiStar /></span>
        <span data-rating="4"><CiStar /></span>
        <span data-rating="5"><CiStar /></span>
        <input type="hidden" name="whatever1" class="rating-value" value="2.56"/>
      </div>
    </div>
  </div>
</div>


</div>
      <Chatbox
        show={chatShow}
        onHide={() => setChatShow(false)}
        />
      </>
                          ) : (
                            <div className="text-center"> --- </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={11} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </>
        </Table>
      </div>
      {/* //pagination------------------------------------
            ------------------------------------------------ */}
      {totalPages > 1 && (
        <div className="d-flex justify-content-start">
          <Pagination>
            <Pagination.Prev
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            />
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
              <Pagination.Item
                key={index + startPage}
                active={index + startPage === currentPage}
                onClick={() => handlePageChange(index + startPage)}
              >
                {index + startPage}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default NonBindingDisputeTrack;

function ProcessedPayment({ apiData, setIsSubmit }) {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const handleConfirmPayment = async (userId) => {
    setModalShow(false);
    await handleCreateReceipt();

    try {
      const formData = new FormData();
      formData.append("receipt", receiptFile);
      // setApiSubmitEco(true);

      const res = await axios.post(
        getURLbyEndPointV2("nonBindingPaymentProcess") + userId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        window.location.reload();
      } else {
        alert(res.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      // setApiSubmitEco(false);
    }
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  let receiptFile;
  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text(`Receipt No: ${apiData.refNo}`, 15, 82);
    pdf.text(`Document No: ${apiData.appNo}`, 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    const now = new Date();
    const currentDate = formatDate(now); // Format date
    const currentTime = formatTime(now); // Format time
    //second line
    pdf.text(`Date: ${currentDate}`, 130, 82);
    pdf.text(`Time: ${currentTime}`, 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.line(15, 115, 195, 115); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Rate", "Amount"];

    const data = [];

    data.push([
      "1",
      "Non-Binding Dispute (Legal assistant charge)",
      `Rs. ${apiData.administrationFee}/-`,
      `Rs. ${apiData.administrationFee}/-`,
    ]);

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [25, 75, 45, 45]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 1));
    pdf.text(
      `Rs. ${apiData.administrationFee}/-`,
      160,
      startY + lineHeight * (data.length + 1)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line
    pdf.text(
      `Amount In words(in INR): ${numberToWords(apiData.administrationFee)}`,
      15,
      startY + lineHeight * (data.length + 2)
    );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 2),
      195,
      5 + startY + lineHeight * (data.length + 2)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 2),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 2),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 2)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    receiptFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob
  };

  return (
    <>
      <Button
        variant="success"
        className="small-font my-1 fw-semibold"
        onClick={handleShow}
      >
        Processed Payment
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        {" "}
        <Modal.Body>
          <div>
            <h5>Payment Process</h5>
            <hr />
            <Card>
              <Card.Header>
                <Row className="d-flex">
                  <Col md={7}>
                    <h6 className="text-medium">
                      {getAuthUserlocal().companyName}
                    </h6>
                    <h6 className="text-medium">
                      Membership No: {getAuthUserlocal().memberShipNo}
                    </h6>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h6 className="text-medium">
                      Wallet Balance: ₹{getAuthUserlocal().balance}
                    </h6>
                    <h6 className="text-medium">
                      Valid Upto: {getAuthUserlocal().validUpTo}
                    </h6>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="ms-5 mb-2">
                  <h6 className="text-medium">
                    Debit Amount: ₹{apiData.administrationFee}
                  </h6>
                  <h6 className="text-medium">
                    Payment Towards: Non-Binding Dispute
                  </h6>
                </div>

                <Card className="p-2 mb-3">
                  <Card.Text className="fs-5 mb-0">Informative Note:</Card.Text>

                  <ul>
                    <li>
                      Any transaction from AECCI ePlatform Service shall be
                      deducted from the wallet.
                    </li>
                    <li>
                      Maintain the balance of the above prescribed amount in
                      your Wallet.
                    </li>
                    <li>
                      Initiate wallet payments only from the company&#39;s
                      registered and authorized bank account.
                    </li>
                    <li>
                      The beneficiary Account displayed on this platform is
                      unique to you only and is not be shared with anyone else.
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        In Case of Rejection, Amount will be Credited to your
                        wallet.
                      </Card.Text>
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        You can monitor Transaction entires in your wallet.
                      </Card.Text>
                    </li>{" "}
                    <li>
                      <Card.Text className="text-medium">
                        For Any Assistant, contact Chamber Desk.
                      </Card.Text>
                    </li>
                  </ul>
                </Card>

                <div className="text-center">
                  <Button
                    variant="success"
                    className="rounded-5 px-5 text-medium"
                    onClick={() => handleConfirmPayment(apiData._id)}
                  >
                    CONFIRM PAYMENT
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}





function Read({ data }) {
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .replace(" ", "");
  };

  return (
    <>
      <Button
        variant="info"
        className="small-font my-1 fw-semibold text-nowrap"
        onClick={handleShow}
      >
        View Summary
      </Button>

      <Modal
        show={modalShow}
        size="lg"
        onHide={() => setModalShow(false)}
        centered
      >
        {" "}
        <Modal.Body>
          <div className="border px-4 py-2">
            <div className="text-center">
              <h2 className="fw-semibold">Case Summary</h2>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-4">
              <div className="fs-6">
                <h6 className="fw-semibold">Application No: {data.appNo}</h6>
                <h6 className="fw-semibold">Status: {data.isApproved}</h6>
              </div>
              <div className="fs-6">
                <h6 className="fw-semibold">Date: {data.date}</h6>
                <h6 className="fw-semibold">
                  Time: {formatTime(data.createdAt)}
                </h6>
              </div>
            </div>
            <hr />
            <div className="mt-3">
              <p style={{ lineHeight: "35px" }}>
                The Case involves a dispute between{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.consignorName}
                </span>{" "}
                and{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.consigneeName}
                </span>{" "}
                it started in the year
                <span className="px-2 fw-semibold text-uppercase">
                  {data.disputeYear}
                </span>{" "}
                over a Product Called{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.productName}
                </span>{" "}
                in the{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.industry}
                </span>
                . The Said Product was shipped form{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.shipmentFrom}
                </span>{" "}
                to{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.shipmentTo}
                </span>{" "}
                and the amount in dispute is{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.amountInDisputeCurrency} {data.amountInDispute}/-
                </span>
                .{" "}
              </p>
              <p style={{ lineHeight: "35px" }}>
                The Amount as in Commercial invoice is{" "}
                <span className="px-2 fw-semibold text-uppercase">
                  {data.sumOfCommercialInvCurrency} {data.sumOfCommercialInv}/-
                </span>{" "}
                and the details of the dispute are as follows:
              </p>
              <h5>Description:</h5>
              <p style={{ lineHeight: "35px" }}>{data.description}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}








function Chatbox(props) {
  return (

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // show={chatShow}
    // onHide={handleClose}
    style={{ display: "flex", alignItems: "center" }}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Modal.Title className="h5">
            APP NO: AECCI/LW-S1/003
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="d-flex flex-column h-100 w-100">
            {/* <div>
              <AecciMessage text="Your Ticket has been processed and our expert will connect you shortly." />
            </div> */}
            <div className="d-flex flex-column mt-auto">
              <Message
                align="Received"
                text="Hello, How i can help you"
                time="18/06/2023 4.00pm"
              />
              <Message
                align="sent"
                text="Hello,"
                time="18/06/2023 4.00pm"
              />
              <Message
                align="Received"
                text="Please share me your xyz documents"
                time="18/06/2023 4.00pm"
              />
              <hr />

              <InputGroup>
      <FormControl
        placeholder="Type your message"
        aria-label="Type your message"
      />
      <Button variant="outline-secondary">
        <FaPaperclip />
      </Button>
      <Button variant="outline-secondary">
        <FaImage />
      </Button>
      <Button variant="outline-secondary">
        <FaCreditCard />
      </Button>
      <InputGroup.Text variant="outline-secondary" id="btnGroupAddon">
      <IoMdSend />
      </InputGroup.Text>
    </InputGroup>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close Chat</Button>
      </Modal.Footer>
    </Modal>
  );
}




const Message = ({ align, text, time }) => {
  // Determine the message alignment based on the 'align' prop
  const messageStyle = {
    backgroundColor: align === "sent" ? "#ececec" : "#63b190",
    color: align === "sent" ? "black" : "white",

    borderRadius:
      align === "sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "75%",
    alignSelf: align === "sent" ? "flex-end" : "flex-start", // Align self based on prop
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "sent" ? "black" : "white",
    textAlign: "right", // Adjust textAlign based on alignment
    marginTop: "auto", // Pushes the time to the bottom
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <span>{text}</span>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};

const AecciMessage = ({ text }) => {
  return (
    <Card className="mb-2 border-none">
      <Card.Body
        style={{ backgroundColor: "#E8DAF5" }}
        className="text-info py-1 rounded-1"
      >
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};