import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { getURLbyEndPointV2 } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";

const RatingSummary = () => {
    const [summary, setSummary] = useState("");
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(null);
    const [slotId, setSlotId] = useState("");
    const [country, setCountry] = useState("");
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [userName, setUserName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();

    // Access the passed data using location.state and set to local states
    useEffect(() => {
        if (location.state) {
            const { slotId, country, time, date, userName, companyName } = location.state;
            setSlotId(slotId || "");
            setCountry(country || "");
            setTime(time || "");
            setDate(date || "");
            setUserName(userName || "");
            setCompanyName(companyName || "");
        }
    }, [location.state]);

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const userId = getAuthUserlocal()?._id; // Fetch the authenticated user's ID
    
        if (!userId) {
            alert("User authentication failed. Please log in again.");
            return;
        }
    
        console.log("Feedback Submitted: ", { summary, rating, slotId, country, time, date, userName, companyName });
    
        try {
            setIsLoading(true); // Indicate the loading state
    
            const res = await axios.post(
                getURLbyEndPointV2("submitB2bReview") + userId,
                { summary, rating, slotId, country, time, date, userName, companyName }
            );
    
            // Check for success in the response
            if (res.data.success) {
                alert("Thank you for joining the event! Your response has been successfully submitted.");
                setIsLoading(false); // Turn off loading state
                
                // Redirect to b2bEvent page after a brief delay
                setTimeout(() => {
                    navigate("/aecci-virtual-b2b-forum"); // Adjust this URL as needed
                }, 2000); // Delay of 2 seconds before redirecting
            } else {
                alert(res.data.message || "Thank you for joining the event! Your response has been successfully submitted.");
                setTimeout(() => {
                    navigate("/aecci-virtual-b2b-forum"); // Adjust this URL as needed
                }, 2000); // Delay of 2 seconds before redirecting
            }
        } catch (error) {
            console.error("Error during submission:", error);
            alert("There was an error submitting your feedback. Please try again.");
        } finally {
            setIsLoading(false); // Ensure loading state is off after completion
        }
    };
    
    

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#f9f9f9",
            }}
        >
            <h2 style={{ marginBottom: "16px" }}>Meeting Summary</h2>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    padding: "20px",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                }}
            >
                <div style={{ marginBottom: "20px" }}>
                    <label>Rate your experience:</label>
                    <div>
                        {[...Array(5)].map((star, index) => {
                            const currentRating = index + 1;

                            return (
                                <FaStar
                                    key={index}
                                    size={30}
                                    onClick={() => setRating(currentRating)}
                                    onMouseEnter={() => setHover(currentRating)}
                                    onMouseLeave={() => setHover(null)}
                                    color={currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                    style={{ cursor: "pointer", marginRight: "5px" }}
                                />
                            );
                        })}
                    </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <label htmlFor="summary">Summary of your experience:</label>
                    <textarea
                        id="summary"
                        rows="4"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                        style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            resize: "none",
                        }}
                    />
                </div>
                <button
          type="submit"
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            position: "relative"
          }}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />
              Submitting...
            </>
          ) : (
            "Submit Feedback"
          )}
        </button>
            </form>
        </div>
    );
};

export default RatingSummary;
