import React from "react";
// import EPlatformHeaderimg from "../../assets/EplatformHeaderimg.avif";
import EPlatformHeaderimg from "../../assets/Headerimg.png";
import WelcomeBanner from "../../assets/WelcomeBanner.avif";
import verificationBanner from "../../assets/verificationBanner.png"

const EPlatformHeader = ({ heading, text }) => {
  return (
    <>
      <div
        className="d-flex justify-content-center text-light font1"
        style={{
          backgroundImage: heading === 'Verification Portal' ? `url(${verificationBanner})` : `url(${WelcomeBanner})`,
          backgroundSize: "cover",
         }}
         
      >
        <div
          className="main-width"
          style={{ marginBottom: "5rem", marginTop: "5rem" }}
        >
          <h2 className="font1">{heading || "e-Platform"}</h2>
          <div>
            <span>{text}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EPlatformHeader;
