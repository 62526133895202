import React, { useState, useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import Layout2 from "../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../components/basic/DashboardHeader";
import { toast } from "react-hot-toast";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { getAuthUserlocal } from "../../store/services";
import infoImg from "../../assets/infoimg.png";
import { useAuth } from "../../context/auth";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { country_list } from "../auth/Register/AllRegiPageLists";
import { titleOptions } from "../../store/AllListData";

const PersonalDetails = () => {
  const [auth, setAuth] = useAuth();
  const userId = auth ? auth.user : null;

  const [pdFormData, setPDFormData] = useState({
    companyName: "",
    memberShipNo: "",
    title: "",
    firstName: "",
    surName: "",
    role: "",
    phoneNo: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPDFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validatePinCode = () => {
    const regex = /^[0-9]{6}$/;
    return regex.test(pdFormData.pinCode);
  };

  const handlePersonalFormSubmit = async (e) => {
    e.preventDefault();

    // Retrieve userId from local storage
    // const authUserLocal = JSON.parse(localStorage.getItem("auth"));

    // if (!userId) {
    //   // Handle the case where userId is null
    //   return response.status(400).json({ error: "Invalid userId" });
    // }

    if (!validatePinCode()) {
      toast.error("Please enter a valid 6-digit pin code.");
      return;
    }

    try {
      pdFormData.pinCode = parseInt(pdFormData.pinCode);

      const res = await axios.put(
        getURLbyEndPoint("updatePersonalDetalis") + userId,
        { ...pdFormData }
      );
      if (res.status === 200) {
        toast.success(res.data.message, { autoClose: 20000 });
      } else if (res.status === 429) {
        // Handle 429 status code (Too Many Requests)

        // Assuming the message is available in the response
        toast.error(res.data.message);
      } else {
        toast.error("Failed to update personal details");
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    // Make the GET API call here
    const fetchData = async () => {
      try {
        const res = await axios.get(
          getURLbyEndPoint("getpersonalinfo") + userId,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const data = res.data.data;

        if (res.data.status) {
          setPDFormData(data);
        }
      } catch (error) {
        alert("something went Wrong!!");
      }
    };

    fetchData();
  }, [auth.token]);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />

          <form
            className="d-flex flex-column"
            onSubmit={handlePersonalFormSubmit}
          >
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">PERSONAL DETAILS</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      The inclusion of correct contact information for the
                      company's stakeholders, along with their personal details,
                      is kindly requested herein.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
              <hr className="mt-1 mb-4" />

              <div className="">
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">TITLE</label>
                  <select
                    style={{ width: "60%" }}
                    className="form-select rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.title}
                    name="title"
                    required
                  >
                    <option value="">Select a title</option>
                    {titleOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">FIRST NAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.firstName}
                    name="firstName"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">SURNAME</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.surName}
                    name="surName"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">JOB TITLE</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.role}
                    name="role"
                    type="text"
                    required
                  />
                </div>

                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">MOBILE NO.</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 10);
                    }}
                    value={pdFormData.phoneNo}
                    name="phoneNo"
                    type="text"
                    maxLength={10}
                  />
                </div>
                <div className=" d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 p-2 w-25"
                    style={{ backgroundColor: "#0b3b5d", border: "0" }}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">PERSONAL ADDRESS</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      The registered addresses of the company&#39;s stakeholders
                      are to be provided here. If required any amendments in
                      future then the user must update the same at Chamber desk.
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
              <hr className="mt-1 mb-4" />

              <div action="" className="mx-2">
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">ADDRESS 1</label>
                  <input
                    disabled
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.address1}
                    name="address1"
                    type="text"
                    required
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">ADDRESS 2</label>
                  <input
                    disabled
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.address2}
                    name="address2"
                    type="text"
                    required
                  />
                </div>

                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">CITY</label>
                  <input
                    disabled
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.city}
                    name="city"
                    type="text"
                  />
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">COUNTRY</label>
                  <select
                    disabled
                    style={{ width: "60%" }}
                    className="form-select rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.country}
                    name="country"
                    required
                  >
                    <option value="">Select a country</option>
                    {country_list.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">STATE</label>
                  <input
                    disabled
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5"
                    onChange={handleInputChange}
                    value={pdFormData.state}
                    name="state"
                    type="text"
                    required
                  />
                </div>

                <div className="w-100 mb-1 py-2 d-flex justify-content-between align-items-start">
                  <label className="fw-semibold fs-6">PIN CODE</label>
                  <input
                    style={{ width: "60%" }}
                    className="form-control rounded-0 ms-5 justify-content-end"
                    onChange={handleInputChange}
                    disabled
                    onInput={(e) => {
                      e.target.value = Math.max(
                        0,
                        parseInt(e.target.value) || 0
                      )
                        .toString()
                        .slice(0, 6);
                    }}
                    value={pdFormData.pinCode}
                    name="pinCode"
                    type="text"
                    required
                    pattern="[0-9]*"
                    inputMode="numeric"
                    minLength={6}
                    maxLength={6}
                  />
                </div>
              </div>
            </div>
          </form>
        </Layout2>
      </Layout>
    </>
  );
};

export default PersonalDetails;
