import React, { useState } from 'react';
import Layout from "../../components/Layouts/Layouts/Layout";
import EPlatformHeader from "../../components/basic/EPlatformHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import EmailForm from "./EmailForm";
import { getURLbyEndPoint } from "../../store/api";
import axios  from "axios";

const CommercialDirectory = () => {
  const [showForm, setShowForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');


  const isValidGmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@gmail.com$/;
    return regex.test(email);
 };





const handleEmailSubmit = async (email) => {
  if (isValidGmail(email)) {
     try {
       const response = await axios.post(getURLbyEndPoint("checkEmailExists"), { email });
       const data = response.data;
       if (data.exists) {
         setShowForm(false);
       } else {
         setErrorMessage('Email not found in database.');
       }
     } catch (error) {
       console.error('Error checking email:', error);
       setErrorMessage('An error occurred while checking the email.');
     }
  } else {
     setErrorMessage('Please enter a valid Gmail ID.');
  }
 };


  return (

    <div>
    <Layout header="true">
      <EPlatformHeader
          heading="Commercial Directory"
          text="Expand your business network with our team-assisted directory of company listings, potential trade partners, and more—all in real time. Access our intelligent, fast and easy-to-use search engine now.Let us help you explore and seize business opportunities and link your business to a wide network of potential partners in various sectors, locally, regionally and internationally."
        />


<div>
      {showForm ? (
        <EmailForm  onEmailSubmit={handleEmailSubmit} />
      ) : (
        <div className="container mt-4">
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
        {<div className='commercial-dir'>
          <h3 className="">Search Commercial Directory</h3>
          <p>
            Every effort has been made to compile complete and accurate
            information for this directory. Even though extraordinary efforts
            were made to ensure its accuracy, neither the Association nor its
            members or employees will be responsible for errors or omissions
            in this directory. The information contained herein should not be
            construed as an endorsement of any member, company, or individual,
            nor reflect in any way upon the products and/or services provided
            by an organization or individual. Asian Exporters’ Chamber of
            Commerce and Industry is not responsible for the services provided
            by the mentioned businesses. Please help us keep this page
            up-to-date by clicking here.
          </p>
        </div>}

        <Container>
          <Row>
            <Col>
              <div>
                <h1 className="text-lg">
                  <strong>A</strong>
                </h1>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - ABDULLABHAI ABDUL KADER
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        src="https://www.aecci.org.in/wp-content/uploads/2021/01/logo-300x86.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> 603, 6th
                          Floor,
                          <br />
                          Merlin Pentagon,
                          <br />
                          Mahalaxmi 5 Roads,
                          <br />
                          Paldi, Ahmedabad – 380 007
                          <br />
                          Gujarat, India.
                          <br />
                          <strong>Year of Establishment:</strong>1869
                          <br />
                          <strong>Activity:</strong> Agro Based Products
                          <br />
                          Exports Area:American, European,
                          <br />
                          African, Middle East and Far East
                          <br />
                          countries
                          <br />
                          <strong>Website:</strong> www.guargum.co.in
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - APPOLO SEASAME INDUSTRIES
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        src="https://www.aecci.org.in/wp-content/uploads/2023/06/APPOLO-LOGO.jpg"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> Plot No:
                          577, N.H-8, Near CNG <br /> Gas Pump, P.O: Dabhan,
                          Tal: Nadiad, <br />
                          Dist: Kheda, Gujarat- 387320
                          <strong>Year of Establishment:</strong>2008
                          <br />
                          <strong>Activity:</strong> Importer & Exporter of
                          All kind of <br />
                          Natural Sesame Seeds and Manufacturer <br /> of
                          Hulled Seeds.
                          <br />
                          Exports Area: Gulf, Europe, Far East, <br /> Russia,
                          Asian, etc.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - AGILE SHIPPING AND LOGISTICS
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        className="img-fluid"
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/agile-shipping-and-logistics.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Website:</strong>{" "}
                          https://agile- ipping.com/
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - ASIAN FOOD INDUSTRIES
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        className="img-fluid"
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/asian-food-industries.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> N.H.No.
                          8, Opp. Escort Tractors,
                          <br />
                          AT. P.O Dabhan, Tal-Nadiad, Dist- Kheda,
                          <br /> Gujarat- 387320
                          <br />
                          <strong>Year of Establishment:</strong>
                          <br />
                          <strong>Activity:</strong> Spices And Groceries
                          <br />
                          Export Area: Gulf Countries
                          <br />
                          <strong>Website:</strong>{" "}
                          www.asianfoodindustries.com
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>- ASPINWAL & CO LTD</Accordion.Header>
                    <Accordion.Body>
                      <img
                        className="img-fluid"
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/aspinwall-logo.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> B WING
                          403/404 Mahavir Icon,
                          <br /> Plot No . 89/90, Sector 15, CBD Belapur East,
                          Navi Mumbai, <br />
                          Thane-400614,
                          <br /> Maharashtra, India
                          <strong>Year of Establishment:</strong>
                          <br />
                          <strong>Activity:</strong>
                          <br />
                          <strong>Website:</strong> https://www.aspinwall.in/
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>- ASIA PACIFIC IMPLEX</Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-4">
                        <p>
                          <strong className="text-center">
                            YOU DO NOT HAVE ACCESS TO THIS AREA
                          </strong>
                          <br />
                          <p>
                            You do not have permission to access this page. If
                            you are logged in, you may need to upgrade your
                            membership.
                          </p>
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div>
                <h1 className="text-lg">
                  <strong>B</strong>
                </h1>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - BARKAT EXPORTS PVT.LTD
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/barkat-logo-150x150.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> Barkat
                          Exporters Pvt Ltd, 27,
                          <br />
                          Zarina CHS, 59A, SV Road Bandra west,
                          <br /> Mumbai, India
                          <br />
                          <strong>Year of Establishment:</strong>1991
                          <br />
                          <strong>Activity:</strong> Meat exporters
                          <br />
                          Exports Area: Dubai and Saudi Arabia
                          <br />
                          <br />
                          <strong>Website:</strong> www.barkatexport.com
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - BRAHANS POLYMERS PVT LTD
                    </Accordion.Header>
                    <Accordion.Body>
                      <img
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/brahans.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong> Plot
                          No-A/ 797 / 1, TTC
                          <br />
                          industries Area Khairane MIDC, Navi <br />
                          Mumbai-400700
                          <strong>Year of Establishment:</strong>1998
                          <br />
                          <strong>Activity:</strong> Rubber Spender & Auto
                          mobile <br />
                          productions <br />
                          Exports Area: American and Gulf countries
                          <strong>Website:</strong>www.brahanspolymer.com
                          <br />
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - BK CLEARING AND FORWARDING AGENCY
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-4">
                        <p>
                          <strong className="bold">Address:</strong>
                          <strong>Year of Establishment:</strong>2010
                          <br />
                          <strong>Activity:</strong> Pharmaceutical,
                          Engineering and <br />
                          Chemical goods
                          <br />
                          Export Area: American, European & gulf Regions
                          <strong>Website:</strong>
                          <br />
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - BGS LOGISTICS PVT LTD
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-4">
                        <p>
                          <strong className="text-center">
                            YOU DO NOT HAVE ACCESS TO THIS AREA
                          </strong>
                          <br />
                          <p>
                            You do not have permission to access this page. If
                            you are logged in, you may need to upgrade your
                            membership.
                          </p>
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - BALAJI GLOBAL SHIPPING
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-4">
                        <p>
                          <strong className="text-center">
                            YOU DO NOT HAVE ACCESS TO THIS AREA
                          </strong>
                          <br />
                          <p>
                            You do not have permission to access this page. If
                            you are logged in, you may need to upgrade your
                            membership.
                          </p>
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div>
                <h1 className="text-lg">
                  <strong>C</strong>
                </h1>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - CARDKEM PHARMA PVT LTD
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - CCHEM TRADE INTERNATIONAL CORPORATION
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      - COCO CART VENTURES PVT LTD
                    </Accordion.Header>
                    <Accordion.Body>
                      <p></p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div>
                <h1 className="text-lg">
                  <strong>D</strong>
                </h1>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>- DIVINE INDUSTRIES</Accordion.Header>
                    <Accordion.Body>
                      <img
                        src="https://www.aecci.org.in/wp-content/uploads/2023/07/brahans.png"
                        alt=""
                      />
                      <div className="mt-4">
                        <p>
                          <strong>Website: </strong>
                          https://divineindustries.in
                          <br />
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div>
                <h1 className="text-lg">
                  <strong>Y</strong>
                </h1>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>- DIVINE INDUSTRIES</Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-4">
                        <p>
                          <strong className="text-center">
                            YOU DO NOT HAVE ACCESS TO THIS AREA
                          </strong>
                          <br />
                          <p>
                            You do not have permission to access this page. If
                            you are logged in, you may need to upgrade your
                            membership.
                          </p>
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col>
            <div>

          
              <h1 className="text-lg">
                <strong>E</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    - ENDRESS HAUSER ( INDIA) PVT LTD
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    - EXPANDED POLYMER SYSTEM PVT LTD
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>

              <div>
              <h1 className="text-lg">
                <strong>H</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- HONEYDRUGS</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- HVS LOGISTICS</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>

              <div>

        
<h1 className="text-lg">
<strong>J</strong>
</h1>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
  <Accordion.Header>- JYOTI IMPEX</Accordion.Header>
  <Accordion.Body>
    <img
      className="img-fluid"
      src="https://www.aecci.org.in/wp-content/uploads/2023/07/jyoti-impex.png"
      alt=""
    />
    <div className="mt-4">
      <p>
        <strong>Website:</strong>www.jyotiimpex.co.in
        <br />
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
</div>
<div>

        
              <h1 className="text-lg">
                <strong>K</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- KK EXPORTS</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- KASYAP SWEETNERS Ltd</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
              <div>

            
<h1 className="text-lg">
<strong>V</strong>
</h1>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
  <Accordion.Header>- VIMO FOODS PVT LTD</Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion>
<Accordion.Item eventKey="0">
  <Accordion.Header>- VOXTUR BIO LTD</Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion>
<Accordion.Item eventKey="0">
  <Accordion.Header>
    - VYAAN EQUIPMENTS INDIA PVT LTD
  </Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
</div>
<div>

        
<h1 className="text-lg">
<strong>W</strong>
</h1>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
  <Accordion.Header>
    - WISELOK VALVES AND FITTINGS
  </Accordion.Header>
  <Accordion.Body>
    <img
      src="https://www.aecci.org.in/wp-content/uploads/2023/06/unnamed-e1686032721935.jpg"
      alt=""
    />
    <div className="mt-4">
      <p>
        <strong className="bold">Address:</strong> 6TH FLOOR,
        B/601, ASMITA UPHAR III,
        <br /> POONAM SAGAR COMPLEX,
        <br /> MIRA ROAD EAST, <br />
        Thane, Maharashtra, 401107
        <strong>Year of Establishment:</strong>2019
        <br />
        <strong>Activity:</strong> Manufacturer of Instrument
        Tube <br />
        Fittings and Valves.
        <br />
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
</div>

            </Col>

            <Col>
            <div>
              <h1 className="text-lg">
                <strong>L</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- LODZ DENIM PVT LTD</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
              <div>

        
<h1 className="text-lg">
<strong>M</strong>
</h1>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
  <Accordion.Header>- JYOTI IMPEX</Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
</div>
<div>

        
<h1 className="text-lg">
<strong>N</strong>
</h1>
<Accordion defaultActiveKey="0">
<Accordion.Item eventKey="0">
  <Accordion.Header>- NEW HORIZON SOLUTIONS</Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>

<Accordion>
<Accordion.Item eventKey="0">
  <Accordion.Header>- NUTRITION LAB PVT LTD</Accordion.Header>
  <Accordion.Body>
    <div className="mt-4">
      <p>
        <strong className="text-center">
          YOU DO NOT HAVE ACCESS TO THIS AREA
        </strong>
        <br />
        <p>
          You do not have permission to access this page. If
          you are logged in, you may need to upgrade your
          membership.
        </p>
      </p>
    </div>
  </Accordion.Body>
</Accordion.Item>
</Accordion>
</div>
<div>
        
        <h1 className="text-lg">
          <strong>O</strong>
        </h1>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              - OVIANI PHARMACHEM PVT LTD
            </Accordion.Header>
            <Accordion.Body>
              <div className="mt-4">
                <p>
                  <strong className="text-center">
                    YOU DO NOT HAVE ACCESS TO THIS AREA
                  </strong>
                  <br />
                  <p>
                    You do not have permission to access this page. If
                    you are logged in, you may need to upgrade your
                    membership.
                  </p>
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
              
      </div>
      <div>
              <h1 className="text-lg">
                <strong>P</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    - PRAKRUTI REMEDIES PVT LTD
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
              <div>
              <h1 className="text-lg">
                <strong>S</strong>
              </h1>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    - SEALINK SHIPPING SERVICES
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>- STAR WIRE PRODUCT</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-4">
                      <p>
                        <strong className="text-center">
                          YOU DO NOT HAVE ACCESS TO THIS AREA
                        </strong>
                        <br />
                        <p>
                          You do not have permission to access this page. If
                          you are logged in, you may need to upgrade your
                          membership.
                        </p>
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    - SHUBHAM ACQUALINK INDIA PVT LTD
                  </Accordion.Header>
                  <Accordion.Body>
                    <img
                      src="https://www.aecci.org.in/wp-content/uploads/2023/09/LOGO-17-150x150.png"
                      alt=""
                    />
                    <div className="mt-4">
                      <p>
                        <strong className="bold">Address:</strong> 128, Lodha
                        Supremus II, <br />
                        North Wing, Road No. 22,
                        <br /> Wagle Industrial Estate,
                        <br /> Thane west
                        <strong>Year of Establishment:</strong> 2001
                        <br />
                        <strong>Activity:</strong> water storage and water
                        management solutions primarily <br />
                        for Commercial, Industrial and Community/Municipal
                        based applications. <br />
                        <strong>Exports Area: </strong>Indian subcontinent,
                        and the Middle East <br />
                        <strong>
                          Website:{" "}
                        </strong>www.shubhamacqualink.com <br />
                        <br />
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
            </Col>
          </Row>

       
        </Container>
      </div>
      )}
    </div>


       
      
      </Layout>
    </div>
  );
};

export default CommercialDirectory;
