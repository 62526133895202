import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button, Row, Col, Badge } from "react-bootstrap";
import { useNavigate } from "react-router";
import { BsEye } from "react-icons/bs";
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";

export const LegalWingForm = ({
    title,
    formData,
    setFormData,
    isCaptchaCorrect,
    setIsCaptchaCorrect,
    wingApiFlag,
    handleExportFormSubmit,
}) => {
    const navigate = useNavigate();
    const [summary, setSummary] = useState("");
    const [captchaMatched, setCaptchaMatched] = useState(false);
    const typesWithTopic = [
        {
            name: "Alternative Dispute Resolution (ADR)",
            types: [
                "Domestic arbitration",
                "International arbitration",
                "International commercial arbitration",
                "Ad-hoc Arbitration",
                "Fast track Arbitration",
                "Institutional Arbitration",
                "Mediation",
                "Conciliation",
            ],
        },
        {
            name: "Common types/causes of global trade disputes",
            types: [
                "Payment terms",
                "Letter of guarantee",
                "Foreign exchange rates",
                "Documentation errors",
            ],
        },
        {
            name: "Commercial/Business law",
            types: [
                "Contract Law",
                "Employment Law",
                "Labour Law",
                "Intellectual Property Law",
                "Securities Law",
                "Tax Law",
            ],
        },
        {
            name: "Company law",
            types: [
                "private limited company",
                "public limited company",
                "sole Proprietorship",
                "one person company",
                "Partnership",
                "limited liability partnership",
            ],
        },
        {
            name: "Director’s disputes",
            types: [
                "Negotiate and Mediate",
                "Shareholder Solutions",
                "Selling or Splitting the Business",
                "Negotiate a Share Sale and Resignation",
                "Company Buy-Back of Shares",
                "Resign or Sell Up",
            ],
        },
        {
            name: "Data protection",
            types: ["Data security", "Data availability", "Access Control"],
        },
    ];

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);
    const handleViewFile = () => {
        const fileURL = URL.createObjectURL(formData.refDoc);
        window.open(fileURL);
    };
    const handleTypeChange = (event) => {

        setFormData({ ...formData, query: event.target.value })
        // setSelectedType(event.target.value);
        // setSelectedTopic(" "); // Reset the selected topic when type changes
    };
    const handleCaptchaChange = (evt) => {
        // setIsVisible(true);
        setCaptchaMatched(evt.target.value);
        if (validateCaptcha(evt.target.value, false)) {
            setIsCaptchaCorrect(true);
            return;
        }
        setIsCaptchaCorrect(false);
        console.log(captchaMatched);
    }



    const handleTopicChange = (event) => {
        setFormData({ ...formData, topic: event.target.value })
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData({ ...formData, refDoc: file });

    };

  

    const MAX_CHARACTERS = 1000; // Define the maximum number of characters

    const handleSummaryChange = (event) => {
        const text = event.target.value;
        if (text.length <= MAX_CHARACTERS) {
            // Only update the state if the input is within the character limit
            setFormData({ ...formData, summary: event.target.value });
        }
    };



    return (
        <>
            <Layout>
                <Layout2>
                    <DashboardHeader />
                    <div className="card mt-3">
                        <div className="card-header pt-3 pb-5">
                            <h5 className="card-title mb-2">
                                {title}
                            </h5>
                            <p className="card-text">
                                To schedule an online consultation, pose inquiries, or arrange a
                                meeting with our expert, please provide the necessary information
                                below:
                            </p>
                            <Button className="" onClick={() => navigate("/Legal-wing-token")}>
                                Track Queries
                            </Button>
                        </div>
                        <form className="card-body" onSubmit={handleExportFormSubmit}>
                            <div className="d-flex justify-content-between mb-3 gap-5">
                                <div className="w-50">
                                    <label htmlFor="queryType" className="form-label">
                                        <h5>Type of Query</h5>
                                    </label>
                                    <select
                                        id="queryType"
                                        className="form-select"
                                        onChange={handleTypeChange}
                                        value={formData.query || ""}
                                        required
                                    >
                                        <option value="">Select Type</option>
                                        {typesWithTopic.map((type, index) => (
                                            <option key={index} value={type.name}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="w-50">
                                    <label htmlFor="queryTopic" className="form-label">
                                        <h5>Type of Topic</h5>
                                    </label>
                                    <select
                                        id="queryTopic"
                                        className="form-select"
                                        onChange={handleTopicChange}
                                        value={formData.topic || ""}
                                        disabled={!formData.query} // Disable the topic select if type is not selected
                                        required
                                    >
                                        <option value="">Select Topic</option>
                                        {formData.query &&
                                            typesWithTopic
                                                .find((type) => type.name === formData.query)
                                                .types.map((subType, subIndex) => (
                                                    <option key={subIndex} value={subType}>
                                                        {subType}
                                                    </option>
                                                ))}
                                    </select>
                                </div>
                            </div>

                            <h5 className="mb-4 text-secondary">Brief of Summary </h5>
                            <textarea
                                className="form-control border-2 mb-4"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder={`Max Characters ${MAX_CHARACTERS}..`}
                                required
                                value={formData.summary || ""} // Set the value of the textarea to the state variable
                                onChange={handleSummaryChange} // Handle the change event
                            ></textarea>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <span className="w-50">Reference Document (if any)</span>
                                <Row className="w-50">
                                    <Col
                                        xs={formData.refDoc ? 10 : 12}
                                        className="d-flex align-items-center"
                                    >
                                        <Button
                                            variant="info"
                                            onClick={() => document.getElementById("fileInput").click()}
                                            disabled={!!formData.refDoc}
                                            className="w-100"
                                        >
                                            UPLOAD
                                        </Button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".pdf"
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                        />
                                    </Col>
                                    {formData.refDoc && (
                                        <Col
                                            xs={2}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <Button variant="link" onClick={handleViewFile}>
                                                <BsEye className="icon-size" />
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex align-items-center">
                                    <LoadCanvasTemplate />
                                    {isCaptchaCorrect && (
                                        <AiFillCheckCircle className="icon-size primary-color" />
                                    )}{" "}
                                </div>
                                <div className="w-50">
                                    <input
                                        className="w-100 p-1 border-1"
                                        placeholder="Type the given captcha wordsssss"
                                        id="user_captcha_input"
                                        onChange={handleCaptchaChange}
                                        name="user_captcha_input"
                                        type="text"
                                    />
                                    {isCaptchaCorrect ? (
                                        <p className="text-success">CAPTCHA is valid</p>
                                    ) : (
                                        <p className="text-danger">CAPTCHA is invalid</p>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center gap-4 ">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="flexCheckDefault"
                                    required
                                />
                                <span className="w-75">
                                    I, hereby duly consent and acknowledge that all details filled
                                    herein are true, correct and any misrepresentation of facts,
                                    documents, or any other relevant information may deem result in
                                    disapproval of our Query.
                                </span>
                            </div>
                            <hr />
                            <Button
                                type="submit"
                                variant="success"
                                className="float-end w-25"
                                disabled={wingApiFlag}
                            >
                                {wingApiFlag ? "Processing..." : "SUBMIT"}
                            </Button>
                        </form>
                    </div>
                </Layout2>
            </Layout>
        </>
    )
}

// export default LegalWingForm;