import React, { useEffect } from "react";
import Layout from "../../components/Layouts/Layouts/Layout";
import { VscAccount } from "react-icons/vsc";
import "./Login.css";
import loginimg from "../../assets/loginimg.jpg";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EPlatformHeader from "../../components/basic/EPlatformHeader";
import loginMobileImg from "../../assets/loginMobileimg.png";
import { getURLbyEndPoint } from "../../store/api";
import { getAuthUser, storeAuthUser } from "../../store/userStore";
import { storeAuthUserlocal } from "../../store/services";
import Form from "react-bootstrap/Form";
import loginAdv from "../../assets/loginAdv.png";
// import NoticePopup from "../../components/basic/NoticePopup"

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginApi, setLoginApi] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

//   const [welcomeBanner, setWelcomeBanner] = useState(false)

//   useEffect(() => {
//     setWelcomeBanner(true);
//  }, []);

//   const toggleModal = () => setWelcomeBanner(!welcomeBanner);

  const handleloginsubmit = async (e) => {
    e.preventDefault();

    setLoginApi(true)
    try {
      const res = await axios.post(getURLbyEndPoint("loginClient"), {
        email,
        password,
        rememberMe,
      });

      // const userDetails = JSON.parse(localStorage.getItem("authUser"));

      if (res && res.data) {
        storeAuthUser(res.data.info);
        storeAuthUserlocal(res.data.info);

        toast.success("USER LOGIN SUCCESSFULLY");

        if (rememberMe) {
          localStorage.setItem("refreshToken", res.data.data.refreshToken);
        }
        await setAuth({
          ...auth,
          user: res.data.data.userId,
          token: res.data.data.token,
        });

        localStorage.setItem("auth", JSON.stringify(res.data.data));
        setLoginApi(false)


        setTimeout(() => {
          navigate(location.state || "/User-dashboard");
        }, 500);
      } else {
        toast.error("Invalid response format.");
      }
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("Server not responding");
      }
    } finally{
      setLoginApi(false)
    }
  };





  return (
    <>
      <Layout>
        <EPlatformHeader
          text="Embark on a new era of seamless business solutions! AECCI proudly unveils its upgraded e-Platform, now featuring digital
services, expert consultancy, shipping document attestation, visa recommendation letter issuance, and robust support for
Trade disputes &amp; Arbitration matters—all under one roof. Elevate your experience and streamline success with AECCI&#39;s
enhanced capabilities!"
        />

        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-between align-items-stretch my-4 main-width">
            <div
              className="shadow justify-content-between rounded-2"
              style={{ width: "70%" }}
            >

              <div className="d-flex ">
              <form
                className="ps-4 py-4 d-flex justify-content-between align-content-center flex-column"
                style={{ width: "50%" }}
                onSubmit={handleloginsubmit}
              >
                <div>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      required
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex w-50 gap-1 align-items-center">
                      <div>
                        <Form.Check
                          type="checkbox"
                          className="m-0 p-0"
                          id="rememberMe"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                      </div>
                      <div>
                        <h6 className="fw-normal">Remember me</h6>
                      </div>
                    </div>

                    <div className="w-50 d-flex justify-content-end">
                      <h6
                        className="cursor-pointer"
                        style={{
                          color: "black",
                          transition: "color 0.3s",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "blue";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        onClick={() => {
                          navigate("/forget-password");
                        }}
                      >
                        Forget Password?
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="rounded-1 bg-color-2"
                      style={{ width: "30%" }}
                      disabled={loginApi}
                    >
                      Log In
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-primary w-50"
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </form>
              <div
                className=" my-4 d-flex justify-content-center align-items-center"
                style={{ width: "50%" }}
              >
                <img
                  className="cover-fill"
                  height="230px"
                  width="200rem"
                  src={loginMobileImg}
                  alt=""
                  srcset=""
                />
              </div>
              </div>



              <div className="d-flex gap-2 ps-2 pb-3  justify-content-center">
                <div 
                  style={{ width: "50%" }}> 

              
                    <button
                      className="btn rounded btn-success"
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate("/e-co-verification");
                      }}
                    >
                      Verify Your Documents
                    </button>
                    </div>
                    <div
                     style={{ width: "50%" }} className="d-flex justify-content-center"> 

                 
                    <button
                 
                      className="btn rounded btn-primary glow "
                      style={{ width: "90%" }}
                      onClick={() => {
                      window.location.href = "https://www.aecci.org.in/e-platform/aecci-press-release/";
                      }}
                    >
                      Press Release
                    </button>
                    </div>
                  </div>


            </div>
            <div
              className="w-25 shadow text-center rounded-2"
              style={{
                backgroundImage: `url(${loginAdv})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
               {/* <NoticePopup isOpen={welcomeBanner} toggle={toggleModal} /> */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;

// <div style={{ width: "500px" }} className="">
//             <form onSubmit={handleloginsubmit}>
//               <div className="mb-3 d-flex justify-content-between align-items-center">
//                 <label style={{ width: "200px" }}>EMAIL ADDRESS</label>

//                 <input
//                   type="email"
//                   className="form-control"
//                   id="exampleInputEmail1"
//                   placeholder="Enter Your E-mail"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                 />
//               </div>
//               <div className="mb-3 d-flex justify-content-between align-items-center">
//                 <label style={{ width: "200px" }}>PASSWORD</label>
//                 <input
//                   type="password"
//                   className="form-control"
//                   id="exampleInputPassword1"
//                   placeholder="Enter password"
//                   value={password}
//                   onChange={(e) => setPassword(e.currentTarget.value)}
//                   required
//                 />
//               </div>
//               <div className="mb-5 form-check">
//                 <input
//                   type="checkbox"
//                   className="form-check-input"
//                   id="exampleCheck1"
//                 />
//                 <label className="form-check-label" htmlFor="exampleCheck1">
//                   Remember me
//                 </label>
//               </div>

//               <div className="mb-3"></div>
//               <button
//                 type="button"
//                 className="py-2 px-3 bg-color rounded"
//                 onClick={() => {
//                   navigate("/User-dashboard");
//                 }}
//               >
//                 Log In
//               </button>
//             </form>
//             <span className="d-flex mt-4">Forgotten your password.</span>
//             <span className="d-flex pb-4">
//               Don’t have your Account?
//               <b className="text-primary" onClick={handleRegisterClick}>
//                 {" "}
//                 Click here{" "}
//               </b>
//               to Register.
//             </span>
//           </div>

//second form

/* <form
                className="ps-4 py-4 d-flex justify-content-center flex-column"
                style={{ width: "50%" }}
                onSubmit={handleloginsubmit}
              >
                <div className="mb-2">
                  <span className="fw-medium" style={{ fontSize: ".8rem" }}>
                    LOGIN WITH USER ID :
                  </span>
                </div>
                <div className="mb-4" style={{ height: "2vh" }}>
                  <input
                    type="email"
                    className="py-0.5 px-2 rounded w-100 shadow-none border border-black"
                    id="exampleInputPassword1"
                    placeholder="User ID:"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3" style={{ height: "2vh" }}>
                  <input
                    type="password"
                    className="py-0.5 px-2 rounded w-100 shadow-none border border-black"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    required
                  />
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                  </div>
                  <div>
                    <span
                      className="cursor-pointer"
                      style={{
                        color: "black", // Set the initial color here
                        transition: "color 0.3s", // Add a smooth color transition
                        cursor: "pointer",
                        fontSize: "1vw",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "blue";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "black";
                      }}
                      onClick={() => {
                        navigate("/forget-password");
                      }}
                    >
                      Forget Password?
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <button
                    type="submit"
                    style={{ width: "7vw", height: "5vh", fontSize: ".8rem" }}
                    className="px-3 py-1 bg-color-2 rounded-1"
                  >
                    <span>LOGIN</span>
                  </button>

                  <button
                    type="button"
                    style={{ width: "10vw", height: "5vh", fontSize: ".8rem" }}
                    className="border-2 rounded-1 py-2 btn btn-outline-primary"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    <span>SIGN UP</span>
                  </button>
                </div>
              </form> */

{
  /* <form
className="ps-4 py-4 d-flex justify-content-center flex-column"
style={{ width: "50%" }}
onSubmit={handleloginsubmit}
>
<div className="form-floating mb-3">
  <input
    type="email"
    className="form-control"
    id="floatingInput"
    placeholder="name@example.com"
  />
  <label htmlFor="floatingInput">Email address</label>
</div>
<div className="form-floating mb-4">
  <input
    type="password"
    className="form-control"
    id="floatingPassword"
    placeholder="Password"
  />
  <label htmlFor="floatingPassword">Password</label>
</div>

<div className="d-flex justify-content-between mb-2">
  <div className="form-check">
    <input
      className="form-check-input"
      type="checkbox"
      id="gridCheck1"
    />
    <label className="form-check-label" htmlFor="gridCheck1">
      Remember me
    </label>
  </div>
  <div>
    <h6>Forget Password?</h6>
  </div>
</div>
<div className="d-flex justify-content-between">
  <button
    type="submit"
    className="rounded-1 bg-color-2"
    style={{ width: "30%" }}
  >
    Log In
  </button>
  <button
    type="submit"
    className="btn btn-outline-primary w-50"
    onClick={() => {
      navigate("/register");
    }}
  >
    Sign in
  </button>
</div>
</form> */
}
