import React, { useEffect, useState } from "react";
import { IoMdCheckbox } from "react-icons/io";
import {
  industries,
  countriesList,
  countryCurrencyWithFlags,
} from "../../../../../store/AllListData";
import { FaBackward } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { getURLbyEndPointV2 } from "../../../../../store/api";
import { getAuthUserlocal } from "../../../../../store/services";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import infoImg from "../../../../../assets/infoimg.png";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button, Col, Row, Form, Table, Modal, CloseButton, Spinner, OverlayTrigger, Image, Tooltip } from "react-bootstrap";
import axios from "axios";

const NonBindingDispute = ({ setActivePage }) => {
  const initialFormData = {
    date: "",
    consignorName: "",
    consigneeName: "",
    industry: "",
    disputeYear: "",
    shipmentFrom: "",
    shipmentTo: "",
    productName: "",
    quantumClaim: "",
    administrationFee: "",
    sumOfCommercialInvCurrency: "USD",
    sumOfCommercialInv: "",
    amountInDisputeCurrency: "USD",
    amountInDispute: "",
    description: "",
    applicationForm: null,
    requestLetter: null,
    otherDoc: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const captcha_length = 6;
  const [caseSummary, setCaseSummary] = useState("");
  const [showCaseSummary, setShowCaseSummary] = useState(false);
  const [apiSubmitForm, setApiSubmitForm] = useState(false);

  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleQuantumClaimChange = (e) => {
    const { value } = e.target;
    let administrationFee = "";
    switch (value) {
      case "Less than $50,000.00":
        administrationFee = 55000;
        break;
      case "$50,000.00 to $100,000.00":
        administrationFee = 75000;
        break;
      case "$100,000.00 to $200,000.00":
        administrationFee = 95000;
        break;
      case "$200,000.00 to $500,000.00":
        administrationFee = 175000;
        break;
      case "Above $500,000.00":
        administrationFee = 180000;
        break;
      default:
        administrationFee = "";
    }
    setFormData((prevState) => ({
      ...prevState,
      quantumClaim: value,
      administrationFee: administrationFee,
    }));
  };

  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };

  const handleFileChange = (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        let sizeLimit;
        if (fileName === "applicationForm") {
          sizeLimit = 5 * 1024 * 1024; // 5MB
        } else if (fileName === "requestLetter") {
          sizeLimit = 5 * 1024 * 1024; // 5MB
        } else if (fileName === "otherDoc") {
          sizeLimit = 15 * 1024 * 1024; // 15MB
        }
        if (file.size <= sizeLimit) {
          setFormData({ ...formData, [fileName]: file });
        } else {
          alert(
            `File size exceeds the limit. Please select a file smaller than ${sizeLimit / (1024 * 1024)
            }MB.`
          );
        }
      } else {
        alert("Please select a PDF file.");
      }
    }
  };

  const handleSubmitNonBindingDispute = async (e) => {
    e.preventDefault();
    try {
      const formDataForApi = new FormData();
      const userId = getAuthUserlocal()._id;
      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        return true;
      };

      if (!validateField(formData.date, "Date")) return;
      if (!validateField(formData.consignorName, "Consignor Name")) return;
      if (!validateField(formData.consigneeName, "Consignee Name")) return;
      if (!validateField(formData.industry, "Industry")) return;
      if (!validateField(formData.disputeYear, "Dispute Year")) return;
      if (!validateField(formData.shipmentFrom, "Shipment From")) return;
      if (!validateField(formData.shipmentTo, "Shipment To")) return;
      if (!validateField(formData.productName, "Product Name")) return;
      if (!validateField(formData.quantumClaim, "Quantum Claim")) return;
      if (!validateField(formData.administrationFee, "Administration Fee"))
        return;
      if (
        !validateField(
          formData.sumOfCommercialInvCurrency,
          "Sum of Commercial Invoice Currency"
        )
      )
        return;
      if (
        !validateField(
          formData.sumOfCommercialInv.toString(),
          "Sum of Commercial Invoice"
        )
      )
        return;
      if (
        !validateField(
          formData.amountInDisputeCurrency,
          "Amount in Dispute Currency"
        )
      )
        return;
      if (
        !validateField(formData.amountInDispute.toString(), "Amount in Dispute")
      )
        return;
      if (!validateField(formData.description, "Description")) return;
      if (!validateField(formData.applicationForm, "Application Form")) return;
      if (!validateField(formData.requestLetter, "Request Letter")) return;
      if (!validateField(formData.otherDoc, "Other Document")) return;

      const dataToInclude = [
        "date",
        "consignorName",
        "consigneeName",
        "industry",
        "disputeYear",
        "shipmentFrom",
        "shipmentTo",
        "productName",
        "quantumClaim",
        "administrationFee",
        "sumOfCommercialInvCurrency",
        "sumOfCommercialInv",
        "amountInDisputeCurrency",
        "amountInDispute",
        "description",
      ];

      dataToInclude.forEach((data) => {
        formDataForApi.append(data, formData[data]);
      });
      // Append files
      formDataForApi.append("applicationForm", formData.applicationForm);
      formDataForApi.append("requestLetter", formData.requestLetter);
      formDataForApi.append("otherDoc", formData.otherDoc);

      setApiSubmitForm(true);
      const res = await axios.post(
        getURLbyEndPointV2("createNonBindingDispute") + userId,
        formDataForApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setCaseSummary(res.data.nonBindingDis);

      if (res.data.status) {
        setApiSubmitForm(true);
        toast.success(res.data.message);
        setShowCaseSummary(true);
        setApiSubmitForm(false);
      } else {
        toast.error("something went wrong");
        setApiSubmitForm(false);
      }
    } catch (e) {
      console.log("wrong entries");
      console.log(e);
      toast.error(e.response.data.message);
      setApiSubmitForm(false);
    } finally {
      setApiSubmitForm(false);
    }
  };

  useEffect(() => {
    const isValid =
      formData.date &&
      formData.consignorName &&
      formData.consigneeName &&
      formData.industry &&
      formData.disputeYear &&
      formData.shipmentFrom &&
      formData.shipmentTo &&
      formData.productName &&
      formData.quantumClaim &&
      formData.sumOfCommercialInv &&
      formData.amountInDispute &&
      formData.description &&
      formData.applicationForm &&
      formData.requestLetter &&
      formData.otherDoc &&
      checkboxChecked &&
      isCaptchaCorrect
    setIsFormValid(isValid);
  }, [formData, checkboxChecked, isCaptchaCorrect]);

  useEffect(() => {
    if (!showCaseSummary) {
      loadCaptchaEnginge(captcha_length);
    }
  }, [showCaseSummary]);

  return (
    <div>
      {showCaseSummary ? (
        <PreviewNonBindingCaseSummery
          setActivePage={setActivePage}
          caseSummary={caseSummary}
        />
      ) : (
        <div>
          <div className="mb-4">
            <div className="d-flex gapx-2 py-1 gap-4 rounded justify-content-end align-items-center">
              <Button onClick={() => setActivePage("trackNonBindingDispute")}>
                Track Your Dispute
              </Button>
              <Button
                variant="secondary"
                onClick={() => setActivePage("homePage")}
              >
                <FaBackward /> Back
              </Button>
            </div>
          </div>
          <div className="border px-5 py-3 mt-3">
            <div className="my-3">
              <h6>
                A party that has not entered into any contract or agreement and
                has chosen not to invoke the model clause of AECCI-IAC may opt
                for the Non Binding Dispute resolution process.
              </h6>
            </div>
            <Row>
              <Col>
                <div className="d-flex justify-content-center fs-6">
                  <Button variant="secondary" disabled>
                    BINDING DISPUTE
                  </Button>
                </div>
              </Col>
              <Col className="d-flex justify-content-center fs-6">
                <Button active>
                  <IoMdCheckbox className="fs-5 mx-1" />
                  NON BINDING DISPUTE
                </Button>
              </Col>
            </Row>
            <Form onSubmit={handleSubmitNonBindingDispute}>
              <Row className="mb-3">
                <Col>
                  <Form.Label className="fw-semibold fs-6 mb-1">Date
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip-2">Enter the date when the form is being filled.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Group as={Row} controlId="Date">
                    <Col md="3">
                      <Form.Control
                        type="date"
                        name="date"
                        onChange={handleInputChange}
                        value={formData.date}
                        required
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mb-3 d-flex align-items-end justify-content-center">
                <div className="flex-grow-1">
                  <Form.Label className="fw-semibold fs-6 mb-1">
                    Name of Consignor
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip-2">Enter the full company name of the exporter/consignor here.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Group controlId="Consignor">
                    <Form.Control
                      type="text"
                      onChange={handleInputChange}
                      value={formData.consignorName}
                      name="consignorName"
                      maxLength={40}
                      required
                      placeholder="Enter Consignor Name"
                    />
                  </Form.Group>
                </div>
                <div className="text-center align-self-end">
                  <h6>V/S</h6>
                </div>
                <div className="flex-grow-1">
                  <Form.Label className="fw-semibold fs-6 mb-1">
                    Name of Consignee
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip-2">Enter the full company name of the importer/consignee here.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Group controlId="Consignee">
                    <Form.Control
                      type="text"
                      onChange={handleInputChange}
                      value={formData.consigneeName}
                      name="consigneeName"
                      maxLength={40}
                      required
                      placeholder="Enter Consignee Name"
                    />
                  </Form.Group>
                </div>
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="Industry">
                  <Form.Label className="fw-semibold fs-6 mb-1">
                    Selection of Industry
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip-2">Select the type of industry in which your company is registered.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Select
                    value={formData.industry}
                    onChange={handleInputChange}
                    name="industry"
                    required
                  >
                    <option value="">Select Industry</option>
                    {industries.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="YearOfDispute">
                  <Form.Label className="fw-semibold fs-6 mb-1">
                    Year Of Dispute
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip-2">Enter only the year when this dispute started.</Tooltip>}
                    >
                      {({ ref, ...triggerHandler }) => (
                        <Button
                          variant="light"
                          {...triggerHandler}
                          className="d-inline-flex align-items-center"
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <Image ref={ref}
                            roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.disputeYear}
                    onChange={handleInputChange}
                    name="disputeYear"
                    required
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row className="mb-3 mt-2 align-items-end">
                <Col>
                  <div className="fw-semibold fs-6">Shipment:</div>
                  <Row>
                    <Form.Group as={Col} controlId="ShipmentFrom">
                      <Form.Label className="fw-semibold fs-6 mb-1">
                        From
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Enter the name of the country from which the goods were exported.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Select
                        value={formData.shipmentFrom}
                        onChange={handleInputChange}
                        name="shipmentFrom"
                        required
                      >
                        <option value="">Shipment From</option>
                        {countriesList.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>

                <Col>
                  <Row className="align-items-center">
                    <Form.Group as={Col} controlId="ShipmentTo">
                      <Form.Label className="fw-semibold fs-6 mb-1">
                        To
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Enter the name of the country to which the goods were imported.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Select
                        value={formData.shipmentTo}
                        onChange={handleInputChange}
                        name="shipmentTo"
                        required
                      >
                        <option value="">Shipment To</option>
                        {countriesList.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="Product">
                    <Form.Label className="fw-semibold fs-6 mb-1">
                      Product
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="button-tooltip-2">Enter the name of the product that was exported/imported.</Tooltip>}
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                            style={{ backgroundColor: 'transparent', border: 'none' }}
                          >
                            <Image ref={ref}
                              roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                          </Button>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Product Name"
                      value={formData.productName}
                      onChange={handleInputChange}
                      name="productName"
                      maxLength={60}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3 mt-2 align-items-end">
                <Col>
                  <Row>
                    <Form.Group as={Col} controlId="ShipmentFrom">
                      <Form.Label className="fw-semibold fs-6 mb-1">
                        Quantum of Claim
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Select the range of the amount that sums the commercial invoice. For example, if the invoice value is $70,000, select the range of $50,000 - $100,000.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Select
                        name="quantumClaim"
                        value={formData.quantumClaim}
                        onChange={handleQuantumClaimChange}
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="Less than $50,000.00">
                          Less than $50,000.00
                        </option>
                        <option value="$50,000.00 to $100,000.00">
                          $50,000.00 to $100,000.00
                        </option>
                        <option value="$100,000.00 to $200,000.00">
                          $100,000.00 to $200,000.00
                        </option>
                        <option value="$200,000.00 to $500,000.00">
                          $200,000.00 to $500,000.00
                        </option>
                        <option value="Above $500,000.00">
                          Above $500,000.00
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-3 mt-2 align-items-end">
                <Col>
                  <Row>
                    <Form.Group as={Col} controlId="ShipmentFrom">
                      <Form.Label className="fw-semibold fs-6 mb-1">
                        Sum of Commercial Invoice
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Enter the amount as mentioned in the commercial/export invoice.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Row>
                        <Col lg={4}>
                          <Form.Select
                            name="sumOfCommercialInvCurrency"
                            value={formData.sumOfCommercialInvCurrency}
                            onChange={handleInputChange}
                            required
                          >
                            {" "}
                            <option value="" disabled selected>
                              Select a Country
                            </option>
                            {countryCurrencyWithFlags.map((value, index) => (
                              <option key={index} value={value.code}>
                                {value.code} ({value.name})
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col lg={8}>
                          <Form.Control
                            type="number"
                            value={formData.sumOfCommercialInv}
                            onChange={handleInputChange}
                            name="sumOfCommercialInv"
                            required
                            placeholder=""
                            min="0"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </Col>

                <Col>
                  <Row className="align-items-center">
                    <Form.Group as={Col} controlId="ShipmentFrom">
                      <Form.Label className="fw-semibold fs-6 mb-1">
                        Amount in Dispute
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Enter the principal amount involved in the dispute.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Row>
                        <Col lg={4}>
                          <Form.Select
                            name="amountInDisputeCurrency"
                            value={formData.amountInDisputeCurrency}
                            onChange={handleInputChange}
                            required
                          >
                            {" "}
                            <option value="" disabled selected>
                              Select a Country
                            </option>
                            {countryCurrencyWithFlags.map((value, index) => (
                              <option key={index} value={value.code}>
                                {value.code} ({value.name})
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col lg={8}>
                          <Form.Control
                            type="number"
                            value={formData.amountInDispute}
                            onChange={handleInputChange}
                            name="amountInDispute"
                            required
                            placeholder=""
                            min="0"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="Description">
                    <Form.Label className="fw-semibold fs-6 mb-1">
                      Describe In Detail
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="button-tooltip-2">Provide a detailed summary of your dispute in a professional manner. Based on this information, your query will either be accepted and proceedings will start, or it will be rejected.</Tooltip>}
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                            style={{ backgroundColor: 'transparent', border: 'none' }}
                          >
                            <Image ref={ref}
                              roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                          </Button>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={formData.description}
                      onChange={handleInputChange}
                      name="description"
                      maxLength={2000}
                      required
                      placeholder="Enter Product Description (max 2000 characters)"
                    />
                    <Form.Text className="text-muted">
                      {formData.description.length} / 2000 characters
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Table bordered>
                <thead>
                  <tr>
                    <th>
                      <div>Documents Required</div>
                    </th>
                    <th>
                      <div>Upload File</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      <div className="d-flex">
                        <a href="https://aecci-bucket-new.s3.amazonaws.com/arbitration_assets/ApplicationForm.pdf" target="_blank" rel="noopener noreferrer">
                          <Button className="px-3 py-2" size="sm">
                            STEP 1 - APPLICATION FORM
                          </Button>
                        </a>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="button-tooltip-2">Download this form, fill in the details online, then re-download and upload it. Ensure the form carries your signature. You may need to print, sign, scan, and then upload it.</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <Button
                              variant="light"
                              {...triggerHandler}
                              className="d-inline-flex align-items-center"
                              style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                              <Image ref={ref}
                                roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                            </Button>
                          )}
                        </OverlayTrigger>
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="d-flex justify-content-center">
                        <label
                          htmlFor="appFormA1"
                          className={`btn btn-sm ${!formData.applicationForm
                              ? "btn-info"
                              : "btn-secondary"
                            } px-3 py-2`}
                        >
                          UPLOAD
                          <input
                            id="appFormA1"
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileChange(event, "applicationForm")
                            }
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-semibold fs-6">
                      <a href="https://aecci-bucket-new.s3.amazonaws.com/arbitration_assets/AuthorisationLetter.pdf " target="_blank" rel="noopener noreferrer">
                        <Button className="px-3 py-2" size="sm">
                          AUTHORIZATION LETTER
                        </Button>
                      </a>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="button-tooltip-2">Based on the provided format of the authorization letter, read it carefully, enter the required details, print it on company letterhead, and upload the scanned copy here.</Tooltip>}
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                            style={{ backgroundColor: 'transparent', border: 'none' }}
                          >
                            <Image ref={ref}
                              roundedCircle src={infoImg} style={{ height: "3vh" }} alt="" />
                          </Button>
                        )}
                      </OverlayTrigger>
                    </td>
                    <td>
                      <div className="d-flex">
                        <label
                          htmlFor="filePicker"
                          className={`btn btn-sm ${!formData.requestLetter
                              ? "btn-info"
                              : "btn-secondary"
                            } px-3 py-2`}
                        >
                          UPLOAD
                          <input
                            id="filePicker"
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileChange(event, "requestLetter")
                            }
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="fs-6">
                      <div className="mx-auto">
                        Signed Manifest, Request for Space, Shipping
                        Contract(s), Canceled Cheques , Correspondence with
                        agent , Correspondence with Carrier, Correspondence with
                        Suppliers/ Buyers , Packing Lists , Booking Note,
                        Anticipated Cargo Form , Witness Statements, Photographs
                        , Repair Estimates , Notes , Receipts AND Others
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <label
                          htmlFor="otherDoc"
                          className={`btn btn-sm ${!formData.otherDoc ? "btn-info" : "btn-secondary"
                            } px-3 py-2`}
                        >
                          UPLOAD
                          <input
                            id="otherDoc"
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileChange(event, "otherDoc")
                            }
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  label="I hereby affirm that all the information provided herein is accurate and true to the best of my knowledge and I shall adhere to all the regulations of Asian Exporters Chamber of Commerce and Industry."
                  onChange={(e) => setCheckboxChecked(e.target.checked)}
                />
              </Form.Group>
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <LoadCanvasTemplate />
                  {isCaptchaCorrect && (
                    <AiFillCheckCircle className="icon-size primary-color" />
                  )}{" "}
                </div>

                <div className="w-50">
                  <input
                    className="w-100 px-2 py-1 border-1"
                    placeholder="Type the given captcha word"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    maxLength={captcha_length}
                    onChange={handleCaptchaChange}
                    value={captchaValue}
                  />
                  {isCaptchaCorrect ? (
                    <p className="text-success">CAPTCHA is valid</p>
                  ) : (
                    <p className="text-danger">CAPTCHA is invalid</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant={!isFormValid ? "secondary" : "success"}
                  type="submit"
                  disabled={!isFormValid}
                >
                  {apiSubmitForm ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Processing...
                    </>
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};
export default NonBindingDispute;

function PreviewNonBindingCaseSummery({ setActivePage, caseSummary }) {
  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .replace(" ", "");
  };

  return (
    <>
      <div className="border px-4 py-2">
        <div className="text-center">
          <h2 className="fw-bold" style={{ fontFamily: "Raleway" }}>Case Summary</h2>
        </div>
        <hr />
        <div className="d-flex justify-content-between mt-2">
          <div className="fs-6">
            <div>
              <strong>Application No:</strong> {caseSummary.appNo}
            </div>
            <div>
              <strong>Status:</strong> {caseSummary.isApproved}
            </div>
          </div>
          <div className="fs-6">
            <div>
              <strong>Date:</strong> {caseSummary.date}
            </div>
            <div>
              <strong>Time:</strong> {formatTime(caseSummary.createdAt)}
            </div>
          </div>
        </div>
        <hr />
        <div className="mt-2">
          <p style={{ lineHeight: "30px" }}>
            The Case involves a dispute between{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.consignorName}
            </span>{" "}
            and{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.consigneeName}
            </span>{" "}
            it started in the year
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.disputeYear}
            </span>{" "}
            over a Product Called{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.productName}
            </span>{" "}
            in the{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.industry}
            </span>
            . The Said Product was shipped form{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.shipmentFrom}
            </span>{" "}
            to{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.shipmentTo}
            </span>{" "}
            and the amount in dispute is{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.amountInDisputeCurrency}{" "}
              {caseSummary.amountInDispute}/-
            </span>
            .{" "}
          </p>
          <p style={{ lineHeight: "35px" }}>
            The Amount as in Commercial invoice is{" "}
            <span className="px-2 fw-bold text-uppercase">
              {caseSummary.sumOfCommercialInvCurrency}{" "}
              {caseSummary.sumOfCommercialInv}/-
            </span>{" "}
            and the details of the dispute are as follows:
          </p>
          <h5>Description:</h5>
          <p style={{ lineHeight: "35px" }}>{caseSummary.description}</p>
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex gapx-2 py-1rounded justify-content-around align-items-center">
          <Button onClick={() => setActivePage("trackNonBindingDispute")}>
            Track Your Document
          </Button>
          <Button variant="secondary" onClick={() => setActivePage("homePage")}>
            <FaBackward /> Back
          </Button>
        </div>
      </div>
    </>
  );
}






function ModalComponentForNBD({ body, disabled }) {
  const [modalShow, setModalShow] = React.useState(false);

  const handleButtonClick = () => {
    if (!disabled) {
      setModalShow(true);
    }
  };

  return (
    <>
      <Button
        className={`bg-white border-0 ${disabled ? "disabled" : ""}`}
        onClick={handleButtonClick}
      >
        <img src={infoImg} style={{ height: "3vh" }} alt="" />
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Body className="d-flex justify-content-center position-relative font1">
          {body}
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="d-flex justify-content-center">
          <img
            className="img-fluid"
            style={{ width: "80%" }}
            src={popUpImg}
            alt="eco"
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
}