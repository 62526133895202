import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import infoImg from "../../../assets/infoimg.png";
import "./digitalLibaray.css";
import { getURLbyEndPoint } from "../../../store/api";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import SideBar1 from "../../../components/Sidebar/SideBar1";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuickLinks from "../../../components/basic/QuickLinks";

const DigitalLibrary = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [allPublications, setAllPublications] = useState([]);
  const [activePublicationType, setActivePublicationType] = useState("All");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [latestPublications, setLatestPublications] = useState({});

  const itemsPerPage = 6;

  const filteredPublications = allPublications.filter(
    (publication) =>
      activePublicationType === "All" ||
      publication.publicationType === activePublicationType
  );

  const totalPages = Math.ceil(filteredPublications.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getURLbyEndPoint("getPublication"));
        const publications = response.data;

        // Group publications by publication type
        const publicationGroups = {};
        publications.forEach((publication) => {
          const publicationType = publication.publicationType;
          if (!publicationGroups[publicationType]) {
            publicationGroups[publicationType] = [];
          }
          publicationGroups[publicationType].push(publication);
        });

        // Find the latest publication for each type
        const latest = {};
        Object.keys(publicationGroups).forEach((publicationType) => {
          latest[publicationType] = publicationGroups[publicationType].reduce(
            (prev, current) => {
              return new Date(prev.publicationDate) >
                new Date(current.publicationDate)
                ? prev
                : current;
            }
          );
        });

        setLatestPublications(latest);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run once on component mount

  useEffect(() => {
    const fetchAllPublications = async () => {
      try {
        const response = await axios.get(getURLbyEndPoint("getPublication"));
        const publications = response.data;
        setAllPublications(publications);
      } catch (error) {
        console.error("Failed to fetch all publications:", error);
      }
    };

    fetchAllPublications();
  }, []);

  const handleImageClick = (pdfUrl) => {
    setSelectedPdfUrl(pdfUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="d-flex flex-column dashboardBlock">
            <div>
              <div className="d-flex align-items-center">
                <h4 className="py-4">Digital Library</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Our Digital Library</Tooltip>}
                >
                  <img
                    src={infoImg}
                    style={{ height: "4vh" }}
                    alt=""
                    srcSet=""
                  />
                </OverlayTrigger>
              </div>
            </div>
            <hr className="mt-1 mb-4" />
          </div>

          <div className="booklibaray px-4 py-3">
            <div className="container bg-white p-4 rounded-4">
              <div className="libarayTitle d-flex justify-content-between align-items-center">
                <h6 className="font-weight-bold text-primary">Recommended</h6>
                <Button
                  style={{ paddingBlock: "2px" }}
                  variant="outline-primary"
                  size="sm"
                  onClick={() => setIsSidebarOpen(true)}
                >
                  See All
                </Button>
              </div>
              <Row className="mt-4">
                {Object.entries(latestPublications).map(
                  ([publicationType, publication]) => (
                    <Col key={publication._id}>
                      <Card
                        className="book-card"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => handleImageClick(publication.pdfUrl)}
                      >
                     
                        <div className="cardImg p-2">
                          <Card.Img
                            className="img-responsive"
                            variant="top"
                            src={publication.bannerImg}
                            alt={publication.title}
                          />
                        </div>
                        <Card.Body className="px-1 pb-0 pt-1">
                          <Card.Title className="book-name">
                            {publication.title}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                )}
              </Row>
            </div>

            <div className="container bg-white mt-4 p-4 rounded-4">
              <div className="libarayTitle ">
                <h6 className="font-weight-bold text-primary">Categoires</h6>
                <div className="book-categories d-flex gap-4 pl-4 py-2">
                  <Nav variant="pills" className="smaller-nav-pills gap-3">
                    <Nav.Item>
                      <Nav.Link
                        href="#"
                        active={activePublicationType === "All"}
                        onClick={() => setActivePublicationType("All")}
                      >
                        All
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        href="#"
                        active={activePublicationType === "Viewpoint"}
                        onClick={() => setActivePublicationType("Viewpoint")}
                      >
                        Viewpoint
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        href="#"
                        active={activePublicationType === "Newsletter"}
                        onClick={() => setActivePublicationType("Newsletter")}
                      >
                        Newsletters
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        href="#"
                        active={activePublicationType === "Magazine"}
                        onClick={() => setActivePublicationType("Magazine")}
                      >
                        Magzines
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        href="#"
                        active={activePublicationType === "Annual report"}
                        onClick={() =>
                          setActivePublicationType("Annual report")
                        }
                      >
                        Annual report
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <Row className="mt-3" fluid>
                {filteredPublications
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((publication) => (
                    <Col md="auto">
                      <Card
                        className="book-card book-card-category"
                        style={{ width: "5rem", height: "auto" }}
                        key={publication._id}
                        onClick={() => handleImageClick(publication.pdfUrl)}
                      >
                        <Card.Img
                          className="img-fluid p-0"
                          variant="top"
                          src={publication.bannerImg}
                          alt={publication.title}
                        />
                        <Card.Body className="px-1 pt-1 pb-0">
                          <Card.Title className="book-name">
                            {publication.title}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
              <div className="mt-4">
                <Pagination>
                  <Pagination.First
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(1)}
                  />
                  <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  />
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber + 1 === currentPage}
                      onClick={() => setCurrentPage(pageNumber + 1)}
                    >
                      {pageNumber + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  />
                  <Pagination.Last
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(totalPages)}
                  />
                </Pagination>
              </div>
            </div>
          </div>

<QuickLinks/>        

          <Modal
            width="900px"
            height="700px"
            show={isModalOpen}
            onHide={closeModal}
          >
            <Modal.Body>
              <embed
                src={selectedPdfUrl}
                type="application/pdf"
                width="100%"
                height="660px"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Offcanvas
            show={isSidebarOpen}
            style={{ width: "500px" }}
            placement={"end"}
            onHide={() => setIsSidebarOpen(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Latest Publications</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="d-flex flex-wrap gap-3 mt-4">
                {allPublications.reverse().map((publication) => (
                  <Card
                    className="book-card"
                    style={{ width: "8rem" }}
                    key={publication._id}
                    onClick={() => handleImageClick(publication.pdfUrl)}
                  >
                    <Card.Img
                      className="img-responsive p-0"
                      variant="top"
                      src={publication.bannerImg}
                      alt={publication.title}
                    />
                    <Card.Body className="px-1 pt-1 pb-0">
                      <Card.Title className="book-name">
                        {publication.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <div style={{ height: "auto" }}>
            <SideBar1 />
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default DigitalLibrary;
