import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { FaBackward } from "react-icons/fa";
import { IoMdCheckbox } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { countriesList, industries } from "../../../../../store/AllListData";
import infoImg from "../../../../../assets/infoimg.png";

const steps = [
  {
    name: "Profile of Claimant",
    content: (
      <>
        <Row className="mb-3 mt-2 align-items-end">
          <Col>
            <Row>
              <Form.Group as={Col} lg="4" controlId="ShipmentFrom">
                <Form.Label className="fw-semibold fs-6 mb-1">
                  Date
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Enter the date when the form is being filled.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Button
                        variant="light"
                        {...triggerHandler}
                        className="d-inline-flex align-items-center"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <Image
                          ref={ref}
                          roundedCircle
                          src={infoImg}
                          style={{ height: "3vh" }}
                          alt=""
                        />
                      </Button>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Row>
                  <Col lg={12}>
                    <Form.Group controlId="Date">
                      <Form.Control
                        type="date"
                        name="date"
                        // onChange={handleInputChange}
                        // value={formData.date}
                        required
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Col>

          <Col lg="2">
            <Row className="align-items-end">
              <Button variant="info">General Info</Button>
            </Row>
          </Col>
        </Row>

        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Form.Label className="fw-semibold fs-6 mb-1">
                  Place
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="button-tooltip-2">place</Tooltip>}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Button
                        variant="light"
                        {...triggerHandler}
                        className="d-inline-flex align-items-center"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <Image
                          ref={ref}
                          roundedCircle
                          src={infoImg}
                          style={{ height: "3vh" }}
                          alt=""
                        />
                      </Button>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Group controlId="Consignor">
                  <Form.Control
                    type="text"
                    // onChange={handleInputChange}
                    // value={formData.consignorName}
                    name="Place"
                    maxLength={40}
                    required
                    placeholder="place"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Time Table</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mb-3 d-flex align-items-end justify-content-center">
          <div className="flex-grow-1">
            <Form.Label className="fw-semibold fs-6 mb-1">
              Name of Claimant
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip-2">Name of Claimant</Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <Image
                      ref={ref}
                      roundedCircle
                      src={infoImg}
                      style={{ height: "3vh" }}
                      alt=""
                    />
                  </Button>
                )}
              </OverlayTrigger>
            </Form.Label>
            <Form.Group controlId="Consignor">
              <Form.Control
                type="text"
                // onChange={handleInputChange}
                // value={formData.consignorName}
                name="consignorName"
                maxLength={40}
                required
                placeholder="Name of Claimant"
              />
            </Form.Group>
          </div>
          <div className="text-center align-self-end">
            <h6>V/S</h6>
          </div>
          <div className="flex-grow-1">
            <Form.Label className="fw-semibold fs-6 mb-1">
              Name of Respondent
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip-2">Name of Respondent</Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <Button
                    variant="light"
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <Image
                      ref={ref}
                      roundedCircle
                      src={infoImg}
                      style={{ height: "3vh" }}
                      alt=""
                    />
                  </Button>
                )}
              </OverlayTrigger>
            </Form.Label>
            <Form.Group controlId="Consignee">
              <Form.Control
                type="text"
                // onChange={handleInputChange}
                // value={formData.consigneeName}
                name="consigneeName"
                maxLength={40}
                required
                placeholder="Name of Respondent"
              />
            </Form.Group>
          </div>
        </div>

        <div
          className="border-3 rounded px-4 py-3"
          style={{ background: "#F4F4F9" }}
        >
          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold  fs-6 mb-1">
                Address
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">address</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignor">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consignorName}
                  name="consignorName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
                City
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Zip Code
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Country
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Select the country
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.industry}
                // onChange={handleInputChange}
                name="industry"
                required
              >
                <option value="">Select Country</option>
                {/* {industries.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))} */}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Phone
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Phone</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                PAN No
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">PAN No</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Email id
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">email id</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="ShipmentFrom">
              <Form.Label className="fw-semibold  fs-6 mb-1">
                Nature of Business
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Nature of Business
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.shipmentFrom}
                // onChange={handleInputChange}
                name="shipmentFrom"
                required
              >
                <option value="">select</option>
                {/* {countriesList.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))} */}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="ShipmentFrom">
              <Form.Label className="fw-semibold  fs-6 mb-1">
                Industry
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Industry</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.shipmentFrom}
                // onChange={handleInputChange}
                name="shipmentFrom"
                required
              >
                <option value="">Select</option>
                {/* {countriesList.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))} */}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3 mt-3 justify-content-center">
            <Col lg="9">
              <h5 className="">Upload Company Registration Document</h5>
            </Col>
            <Col lg="3">
              <Button variant="success">Upload</Button>
            </Col>
          </Row>
        </div>

        <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
          <Form.Check
            type="checkbox"
            label="I hereby affirm that all the information provided herein is accurate and true to the best of my knowledge and I shall adhere to all the regulations of Asian Exporters Chamber of Commerce and Industry."
          // onChange={(e) => setCheckboxChecked(e.target.checked)}
          />
        </Form.Group>

        {/* <div className="d-flex justify-content-end">
          <Button
            variant="success"
            // variant={!isFormValid ? "secondary" : "success"}
            type="submit"
          // disabled={!isFormValid}
          >
            NEXT
          </Button>
        </div> */}
      </>
    ),
  },
  {
    name: "Details of Dispute",
    content: (
      <>
      <div>
      <Row className="mb-3 mt-3 justify-content-center align-items-center">
            <Col lg="9">
              <h5 className="">Agreement/contract</h5>
            </Col>
            <Col lg="3">
              <Button variant="info">Upload</Button>
            </Col>
          </Row>
     
        <Form.Group controlId="ClaimantName">
          <Row>
            <Col lg="8">
            <Form.Label className="fw-semibold fs-6 mb-1">Agreement Start Date</Form.Label>
            </Col>
            <Col lg="4">
          <Form.Control type="date" name="claimantName" maxLength={40} required />
            </Col>
          </Row>
        </Form.Group>


        <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
              Product
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Commercial invoice amount
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>



        <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
              Dispute Start Date
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="date"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Amount of Activity
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>



          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
              Quantum of Claim
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.industry}
                // onChange={handleInputChange}
                name="industry"
                required
              >
                <option value="">Select</option>
                {/* {industries.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))} */}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Amount in Dispute (in USD)
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>


        <Form.Group controlId="RespondentName">
        <Form.Label className="fw-semibold fs-6  mb-1">Describe your dispute in Details</Form.Label>
          <Form.Control type="textarea" as="textarea"
          row="2" name="respondentName" maxLength={40} required />
        </Form.Group>


        <div
          className="border-3 rounded px-4 py-3 mt-3"
          style={{ background: "#F4F4F9" }}
        >
          <h6>Details of Respondent</h6>


          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
             Name of Company
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Name of Representative
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold  fs-6 mb-1">
                Address
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">address</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignor">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consignorName}
                  name="consignorName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>


          <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
                City
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Select the country
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.industry}
                // onChange={handleInputChange}
                name="industry"
                required
              >
                <option value="">Select Country</option>
                {/* {industries.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))} */}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
                State
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Select the city
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.industry}
                // onChange={handleInputChange}
                name="industry"
                required
              >
                <option value="">Select state</option>
                {/* {industries.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))} */}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Zip Code
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Phone</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                PAN No
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Email Id</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
             Website
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">email id</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
          <Form.Check
            type="checkbox"
            label="I hereby affirm that all the information provided herein is accurate and true to the best of my knowledge and I shall adhere to all the regulations of Asian Exporters Chamber of Commerce and Industry."
          // onChange={(e) => setCheckboxChecked(e.target.checked)}
          />
        </Form.Group>

        </div>

        </div>
      </>
    ),
  },
  {
    name: "Required Documents",
    content: (
      <>
       <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Arbitration Form - A1</div>
          </Col>
          <Col lg="3">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Fill Form</Button>
              </Col>
            </Row>
          </Col>

          <Col lg="3">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Correspondence with supplier / Buyer</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Commercial Invoice</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        


        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Inspection Reports, If any</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>



        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Correspondence with Agent, if any</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>


        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Correspondence with Carrier, If any</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>



        
        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">Photographs / Witness Statements, If any</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>



        <Row className="mb-3 mt-2 align-items-end">
          <Col lg="6">
           <div className="fw-semibold fs-6 mb-1">If any Legal action initiated, corresponding Required</div>
          </Col>

          <Col lg="6">
            <Row clasName="">
              <Col lg="12" className="d-flex justify-content-end">
                <Button variant="info">Upload</Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <hr></hr>
      </>
    ),
  },
  {
    name: "Calculation of Fees",
    content: (
      <>
      
      <Row className="mb-3">
            <Form.Group as={Col} controlId="Industry">
              <Form.Label className="fw-semibold fs-6  mb-1">
              Quantum of Claim
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Select the City</Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Select
                // value={formData.industry}
                // onChange={handleInputChange}
                name="industry"
                required
              >
                <option value="">Select</option>
                {/* {industries.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))} */}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="YearOfDispute">
              <Form.Label className="fw-semibold fs-6  mb-1">
                Amount in Dispute (in USD)
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">zipcode</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <Image
                        ref={ref}
                        roundedCircle
                        src={infoImg}
                        style={{ height: "3vh" }}
                        alt=""
                      />
                    </Button>
                  )}
                </OverlayTrigger>
              </Form.Label>
              <Form.Group controlId="Consignee">
                <Form.Control
                  type="text"
                  // onChange={handleInputChange}
                  // value={formData.consigneeName}
                  name="consigneeName"
                  maxLength={40}
                  required
                  placeholder=""
                />
              </Form.Group>
            </Form.Group>
          </Row>


          <Form.Group controlId="RespondentName">
        <Form.Label className="fw-semibold fs-6  mb-1">Notes:</Form.Label>
          <Form.Control type="textarea" as="textarea"
          row="3" name="respondentName" maxLength={40} required />
        </Form.Group>

        <Row className="mt-3">
          <Col>
                  <div className="fw-semibold fs-6  mb-1">Case filling fee</div>
          </Col>
          <Col className="text-right fw-bold">
          <div>
          USD 300$
            </div>
            <div>
              (INR: ___RS)</div></Col>
        </Row>
        <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
          <Form.Check
            type="checkbox"
            label="I hereby affirm that all the information provided herein is accurate and true to the best of my knowledge and I shall adhere to all the regulations of Asian Exporters Chamber of Commerce and Industry."
          // onChange={(e) => setCheckboxChecked(e.target.checked)}
          />
        </Form.Group>
      </>
    ),
  },
];

const BindingDispute = ({ setActivePage }) => {
  const [currentStep, setCurrentStep] = useState(0);

  
  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div>
      <div className="mb-4">
        <div className="d-flex gapx-2 py-1 gap-4 rounded justify-content-end align-items-center">
          <Button onClick={() => setActivePage("trackNonBindingDispute")}>
            Track Your Dispute
          </Button>
          <Button variant="secondary" onClick={() => setActivePage("homePage")}>
            <FaBackward /> Back
          </Button>
        </div>
      </div>
      <div className="border px-5 py-3 mt-3">
        <div className="my-3">
          <h6>
            A party that has not entered into any contract or agreement and has
            chosen not to invoke the model clause of AECCI-IAC may opt for the
            non-binding dispute resolution process.
          </h6>
        </div>
        <Row>
          <Col>
            <div className="d-flex justify-content-center fs-6">
              <Button active>
                <IoMdCheckbox className="fs-5 mx-1" />
                BINDING DISPUTE
              </Button>
            </div>
          </Col>
          <Col className="d-flex justify-content-center fs-6">
            <Button disabled variant="secondary">
              NON BINDING DISPUTE
            </Button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <div className="mx-auto mt-5">
            <div className="progress px-1" style={{ height: "3px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${((currentStep) / steps.length) * 100}%` }}
                aria-valuenow={(currentStep + 1) * 2}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="step-container d-flex justify-content-between">
              {steps.map((step, index) => (
                <div key={index} className="step-item text-center">
                  <div className={`step-circle ${currentStep >= index ? "active" : ""}`}>
                    {currentStep > index ? <MdDone /> : index + 1}
                  </div>
                  <div className="step-label">{step.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-3 mt-2">
            {steps[currentStep].content}
          </div>
          <div className="d-flex justify-content-between">
            {currentStep > 0 && (
              <Button variant="secondary" onClick={handlePrevious}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button variant="primary" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <Button variant="success" type="submit">
                Submit
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BindingDispute;
