import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import EventBanner from "../../assets/popupBanners/B2bBanner.png";
import { IoClose } from 'react-icons/io5';
import warningGif from "../../assets/gifs/warning.gif";


const NoticePopup = (props) => {
  return (
    <div>
      <Modal size="lg" show={props.isOpen} onHide={props.toggle} centered>
        <Modal.Body>
          <div className="text-center">
            <div className="text-end">
              <IoClose className="icon-size text-danger" onClick={props.toggle} />
            </div>
            <img
              src={warningGif}
              alt="Warning GIF"
              width="160px"
              height="100%"
            />
            <h4>IMPORTANT NOTICE</h4>
            <div className='w-75 mx-auto'>
            <h6 className="text-primary fs-5">
                Effective from <span className='fw-bold text-danger'> October 15, 2024,</span> all users must maintain a minimum wallet balance to continue using our e-platform services, including services such as the Electronic Certificate of Origin.
            </h6>
            <h6 className="text-info">
              <strong>Non-members:</strong> Minimum deposit required is <strong>3000</strong>.
            </h6>
              <h6 className="text-info">
              <strong>Members:</strong> Minimum deposit required is <strong>2000</strong>.
            </h6>
            <h6 className="text-secondary">
              This change is necessary to manage the increased volume of transactions and to ensure a smooth experience for all users.
            </h6>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default NoticePopup;