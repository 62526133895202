import React, { useState } from "react";
import { useNavigate } from "react-router";
import Layout from "../../components/Layouts/Layouts/Layout";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import WelcomeBanner from "../../components/basic/WelcomeBanner";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import axios from "axios";
import { getURLbyEndPoint } from "../../store/api";
import EPlatformHeader from "../../components/basic/EPlatformHeader";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    otp: "",
  });

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      //validation
      if (!email) {
        console.error("email is required");
        alert("Email ID is required!!");
        return;
      }

      setLoading(true);
      // Simulate API request for demonstration purposes
      // Replace the setTimeout with your actual API request
      const res = await axios.put(getURLbyEndPoint("forgetPasswordSendOtp"), {
        email,
      });

      console.log(res);

      setTimeout(() => {
        setOtpSent(true);
        setSuccess("OTP sent Successfully!!");
        setLoading(false);
      }, 2000);

      //for setting false again
      setError(false);
    } catch (error) {
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  // Function to update form data
  const updateFormData = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //on click change password
  const handleChangePass = async (e) => {
    e.preventDefault();
    formData.email = email;
    console.log(formData);

    // Validation logic
    if (
      !formData.newPassword.trim() ||
      !formData.confirmNewPassword.trim() ||
      !formData.otp.trim()
    ) {
      console.error("All fields are required");
      alert("All fields are required");
      return;
    }
    if (formData.newPassword !== formData.confirmNewPassword) {
      alert("New Password and Confirm New Password do not match");
      return;
    }
    // Password complexity validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=\S+$).{8,15}$/;
    if (!passwordRegex.test(formData.newPassword)) {
      // Display an error message or perform other actions
      alert(
        "Password must be 8-15 characters, contain at least one lowercase letter, one number, and may include one uppercase letter and one special character."
      );
      return;
    }

    //calling api for changing password
    try {
      const res = await axios.post(
        getURLbyEndPoint("forgetPassword"),
        formData
      );

      if (res.data.status) {
        // Password changed successfully
        alert(res.data.message);

        navigate("/login");
      } else {
        // Handle API error
        alert(res.data.message);
      }
    } catch (error) {
      // Handle network error or other exceptions
      console.error("API call error:", error);
      setError(error.response.data.message);
      setSuccess(false);
      alert("Failed to change password. Please try again.");
    }
  };

  return (
    <>
      <Layout>
        <EPlatformHeader
          text="Embark on a new era of seamless business solutions! AECCI proudly unveils its upgraded e-Platform, now featuring digital
services, expert consultancy, shipping document attestation, visa recommendation letter issuance, and robust support for
Trade disputes &amp; Arbitration matters—all under one roof. Elevate your experience and streamline success with AECCI&#39;s
enhanced capabilities!"
        />
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="main-width my-3">
            {error && (
              <Alert variant="danger" className="mt-2">
                {error}
              </Alert>
            )}
            {success && (
              <Alert variant="success" className="mt-2">
                {success}
              </Alert>
            )}
            <h3 className="mb-3">FORGET YOUR PASSWORD? </h3>
            <div>
              <Form onSubmit={handleSendOtp}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3 w-50"
                >
                  <Form.Control
                    type="email"
                    required
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={otpSent}
                    maxLength={50}
                  />
                </FloatingLabel>
                <Button
                  type="submit"
                  variant="primary"
                  style={{ backgroundColor: "#ed9808" }}
                  className="border-none"
                  disabled={loading || otpSent}
                >
                  {loading ? "SENDING..." : otpSent ? "OTP SENT" : "SEND OTP"}
                </Button>{" "}
              </Form>

              {/* //after otp has been sent */}
              {otpSent && (
                <>
                  <Form className="mt-3" onSubmit={handleChangePass}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="New Password"
                      className="mb-3 w-50"
                    >
                      <Form.Control
                        type="password" // Change type to password
                        required
                        placeholder="Enter new password"
                        value={formData.newPassword}
                        onChange={(e) =>
                          updateFormData("newPassword", e.target.value)
                        }
                      />{" "}
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Confirm New Password"
                      className="mb-3 w-50"
                    >
                      <Form.Control
                        type="text" // Change type to password
                        required
                        placeholder="Confirm new password"
                        value={formData.confirmNewPassword}
                        onChange={(e) =>
                          updateFormData("confirmNewPassword", e.target.value)
                        }
                      />{" "}
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter OTP"
                      className="mb-3 w-50"
                    >
                      <Form.Control
                        type="text"
                        required
                        placeholder="name@example.com"
                        value={formData.otp}
                        onChange={(e) => updateFormData("otp", e.target.value)}
                        onWheel={(e) => e.preventDefault()} // Disable scrolling with arrow buttons
                        onKeyPress={(e) => {
                          const isDigit = /^\d$/g.test(e.key);
                          const isPositiveInteger =
                            (e.target.value === "" && isDigit) || // Allow the first digit if the input is empty
                            /^[0-9]\d*$/g.test(e.target.value + e.key); // Check for positive integers

                          if (!isPositiveInteger) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FloatingLabel>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ backgroundColor: "#ed9808" }}
                      className="border-none"
                    >
                      Change Password
                    </Button>{" "}
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ForgetPassword;
