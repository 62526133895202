import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EmailForm = ({ onEmailSubmit }) => {
 const [email, setEmail] = useState("");

 const handleSubmit = (e) => {
    e.preventDefault();
    onEmailSubmit(email); // Call the passed function with the email
 };

 return (
    <div className="container m-auto p-6 border rounded" style={{padding: "40px", marginInline: "200px", marginTop: "200px"}}>
      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Enter Your Register Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Valid email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="success" type="submit">
          Submit
        </Button>
      </Form>
    </div>
 );
};

export default EmailForm;
