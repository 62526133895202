import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const HrWingForm = ({
    title,
    formData,
    setFormData,
    isCaptchaCorrect,
    setIsCaptchaCorrect,
    wingApiFlag,
    handleExportFormSubmit,
}) => {
  const navigate = useNavigate();
  
  const [captchaMatched, setCaptchaMatched] = useState(false);

  const typesWithTopic = [
    {
      name: "Employment Law",
      types: [
        "Employment discrimination",
        "Wages and benefits",
        "Noncompetition agreement",
      ],
    },
    {
      name: "Staff Grievances and Disputes",
      types: ["Handle Employee Grievances"],
    },
    {
      name: "Disciplinary Issues",
      types: [
        "Attendance Related Problems",
        "Job Behavior Problems",
        "Dishonesty Problems",
        "Outside Activities Problems",
      ],
    },
    {
      name: "Contracts of Employment",
      types: [
        "Full-time contract",
        "Part-time contract",
        "Zero-hour contract",
        "Casual contract",
        "Freelance contract",
        "Union contract",
        "Executive contract",
        "Fixed-term contract",
        "At-will agreement",
        "Non-compete and confidentiality contracts",
      ],
    },
    {
      name: "Managing Apprentices",
      types: [
        "Trade apprentice",
        "Graduate apprentice",
        "Technician apprentice",
        "Technician (vocational) apprentice",
        "Optional trade apprentice",
      ],
    },
    {
      name: "Calculating Settlement Agreements",
      types: ["Termination payment calculation", "Settlement agreement"],
    },
  ];

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleTypeChange = (event) => {
    setFormData({ ...formData, query: event.target.value })
  };

  const handleCaptchaChange = (evt) => {
    // setIsVisible(true);
    setCaptchaMatched(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
        setIsCaptchaCorrect(true);
        return;
    }
    setIsCaptchaCorrect(false);
    console.log(captchaMatched);
}

  const handleTopicChange = (event) => {
    setFormData({ ...formData, topic: event.target.value })
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({...formData, refDoc: file });
  };

 
  const MAX_CHARACTERS = 1000; // Define the maximum number of characters

  const handleSummaryChange = (event) => {
    const text = event.target.value;
        if (text.length <= MAX_CHARACTERS) {
            // Only update the state if the input is within the character limit
            setFormData({ ...formData, summary: event.target.value });
        }
  };
  

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />
        <div className="card mt-3">
          <div className="card-header pt-3 pb-5">
            <h5 className="card-title mb-2">
              {title}
            </h5>
            <p className="card-text">
              To schedule an online consultation, pose inquiries, or arrange a
              meeting with our expert, please provide the necessary information
              below:
            </p>
            <Button className="" onClick={() => navigate("/Hr-wing-token")}>
                                Track Queries
                            </Button>
          </div>
          <form className="card-body" onSubmit={handleExportFormSubmit}>
            <div className="d-flex justify-content-between mb-3 gap-5">
              <div className="w-50">
                <label htmlFor="queryType" className="form-label">
                  <h5>Type of Query</h5>
                </label>
                <select
                  id="queryType"
                  className="form-select"
                  onChange={handleTypeChange}
                  value={formData.query || ""}
                  required
                >
                  <option value="">Select Type</option>
                  {typesWithTopic.map((type, index) => (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-50">
                <label htmlFor="queryTopic" className="form-label">
                  <h5>Type of Topic</h5>
                </label>
                <select
                  id="queryTopic"
                  className="form-select"
                  onChange={handleTopicChange}
                  value={formData.topic || ""}
                  disabled={!formData.query} // Disable the topic select if type is not selected
                  required
                >
                  <option value="">Select Topic</option>
                  {formData.query &&
                    typesWithTopic
                      .find((type) => type.name === formData.query)
                      .types.map((subType, subIndex) => (
                        <option key={subIndex} value={subType}>
                          {subType}
                        </option>
                      ))}
                </select>
              </div>
            </div>

            <h5 className="mb-4 text-secondary">Brief of Summary </h5>
            <textarea
              className="form-control border-2 mb-4"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder={`Max Characters ${MAX_CHARACTERS}..`}
              required
              value={formData.summary} // Set the value of the textarea to the state variable
              onChange={handleSummaryChange} // Handle the change event
            ></textarea>
            <div className="d-flex justify-content-between mb-4">
              <span className="w-50">Reference Document (if any)</span>
              <label className="bg-color p-2 w-50 border-none text-center">
                UPLOAD
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </label>
            </div>
            <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex align-items-center">
                                    <LoadCanvasTemplate />
                                    {isCaptchaCorrect && (
                                        <AiFillCheckCircle className="icon-size primary-color" />
                                    )}{" "}
                                </div>
                                <div className="w-50">
                                    <input
                                        className="w-100 p-1 border-1"
                                        placeholder="Type the given captcha wordsssss"
                                        id="user_captcha_input"
                                        onChange={handleCaptchaChange}
                                        name="user_captcha_input"
                                        type="text"
                                    />
                                    {isCaptchaCorrect ? (
                                        <p className="text-success">CAPTCHA is valid</p>
                                    ) : (
                                        <p className="text-danger">CAPTCHA is invalid</p>
                                    )}
                                </div>
                            </div>
            <div className="d-flex justify-content-center gap-4 ">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
                required
              />
              <span className="w-75">
                I, hereby duly consent and acknowledge that all details filled
                herein are true, correct and any misrepresentation of facts,
                documents, or any other relevant information may deem result in
                disapproval of our Query.
              </span>
            </div>
            <hr />
            <Button
                                type="submit"
                                variant="success"
                                className="float-end w-25"
                                disabled={wingApiFlag}
                            >
                                {wingApiFlag ? "Processing..." : "SUBMIT"}
                            </Button>
          </form>
        </div>
      </Layout2>
    </Layout>
  );
};

export default HrWingForm;
