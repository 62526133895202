import React, { useState, useEffect } from "react";
import logo from "../../../assets/logo.jpg";
import headerImg1 from "../../../assets/headerImgs/headerImg1.png";
import headerImg2 from "../../../assets/headerImgs/headerImg2.png";
import aecciLogo from "../../../assets/headerImgs/aecciLogo.png";
import UserAccount from "../../basic/UserAccount";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import HeaderMenu from "../../NavigationMenu/HeaderMenu";

const Header = () => {
  const renderNavItems = (items) => {
    return items.map((item, index) => {
      if (item.submenu) {
        return (
          <NavDropdown
            title={<span style={{ color: "#fff" }}>{item.label}</span>}
            key={index}
            dataBsToggle="dropdown"
            dataBsAutoClose="outside"
          >
            <div className="submenus">{renderNavItems(item.submenu)}</div>
          </NavDropdown>
        );
      } else {
        return (
          <Nav.Link href={item.url} key={index}>
            {item.label}
          </Nav.Link>
        );
      }
    });
  };

  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState("1.1rem");

  const [showPopover, setShowPopover] = useState(false);
  const handleAccountIconClick = () => {
    setShowPopover(!showPopover);
  };
  const handlePopoverClose = () => {
    setShowPopover(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("auth");

    navigate("/login");
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let newFontSize = "1.1rem";

      if (width < 1050) {
        newFontSize = "0.9rem";
      } else if (width >= 1050 && width <= 1360) {
        newFontSize = "0.8rem";
      }

      setFontSize(newFontSize);
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center ">
          <div className="header-brand">
            <a href="https://aecci.org.in/">
            <div>
              <img
                src={headerImg1}
                style={{ width: "40%", height: "auto" }}
                alt="Company logo"
                srcset=""
              />{" "}
              <img
                src={logo}
                style={{ width: "10%", height: "auto" }}
                alt="Company logo"
                srcset=""
              />{" "}
              <img
                src={headerImg2}
                style={{ width: "40%", height: "auto" }}
                alt="Company logo"
                srcset=""
              />{" "}
            </div>
            </a>
          </div>
        </div>
        {/* <div className="d-none">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            show={showPopover}
            overlay={
              <Popover
                id="popover-account-details"
                className="rounded-4 border-none"
              >
                <Popover.Header
                  className="ps-3"
                  style={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                >
                  {getAuthUserlocal() ? (
                    <>
                      <h6 className="fw-bold mb-0">
                        Hi, {getAuthUserlocal().firstName}{" "}
                        {getAuthUserlocal().surName}
                      </h6>
                      <span className="fw-semibold text-secondary">
                        {getAuthUserlocal().email}
                      </span>
                      <br />
                      <span className="fw-semibold text-secondary">
                        Membership No. : {getAuthUserlocal().memberShipNo}
                      </span>
                    </>
                  ) : (
                    "Account"
                  )}
                </Popover.Header>
                <Popover.Body
                  className="p-0 w-100"
                  style={{ minWidth: "8rem" }}
                >
                  {getAuthUserlocal() ? (
                    <div className="w-100">
                      <Dropdown.Item
                        href="#"
                        className="text-start ps-3 py-2"
                        onClick={() => {
                          navigate("/User-dashboard");
                        }}
                      >
                        <h6>Dashboard</h6>
                      </Dropdown.Item>
                      <hr className="my-0" />

                      <Dropdown.Item
                        href="#"
                        className="text-start ps-3 py-2"
                        onClick={() => {
                          navigate("/e-co");
                        }}
                      >
                        <h6>e-CO</h6>
                      </Dropdown.Item>
                      <hr className="my-0" />

                      <Dropdown.Item
                        href="#"
                        className="text-start ps-3 py-2"
                        onClick={() => {
                          window.location.href =
                            "https://www.aecci.org.in/contact-us/";
                        }}
                      >
                        <h6>Support</h6>
                      </Dropdown.Item>
                      <hr className="my-0" />
                      <Dropdown.Item
                        href="#"
                        className="text-danger ps-3 py-2"
                        onClick={handleLogout}
                      >
                        <h6>Log Out</h6>
                      </Dropdown.Item>
                    </div>
                  ) : (
                    <Dropdown.Item
                      href="#"
                      className="text-start ps-3 py-2"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      <h6>Log In</h6>
                    </Dropdown.Item>
                  )}
                </Popover.Body>
              </Popover>
            }
            rootClose
            onHide={handlePopoverClose}
          >
            <div onClick={handleAccountIconClick} className="ml-2">
              <RiAccountCircleFill className="icon-size" />
            </div>
          </OverlayTrigger>
        </div>{" "} */}
        <div className="desktop-headeraccount">
          <UserAccount />
        </div>
      </div>

      {/* <div className="d-flex justify-content-center align-items-center headerLogoContent">
        <div className="d-flex justify-content-end">
          <div
          >
 
            <div className="m-auto">

            <img
              src={aecciLogo}
              alt="Company logo"
              srcset=""
            />
              </div>
          </div>
        </div>

        <div className="desktop-headeraccount">
          <UserAccount />
        </div>
      </div> */}

      <HeaderMenu />
    </>
  );
};

export default Header;
