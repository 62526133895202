import React, { useEffect, useState } from "react";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../../store/api";
import { getAuthUserlocal } from "../../../../store/services";
import BuissnessAdviceForm from "../BuissnessAdviceWing/BuissnessAdviceForm";

const BuissnessAdviceWing = () => {
  const [formData, setFormData] = useState({
    wing: "Business",
    query: "",
    topic: "null",
    summary: "",
    refDoc: null,
  });
  const [wingApiFlag, setWingApiFlag] = useState(false);
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const userId = getAuthUserlocal()?._id;

  const handleSubmitForm = async (e) => {
    debugger;
    e.preventDefault();
    if (
      !formData.wing ||
      !formData.query ||
      !formData.topic ||
      !formData.summary
    ) {
      alert("Please Fill Form Correctly");
      return;
    }

    if (!isCaptchaCorrect) {
      alert("Please Fill Correct Captcha");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("wing", formData.wing);
    formDataToSend.append("query", formData.query);
    formDataToSend.append("topic", formData.topic);
    formDataToSend.append("summary", formData.summary);

    if (formData.refDoc) {
      formDataToSend.append("refDoc", formData.refDoc);
    }

    try {
      setWingApiFlag(true);

      const res = await axios.post(
        getURLbyEndPointV2("createQuery") + userId,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        alert("Query created success");
        setWingApiFlag(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error while creating query Buissness Advice wing:", error);
      alert(
        "Error while creting query Buissness Advice wing. Please try again."
      );
    } finally {
      setWingApiFlag(false);
    }
  };

  return (
    <BuissnessAdviceForm
      title=" BUISSNESS ADVICE WING-Online Consultation / Query Notes"
      formData={formData}
      isCaptchaCorrect={isCaptchaCorrect}
      setIsCaptchaCorrect={setIsCaptchaCorrect}
      wingApiFlag={wingApiFlag}
      setFormData={setFormData}
      handleExportFormSubmit={handleSubmitForm}
    />
  );
};

export default BuissnessAdviceWing;
