import {
  Button,
  Form,
  Table,
  Modal,
  Row,
  Col,
  CloseButton,
  Badge,
} from "react-bootstrap";
import { BsEye, BsFillInfoCircleFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { PopUpModal } from "../basic/ModalComponent";
import { MdDelete } from "react-icons/md";
import Logo from "../../assets/footerLogo.png";
import jsPDF from "jspdf";
import eCoTemplate from "../../assets/coFormatTemplate.jpg";
import israeliStamp from "../../assets/CoImages/IsraeilStamp.png";
import { getAuthUserlocal } from "../../store/services";
import { numberToWords } from "../basic/numberToWords";
import { ProcessedPaymentMco } from "../../pages/sideBarPage/ServicesPages/e-co";
import { getURLbyEndPoint } from "../../store/api";
import axios from "axios";
import { PDFDocument } from "pdf-lib";

const userData = getAuthUserlocal();
const userId = userData?.ecoUserId;
const priceControl = userData?.priceControl;

const ManualCo = () => {
  const userData = getAuthUserlocal();
  const userId = userData?.ecoUserId;
  const priceControl = getAuthUserlocal()?.priceControl;

  const [modalShow, setModalShow] = useState(false);
  const [mCoCreated, setMcoCreated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiSubmitMco, setApiSubmitMco] = useState(false);
  const [filledFormData, setFilledFormData] = useState({
    addressTypeFullName: "",
    addressTypeAddress: "",
    addressTypePincode: "",
    addressTypeState: "",
  });

  const intialExtendedData = {
    itemNo: "",
    noOfPack: "",
    desOfGoods: "",
    originCriteria: "",
    weightAndQuantity: "",
    noAndDateInvoice: "",
  };

  const initialFormData = {
    eCoDetails: {
      consignedFrom: "",
      consignedTo: "",
      meanOfTran: "",
      extendedData: [intialExtendedData],
      israeliDec: "false",
      orginOfCom: "INDIA",
      designation: "",
      impCountry: "",
      forComRegion: "",
      place: "",
      date: "",
      stamp: null,
    },
    eCoFile: null,
    comInvoice: null,
    packingList: null,
    addDoc1: null,
    addDoc2: null,
    refDoc: null,
    receipt: null,
    addressType: null,
    collectionTimeMessage: `if Document Submitted Between 5:30PM to 9:30AM Then Collection time is 11:30AM to 1:30PM
      
    if Document Submitted Between 9:30AM to 2PM
    Then Collection time is 4PM to 6PM`,
    addressTypeFullName: null,
    addressTypeAddress: null,
    addressTypePincode: null,
    addressTypeState: null,
    selectedDeliveryMode: "Collect From Chamber Desk",
    documentFee: 0,
    processingFee: 0,
    deliveryModeFee: 0,
    totalAmt: 0,

    eCoFileFlag: false,
    comInvoiceFlag: false,
    packingListFlag: false,
    addDoc1Flag: false,
    addDoc2Flag: false,
    refDocFlag: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleAddEcoForm = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      eCoDetails: {
        ...prevFormData.eCoDetails,
        extendedData: [
          ...prevFormData.eCoDetails.extendedData,
          intialExtendedData,
        ],
      },
    }));
  };

  const handleFileChange = async (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          try {
            const reader = new FileReader();
            reader.onload = async function (event) {
              const buffer = event.target.result;
              const pdfDoc = await PDFDocument.load(buffer);
              const pageCount = pdfDoc.getPageCount();

              if (fileName !== "refDoc") {
                if (pageCount > 2) {
                  alert("Error: PDF should not exceed 2 pages.");
                  return;
                }
              }

              setFormData((prevFormData) => ({
                ...prevFormData,
                [fileName]: file,
              }));

              setFormData((prevFormData) => ({
                ...prevFormData,
              }));

              calculateTotalAmount();
            };
            reader.readAsArrayBuffer(file);
          } catch (error) {
            console.error("Error reading PDF file:", error);
          }
        } else {
          alert("File size exceeds 5MB. Please select a smaller file.");
        }
      } else {
        alert("Please select a PDF file.");
      }
    }
  };

  const handleAddFileClick = (fileType) => {
    if (!formData.addDoc1Flag) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        addDoc1Flag: true,
      }));
    } else if (!formData.addDoc2Flag) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        addDoc2Flag: true,
      }));
    }

    calculateDocumentFee();
    calculateProcessingFee();
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));

    calculateProcessingFee();

    // Call calculateDocumentFee whenever relevant flags are updated
    if (
      fieldName === "eCoFileFlag" ||
      fieldName === "comInvoiceFlag" ||
      fieldName === "packingListFlag" ||
      fieldName === "addDoc1Flag" ||
      fieldName === "addDoc2Flag"
    ) {
      calculateDocumentFee();
      calculateTotalAmount(); // Also recalculate total amount after updating document fee
    } else {
      calculateTotalAmount(); // Recalculate total amount for any other changes
    }
  };

  const calculateDocumentFee = () => {
    let docFee = 0;
    // Calculate document fee based on flags
    if (formData.eCoFileFlag) {
      docFee += priceControl?.semiEco;
    }
    if (formData.comInvoiceFlag) {
      docFee += priceControl?.comInvoiceMco;
    }
    if (formData.packingListFlag) {
      docFee += priceControl?.packListMco;
    }
    if (formData.addDoc1Flag) {
      docFee += priceControl?.addDocMco;
    }
    if (formData.addDoc2Flag) {
      docFee += priceControl?.addDocMco;
    }

    // Update document fee state
    setFormData((prevFormData) => ({
      ...prevFormData,
      documentFee: docFee,
    }));

    // Recalculate total amount after updating document fee
    calculateTotalAmount();
  };

  const calculateProcessingFee = () => {
    let processingFee = 0;
    // Calculate processing fee based on flags
    if (formData.eCoFileFlag) {
      processingFee += priceControl?.mcoPc;
    }
    if (formData.comInvoiceFlag) {
      processingFee += priceControl?.mcoComInvoicePc;
    }
    if (formData.packingListFlag) {
      processingFee += priceControl?.mcoPackListPc;
    }
    if (formData.addDoc1Flag) {
      processingFee += priceControl?.mcoAddDocPc;
    }
    if (formData.addDoc2Flag) {
      processingFee += priceControl?.mcoAddDocPc;
    }

    // Update the state with the calculated processing fee
    setFormData((prevFormData) => ({
      ...prevFormData,
      processingFee: processingFee,
    }));

    // Calculate total amount after updating processing fee
    calculateTotalAmount();
  };

  const handleDeliveryModeChange = (event) => {
    const selectedDeliveryMode = event.target.value;
    if (!selectedDeliveryMode) {
      alert("Please Select Delivery Mode");
      return;
    }
    const deliveryModeValue =
      selectedDeliveryMode === "Collect From Chamber Desk"
        ? 0
        : selectedDeliveryMode === "Intercity" ||
          selectedDeliveryMode === "Gujarat"
        ? priceControl?.interGujDelMode
        : selectedDeliveryMode === "Pan India"
        ? priceControl?.panIndDelMode
        : null;

    let collectionTimeMessage = `if Document Submitted Between 5:30PM to 9:30AM\nThen Collection time 11:30AM to 1:30PM\n\nif Document Submitted Between 9:30AM to 2PM\nThen Collection time 4PM to 6PM`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      deliveryModeFee: deliveryModeValue,
      selectedDeliveryMode: selectedDeliveryMode,
      collectionTimeMessage: collectionTimeMessage,
    }));
    calculateDocumentFee();
    calculateProcessingFee();
    calculateTotalAmount();
  };

  useEffect(() => {
    const syntheticEvent = { target: { value: formData.selectedDeliveryMode } };
    handleDeliveryModeChange(syntheticEvent);
  }, [
    formData.selectedDeliveryMode,
    formData.eCoFileFlag,
    formData.addDoc1Flag,
    formData.addDoc2Flag,
    formData.comInvoiceFlag,
    formData.packingListFlag,
    formData.refDocFlag,
  ]);

  const calculateTotalAmount = () => {
    const total =
      formData.documentFee + formData.processingFee + formData.deliveryModeFee;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmt: total,
    }));
  };

  // ------------------------------------------------
  // ------------------------------------------------
  // ---------------simple functions setups----------
  // ------------------------------------------------
  // Function to handle viewing a document
  const viewDocument = (documentId) => {
    const documentFile = formData[documentId];
    if (documentFile instanceof File || documentFile instanceof Blob) {
      const documentUrl = URL.createObjectURL(documentFile);
      window.open(documentUrl, "_blank");
    } else {
      console.log("Document not found or invalid");
    }
  };

  const editDocument = (documentId) => {
    document.getElementById(documentId).click();
  };

  const deleteDocument = (documentId) => {
    console.log(documentId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [documentId]: null,
    }));
  };

  const handleCreateCo = async () => {
    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
    });

    for (const [index, data] of formData.eCoDetails.extendedData.entries()) {
      // Set background image
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(eCoTemplate, "JPEG", 0, 0, imgWidth, imgHeight);

      pdf.setFontSize(9);
      pdf.setFont("helvetica");
      pdf.setTextColor(80, 80, 80);
      // pdf.text(15, 25, formData.eCoDetails.consignedFrom);

      //for consigned from value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedFromWidth = 100;
      const consignedFromLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedFrom,
        consignedFromWidth
      );
      let yPosConsignedFrom = 25;
      consignedFromLine.forEach((line) => {
        pdf.text(15, yPosConsignedFrom, line);
        yPosConsignedFrom += 4;
      });

      //for consigned to value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedToWidth = 100;
      const consignedToLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedTo,
        consignedFromWidth
      );
      let yPosConsignedTo = 55;
      consignedToLine.forEach((line) => {
        pdf.text(15, yPosConsignedTo, line);
        yPosConsignedTo += 4;
      });

      //for Mean of transport value------------------
      // ---------------------------------------
      // ---------------------------------------
      const meanOfTranWidth = 100;
      const meanOfTranLine = pdf.splitTextToSize(
        formData.eCoDetails.meanOfTran,
        consignedFromWidth
      );
      let yPosmeanOfTran = 102;
      meanOfTranLine.forEach((line) => {
        pdf.text(15, yPosmeanOfTran, line);
        yPosmeanOfTran += 4;
      });

      //for Item NO value------------------
      // ---------------------------------------
      // ---------------------------------------
      const itemNoWidth = 8;
      const itemNoLine = pdf.splitTextToSize(data.itemNo, itemNoWidth);
      let yPositemNo = 122;
      itemNoLine.forEach((line) => {
        pdf.text(15, yPositemNo, line);
        yPositemNo += 4;
      });

      //for No of Pack value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noOfPackWidth = 22;
      const noOfPackLine = pdf.splitTextToSize(data.noOfPack, noOfPackWidth);
      let yPosnoOfPack = 122;
      noOfPackLine.forEach((line) => {
        pdf.text(26, yPosnoOfPack, line);
        yPosnoOfPack += 4;
      });

      //for Description of goods value------------------
      // ---------------------------------------
      // ---------------------------------------
      const desOfGoodsWidth = 70;
      const desOfGoodsLine = pdf.splitTextToSize(
        data.desOfGoods,
        desOfGoodsWidth
      );
      let yPosdesOfGoods = 122;
      desOfGoodsLine.forEach((line) => {
        pdf.text(52, yPosdesOfGoods, line);
        yPosdesOfGoods += 4;
      });

      //for Orginal Criteria value------------------
      // ---------------------------------------
      // ---------------------------------------
      pdf.setFontSize(7);

      const originCriteriaWidth = 13;
      const originCriteriaLine = pdf.splitTextToSize(
        data.originCriteria,
        originCriteriaWidth
      );
      let yPosoriginCriteria = 122;
      originCriteriaLine.forEach((line) => {
        pdf.text(125, yPosoriginCriteria, line);
        yPosoriginCriteria += 4;
      });
      pdf.setFontSize(9);

      //for Weight and quantity value------------------
      // ---------------------------------------
      // ---------------------------------------
      const weightAndQuantityWidth = 20;
      const weightAndQuantityLine = pdf.splitTextToSize(
        data.weightAndQuantity,
        weightAndQuantityWidth
      );
      let yPosweightAndQuantity = 122;
      weightAndQuantityLine.forEach((line) => {
        pdf.text(140, yPosweightAndQuantity, line);
        yPosweightAndQuantity += 4;
      });

      //for no. and date invoice value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noAndDateInvoiceWidth = 30;
      const noAndDateInvoiceLine = pdf.splitTextToSize(
        data.noAndDateInvoice,
        noAndDateInvoiceWidth
      );
      let yPosnoAndDateInvoice = 122;
      noAndDateInvoiceLine.forEach((line) => {
        pdf.text(165, yPosnoAndDateInvoice, line);
        yPosnoAndDateInvoice += 4;
      });

      //adding image in if israeli or not
      // Set background image
      // if (
      //   formData.eCoDetails.israeliDec === "true" &&
      //   pdf.addImage(israeliStamp, "JPEG", 128, 196, 75, 40)
      // )
      //adding this text for india and foreign origin

      pdf.setFontSize(8);
      const orginOfComWidth = 70;
      const originOfComText = `The undersigned hereby declares that the above details and statements are correct; that all the goods were produced in ${formData.eCoDetails.orginOfCom} and that they comply with the origin requirements.`;
      const orginOfComLine = pdf.splitTextToSize(
        originOfComText,
        orginOfComWidth
      );
      let yPosorginOfCom = 234;
      orginOfComLine.forEach((line) => {
        pdf.text(123, yPosorginOfCom, line);
        yPosorginOfCom += 4;
      });

      //for Importing Country value------------------
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(8);
      pdf.setTextColor(37, 35, 36);

      //customization for centering imorting country
      const validUptoTextWidth = pdf.getTextWidth(
        formData.eCoDetails.impCountry
      ); // Measure text width
      const validUptoCenterX = 164 - validUptoTextWidth / 2; // Calculate center X
      pdf.text(validUptoCenterX, 252, formData.eCoDetails.impCountry);

      // pdf.text(164, 252, formData.eCoDetails.impCountry);
      pdf.setTextColor(0, 0, 200);
      pdf.text(123, 259, `FOR ${formData.eCoDetails.forComRegion}`);
      pdf.text(123, 277, formData.eCoDetails.designation);
      pdf.setTextColor(0, 0, 0);

      //for STAMP value------------------
      const imgDataStamp = await readAsDataURL(formData.eCoDetails.stamp);
      pdf.addImage(imgDataStamp, "JPEG", 124, 260, 15, 15);

      //for place and value are adding here value------------------
      pdf.text(125, 282, `Place: ${formData.eCoDetails.place}`);
      pdf.text(170, 282, `Date: ${formData.eCoDetails.date}`);

      //adding page no.
      pdf.setFont("helvetica", "normal");
      pdf.text(
        117,
        225,
        `${index + 1}/${formData.eCoDetails.extendedData.length}`
      );

      //for more pages
      if (index < formData.eCoDetails.extendedData.length - 1) {
        pdf.addPage();
      }
    }
    // Generate Blob object
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Convert Blob to File
    const eCoFile = new File([blobPdf], "eCoFile.pdf", {
      type: "application/pdf",
    });

    setFormData({
      ...formData,
      eCoFile: eCoFile,
    });
  };

  // //create eco here--------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text(`Receipt No:`, 15, 82);
    pdf.text(`Document No:`, 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address = getAuthUserlocal().address1;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    //second line
    pdf.text(`Date:`, 130, 82);
    pdf.text(`Time:`, 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.line(15, 115, 195, 115);

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];

    const data = [];
    if (formData.eCoFileFlag) {
      data.push([
        "1",
        "Semi-Electronic Certificate of Origin",
        "1",
        `Rs. ${priceControl?.semiEco}/-`,
        `Rs. ${priceControl?.semiEco}/-`,
      ]);
    }
    if (formData.comInvoiceFlag) {
      data.push([
        "2",
        "Commercial Invoice.",
        "1",
        `Rs. ${priceControl?.comInvoiceMco}/-`,
        `Rs. ${priceControl?.comInvoiceMco}/-`,
      ]);
    }
    if (formData.packingListFlag) {
      data.push([
        "3",
        "Packing List",
        "1",
        `Rs. ${priceControl?.packListMco}/-`,
        `Rs. ${priceControl?.packListMco}/-`,
      ]);
    }
    if (formData.addDoc1Flag) {
      data.push([
        "4",
        "Additional Document 1",
        "1",
        `Rs. ${priceControl?.addDocMco}/-`,
        `Rs. ${priceControl?.addDocMco}/-`,
      ]);
    }
    if (formData.addDoc2Flag) {
      data.push([
        "5",
        "Additional Document 2",
        "1",
        `Rs. ${priceControl?.addDocMco}/-`,
        `Rs. ${priceControl?.addDocMco}/-`,
      ]);
    }
    if (formData.refDocFlag) {
      data.push(["6", "Reference Document", "1", "Rs. 0/-", "Rs. 0/-"]);
    }

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [20, 60, 45, 40, 40]; // Define different widths for each column
    const startX = 13;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Processing charge:", 15, startY + lineHeight * (data.length + 1));
    pdf.text(
      `Rs. ${formData.processingFee}/-`,
      175,
      startY + lineHeight * (data.length + 1)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line

    pdf.text("Delivery Mode:", 15, startY + lineHeight * (data.length + 2));
    pdf.text(
      `For ${formData.selectedDeliveryMode}`,
      40,
      startY + lineHeight * (data.length + 2)
    );
    pdf.text(
      `Rs. ${formData.deliveryModeFee}/-`,
      175,
      startY + lineHeight * (data.length + 2)
    );
    // Split the address into multiple lines based on a specified width

    // Starting Y position
    // const addressLine = pdf.splitTextToSize(formData.addressTypeAddress, 90); // Adjust the width as needed

    if (formData.selectedDeliveryMode !== "Collect From Chamber Desk") {
      const startYAddress = startY + 6 + lineHeight * (data.length + 2);
      pdf.text("Address: ", 15, startYAddress);
      const address = formData.addressTypeAddress;
      const addressLines = pdf.splitTextToSize(address, 100);
      const addLineHeight = 5;

      addressLines.forEach((line, index) => {
        pdf.text(line, 30, startYAddress + index * addLineHeight);
      });

      // Calculate the total height of the address block
      const addressHeight = addressLines.length * addLineHeight;

      // Position the pincode below the address block
      pdf.text(
        `Pincode: ${formData.addressTypePincode}`,
        15,
        startYAddress + addressHeight
      );
    }

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 4),
      195,
      5 + startY + lineHeight * (data.length + 4)
    ); // horizontal line

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 5));
    pdf.text(
      `Rs. ${formData.totalAmt}/-`,
      175,
      startY + lineHeight * (data.length + 5)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 5),
      195,
      5 + startY + lineHeight * (data.length + 5)
    ); // horizontal line
    pdf.text(
      `Amount In words: ${numberToWords(formData.totalAmt)} Rupees`,
      15,
      startY + lineHeight * (data.length + 6)
    );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 6),
      195,
      5 + startY + lineHeight * (data.length + 6)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 7),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 8),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 8)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  // creating e-co here
  const handlePreviewSave = async () => {
    // //validation for eco creation---------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    const validationRules = {
      consignedFrom: {
        message:
          "Please Enter Goods consigned from field and should 250 letters max.",
        isValid: () => {
          const consignedFrom = formData.eCoDetails.consignedFrom.trim();
          return consignedFrom !== "" && consignedFrom.length <= 250;
        },
      },

      consignedTo: {
        message: "Please Enter Goods consigned to and should 345 letters max.",
        isValid: () => {
          const consignedTo = formData.eCoDetails.consignedTo.trim();
          return consignedTo !== "" && consignedTo.length <= 345;
        },
      },

      meanOfTran: {
        message:
          "Please Enter Means of transport & route and should 97 letters max.",
        isValid: () => {
          const meanOfTran = formData.eCoDetails.meanOfTran.trim();
          return meanOfTran !== "" && meanOfTran.length <= 97;
        },
      },

      israeliDec: {
        message: "Please Enter Israeli Declaration.",
        isValid: () => {
          const israeliDec = formData.eCoDetails.israeliDec;
          return israeliDec !== null && israeliDec !== "";
        },
      },

      orginOfCom: {
        message: "Please select orgin INDIA/FOREIGN ORIGIN.",
        isValid: () => {
          const orginOfComVal = formData.eCoDetails.orginOfCom;
          return orginOfComVal !== null && orginOfComVal !== "";
        },
      },

      designation: {
        message: "Please Select any Authorised Signatory.",
        isValid: () => {
          const designation = formData.eCoDetails.designation.trim();
          return designation !== null && designation !== "";
        },
      },

      impCountry: {
        message: "Please Enter Importing Country and should bw 20 letters max.",
        isValid: () => {
          const impCountry = formData.eCoDetails.impCountry.trim();
          return impCountry !== "" && impCountry.length <= 20;
        },
      },
      forComRegion: {
        message:
          "Please Enter For company/region field and should 40 letters max.",
        isValid: () => {
          const forComRegion = formData.eCoDetails.forComRegion.trim();
          return forComRegion !== "" && forComRegion.length <= 40;
        },
      },

      place: {
        message: "Please Enter place field and should 20 letters max.",
        isValid: () => {
          const place = formData.eCoDetails.place.trim();
          return place !== "" && place.length <= 20;
        },
      },
      date: {
        message: "Please Enter Date.",
        isValid: () => {
          const date = formData.eCoDetails.date.trim();
          return date !== null && date !== "";
        },
      },

      stamp: {
        message: "Please upload stamp.",
        isValid: () => {
          const stamp = formData.eCoDetails.stamp;
          return (
            stamp !== null &&
            stamp.type.startsWith("image/") &&
            stamp.size <= 5 * 1024 * 1024
          ); // 5MB limit
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    let validationForEco = false;

    {
      formData.eCoDetails.extendedData.map((data, index) => {
        const validationRulesForEco = {
          itemNo: {
            message: `Please Enter page ${
              index + 1
            } item number field and should be 40 letters max.`,
            isValid: () => {
              const itemNo = data.itemNo.trim();
              return itemNo !== "" && itemNo.length <= 40;
            },
          },
          noOfPack: {
            message: `Please Enter page ${
              index + 1
            } No of pack field and should be 240 letters max.`,
            isValid: () => {
              const noOfPack = data.noOfPack.trim();
              return noOfPack !== "" && noOfPack.length <= 240;
            },
          },
          desOfGoods: {
            message: `Please Enter page ${
              index + 1
            } kind of packages, description of goods.`,
            isValid: () => {
              const desOfGoods = data.desOfGoods.trim();
              return desOfGoods !== "" && desOfGoods.length <= 1100;
            },
          },
          originCriteria: {
            message: `Please Enter page ${
              index + 1
            } Origin Criteria field and should be 40 letters max.`,
            isValid: () => {
              const originCriteria = data.originCriteria.trim();
              return originCriteria !== "" && originCriteria.length <= 40;
            },
          },
          weightAndQuantity: {
            message: `Please Enter page ${
              index + 1
            } Gross weight or order quantity field and should be 100 letters max.`,
            isValid: () => {
              const weightAndQuantity = data.weightAndQuantity.trim();
              return (
                weightAndQuantity !== "" && weightAndQuantity.length <= 100
              );
            },
          },
          noAndDateInvoice: {
            message: `Please Enter page ${
              index + 1
            } Number and date of invoices field and should be 150 letters max.`,
            isValid: () => {
              const noAndDateInvoice = data.noAndDateInvoice.trim();
              return noAndDateInvoice !== "" && noAndDateInvoice.length <= 150;
            },
          },
        };

        // Check each validation rule
        for (const field in validationRulesForEco) {
          const { message, isValid } = validationRulesForEco[field];
          if (!isValid()) {
            alert(message);
            validationForEco = true;
            return;
          }
        }
      });
    }

    if (validationForEco) {
      return;
    }

    await handleCreateCo();
    setMcoCreated(true);
  };

  const handleSubmitEco = async () => {
    setFormData({
      ...formData,
      eCoFileFlag: true,
    });
    setMcoCreated(false);
    setModalShow(false);
  };

  //Main Draft function for MCO FORM---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  //   const handleSubmitMcoDraft = async () => {
  //     const userId = getAuthUserlocal()._id;

  //     // Create a new FormData object
  //     const formDataForDraft = new FormData();

  //     // Append files to the FormData
  //     const filesToInclude = [
  //       "eCoFile",
  //       "comInvoice",
  //       "packingList",
  //       "addDoc1",
  //       "addDoc2",
  //       "refDoc",
  //       "receipt",
  //     ];

  //     filesToInclude.forEach((fieldName) => {
  //       const file = formData[fieldName];
  //       if (file) {
  //         const reader = new FileReader();
  //         reader.onload = () => {
  //           const base64String = reader.result.split(",")[1]; // Extract base64 string from data URL
  //           formDataForDraft.append(fieldName, base64String);
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     });

  //     // Append other form data fields to the FormData
  //     const otherFieldsToInclude = [
  //       "totalAmt",
  //       "addressType",
  //       "selectedDeliveryMode",
  //       "addressTypeFullName",
  //       "addressTypeAddress",
  //       "addressTypePincode",
  //       "addressTypeState",
  //       "deliveryModeFee",
  //       "collectionTimeMessage",
  //       "processingFee",
  //       "documentFee",
  //       "eCoFileFlag",
  //       "comInvoiceFlag",
  //       "packingListFlag",
  //       "addDoc1Flag",
  //       "addDoc2Flag",
  //       "refDocFlag",
  //       "israeliDec",
  //       "orginOfCom",
  //       "designation",
  //       "impCountry",
  //       "forComRegion",
  //       "place",
  //       "date",
  //       "stamp",
  //       "noAndDateInvoice",
  //     ];

  //     otherFieldsToInclude.forEach((fieldName) => {
  //       formDataForDraft.append(fieldName, formData[fieldName]);
  //     });

  //     try {
  //       const res = await axios.post(
  //         getURLbyEndPoint("createMcoDraft") + userId,
  //         formDataForDraft
  //       );

  //       if (res.data.status) {
  //         alert("Draft saved!!");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       alert("Something went wrong");
  //     } finally {
  //       // Any cleanup or finalization code can go here
  //     }
  // };

  //Main submit for MCO FORM---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  const handleSubmitEcoForm = async () => {
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     addressType: addressType, // Explicitly update addressType
    //     collectionTimeMessage: collectionTimeMessage, // Explicitly update addressType
    //  }));
    const userId = getAuthUserlocal()._id;

    await handleCreateReceipt(userId, formData);

    if (formData.eCoDetails.extendedData.length < 0) {
      alert("Something went wrong");
      return;
    }

    const validationRules = {
      eCoForm: {
        message: "Please Fill eCo form.",
        isValid: () => {
          const eCOForm = formData.eCoFile;
          return eCOForm !== null;
        },
      },

      comInvoice: {
        message: "Please upload Commertial Invoice.",
        isValid: () => {
          const comInvoice = formData.comInvoice;
          return (
            comInvoice !== null &&
            comInvoice.type === "application/pdf" &&
            comInvoice.size <= 5 * 1024 * 1024
          );
        },
      },

      packingList: {
        message: "Please upload packing List.",
        isValid: () => {
          const packingList = formData.packingList;
          return (
            packingList !== null &&
            packingList.type === "application/pdf" &&
            packingList.size <= 5 * 1024 * 1024
          );
        },
      },

      addDoc1: {
        message: "Please upload additional document 1.",
        isValid: () => {
          if (formData.addDoc1Flag) {
            const addDoc1 = formData.addDoc1;
            return (
              addDoc1 !== null &&
              addDoc1.type === "application/pdf" &&
              addDoc1.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      addDoc2: {
        message: "Please upload additional document 2.",
        isValid: () => {
          if (formData.addDoc2Flag) {
            const addDoc2 = formData.addDoc2;
            return (
              addDoc2 !== null &&
              addDoc2.type === "application/pdf" &&
              addDoc2.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      refDoc: {
        message: "Please upload reference document.",
        isValid: () => {
          if (formData.refDocFlag) {
            const refDoc = formData.refDoc;
            return (
              refDoc !== null &&
              refDoc.type === "application/pdf" &&
              refDoc.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }
    //api setup started
    const formDataForApi = new FormData();

    // Append files to the FormData
    const dataToInclude = [
      //data
      "totalAmt",
      "addressType",
      "selectedDeliveryMode",
      "addressTypeFullName",
      "addressTypeAddress",
      "addressTypePincode",
      "addressTypeState",
      "deliveryModeFee",
      "collectionTimeMessage",
      "processingFee",
      "documentFee",
      "eCoFileFlag",
      "comInvoiceFlag",
      "packingListFlag",
      "addDoc1Flag",
      "addDoc2Flag",
      "refDocFlag",

      //files
      "eCoFile",
      "comInvoice",
      "packingList",
      "addDoc1",
      "addDoc2",
      "refDoc",
      "receipt",
    ];

    dataToInclude.forEach((data) => {
      formDataForApi.append(data, formData[data]);
    });
    // Append data to the FormData
    const fieldsToInclude = [
      "israeliDec",
      "orginOfCom",
      "designation",
      "impCountry",
      "forComRegion",
      "place",
      "date",
      "stamp",
    ];
    fieldsToInclude.forEach((field) => {
      formDataForApi.append(field, formData.eCoDetails[field]);
    });

    formDataForApi.append(
      "noAndDateInvoice",
      formData.eCoDetails.extendedData[0].noAndDateInvoice
    );

    // validation for balance
    if (
      !getAuthUserlocal().specialControl.accessList.includes("walletExceed") &&
      getAuthUserlocal().balance - formData.totalAmt < 500
    ) {
      alert("Low balance");
      return;
    }

    try {
      setApiSubmitMco(true);

      const res = await axios.post(
        getURLbyEndPoint("createMco") + userId,
        formDataForApi
      );

      if (res.data.status) {
        alert("Semi-Electronic Certificate of Origin Submitted SuccessFully");
        window.location.reload();
        setApiSubmitMco(true);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      setApiSubmitMco(false);
    }

    console.log(formDataForApi);
  };

  const openEcoFile = () => {
    // Create URL for the eCoFile
    const eCoFileUrl = URL.createObjectURL(formData.eCoFile);

    // Open the eCoFile in a new tab
    window.open(eCoFileUrl, "_blank");
  };

  useEffect(() => {
    // Retrieve user details from local storage
    const userDetailslocal = getAuthUserlocal();
    if (userDetailslocal && formData.addressType === "register") {
      setFormData((prevState) => ({
        ...prevState,
        addressTypeFullName: `${userDetailslocal.firstName} ${userDetailslocal.surName}`,
        addressTypeAddress: `${userDetailslocal.companyName}- ${userDetailslocal.address1}, ${userDetailslocal.address2}`,
        addressTypePincode: userDetailslocal.pinCode,
        addressTypeState: userDetailslocal.state,
      }));
    } else if (formData.addressType === "other") {
      // Update formData with filledFormData
      setFormData((prevState) => ({
        ...prevState,
        ...filledFormData,
        addressType: "other",
        addressTypeFullName: "",
        addressTypeAddress: "",
        addressTypePincode: "",
        addressTypeState: "",
      }));
    }
    console.log(formData);
  }, [formData.addressType, filledFormData, formData.selectedDeliveryMode]);

  // Function to handle address type change
  const handleAddressTypeChange = (type) => {
    if (type === "register") {
      // Retrieve user details from local storage
      const userDetailslocal = getAuthUserlocal();
      if (userDetailslocal) {
        setFormData((prevState) => ({
          ...prevState,
          addressType: "register",
          addressTypeFullName: `${userDetailslocal.title} ${userDetailslocal.firstName} ${userDetailslocal.surName}`,
          addressTypeAddress: `${userDetailslocal.companyName}- ${userDetailslocal.address1}, ${userDetailslocal.address2}`,
          addressTypePincode: userDetailslocal.pinCode,
          addressTypeState: userDetailslocal.state,
        }));
      }
    } else if (type === "other") {
      // Update formData with blank values for "other" address type
      setFormData((prevState) => ({
        ...prevState,
        addressType: "other",
        addressTypeFullName: "",
        addressTypeAddress: "",
        addressTypePincode: "",
        addressTypeState: "",
      }));
    }
  };

  // useEffect(() => {
  //   // Assuming you have a function to calculate the collection time message based on the delivery mode
  //   const calculateCollectionTimeMessage = () => {
  //     let collectionTimeMessage = `if Document Submitted Between 5:30PM to 9:30AM
  //     Then Collection time is 11:30AM to 1:30PM

  //     if Document Submitted Between 9:30AM to 2PM
  //     Then Collection time is 4PM to 6PM`;
  //     if (formData.selectedDeliveryMode === "Intercity") {
  //       // Logic to set the message for Intercity / Gujarat
  //       collectionTimeMessage = null;
  //     } else if (formData.selectedDeliveryMode === "Gujarat") {
  //       // Logic to set the message for Pan India
  //       collectionTimeMessage = null;
  //     } else if (formData.selectedDeliveryMode === "Pan India") {
  //       // Logic to set the message for Pan India
  //       collectionTimeMessage = null;
  //     }
  //     // You can add more conditions for other delivery modes as needed
  //     return collectionTimeMessage;
  //   };

  //   // Update the formData state with the calculated collection time message
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     collectionTimeMessage: calculateCollectionTimeMessage(),
  //   }));
  // }, [formData.selectedDeliveryMode]);

  // useEffect to load user details initially if addressType is "register"
  useEffect(() => {
    if (formData.addressType === "register") {
      const userDetailslocal = getAuthUserlocal();
      if (userDetailslocal) {
        setFormData((prevState) => ({
          ...prevState,
          addressTypeFullName: `${userDetailslocal.title} ${userDetailslocal.firstName} ${userDetailslocal.surName}`,
          addressTypeAddress: `${userDetailslocal.companyName}- ${userDetailslocal.address1}, ${userDetailslocal.address2}`,
          addressTypePincode: userDetailslocal.pinCode,
          addressTypeState: userDetailslocal.state,
        }));
      }
    }
  }, []);

  useEffect(() => {
    calculateProcessingFee();
    calculateTotalAmount();
  }, [
    formData.documentFee,
    formData.processingFee,
    formData.deliveryModeFee,
    formData.eCoFileFlag,
    formData.comInvoiceFlag,
    formData.packingListFlag,
    formData.addDoc1Flag,
    formData.addDoc2Flag,
  ]);

  return (
    <div>
      <Table bordered striped>
        <thead>
          <tr>
            <th className="fw-medium" style={{ width: "40%" }}>
              DOCUMENT TYPE
            </th>
            <th className="fw-medium" style={{ width: "20%" }}>
              FEES
            </th>
            <th className="fw-medium" style={{ width: "20%" }}>
              UPLOADS
            </th>
            <th className="fw-medium" style={{ width: "20%" }}>
              ACTION
            </th>
          </tr>
        </thead>

        <tbody>
          {/* //e-co row------------------------------
                              --------------------------------------------
                              ------------------------------------------- */}
          <tr>
            <td>
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex">
                  <h6>Semi-Electronic Certificate of Origin*</h6>
                  <div>
                    <PopUpModal body={<ManualCoo />} />
                  </div>
                </div>
                <p style={{ fontSize: "14px" }}>(1 Original, Max 2 pages)</p>
              </div>
            </td>
            <td>Rs. {priceControl?.semiEco}/-</td>
            <td>
              <Button
                variant="secondary"
                onClick={() => setModalShow(true)}
                className="w-100 setup-bg-color"
                disabled={formData.eCoFileFlag}
              >
                Fill Form
              </Button>
            </td>
            <td className="align-middle">
              <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                {formData.eCoFile && (
                  <BsEye
                    className="fs-5 cursor-pointer"
                    onClick={openEcoFile}
                  />
                )}
                <FaPen
                  className="fs-6 cursor-pointer"
                  onClick={() => setModalShow(true)}
                />
              </div>
            </td>
          </tr>
          {/* commertial invoice row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
          {formData.eCoFileFlag && (
            <tr>
              <td>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <h6>Commercial Invoice</h6>
                    <div>
                      <PopUpModal body={<ComInvoiceTip />} />
                    </div>
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    Inv No.
                    {formData.eCoDetails.extendedData[0].noAndDateInvoice}
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    (1 Original, Max 2 pages)
                  </div>
                </div>
              </td>
              <td>
                {formData.comInvoiceFlag
                  ? `Rs. ${priceControl?.comInvoiceMco}/-`
                  : "Rs. 0/-"}
              </td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-inline ms-3 align-middle mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="comInvoice"
                      type="radio"
                      checked={formData.comInvoiceFlag}
                      onChange={() => {
                        const updatedFormData = {
                          ...formData,
                          comInvoiceFlag: true,
                        };
                        setFormData(updatedFormData);
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="comInvoice"
                      type="radio"
                      checked={!formData.comInvoiceFlag}
                      onChange={() => {
                        const updatedFormData = {
                          ...formData,
                          comInvoiceFlag: false,
                        };
                        setFormData(updatedFormData);
                      }}
                    />
                  </div>

                  <div>
                    {/* Hidden file input for commercial invoice */}
                    <input
                      type="file"
                      id="comInvoice"
                      accept=".pdf" // Add accepted file types if needed
                      onChange={(event) =>
                        handleFileChange(event, "comInvoice")
                      }
                      style={{ display: "none" }}
                    />

                    {/* Button and associated label for commercial invoice */}
                    <Button
                      className="setup-bg-color w-100 mb-2"
                      disabled={formData.comInvoice}
                    >
                      <label htmlFor="comInvoice" className="mb-0">
                        Upload
                      </label>
                    </Button>
                  </div>

                  {!formData.comInvoiceFlag && (
                    <span style={{ fontSize: "14px" }} className="text-center">
                      Upload commercial Invoice for Reference Only
                    </span>
                  )}
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("comInvoice")}
                    disabled={!formData.comInvoice}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>

                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() =>
                      document.getElementById("comInvoice").click()
                    }
                  />
                </div>
              </td>
            </tr>
          )}
          {/* Packing List row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
          {formData.comInvoice && (
            <tr>
              <td>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <h6>Packing List*</h6>
                    <div>
                      <PopUpModal body={<PackListTip />} />
                    </div>
                  </div>
                  <p style={{ fontSize: "14px" }}>(1 Original, Max 2 pages)</p>
                </div>
              </td>
              <td>
                {formData.packingListFlag
                  ? `Rs. ${priceControl?.packListMco}/-`
                  : "Rs. 0/-"}
              </td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-inline ms-3 align-middle mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name="packList"
                      type="radio"
                      checked={formData.packingListFlag}
                      onChange={() => {
                        const updatedFormData = {
                          ...formData,
                          packingListFlag: true,
                        };
                        setFormData(updatedFormData);
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="packList"
                      type="radio"
                      checked={!formData.packingListFlag}
                      onChange={() => {
                        const updatedFormData = {
                          ...formData,
                          packingListFlag: false,
                        };
                        setFormData(updatedFormData);
                      }}
                    />
                  </div>

                  <div>
                    {/* Hidden file input for commercial invoice */}
                    <input
                      type="file"
                      id="packingList"
                      accept=".pdf" // Add accepted file types if needed
                      onChange={(event) =>
                        handleFileChange(event, "packingList")
                      }
                      style={{ display: "none" }}
                    />

                    {/* Button and associated label for commercial invoice */}
                    <Button
                      className="setup-bg-color w-100 mb-2"
                      disabled={formData.packingList}
                    >
                      <label htmlFor="packingList" className="mb-0">
                        Upload
                      </label>
                    </Button>
                  </div>

                  {!formData.packingListFlag && (
                    <span style={{ fontSize: "14px" }} className="text-center">
                      Upload Packing List for Reference Only{" "}
                    </span>
                  )}
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("packingList")}
                    disabled={!formData.packingList}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>

                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() => editDocument("packingList")}
                  />
                </div>
              </td>
            </tr>
          )}
          {/* 
                            ---------------------additional Document 1---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
          {formData.addDoc1Flag && (
            <tr>
              <td>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <h6>Additional Document 1</h6>
                    <div>
                      <PopUpModal body={<AddDocTips />} />
                    </div>
                  </div>
                  <p style={{ fontSize: "14px" }}>(1 Original, Max 2 pages)</p>
                </div>
              </td>
              <td>Rs. {priceControl?.addDocMco}/-</td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  {/* Hidden file input for commercial invoice */}
                  <input
                    type="file"
                    id="addDoc1"
                    accept=".pdf" // Add accepted file types if needed
                    onChange={(event) => handleFileChange(event, "addDoc1")}
                    style={{ display: "none" }}
                  />

                  {/* Button and associated label for commercial invoice */}
                  <Button
                    className="setup-bg-color w-100 mb-2"
                    disabled={formData.addDoc1}
                  >
                    <label htmlFor="addDoc1" className="mb-0">
                      Upload
                    </label>
                  </Button>
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("addDoc1")}
                    disabled={!formData.addDoc1}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>

                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() => editDocument("addDoc1")}
                  />

                  {!formData.addDoc2Flag && (
                    <MdDelete
                      className="fs-5 cursor-pointer"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          addDoc1: null,
                          addDoc1Flag: false,
                        });
                      }}
                    />
                  )}
                </div>
              </td>
            </tr>
          )}
          {/* 
                            ---------------------additional Document 2---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
          {formData.addDoc2Flag && (
            <tr>
              <td>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <h6>Additional Document 2</h6>
                    <div>
                      <PopUpModal body={<AddDocTips />} />
                    </div>
                  </div>
                  <p style={{ fontSize: "14px" }}>(1 Original, Max 2 pages)</p>
                </div>
              </td>
              <td>Rs. {priceControl?.addDocMco}/-</td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  {/* Hidden file input for commercial invoice */}
                  <input
                    type="file"
                    id="addDoc2"
                    accept=".pdf" // Add accepted file types if needed
                    onChange={(event) => handleFileChange(event, "addDoc2")}
                    style={{ display: "none" }}
                  />

                  {/* Button and associated label for commercial invoice */}
                  <Button
                    className="setup-bg-color w-100 mb-2"
                    disabled={formData.addDoc2}
                  >
                    <label htmlFor="addDoc2" className="mb-0">
                      Upload
                    </label>
                  </Button>
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("addDoc2")}
                    disabled={!formData.addDoc2}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>
                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() => editDocument("addDoc2")}
                  />

                  <MdDelete
                    className="fs-5 cursor-pointer"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        addDoc2: null,
                        addDoc2Flag: false,
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
          )}
          {/* 
                            ---------------------Refrence document ---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
          {formData.refDocFlag && (
            <tr>
              <td>
                <h6>
                  <div className="d-flex align-items-center">
                    <h6>Reference Document*</h6>
                    <div>
                      <PopUpModal body={<RefDocTips />} />
                    </div>
                  </div>
                </h6>
              </td>
              <td>Rs. 0/-</td>

              <td>
                <div className="d-flex flex-column justify-content-center">
                  {/* Hidden file input for commercial invoice */}
                  <input
                    type="file"
                    id="refDoc"
                    accept=".pdf" // Add accepted file types if needed
                    onChange={(event) => handleFileChange(event, "refDoc")}
                    style={{ display: "none" }}
                  />

                  {/* Button and associated label for commercial invoice */}
                  <Button
                    className="setup-bg-color w-100 mb-2"
                    disabled={formData.refDoc}
                  >
                    <label htmlFor="refDoc" className="mb-0">
                      Upload
                    </label>
                  </Button>
                </div>
              </td>

              <td className="align-middle">
                <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                  <a
                    href="#"
                    onClick={() => viewDocument("refDoc")}
                    disabled={!formData.refDoc}
                  >
                    <BsEye className="fs-5 cursor-pointer" />
                  </a>
                  <FaPen
                    className="fs-6 cursor-pointer"
                    onClick={() => editDocument("refDoc")}
                  />

                  <MdDelete
                    className="fs-5 cursor-pointer"
                    onClick={() => {
                      setFormData({
                        ...formData,
                        refDoc: null,
                        refDocFlag: false,
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td colSpan={4}>
              <div className="d-flex justify-content-evenly w-100">
                {!formData.addDoc2Flag && (
                  <Button
                    className="small-font my-1 fw-semibold px-3 setup-bg-color"
                    onClick={handleAddFileClick}
                  >
                    SUBMIT ADDITIONAL DOCUMENT?
                  </Button>
                )}

                {!formData.refDocFlag && (
                  <Button
                    className="small-font my-1 fw-semibold px-3"
                    variant="info"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        refDocFlag: true,
                      })
                    }
                  >
                    {" "}
                    SUBMIT REFRENCE DOCUMENT?
                  </Button>
                )}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h6>
                <div className="d-flex align-items-center">
                  <h6>Processing Fee</h6>
                  <div>
                    <PopUpModal body={<ProcessingFeeTip />} />
                  </div>
                </div>
              </h6>
            </td>
            <td>Rs. {formData.processingFee}/-</td>

            <td colSpan={2}></td>
          </tr>
          <tr>
            <td>
              <h6>
                <div className="d-flex align-items-center">
                  <h6>Delivery Mode</h6>
                  <div>
                    <PopUpModal body={<DeliveryModeTip />} />
                  </div>
                </div>
              </h6>

              <div className="d-flex flex-column">
                <div>
                  <Form.Check
                    inline
                    label="Collect From Chamber Desk"
                    name="delivery mode"
                    type="radio"
                    value="Collect From Chamber Desk"
                    onChange={handleDeliveryModeChange}
                    checked={
                      formData.selectedDeliveryMode ===
                      "Collect From Chamber Desk"
                    }
                  />
                </div>
                <Form.Check
                  inline
                  label="Intercity"
                  name="delivery mode"
                  type="radio"
                  value="Intercity"
                  onChange={handleDeliveryModeChange}
                  checked={formData.selectedDeliveryMode === "Intercity"}
                />
                <Form.Check
                  inline
                  label="Gujarat"
                  name="delivery mode"
                  type="radio"
                  value="Gujarat"
                  onChange={handleDeliveryModeChange}
                  checked={formData.selectedDeliveryMode === "Gujarat"}
                />
                <Form.Check
                  inline
                  label="Pan India"
                  name="delivery mode"
                  type="radio"
                  value="Pan India"
                  onChange={handleDeliveryModeChange}
                  checked={formData.selectedDeliveryMode === "Pan India"}
                />
              </div>
              <div>
                {(formData.selectedDeliveryMode === "Intercity" ||
                  formData.selectedDeliveryMode === "Gujarat" ||
                  formData.selectedDeliveryMode === "Pan India") && (
                  <div className="mt-2">
                    <ul>
                      <h6>T&C Applied.</h6>
                      <li>
                        AECCI does not assume responsibility for the services
                        rendered by courier companies.
                      </li>
                      <li>
                        Users are responsible for any additional charges
                        incurred for courier services.
                      </li>
                      <li>
                        Tracking details for couriers can be accessed under
                        "Track Your Document," enabling users to monitor their
                        courier using the provided number.
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </td>
            <td>Rs. {formData.deliveryModeFee}/-</td>
            <td colSpan={2}>
              {formData.selectedDeliveryMode ===
                "Collect From Chamber Desk" && (
                <div className="">
                  <h6>Submission & Collection Time</h6>
                  <h6>
                    <Badge bg="secoundary" className="p-3 text-md text-dark">
                      <div className="text-left text-dark">
                        if Document Submitted Between 5:30PM to 9:30AM
                        <br />
                        <br />
                        Then Collection time is 11:30AM to 1:30PM <br />
                        <br />
                        <br />
                        if Document Submitted Between 9:30AM to 2PM
                        <br /> <br />
                        Then Collection time is 4PM to 6PM{" "}
                      </div>
                      {/* <span>{collectionTimeMessage}</span> */}
                    </Badge>
                  </h6>
                </div>
              )}
              {(formData.selectedDeliveryMode === "Intercity" ||
                formData.selectedDeliveryMode === "Gujarat" ||
                formData.selectedDeliveryMode === "Pan India") && (
                <div>
                  <h6>Courier Sent To:</h6>
                  <Form.Check
                    inline
                    label="Registered Address"
                    name="address"
                    type="radio"
                    checked={formData.addressType === "register"}
                    onChange={() => handleAddressTypeChange("register")}
                  />
                  <Form.Check
                    inline
                    label="Other Address"
                    name="address"
                    type="radio"
                    checked={formData.addressType === "other"}
                    onChange={() => handleAddressTypeChange("other")}
                  />

                  <Form.Group controlId="fullName">
                    <Form.Label style={{ fontSize: "0.85rem" }}>
                      Kind Attention
                    </Form.Label>
                    <input
                      type="text"
                      placeholder="Enter full name"
                      value={formData.addressTypeFullName}
                      onChange={(e) =>
                        handleFieldChange("addressTypeFullName", e.target.value)
                      }
                      disabled={formData.addressType === "register"}
                      className="form-control"
                    />
                  </Form.Group>
                  <Form.Group controlId="address">
                    <Form.Label style={{ fontSize: "0.85rem" }}>
                      Company Name & Address
                    </Form.Label>
                    <textarea
                      rows={6}
                      placeholder="Enter Your Company Name with Address"
                      value={formData.addressTypeAddress}
                      onChange={(e) =>
                        handleFieldChange("addressTypeAddress", e.target.value)
                      }
                      disabled={formData.addressType === "register"}
                      className="form-control"
                    />
                  </Form.Group>
                  <div className="row">
                    <div className="col">
                      <Form.Group controlId="pincode">
                        <Form.Label style={{ fontSize: "0.85rem" }}>
                          Pincode
                        </Form.Label>
                        <input
                          type="number"
                          placeholder="Enter pincode"
                          value={formData.addressTypePincode}
                          onChange={(e) =>
                            handleFieldChange(
                              "addressTypePincode",
                              e.target.value
                            )
                          }
                          disabled={formData.addressType === "register"}
                          className="form-control"
                        />
                      </Form.Group>
                    </div>
                    <div className="col">
                      <Form.Group controlId="state">
                        <Form.Label style={{ fontSize: "0.85rem" }}>
                          State
                        </Form.Label>
                        <input
                          type="text"
                          placeholder="Enter state"
                          className="form-control"
                          value={formData.addressTypeState}
                          onChange={(e) =>
                            handleFieldChange(
                              "addressTypeState",
                              e.target.value
                            )
                          }
                          disabled={formData.addressType === "register"}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <h6>Total</h6>
            </td>

            <td>
              <span>Rs. {formData.totalAmt}/-</span>
            </td>
            <td colSpan={2} className="text-end">
              <PreviewManualReceipt formData={formData} />

              {/* <Button
                                      style={{ backgroundColor: "#ed6c02" }}
                                      className="text-white small-font my-1 fw-semibold me-1 border-none"
                                    >
                                      Add More E-Co(NP)
                                    </Button>  */}
              <ProcessedPaymentMco
                formData={formData}
                setIsSubmit={setIsSubmit}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex justify-content-center gap-3">
        <Button
          variant="success"
          disabled={!isSubmit || apiSubmitMco}
          onClick={handleSubmitEcoForm}
        >
          {apiSubmitMco ? "Processing..." : "SUBMIT"}
          {/* SUBMIT */}
        </Button>
        <Button onClick={resetFormData}>RESET</Button>
        {/* <Button
          onClick={() => {
            console.log(formData);
            handleSubmitMcoDraft();
          }}
        >
          Draft
        </Button> */}
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        formData={formData}
        setFormData={setFormData}
        handlePreviewSave={handlePreviewSave}
        mCoCreated={mCoCreated}
        setMcoCreated={setMcoCreated}
        handleSubmitEco={handleSubmitEco}
        handleAddEcoForm={handleAddEcoForm}
      />
    </div>
  );
};

export default ManualCo;

// all tips here start

function ManualCoo() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            TIPS for Semi Electronic Certificate of Origin
          </h5>
        </div>
        <h6 className="mt-3">
          For Coo preparation, follow the below guidelines:
        </h6>
        <ul className="text-small">
          <li>1 original (limited to a maximum of 2 pages)</li>
          <li>All the columns of Coo are mandatory to fill up.</li>
          <li>
            Commercial Invoice, Packing List and other documents must handy in
            PDF format only, maximum size of the file being uploaded should be
            5MB.
          </li>
          <li>
            If Means of transport by road, then user must upload the LR copies
            for reference.
          </li>
          <li>
            Israeli Declaration -If the shipment is for Gulf Country then
            mandatory tick "YES".
          </li>
        </ul>

        <h5 className="ms-5 text-decoration-underline">
          Special Note for Column No. 12
        </h5>

        <ul className="text-small">
          <li>
            DECLARATION BY THE EXPORTER, the default origin country will be
            India. If shipment is Re-export/ foreign origin then user must
            specifically choose foreign Origin in the tab given
          </li>
          <li>
            If Re-export/ Foreign Origin Shipment, Upload Origin Country COO
            must in “Reference Document” Section.
          </li>
          <li>
            Should be filled up in capital letters only; Importing country name
            must match in Invoice, Packing List and other document
          </li>
          <li>
            Authorized Signature being uploaded must match with the Annexure of
            list of Authorized signatories given in Chamber.
          </li>
          <li>Place of issue and date must mention. </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

function ComInvoiceTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Commercial Invoice
          </h5>
        </div>
        <h6 className="mt-3">
          Submitting Commercial Invoice, refer below TIPS:
        </h6>
        <ul className="text-small">
          <li>1 original (limited to a maximum of 2 pages) </li>
          <li>
            Commercial Invoice must be handy in PDF format only; Maximum size of
            the file being uploaded <br />
            should be 5MB
          </li>
          <li>
            Signature on Commercial Invoice must match the signature uploaded in
            the Coo
          </li>
          <li>
            if Chamber attestation not required on the invoice Tick “NO” in the
            column, but ensure to upload it for reference purposes.
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

function PackListTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Packing List
          </h5>
        </div>
        <h6 className="mt-3">Submitting Packing List, refer below TIPS:</h6>
        <ul className="text-small">
          <li>1 original (limited to a maximum of 2 pages)</li>
          <li>
            Packing List must be handy in PDF format only, Maximum size of the
            file being uploaded should be 5MB
          </li>
          <li>
            Signature on Packing List must match the signature uploaded in the
            Coo
          </li>
          <li>
            If Chamber attestation not required for the Packing List Tick “NO”
            in the column, but ensure to upload it for reference purposes.
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

function AddDocTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Additional Document
          </h5>
        </div>
        <h6 className="mt-3">
          Submitting Additional Documents refer below TIPS:
        </h6>
        <ul className="text-small">
          <li>1 original (limited to a maximum of 2 pages)</li>
          <li>
            Additional Documents must be handy in PDF format only, Maximum size
            of the file being uploaded should be 5MB.
          </li>
          <li>
            Health Certificate/ Phyto Certificate/ Fumigation Certificate anyone
            can be uploaded in Additional Documents for attestation.
          </li>
          <li>Must Upload Clear and visible copies of documents.</li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

function RefDocTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Reference Document
          </h5>
        </div>
        <h6 className="mt-3">
          Submitting Reference Documents, refer below TIPS:
        </h6>
        <ul className="text-small">
          <li>
            Documents uploaded here only for chamber records; no attestation
            will be provided.
          </li>
          <li>
            Reference documents must be handy in PDF Format only (up to 5 pages
            only), maximum size of the file being uploaded should be 5MB.
          </li>
          <li>By Road Shipments, Upload LR Copies.</li>
          <li>
            If Re-export/ Foreign Origin Shipment, Upload Origin Country COO
            must.
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request
          </li>
        </ul>
      </div>
    </>
  );
}

function ProcessingFeeTip() {
  return (
    <>
      <div className="border px-4 py-2 m-auto">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes Tips for Processing Fee
          </h5>
        </div>
        <h6 className="mt-3">
          <strong>
            Additional Processing Fee (Per Set) price distribution for
            Semi-Electronic Certificate of Origin
          </strong>
        </h6>
        <Table striped bordered hover size="sm" style={{ width: "400px" }}>
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Semi-Electronic Certificate of Origin</td>
              <td>Rs. 150/-</td>
            </tr>
            <tr>
              <td>Commercial Invoice</td>
              <td>Rs. 50/-</td>
            </tr>
            <tr>
              <td>Packing List</td>
              <td>Rs. 50/-</td>
            </tr>
            <tr>
              <td>Additional Document 1</td>
              <td>Rs. 100/-</td>
            </tr>
            <tr>
              <td>Additional Document 2</td>
              <td>Rs. 100/-</td>
            </tr>
          </tbody>
        </Table>
        <h4 className="">
          THIS IS Semi-Electronic Certificate of Origin, WHY YOU PAY PROCESSING
          CHARGES?
        </h4>
        <ul>
          <li>Printing of the Semi-Electronic Certificate of Origin</li>
          <li>The printed copies by the chamber will be in black ink.</li>
          <li>
            Dedicated manpower for document verification and quality control
          </li>
          <li>
            Physical stamping and authorized officer signature for added
            credibility
          </li>
          <li>
            Software development contribution for seamless application
            submission
          </li>
        </ul>
      </div>
    </>
  );
}

function DeliveryModeTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes Tips for Delivery Mode
          </h5>
        </div>
        <ul className="text-small mt-3">
          <li className="py-2">
            The Delivery charges mentioned here are tentative and subject to
            change. In the event of a price increase, clients will be required
            to pay the additional amount accordingly.
          </li>
          <li className="py-2">
            Submission of applications & purchase of Sets: 9:30 am to 6:00 pm
          </li>
          <li className="py-2">
            Collection of approved COO (electronic and manual) & Query Notes:
            9:30 am to 6:00 pm
          </li>
          <li className="py-2">
            For Postal Delivery, upon dispatching, the applicant will be
            furnished with the tracking details on the platform for courier
            shipment.{" "}
            <strong>
              Chamber shall not be held responsible for any delay in courier
              delivery!!
            </strong>
          </li>
        </ul>
      </div>
    </>
  );
}

function CollectChemberTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Collect from Chamber- (in Person- no charges)
          </h5>
        </div>
        <ul className="text-small mt-3">
          <li>
            Once the status in the "Track Your Document" section shows COO
            approved, you can collect the COO Manual Print in person at Chamber
            Desk.
          </li>
          <li>Collection of Approved COO: 10 am to 6 pm</li>
        </ul>
      </div>
    </>
  );
}

function IntercityTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Intercity & Gujarat- Rs. 100/-
          </h5>
        </div>
        <ul className="text-small mt-3">
          <li>
            {" "}
            Subject to availability Courier companies, within Mumbai/ Navi
            Mumbai, courier delivery may receive within a day. (If application
            submitted before 4 pm)
          </li>
        </ul>
      </div>
    </>
  );
}

function PanIndiaTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Outside Maharashtra & Other states - Rs.300/-
          </h5>
        </div>
        <ul className="text-small mt-3">
          <li>
            For locations outside of Mumbai/ Navi Mumbai, documents will be
            delivered via regular post. The courier delivery may take a day or
            two to be received, depending on the availability and efficiency of
            the courier service.
          </li>
        </ul>
      </div>
    </>
  );
}

// tips end

function MyVerticallyCenteredModal(props) {
  const priceControl = getAuthUserlocal()?.priceControl;

  const { formData } = props;
  const { setFormData } = props;
  const { handlePreviewSave } = props;
  const { mCoCreated } = props;
  const { setMcoCreated } = props;
  const { handleSubmitEco } = props;
  const { handleAddEcoForm } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ backgroundColor: "#0b3b5d", color: "white" }}
        className="text-white"
      >
        <div className="d-flex justify-content-center w-100">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <h4>CERTIFICATE OF ORIGIN (NON PREFERENTIAL)</h4>
            <h6>(COMBINED DECLARATION AND CERTIFICATE ISSUE-O IN INDIA)</h6>
          </Modal.Title>
        </div>
        <CloseButton variant="white" onClick={props.onHide} />
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
        {!mCoCreated ? (
          <>
            <Row>
              <Col md={7}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    1. Goods consigned from (Exporter's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="mb-1"
                    rows={4}
                    style={{ resize: "none" }}
                    maxLength={250}
                    placeholder="Enter Exporter business name, address, country"
                    value={formData.eCoDetails.consignedFrom}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedFrom: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 6) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedFrom.length}/250 Max
                  </h6>
                  <hr className="mt-4" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    2. Goods consigned to (Consignee's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    type="text"
                    className="mb-1"
                    maxLength={345}
                    placeholder="Enter Consignee business name, address, country"
                    value={formData.eCoDetails.consignedTo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedTo: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 10) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedTo.length}/345 Max
                  </h6>
                  <hr className="mt-4" />{" "}
                </Form.Group>{" "}
                <Form.Group className="mb-3">
                  <Form.Label>
                    3. Means of transport & route (as far as known)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Enter Means of transport & route"
                    className="mb-1"
                    rows={2}
                    style={{ resize: "none" }}
                    maxLength={97}
                    value={formData.eCoDetails.meanOfTran}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          meanOfTran: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 2) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.meanOfTran.length}/97 Max
                  </h6>
                </Form.Group>
              </Col>
              <Col
                md={5}
                className="d-flex flex-column align-items-stretch text-center"
                style={{
                  borderLeft: "1px solid lightgrey",
                  paddingLeft: "10px",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Logo}
                    width="40%"
                    height="auto"
                    className="img-fluid mb-2"
                    alt="logo"
                    style={{ backgroundColor: "transparent" }}
                  />

                  <h5>Asian Exporters' Chamber of Commerce and Industry</h5>
                  <h6>
                    (Recognized by Ministry of Commerce & Industry, Govt. of
                    India)
                  </h6>
                  <span style={{ fontSize: ".8rem" }}>
                    Regd, Office, 604, 6th Floor, Hilton Center, Plot No, 66,
                    Sector 11, CBD Belapur. Navi Mumbai-400614 (INDIA) Tel:
                    +91-22-4127 1145/46, Fax: +91-22-4127 1147 Email:
                    info@aecci.org.in | Website: www.aecci.org.in CIN:
                    U91900MH2015NPL265816
                  </span>
                </div>
                <div className="mt-auto">
                  <Form.Group className="mb-3 w-100 text-start">
                    <Form.Label>4. For Office use</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ resize: "none" }}
                      className="bg-white"
                      rows={2}
                      type="text"
                      placeholder="Enter Exporter business name, address, country"
                      readOnly
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td style={{ width: "10%" }}>5. item Number</td>
                  <td style={{ width: "15%" }}>
                    6. Marks and number of packages
                  </td>
                  <td style={{ width: "30%" }}>
                    7. Number and kind of packages, description of goods
                  </td>
                  <td style={{ width: "20%" }}>8. Origin Criteria</td>
                  <td style={{ width: "12%" }}>
                    9. Gross weight or order quantity
                  </td>
                  <td style={{ width: "12%" }}>
                    10. Number and date of invoices
                  </td>
                </tr>
              </thead>
              <tbody>
                {formData.eCoDetails.extendedData.map((data, index) => (
                  <>
                    <tr>
                      <td colSpan={6}>Page No. {index + 1}</td>
                    </tr>
                    <tr key={index}>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.itemNo}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              itemNo: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.itemNo.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={240}
                          value={data.noOfPack}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noOfPack: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noOfPack.length}/240 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={1100}
                          value={data.desOfGoods}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              desOfGoods: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.originCriteria}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              originCriteria: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.originCriteria.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={100}
                          value={data.weightAndQuantity}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              weightAndQuantity: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.weightAndQuantity.length}/100 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={150}
                          value={data.noAndDateInvoice}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noAndDateInvoice: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noAndDateInvoice.length}/150 Max
                        </h6>
                      </td>
                    </tr>
                  </>
                ))}
                {/* 
  //-------------delete page function is here-------------------
  --------------------------------------------------------------
  -------------------------------------------------------------- */}

                <tr>
                  <td colSpan={6}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {formData.eCoDetails.extendedData.length < 2 && (
                          <span>Do You Want To Extend Page?</span>
                        )}
                        {formData.eCoDetails.extendedData.length < 2 && (
                          <Button
                            variant="info"
                            className="ms-3 py-0 my-0"
                            onClick={handleAddEcoForm}
                          >
                            Yes
                          </Button>
                        )}
                      </div>

                      {formData.eCoDetails.extendedData.length > 1 && (
                        <>
                          <div
                            className="d-flex align-items-center text-danger cursor-pointer max-content"
                            onClick={() => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                eCoDetails: {
                                  ...prevFormData.eCoDetails,
                                  extendedData:
                                    prevFormData.eCoDetails.extendedData.slice(
                                      0,
                                      -1
                                    ), // Remove last element
                                },
                              }));
                            }}
                          >
                            <MdDelete className="fs-3" />

                            <h6 className="mb-0 ms-1">Delete Page</h6>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />
            <div className="d-flex">
              <div
                style={{
                  width: "50%",
                  position: "relative",
                }}
                className="d-flex flex-column justify-content-between mr-1"
              >
                <Form.Label className="d-flex ">
                  <h6 className="text-nowrap me-2">
                    11. CERTIFICATION: {"  "}
                  </h6>
                  <span className="text-small">
                    {" "}
                    It is hereby certified on the basis of Control carried out,
                    that the declaration by the exporter is correct.
                  </span>
                </Form.Label>
                <div>
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(55deg)",
                      width: "100%",
                    }}
                  />
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(-55deg)",
                      width: "100%",
                    }}
                  />
                </div>

                <Form.Label className="mb-0">
                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  <span className="text-small">
                    Authorised Signatory - ASIAN EXPORTERS' CHAMBER OF COMMERCE
                    AND INDUSTRY{" "}
                  </span>
                </Form.Label>
              </div>
              <div style={{ width: "50%", borderLeft: "1px solid grey" }}>
                <div className="ms-2">
                  <span className="text-secondary">
                    Israeli declaration required:
                  </span>
                  <div className="d-inline ms-3 align-middle">
                    <Form.Check
                      inline
                      label="Yes"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "true"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "true",
                          },
                        })
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "false"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "false",
                          },
                        })
                      }
                    />
                  </div>

                  <h6 className="my-3">12. DECLARATION BY THE EXPORTERS:</h6>

                  <span>
                    The Undersigned hereby declares that the above details and
                    statements are correct that all the goods were produced in{" "}
                    {"  "}
                    <div className="d-inline-block">
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        label="INDIA"
                        type="radio"
                        checked={formData.eCoDetails.orginOfCom === "INDIA"} // Bind value from form data
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "INDIA",
                            },
                          })
                        }
                      />{" "}
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        type="radio"
                        label="FOREIGN ORIGIN"
                        checked={
                          formData.eCoDetails.orginOfCom === "FOREIGN ORIGIN"
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "FOREIGN ORIGIN",
                            },
                          })
                        }
                      />
                    </div>{" "}
                    and that they comply with the origin requirements
                  </span>

                  <span className="d-block mt-2 mb-3">for exports to</span>

                  <Form.Group className="w-50">
                    <Form.Label className="mb-0">
                      (Importing Country)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      value={formData.eCoDetails.impCountry}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            impCountry: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.impCountry.length}/30 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">FOR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Company Name here"
                      maxLength={40}
                      value={formData.eCoDetails.forComRegion}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            forComRegion: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.forComRegion.length}/40 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">
                      Authorised Signatory
                    </Form.Label>
                    <Form.Select
                      value={formData.eCoDetails.designation}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            designation: e.target.value,
                          },
                        });
                      }}
                    >
                      <option value="">Select Role</option>
                      {[
                        "Authorised Signatory",
                        "Chairman",
                        "CEO",
                        "Managing Director",
                        "Director",
                        "Partner",
                        "Proprietor",
                        "President",
                        "Vice President",
                        "General Manager (GM)",
                        "Executive Director",
                      ].map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">Place</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Place here"
                      maxLength={20}
                      value={formData.eCoDetails.place}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            place: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.place.length}/20 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label className="mb-0">Date</Form.Label>
                    <Form.Control
                      as="input"
                      type="date"
                      className="w-50"
                      placeholder="Enter Place here"
                      value={formData.eCoDetails.date}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            date: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Control
                      as="input"
                      type="file"
                      className="w-50 mb-2"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0]; // Get the first file selected
                        const updatedFormData = {
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            stamp: file, // Update the stamp field with the selected file
                          },
                        };
                        setFormData(updatedFormData); // Update the formData state
                      }}
                    />
                  </Form.Group>

                  {formData.eCoDetails.stamp && ( // Check if eCoFile exists
                    <div>
                      <img
                        width="15%"
                        height="auto"
                        src={URL.createObjectURL(formData.eCoDetails.stamp)} // Use eCoFile for image source
                        alt="Stamp"
                      />
                    </div>
                  )}

                  <span className="d-block">Authorized Stamp & Signature</span>

                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  {/* border-bottom: 1px dotted #000; */}
                  <span>
                    Place & date, stamp & signature of authorized signatory
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <iframe
              title="eCO File"
              src={URL.createObjectURL(formData.eCoFile)}
              width="100%"
              height="500px"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {!mCoCreated ? (
          <>
            <Button className="setup-bg-color" onClick={handlePreviewSave}>
              PREVIEW & SAVE
            </Button>
            <Button onClick={props.onHide} variant="secondary">
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setMcoCreated(false);
              }}
            >
              EDIT
            </Button>
            <Button
              onClick={() => {
                props.onHide();
                handleSubmitEco();
              }}
              variant="success"
            >
              SUBMIT
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const readAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // When the file is loaded
    reader.onload = () => {
      // Resolve the Promise with the result
      resolve(reader.result);
    };

    // If an error occurs during reading the file
    reader.onerror = () => {
      // Reject the Promise with the error
      reject(reader.error);
    };

    // Start reading the file
    reader.readAsDataURL(file);
  });
};

function PreviewManualReceipt({ formData }) {
  const priceControl = getAuthUserlocal()?.priceControl;

  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <Button
        className="small-font my-1 fw-semibold me-1 setup-bg-color"
        disabled={!formData.packingList}
        onClick={handleShow}
      >
        Preview Receipt
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="d-flex justify-content-cente position-relative font1">
          <div className="text-center w-100 d-flex flex-column justify-content-center">
            <div>
              <img
                src={Logo}
                width="15%"
                height="auto"
                className="img-fluid mb-2"
                alt="logo"
                style={{
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <h3>Asian Exporters' Chamber of Commerce And Industry</h3>
            <h6 className="mb-0">
              Regd Office:604, 6th Floor, Hilton Centre, Plot No.66,
              Sector-11,CBD Belapur, Navi Mumbai,
            </h6>
            <h6>Maharashtra, E-mail: info@aecci.org.in</h6>
            <h4 className="fw-semibold">RECEIPT PREVIEW</h4>
            <hr className="bg-dark mb-0" />

            {/* ----------------receipt table start here-------------------------------- */}
            <div className="text-start mb-3">
              <span className="text-small d-block mb-0">Receipt No:</span>
              <span className="text-small d-block mb-0">Document No:</span>
              <span className="text-small d-block mb-0">
                Received From: {getAuthUserlocal().companyName}
              </span>
              <span className="text-small d-block mb-0">
                Address: {getAuthUserlocal().address1}
              </span>
              <span className="text-small d-block mb-0">
                GSTIN: {getAuthUserlocal().gstNo}
              </span>
            </div>

            <Table className="text-start" hover>
              <thead>
                <tr>
                  <th>Srno</th>
                  <th>Particulers</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Semi-Electronic Certificate of Origin</td>
                  <td>1</td>
                  <td>Rs. {priceControl?.semiEco}/-</td>
                  <td>Rs. {priceControl?.semiEco}/-</td>
                </tr>
                {formData.comInvoiceFlag && (
                  <tr>
                    <td>2</td>
                    <td>Commercial Invoice.</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.comInvoiceMco}/-</td>
                    <td>Rs. {priceControl?.comInvoiceMco} /-</td>
                  </tr>
                )}
                {formData.packingListFlag && (
                  <tr>
                    <td>3</td>
                    <td>Packing List*</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.packListMco}/-</td>
                    <td>Rs. {priceControl?.packListMco} /-</td>
                  </tr>
                )}
                {formData.addDoc1Flag && (
                  <tr>
                    <td>4</td>
                    <td>Additional Document 1</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.addDocMco}/-</td>
                    <td>Rs. {priceControl?.addDocMco} /-</td>
                  </tr>
                )}{" "}
                {formData.addDoc2Flag && (
                  <tr>
                    <td>5</td>
                    <td>Additional Document 2</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.addDocMco}/-</td>
                    <td>Rs. {priceControl?.addDocMco} /-</td>
                  </tr>
                )}{" "}
                {formData.refDocFlag && (
                  <tr>
                    <td>6</td>
                    <td>Reference Document</td>
                    <td>1</td>
                    <td>Rs. 0/-</td>
                    <td>Rs. 0 /-</td>
                  </tr>
                )}
                <tr>
                  <td colSpan={4}>Processing charge</td>
                  <td>Rs. {formData.processingFee}/-</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    Delivery Mode Charges:
                    <br />
                    For <strong>{formData.selectedDeliveryMode}</strong>
                  </td>
                  <td>Rs. {formData.deliveryModeFee}/-</td>
                </tr>
                <tr>
                  <td colSpan={4}>Total</td>
                  <td>Rs. {formData.totalAmt}/-</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    Amount In words: {numberToWords(formData.totalAmt)} Rupees
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan={5} className="text-center">
                    <Button variant="success" >
                      Processed Payment
                    </Button>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
