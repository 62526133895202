import React, { useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { Col, Form, Row } from "react-bootstrap";
import { country_list } from "./AllRegiPageLists";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import toast from "react-hot-toast";
import "./Register.css";
import EPlatformHeader from "../../../components/basic/EPlatformHeader";

const TrialUserRegister = () => {
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const initialFormData = {
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
    country: "",
    pinCode: "",
    phoneNo: "",
    inputNumber: "",
    userMessage: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeCin = (event) => {
    const value = event.target.value.toUpperCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      inputNumber: value,
    }));
    const iecRegex = /^[a-zA-Z0-9]{10}$/;
    const cinRegex = /^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/;
    const isIECValid = iecRegex.test(value);
    const isCINValid = cinRegex.test(value);
    const isValid = isIECValid || isCINValid;
    setIsInputValid(isValid);
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setFormData({
      ...formData,
      country: selectedCountry,
    });
  };

  const handleRegiFormSubmit = async (e) => {
    setRegisterFormSuccess(true);

    e.preventDefault();
    try {
      formData.pinCode = parseInt(formData.pinCode);
      formData.phoneNo = parseInt(formData.phoneNo);

      if (isInputValid) {
        console.log(formData.inputValue);
      } else {
        alert("Please enter a valid IEC code or CIN No.");
        return false;
      }

      // Form validation
      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      // Validate non-zero number
      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };

      if (!validateField(formData.companyName, "Company Name")) return;
      if (!validateField(formData.inputNumber, "IEC CODE / CIN NO")) return;
      if (!validateField(formData.pinCode.toString(), "PIN Code")) return;
      if (!validateNonZeroNumber(formData.pinCode, "PIN Code")) return;
      if (!validateField(formData.firstName, "First Name")) return;
      if (!validateField(formData.lastName, "Last Name")) return;
      if (!validateField(formData.email, "Email")) return;
      if (
        !validateField(
          formData.phoneNo.toString(),
          "Phone No.",
          /^(?:\d{10,15})$/
        )
      )
        return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;

      //seding api here----------------------------------------
      const res = await axios.post(getURLbyEndPoint("createTrialClient"), {
        ...formData,
      });

      //here
      if (res.data.status) {
        toast.success(res.data.message);
        setFormSuccessful(true);
        setRegisterFormSuccess(false);
        setFormData(initialFormData);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      console.log("wrong entries");
      console.log(e);
      toast.error(e.response.data.message);
    } finally {
      setRegisterFormSuccess(false);
    }
  };

  return (
    <>
      <Layout>
      <EPlatformHeader
      heading="Request Form - Trial Version for ePlatform"
            text="AECCI ePlatform Simplifying Trade Documentation "
          />
        <div className="main-width mx-auto my-4">
          {/* <h6><FaRegUser /> Trial User Registration</h6>
                    <hr></hr> */}
          <Row className="mt-2">
            <Col>
              <h2 className="montserratAlternatesFont fw-bold">
                No Queue, No Visit to Chamber, Paperless eStamping & Attestation
                Facility within minutes
              </h2>

              <h6 className="my-4 fw-semibold">
              Considering the urgent needs of exporters, custom house agents, shipping/logistics professionals, and clearing agents, AECCI proudly introduces our innovative ePlatform. Now, issuing a Certificate of Origin (COO) is hassle-free and entirely online.
              </h6>

              <h5 className="fw-bold mt-4">
              Why Choose AECCI ePlatform?
              </h5>

              <ul>
                <li className="my-2">
                <span className="fw-bold">Quick and Efficient:</span> Issue your Certificate of Origin within minutes, without the need to visit our chamber.
                </li>
                <li className="my-2">
                <span className="fw-bold">Completely Online:</span> No more long queues or waiting times. Handle everything digitally from the comfort of your office or home.
                </li>
                <li className="my-2">
                <span className="fw-bold">Easy Registration:</span> Digitally register your company, fill out the eCO form online, and submit it with just a few clicks.
                </li>
                <li className="my-2">
                <span className="fw-bold">Fast Processing:</span> Within 20 minutes of submission, your eCO will be attested. You can then view it in your “Track Your Document” section.
                </li>
              </ul>

              <h6>
              Join the growing community of exporters and agents who are enjoying the convenience and efficiency of our ePlatform. Register today and experience the future of trade documentation!
              </h6>

              <h4 className="fw-bolder my-4">Get Started Now!</h4>
              <h5 className="fw-bold my-2">Register and Start a Free Trial</h5>

<h6 className="my-2">

For any assistance or queries, please don't hesitate to contact our support team.</h6>
<h6 className="my-1"><strong>AECCI ePlatform – Simplifying Trade Documentation</strong></h6>
            </Col>
            <Col>
            { !formSuccessful ? (
              <div
                className="text-white montserratFont"
                style={{ background: "#002b39", paddingBlock: "30px", paddingInline: "50px" }}
                >
                <form onSubmit={handleRegiFormSubmit}>
                  <p className="mt-4" style={{ fontSize: "0.9em" }}>
                    * indicates required fields
                  </p>
                  <div className="my-4">
                    <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      Company Name *
                    </label>
                    <div className="d-flex flex-column">
                      <input
                        style={{ width: "30vw", height: "1.8vw" }}
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.companyName}
                        name="companyName"
                        type="text"
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      Company Email Address *
                    </label>
                    <div className="d-flex flex-column">
                      <input
                        style={{ width: "30vw",height: "1.8vw"  }}
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.email}
                        name="email"
                        type="email"
                        maxLength={36}
                        required
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <Row>
                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          First Name *
                        </label>
                        <div className="d-flex flex-column">
                          <input
                            style={{ height: "1.8vw"  }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.firstName}
                            name="firstName"
                            type="text"
                            maxLength={25}
                            required
                          />
                        </div>
                      </Col>

                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Last Name *
                        </label>
                        <div className="d-flex flex-column">
                          <input
                            style={{ height: "1.8vw"}}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.lastName}
                            name="lastName"
                            type="text"
                            maxLength={25}
                            required
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="my-4">
                    <Row>
                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Country *
                        </label>

                        <select
                          style={{ height: "1.8vw" }}
                          className="form-control rounded-0 select-custom-font-size"
                          required
                          value={formData.country}
                          onChange={handleCountryChange}
                          name="country"
                        >
                          <option value="">Select a country...</option>
                          {country_list.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Pincode *{" "}
                        </label>
                        <input
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value) || 0
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                          style={{ height: "1.8vw"  }}
                          className="form-control rounded-0"
                          onChange={handleInputChange}
                          value={formData.pinCode}
                          name="pinCode"
                          type="text"
                          required
                          pattern="[0-9]*"
                          inputMode="numeric"
                          minLength={6}
                          maxLength={6}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="my-4">
                    <Row>
                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          IEC CODE / CIN NO *
                        </label>
                        <input
                            style={{ height: "1.8vw"  }}
                          className="form-control rounded-0"
                          onChange={handleInputChangeCin}
                          value={formData.inputNumber}
                          name="inputNumber"
                          type="text"
                          minLength={10}
                          maxLength={10}
                          required
                        />
                      </Col>
                      <Col>
                        <label
                          className="fw-bold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          Contact No *
                        </label>
                        <input
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value) || 0
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                            style={{ height: "1.8vw"  }}
                          className="form-control rounded-0"
                          onChange={handleInputChange}
                          value={formData.phoneNo}
                          name="phoneNo"
                          type="text"
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="my-4">
                    <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                      How Can We Help You?
                    </label>
                    <Form.Control
                      as="textarea"
                      style={{
                        height: "1.8vw" ,
                        //  width: "30vw",
                        resize: "none",
                      }}
                      className="form-control rounded-0"
                      onChange={handleInputChange}
                      value={formData.userMessage} 
                      name="userMessage"
                      maxLength={140}
                      rows={2}
                      required
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="m-1 px-5 py-2 rounded-1 border-0 fw-medium "
                      style={{ backgroundColor: "#87F8AE" }}
                      disabled={registerFormSuccess}
                    >
                      {!registerFormSuccess ? "Submit" : "Processing..."}
                    </button>
                    {/* {formSuccessful && (
                      <div style={{ marginTop: "10px", color: "lightgreen" }}>
                        Request send! We will contact you soon.
                      </div>
                    )} */}
                  </div>
                </form>
              </div>
            ) : (

              <div className=""
              style={{ background: "#002b39", paddingBlock: "30px", height: "100%", paddingInline: "50px" }}>

              <div className="d-flex" style={{ marginTop: "10px",  color: "#87F8AE" }}>
             <h3>Request send! <br/>We will contact you soon.</h3> 
            </div>
              </div>
            )}
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default TrialUserRegister;
