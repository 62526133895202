let userDetails, userEmail, gstno;

function storeAuthUser(userData) {
  userDetails = userData;
}
function getAuthUser() {
  return userDetails;
}

function storeEmail(email) {
  userEmail = email;
}
function getEmail() {
  return userEmail;
}

export { storeAuthUser, getAuthUser, storeEmail, getEmail };
