import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { getAuthUserlocal } from "../../../store/services";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import infoImg from "../../../assets/infoimg.png";
import Prof1 from "../../../assets/profile1.png";
import Prof2 from "../../../assets/profile2.png";
import Prof3 from "../../../assets/profile3.png";
import Prof4 from "../../../assets/profile4.png";
import Prof5 from "../../../assets/profile5.png";
import Prof6 from "../../../assets/profile6.png";
import submitEco from "../../../assets/submitEco.png";
import trackDoc from "../../../assets/trackDoc.png";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router";
import { IoCallSharp } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { LuActivitySquare } from "react-icons/lu";
import { FaBoxOpen } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import ModalComponent from "../../../components/basic/ModalComponent";
import Form from "react-bootstrap/Form";
import Contactinfo from "../../../components/basic/Contactinfo";
import QuickLinks from "../../../components/basic/QuickLinks";

const DigitalPrint = () => {
  const userId = getAuthUserlocal().ecoUserId;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    // Check conditions and open the modal if necessary
    const shouldShowModal = () => {
      const userBalance = getAuthUserlocal().balance;
      console.log(userBalance);

      if (!localStorage.getItem("modalShown") && userBalance < 3000) {
        handleShow();
        localStorage.setItem("modalShown", "true");
      }
    };

    shouldShowModal();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <Layout>
      <Layout2>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="main-width">
              <DashboardHeader />

              {/* <hr className="mt-3 mb-2" /> */}
              <div className="d-flex align-items-center">
                {" "}
                <h4 className="py-4">e-CO/ Digital Print</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Digital Print</Tooltip>}
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>
              <hr className="mt-1 mb-2" />
              <p
                className="align-content-center my-4"
                style={{ color: "gray" }}
              >
                Obtain your Certificate of Origin (Non- Preferential)
                effortlessly through three convenient options: In-person (at the
                Chamber desk), through a semi-manual printing process, or via a
                digital copy. Choose the method that best suits your needs and
                obtain your certificate with ease. Make sure to Review the
                requirements, double-check the accuracy of your document, and
                make any necessary corrections before submission.
              </p>

              <table
                className="table table-bordered mb-3"
                style={{ tableLayout: "fixed" }}
              >
                <colgroup>
                  <col style={{ width: "100%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{ background: "rgb(0 0 0 / 5%)" }}
                      scope="col"
                      className="py-2 ps-4 text-center"
                    >
                      <h4>SUBMIT YOUR DOCUMENT</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                <td>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3 d-flex justify-content-center gap-4">
                            <Form.Check
                              inline
                              label="Manual Print"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                            />
                            <Form.Check
                              inline
                              label="DIGITAL COPY"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              defaultChecked
                            />
                          </div>
                        ))}
                      </Form>
                    </td>
                </tr>
                  <tr>
                    <td>
                      <div class="row">
                        <div class="col ">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ "flex-direction": "column" }}
                          >
                            {/* <img
                              src={submitEco}
                              style={{ width: "33%" }}
                            /> */}
                            <p className="m-0">
                              Submit e-Co
                              <ModalComponent />
                            </p>
                          </div>
                        </div>

                        <div class="col">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ "flex-direction": "column" }}
                          >
                            {/* <img
                              src={trackDoc}
                              style={{ width: "33%" }}
                            /> */}
                            <p className="m-0">
                              Track Your Documents <ModalComponent />
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
             <Contactinfo/>
           <QuickLinks/>
            </div>

            <Modal
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Body className="mb-3">
                <div className="text-center">
                  <div className="text-end">
                    <IoClose
                      className="icon-size text-danger"
                      onClick={handleClose}
                    />
                  </div>
                  <div>
                    <RiErrorWarningFill
                      className="text-danger"
                      style={{ fontSize: "8rem" }}
                    />
                  </div>
                  <h4>Your wallet account balance is low!!!</h4>
                  <h6 className="text-secondary">
                    *For service access, please make a deposit into your account
                    in advance.
                  </h6>
                  <Button
                    className="setup-bg-color"
                    onClick={() => Navigate("/wallet")}
                  >
                    Deposit Funds
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          {/* <div style={{ height: "90vh", overflow: "hidden" }}>
              <iframe
                title="ECO iFrame"
                src={`https://eservices.aecci.org.in/?userId=${userId}`}
                width="100%"
                height="90%"
                frameBorder="0"
                scrolling="auto"
              ></iframe>
            </div> */}
        </div>
      </Layout2>
    </Layout>
  );
};

export default DigitalPrint;
