import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import "./Navbar.css";

const HeaderMenu = () => {
  return (
    <header>
      <div className="nav-area">
        {/* for large screens */}
        <Navbar />
        {/* for small screens */}
        <MobileNav />
      </div>
    </header>
  );
};

export default HeaderMenu;
