import { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
  Table,
  CloseButton,
} from "react-bootstrap";
import axios from "axios";
import { getAuthUserlocal } from "../../store/services";
import { formatDateTime } from "../basic/SimpleFunctions";
import { getURLbyEndPoint } from "../../store/api";
import { PopUpModal } from "../basic/ModalComponent";
import { ElectronicCo } from "../../pages/sideBarPage/ServicesPages/e-co";
import { BsEye } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { ComInvoiceTip } from "../../pages/sideBarPage/ServicesPages/e-co";
import { PackListTip } from "../../pages/sideBarPage/ServicesPages/e-co";
import { readAsDataURL } from "../../pages/sideBarPage/ServicesPages/e-co";
import jsPDF from "jspdf";
import eCoTemplate from "../../assets/coFormatTemplate.jpg";
import israeliStamp from "../../assets/CoImages/IsraeilStamp.png";
import Logo from "../../assets/footerLogo.png";
import { numberToWords } from "../basic/numberToWords";
import { MdDelete } from "react-icons/md";
import { AddDocTips } from "../../pages/sideBarPage/ServicesPages/e-co";
import { RefDocTips } from "../../pages/sideBarPage/ServicesPages/e-co";
import { PreviewReceipt } from "../../pages/sideBarPage/ServicesPages/e-co";
import { ProcessedPayment } from "../../pages/sideBarPage/ServicesPages/e-co";

const userId = getAuthUserlocal?.ecoUserId;
const priceControl = getAuthUserlocal()?.priceControl;
const DraftTrack = () => {
  const [draftData, setDraftData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [eCoCreated, setECoCreated] = useState(false);
  const [apiSubmitEco, setApiSubmitEco] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiSubmitEcoDraft, setApiSubmitEcoDraft] = useState(false);
  const [draftName, setDraftName] = useState("");
  const [expiryInfo, setExpiryInfo] = useState([]);
  const [editingDraftId, setEditingDraftId] = useState(null);
  const [showEditComponent, setShowEditComponent] = useState(false);

  const intialExtendedData = {
    itemNo: "",
    noOfPack: "",
    desOfGoods: "",
    originCriteria: "",
    weightAndQuantity: "",
    noAndDateInvoice: "",
  };

  const initialFormData = {
    eCoDetails: {
      consignedFrom: "",
      consignedTo: "",
      meanOfTran: "",
      extendedData: [intialExtendedData],
      israeliDec: "false",
      orginOfCom: "INDIA",
      designation: "",
      impCountry: "",
      forComRegion: "",
      place: "",
      date: "",
      stamp: null,
    },

    //files
    eCoFile: null,
    comInvoice: null,
    packingList: null,
    addDoc1: null,
    addDoc2: null,
    refDoc: null,
    receipt: null,
    totalAmt: 0,

    eCoFileFlag: false,
    comInvoiceFlag: false,
    packingListFlag: false,
    addDoc1Flag: false,
    addDoc2Flag: false,
    refDocFlag: false,
  };
  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  // adding extend page----------------------------------
  const handleAddEcoForm = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      eCoDetails: {
        ...prevFormData.eCoDetails,
        extendedData: [
          ...prevFormData.eCoDetails.extendedData,
          intialExtendedData,
        ],
      },
    }));
  };

  //simple file saving
  const handleFileChange = (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          setFormData({ ...formData, [fileName]: file });
        } else {
          alert("File size exceeds 5MB. Please select a smaller file.");
        }
      } else {
        alert("Please select a PDF file.");
      }
    }
  };

  const handleAddFileClick = (fileType) => {
    if (!formData.addDoc1Flag) {
      setFormData({
        ...formData,
        addDoc1Flag: true,
      });
    } else if (!formData.addDoc2Flag) {
      setFormData({
        ...formData,
        addDoc2Flag: true,
      });
    }
  };

  const calculateTotalAmt = () => {
    let total = 0;

    if (formData.eCoFileFlag) {
      total += priceControl?.eco;
    }
    if (formData.comInvoiceFlag) {
      total += priceControl?.comInvoiceEco;
    }
    if (formData.packingListFlag) {
      total += priceControl?.packListEco;
    }
    if (formData.addDoc1Flag) {
      total += priceControl?.addDocEco;
    }
    if (formData.addDoc2Flag) {
      total += priceControl?.addDocEco;
    }

    //for per set(Processing charges)
    total += priceControl?.ecoPc;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmt: total,
    }));
  };

  useEffect(() => {
    calculateTotalAmt();
  }, [
    formData.eCoFileFlag,
    formData.comInvoiceFlag,
    formData.packingListFlag,
    formData.addDoc1Flag,
    formData.addDoc2Flag,
  ]);

  // ------------------------------------------------
  // ------------------------------------------------
  // ---------------simple functions setups----------
  // ------------------------------------------------
  // Function to handle viewing a document
  const viewDocument = (documentId) => {
    const documentFile = formData[documentId];
    if (documentFile instanceof File || documentFile instanceof Blob) {
      const documentUrl = URL.createObjectURL(documentFile);
      window.open(documentUrl, "_blank");
    } else {
      console.log("Document not found or invalid");
    }
  };

  const editDocument = (documentId) => {
    document.getElementById(documentId).click();
  };

  const deleteDocument = (documentId) => {
    console.log(documentId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [documentId]: null,
    }));
  };

  //creating co
  const handleCreateCo = async () => {
    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
    });

    for (const [index, data] of formData.eCoDetails.extendedData.entries()) {
      // Set background image
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(eCoTemplate, "JPEG", 0, 0, imgWidth, imgHeight);

      pdf.setFontSize(9);
      pdf.setFont("helvetica");
      pdf.setTextColor(80, 80, 80);
      // pdf.text(15, 25, formData.eCoDetails.consignedFrom);

      //for consigned from value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedFromWidth = 100;
      const consignedFromLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedFrom,
        consignedFromWidth
      );
      let yPosConsignedFrom = 25;
      consignedFromLine.forEach((line) => {
        pdf.text(15, yPosConsignedFrom, line);
        yPosConsignedFrom += 4;
      });

      //for consigned to value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedToWidth = 100;
      const consignedToLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedTo,
        consignedFromWidth
      );
      let yPosConsignedTo = 55;
      consignedToLine.forEach((line) => {
        pdf.text(15, yPosConsignedTo, line);
        yPosConsignedTo += 4;
      });

      //for Mean of transport value------------------
      // ---------------------------------------
      // ---------------------------------------
      const meanOfTranWidth = 100;
      const meanOfTranLine = pdf.splitTextToSize(
        formData.eCoDetails.meanOfTran,
        consignedFromWidth
      );
      let yPosmeanOfTran = 102;
      meanOfTranLine.forEach((line) => {
        pdf.text(15, yPosmeanOfTran, line);
        yPosmeanOfTran += 4;
      });

      //for Item NO value------------------
      // ---------------------------------------
      // ---------------------------------------
      const itemNoWidth = 8;
      const itemNoLine = pdf.splitTextToSize(data.itemNo, itemNoWidth);
      let yPositemNo = 122;
      itemNoLine.forEach((line) => {
        pdf.text(15, yPositemNo, line);
        yPositemNo += 4;
      });

      //for No of Pack value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noOfPackWidth = 22;
      const noOfPackLine = pdf.splitTextToSize(data.noOfPack, noOfPackWidth);
      let yPosnoOfPack = 122;
      noOfPackLine.forEach((line) => {
        pdf.text(26, yPosnoOfPack, line);
        yPosnoOfPack += 4;
      });

      //for Description of goods value------------------
      // ---------------------------------------
      // ---------------------------------------
      const desOfGoodsWidth = 70;
      const desOfGoodsLine = pdf.splitTextToSize(
        data.desOfGoods,
        desOfGoodsWidth
      );
      let yPosdesOfGoods = 122;
      desOfGoodsLine.forEach((line) => {
        pdf.text(52, yPosdesOfGoods, line);
        yPosdesOfGoods += 4;
      });

      //for Orginal Criteria value------------------
      // ---------------------------------------
      // ---------------------------------------
      pdf.setFontSize(7);

      const originCriteriaWidth = 13;
      const originCriteriaLine = pdf.splitTextToSize(
        data.originCriteria,
        originCriteriaWidth
      );
      let yPosoriginCriteria = 122;
      originCriteriaLine.forEach((line) => {
        pdf.text(125, yPosoriginCriteria, line);
        yPosoriginCriteria += 4;
      });
      pdf.setFontSize(9);

      //for Weight and quantity value------------------
      // ---------------------------------------
      // ---------------------------------------
      const weightAndQuantityWidth = 20;
      const weightAndQuantityLine = pdf.splitTextToSize(
        data.weightAndQuantity,
        weightAndQuantityWidth
      );
      let yPosweightAndQuantity = 122;
      weightAndQuantityLine.forEach((line) => {
        pdf.text(140, yPosweightAndQuantity, line);
        yPosweightAndQuantity += 4;
      });

      //for no. and date invoice value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noAndDateInvoiceWidth = 30;
      const noAndDateInvoiceLine = pdf.splitTextToSize(
        data.noAndDateInvoice,
        noAndDateInvoiceWidth
      );
      let yPosnoAndDateInvoice = 122;
      noAndDateInvoiceLine.forEach((line) => {
        pdf.text(165, yPosnoAndDateInvoice, line);
        yPosnoAndDateInvoice += 4;
      });

      //adding image in if israeli or not
      // Set background image
      if (
        formData.eCoDetails.israeliDec === "true" &&
        pdf.addImage(israeliStamp, "JPEG", 128, 196, 75, 40)
      )
        //adding this text for india and foreign origin

        pdf.setFontSize(8);
      const orginOfComWidth = 70;
      const originOfComText = `The undersigned hereby declares that the above details and statements are correct; that all the goods were produced in ${formData.eCoDetails.orginOfCom} and that they comply with the origin requirements.`;
      const orginOfComLine = pdf.splitTextToSize(
        originOfComText,
        orginOfComWidth
      );
      let yPosorginOfCom = 234;
      orginOfComLine.forEach((line) => {
        pdf.text(123, yPosorginOfCom, line);
        yPosorginOfCom += 4;
      });

      //for Importing Country value------------------
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(8);
      pdf.setTextColor(37, 35, 36);

      //customization for centering imorting country
      const validUptoTextWidth = pdf.getTextWidth(
        formData.eCoDetails.impCountry
      ); // Measure text width
      const validUptoCenterX = 164 - validUptoTextWidth / 2; // Calculate center X
      pdf.text(validUptoCenterX, 252, formData.eCoDetails.impCountry);

      // pdf.text(164, 252, formData.eCoDetails.impCountry);
      pdf.setTextColor(0, 0, 200);
      pdf.setFontSize(7);
      pdf.text(123, 259, `FOR ${formData.eCoDetails.forComRegion}`);
      pdf.setFontSize(8);
      pdf.text(123, 277, formData.eCoDetails.designation);
      pdf.setTextColor(0, 0, 0);

      //for STAMP value------------------
      const imgDataStamp = await readAsDataURL(formData.eCoDetails.stamp);
      pdf.addImage(imgDataStamp, "JPEG", 124, 260, 15, 15);

      //for place and value are adding here value------------------
      pdf.text(125, 282, `Place: ${formData.eCoDetails.place}`);
      pdf.text(170, 282, `Date: ${formData.eCoDetails.date}`);

      //adding page no.
      pdf.setFont("helvetica", "normal");
      pdf.text(
        122,
        225,
        `${index + 1}/${formData.eCoDetails.extendedData.length}`,
        { align: "right" }
      );

      //for more pages
      if (index < formData.eCoDetails.extendedData.length - 1) {
        pdf.addPage();
      }
    }
    // Generate Blob object
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Convert Blob to File
    const eCoFile = new File([blobPdf], "eCoFile.pdf", {
      type: "application/pdf",
    });

    setFormData({
      ...formData,
      eCoFile: eCoFile,
    });
  };

  // //create eco here--------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    //second line
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.line(15, 115, 195, 115); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];

    const data = [];
    if (formData.eCoFileFlag) {
      data.push([
        "1",
        "e-Co(NP)",
        "1",
        `Rs. ${priceControl?.eco}/-`,
        `Rs. ${priceControl?.eco}/-`,
      ]);
    }
    if (formData.comInvoiceFlag) {
      data.push([
        "2",
        "Commercial Invoice.",
        "1",
        `Rs. ${priceControl?.comInvoiceEco}/-`,
        `Rs. ${priceControl?.comInvoiceEco}/-`,
      ]);
    }
    if (formData.packingListFlag) {
      data.push([
        "3",
        "Packing List",
        "1",
        `Rs. ${priceControl?.packListEco}/-`,
        `Rs. ${priceControl?.packListEco}/-`,
      ]);
    }
    if (formData.addDoc1Flag) {
      data.push([
        "4",
        "Additional Document 1",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    if (formData.addDoc2Flag) {
      data.push([
        "5",
        "Additional Document 2",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    if (formData.refDocFlag) {
      data.push(["6", "Reference Document", "1", "Rs. 0/-", "Rs. 0 /-"]);
    }

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Processing charge:", 15, startY + lineHeight * (data.length + 1));
    pdf.text(
      `Rs. ${priceControl?.ecoPc}/-`,
      175,
      startY + lineHeight * (data.length + 1)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 2));
    pdf.text(
      `Rs. ${formData.totalAmt}/-`,
      175,
      startY + lineHeight * (data.length + 2)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 2),
      195,
      5 + startY + lineHeight * (data.length + 2)
    ); // horizontal line
    pdf.text(
      `Amount In words: ${numberToWords(formData.totalAmt)}`,
      15,
      startY + lineHeight * (data.length + 3)
    );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 3),
      195,
      5 + startY + lineHeight * (data.length + 3)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 3)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };

  // creating e-co here
  const handlePreviewSave = async () => {
    // //validation for eco creation---------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    const validationRules = {
      consignedFrom: {
        message:
          "Please Enter Goods consigned from field and should 250 letters max.",
        isValid: () => {
          const consignedFrom = formData.eCoDetails.consignedFrom.trim();
          return consignedFrom !== "" && consignedFrom.length <= 250;
        },
      },

      consignedTo: {
        message: "Please Enter Goods consigned to and should 345 letters max.",
        isValid: () => {
          const consignedTo = formData.eCoDetails.consignedTo.trim();
          return consignedTo !== "" && consignedTo.length <= 345;
        },
      },

      meanOfTran: {
        message:
          "Please Enter Means of transport & route and should 97 letters max.",
        isValid: () => {
          const meanOfTran = formData.eCoDetails.meanOfTran.trim();
          return meanOfTran !== "" && meanOfTran.length <= 97;
        },
      },

      israeliDec: {
        message: "Please Enter Israeli Declaration.",
        isValid: () => {
          const israeliDec = formData.eCoDetails.israeliDec;
          return israeliDec !== null && israeliDec !== "";
        },
      },

      orginOfCom: {
        message: "Please select orgin INDIA/FOREIGN ORIGIN.",
        isValid: () => {
          const orginOfComVal = formData.eCoDetails.orginOfCom;
          return orginOfComVal !== null && orginOfComVal !== "";
        },
      },

      designation: {
        message: "Please Select any Authorised Signatory.",
        isValid: () => {
          const designation = formData.eCoDetails.designation.trim();
          return designation !== null && designation !== "";
        },
      },

      impCountry: {
        message: "Please Enter Importing Country and should bw 20 letters max.",
        isValid: () => {
          const impCountry = formData.eCoDetails.impCountry.trim();
          return impCountry !== "" && impCountry.length <= 30;
        },
      },
      forComRegion: {
        message:
          "Please Enter For company/region field and should 40 letters max.",
        isValid: () => {
          const forComRegion = formData.eCoDetails.forComRegion.trim();
          return forComRegion !== "" && forComRegion.length <= 40;
        },
      },

      place: {
        message: "Please Enter place field and should 20 letters max.",
        isValid: () => {
          const place = formData.eCoDetails.place.trim();
          return place !== "" && place.length <= 20;
        },
      },
      date: {
        message: "Please Enter Date.",
        isValid: () => {
          const date = formData.eCoDetails.date.trim();
          return date !== null && date !== "";
        },
      },

      stamp: {
        message: "Please upload stamp.",
        isValid: () => {
          const stamp = formData.eCoDetails.stamp;
          return (
            stamp !== null &&
            stamp.type.startsWith("image/") &&
            stamp.size <= 5 * 1024 * 1024
          ); // 5MB limit
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    let validationForEco = false;

    {
      formData.eCoDetails.extendedData.map((data, index) => {
        const validationRulesForEco = {
          itemNo: {
            message: `Please Enter page ${
              index + 1
            } item number field and should be 40 letters max.`,
            isValid: () => {
              const itemNo = data.itemNo.trim();
              return itemNo !== "" && itemNo.length <= 40;
            },
          },
          noOfPack: {
            message: `Please Enter page ${
              index + 1
            } No of pack field and should be 240 letters max.`,
            isValid: () => {
              const noOfPack = data.noOfPack.trim();
              return noOfPack !== "" && noOfPack.length <= 240;
            },
          },
          desOfGoods: {
            message: `Please Enter page ${
              index + 1
            } kind of packages, description of goods.`,
            isValid: () => {
              const desOfGoods = data.desOfGoods.trim();
              return desOfGoods !== "" && desOfGoods.length <= 1100;
            },
          },
          originCriteria: {
            message: `Please Enter page ${
              index + 1
            } Origin Criteria field and should be 40 letters max.`,
            isValid: () => {
              const originCriteria = data.originCriteria.trim();
              return originCriteria !== "" && originCriteria.length <= 40;
            },
          },
          weightAndQuantity: {
            message: `Please Enter page ${
              index + 1
            } Gross weight or order quantity field and should be 100 letters max.`,
            isValid: () => {
              const weightAndQuantity = data.weightAndQuantity.trim();
              return (
                weightAndQuantity !== "" && weightAndQuantity.length <= 100
              );
            },
          },
          noAndDateInvoice: {
            message: `Please Enter page ${index + 1
              } Number and date of invoices field and should be 200 letters max.`,
            isValid: () => {
              const noAndDateInvoice = data.noAndDateInvoice.trim();
              return noAndDateInvoice !== "" && noAndDateInvoice.length <= 200;
            },
          },
        };

        // Check each validation rule
        for (const field in validationRulesForEco) {
          const { message, isValid } = validationRulesForEco[field];
          if (!isValid()) {
            alert(message);
            validationForEco = true;
            return;
          }
        }
      });
    }

    if (validationForEco) {
      return;
    }

    await handleCreateCo();
    setECoCreated(true);
  };

  const handleSubmitEco = async () => {
    setFormData({
      ...formData,
      eCoFileFlag: true,
    });
    setECoCreated(false);
    setModalShow(false);
  };

  //Main submit for ECO FORM---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  const handleSubmitEcoForm = async () => {
    await handleCreateReceipt();

    if (formData.eCoDetails.extendedData.length < 0) {
      alert("Something went wrong");
      return;
    }

    const validationRules = {
      eCoForm: {
        message: "Please Fill eCo form.",
        isValid: () => {
          const eCOForm = formData.eCoFile;
          return eCOForm !== null;
        },
      },

      comInvoice: {
        message: "Please upload Commertial Invoice.",
        isValid: () => {
          const comInvoice = formData.comInvoice;
          return (
            comInvoice !== null &&
            comInvoice.type === "application/pdf" &&
            comInvoice.size <= 5 * 1024 * 1024
          );
        },
      },

      packingList: {
        message: "Please upload packing List.",
        isValid: () => {
          const packingList = formData.packingList;
          return (
            packingList !== null &&
            packingList.type === "application/pdf" &&
            packingList.size <= 5 * 1024 * 1024
          );
        },
      },

      addDoc1: {
        message: "Please upload additional document 1.",
        isValid: () => {
          if (formData.addDoc1Flag) {
            const addDoc1 = formData.addDoc1;
            return (
              addDoc1 !== null &&
              addDoc1.type === "application/pdf" &&
              addDoc1.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      addDoc2: {
        message: "Please upload additional document 2.",
        isValid: () => {
          if (formData.addDoc2Flag) {
            const addDoc2 = formData.addDoc2;
            return (
              addDoc2 !== null &&
              addDoc2.type === "application/pdf" &&
              addDoc2.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      refDoc: {
        message: "Please upload reference document.",
        isValid: () => {
          if (formData.refDocFlag) {
            const refDoc = formData.refDoc;
            return (
              refDoc !== null &&
              refDoc.type === "application/pdf" &&
              refDoc.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }
    //api setup started
    const userId = getAuthUserlocal()._id;
    const formDataForApi = new FormData();

    // Append files to the FormData
    const dataToInclude = [
      //data
      "totalAmt",
      "eCoFileFlag",
      "comInvoiceFlag",
      "packingListFlag",
      "addDoc1Flag",
      "addDoc2Flag",
      "refDocFlag",

      //files
      "eCoFile",
      "comInvoice",
      "packingList",
      "addDoc1",
      "addDoc2",
      "refDoc",
      "receipt",
    ];

    dataToInclude.forEach((data) => {
      formDataForApi.append(data, formData[data]);
    });
    // Append data to the FormData
    const fieldsToInclude = [
      "israeliDec",
      "orginOfCom",
      "designation",
      "impCountry",
      "forComRegion",
      "place",
      "date",
      "stamp",
    ];
    fieldsToInclude.forEach((field) => {
      formDataForApi.append(field, formData.eCoDetails[field]);
    });

    formDataForApi.append(
      "noAndDateInvoice",
      formData.eCoDetails.extendedData[0].noAndDateInvoice
    );

    //validation for balance
     //validation for balance
     const user = getAuthUserlocal();
     const hasWalletExceedAccess =
       user.specialControl?.accessList?.includes("walletExceed");
     const isTrialUser = user?.isTrial;
     const balanceAfterTransaction = user?.balance - formData.totalAmt;
     if (
       !hasWalletExceedAccess &&
       !isTrialUser &&
       balanceAfterTransaction < 500
     ) {
       alert("Low balance");
       return;
     }

    try {
      setApiSubmitEco(true);

      const res = await axios.post(
        getURLbyEndPoint("createEco") + userId,
        formDataForApi
      );

      if (res.data.status) {
        alert("E-CO created successfully");
        window.location.reload();

        // Delete the draft after successful form submission
        if (editingDraftId) {
          const deleteResponse = await axios.delete(
            getURLbyEndPoint("deleteDraft") + editingDraftId
          );

          if (deleteResponse.status === 200) {
            // Draft deleted successfully
            setDraftData((prevDraftData) =>
              prevDraftData.filter((draft) => draft._id !== editingDraftId)
            );
            setEditingDraftId(null); // Reset the editingDraftId state
          } else {
            alert("Failed to delete draft.");
          }
        }

        window.location.reload();
        setApiSubmitEco(true);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      setApiSubmitEco(false);
    }
  };

  //Main Draft function---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  function convertBinaryToBase64(binaryData) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Get rid of data URL prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(binaryData);
    });
  }

  const handleUpdateEcoDraft = async () => {
    const formDataForDraft = new FormData();

    formDataForDraft.append("draftName", draftName);
    formDataForDraft.append("draftId", editingDraftId);
    formDataForDraft.append(
      "documentType",
      "ELECTRONIC CERTIFICATE OF ORIGIN(eCo)"
    );

    const filesToInclude = [
      "eCoFile",
      "comInvoice",
      "packingList",
      "addDoc1",
      "addDoc2",
      "refDoc",
      "receipt",
      "stamp",
    ];

    try {
      for (const fieldName of filesToInclude) {
        const file = formData[fieldName];
        if (file !== undefined && file !== null) {
          formDataForDraft.append(fieldName, file);
        }
      }

      // Append other fields to formDataForDraft
      formDataForDraft.append("totalAmt", formData.totalAmt);
      formDataForDraft.append("eCoFileFlag", formData.eCoFileFlag);
      formDataForDraft.append("comInvoiceFlag", formData.comInvoiceFlag);
      formDataForDraft.append("packingListFlag", formData.packingListFlag);
      formDataForDraft.append("addDoc1Flag", formData.addDoc1Flag);
      formDataForDraft.append("addDoc2Flag", formData.addDoc2Flag);
      formDataForDraft.append("refDocFlag", formData.refDocFlag);

      const eCoDetailsWithStamp = {
        ...formData.eCoDetails,
        stamp: formData.eCoDetails.stamp
          ? await convertBinaryToBase64(formData.eCoDetails.stamp)
          : null,
      };
      formDataForDraft.append(
        "eCoDetails",
        JSON.stringify(eCoDetailsWithStamp)
      );

      setApiSubmitEcoDraft(true);
      const response = await axios.put(
        getURLbyEndPoint("updateDraft") + editingDraftId,
        formDataForDraft
      );

      if (response.data.status) {
        alert("Draft updated successfully!");
        setEditingDraftId(null);
        window.location.reload();
        setApiSubmitEcoDraft(true);
      } else {
        alert(response.data.message);
        setApiSubmitEcoDraft(false);
      }
    } catch (error) {
      console.error("Error while updating draft:", error);
      alert("Something went wrong when updating the draft");
      setApiSubmitEcoDraft(false);
    }
  };

  const base64ToFile = (
    base64String,
    fileName,
    mimeType = "application/pdf"
  ) => {
    if (!base64String) return null;

    const byteCharacters = atob(base64String);
    if (!byteCharacters) return null;

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });
    return file;
  };

  const handleEditDraft = (data) => {
    function convertToFile(chunks, fileName) {
      const concatenatedChunks = chunks.reduce((acc, chunk) => {
        acc.push(
          ...atob(chunk)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        return acc;
      }, []);

      const blob = new Blob([new Uint8Array(concatenatedChunks)], {
        type: "application/pdf",
      });

      const file = new File([blob], fileName, { type: blob.type });
      return file;
    }

    setEditingDraftId(data._id);
    setDraftName(data.draftName);
    // Convert base64 strings for files back into File objects
    if (data.eCoFile) {
      const eCoFile = convertToFile(data.eCoFile, "eCoFile.pdf");
      if (!eCoFile) {
        console.error("Invalid base64 string for eCoFile");
      } else {
        data.eCoFile = eCoFile;
      }
    }
    if (data.comInvoice) {
      const comInvoice = convertToFile(data.comInvoice, "comInvoice.pdf");
      if (!comInvoice) {
        console.error("Invalid base64 string for comInvoice");
      } else {
        data.comInvoice = comInvoice;
      }
    }
    if (data.packingList) {
      const packingList = convertToFile(data.packingList, "packingList.pdf");
      if (!packingList) {
        console.error("Invalid base64 string for packingList");
      } else {
        data.packingList = packingList;
      }
    }
    if (data.addDoc1) {
      const addDoc1 = convertToFile(data.addDoc1, "addDoc1.pdf");
      if (!addDoc1) {
        console.error("Invalid base64 string for addDoc1");
      } else {
        data.addDoc1 = addDoc1;
      }
    }
    if (data.addDoc2) {
      const addDoc2 = convertToFile(data.addDoc2, "addDoc2.pdf");
      if (!addDoc2) {
        console.error("Invalid base64 string for addDoc2");
      } else {
        data.addDoc2 = addDoc2;
      }
    }
    if (data.refDoc) {
      const refDoc = convertToFile(data.refDoc, "refDoc.pdf");
      if (!refDoc) {
        console.error("Invalid base64 string for refDoc");
      } else {
        data.refDoc = refDoc;
      }
    }
    if (data.eCoDetails.stamp) {
      const stamp = base64ToFile(
        data.eCoDetails.stamp,
        "stamp.jpg",
        "image/jpeg"
      );
      if (!stamp) {
        console.error("Invalid base64 string for stamp");
      } else {
        data.eCoDetails.stamp = stamp;
      }
    }

    const transformedData = {
      ...data,
      eCoDetails: {
        ...data.eCoDetails,
        extendedData: (data.eCoDetails.extendedData || []).map((item) => ({
          ...item,
        })),
      },
    };

    setFormData(transformedData);
    setShowEditComponent(true);
  };

  const openEcoFile = () => {
    // Create URL for the eCoFile
    const eCoFileUrl = URL.createObjectURL(formData.eCoFile);

    // Open the eCoFile in a new tab
    window.open(eCoFileUrl, "_blank");
  };

  const fetchDraftData = async () => {
    try {
      const userId = getAuthUserlocal()._id;
      const response = await axios.get(
        getURLbyEndPoint("getAllDraft") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setDraftData([...data.data]);
      } else {
        alert("Failed to retrieve Drafts Data.");
      }
    } catch (error) {
      console.error("Error fetching Draft data:", error);
    }
  };

  const handleDeleteDraft = async (data) => {
    try {
      const draftId = data._id; // Assuming the draft ID is stored in the data object

      const response = await axios.delete(
        getURLbyEndPoint("deleteDraft") + draftId
      );

      if (response.status === 200) {
        alert("Draft deleted successfully.");
        setDraftData((prevDraftData) =>
          prevDraftData.filter((draft) => draft._id !== draftId)
        );
      } else {
        alert("Failed to delete draft.");
      }
    } catch (error) {
      console.error("Error deleting draft:", error);
      alert("Error deleting draft. Please try again later.");
    }
  };



  useEffect(() => {
    const calculateExpiry = () => {
      const now = new Date();
      const expiryData = draftData.map((data) => {
        const createdAt = new Date(data.createdAt);
        const expiresInMilliseconds =
          7 * 24 * 60 * 60 * 1000 - (now - createdAt);
        const expiresInDays = Math.ceil(
          expiresInMilliseconds / (1000 * 60 * 60 * 24)
        );
        const expiresInHours = Math.floor(
          (expiresInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const expiresInMinutes = Math.floor(
          (expiresInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );
        const expiresInSeconds = Math.floor(
          (expiresInMilliseconds % (1000 * 60)) / 1000
        );
        return {
          expiresInDays,
          expiresInHours,
          expiresInMinutes,
          expiresInSeconds,
        };
      });
      setExpiryInfo(expiryData);
    };

    calculateExpiry();
  }, [draftData]);

  useEffect(() => {
    fetchDraftData();
  }, []);

  return (
    <>
      {showEditComponent ? (
        <div>
          <Table bordered striped>
            <thead>
              <tr>
                <th className="fw-medium" style={{ width: "40%" }}>
                  DOCUMENT TYPE
                </th>
                <th className="fw-medium" style={{ width: "20%" }}>
                  FEES
                </th>
                <th className="fw-medium" style={{ width: "20%" }}>
                  UPLOADS
                </th>
                <th className="fw-medium" style={{ width: "20%" }}>
                  ACTION
                </th>
              </tr>
            </thead>

            <tbody>
              {/* //e-co row------------------------------
                              --------------------------------------------
                              ------------------------------------------- */}
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <h6>electronic-CO (NP)*</h6>
                    <div>
                      <PopUpModal body={<ElectronicCo />} />
                    </div>
                  </div>
                </td>
                <td>Rs. {priceControl?.eco}/-</td>
                <td>
                  <Button
                    variant="secondary"
                    onClick={() => setModalShow(true)}
                    className="w-100 setup-bg-color"
                    disabled={formData.eCoFileFlag}
                  >
                    Fill Form
                  </Button>
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                    {formData.eCoFile && (
                      <BsEye
                        className="fs-5 cursor-pointer"
                        onClick={openEcoFile}
                      />
                    )}
                    <FaPen
                      className="fs-6 cursor-pointer"
                      onClick={() => setModalShow(true)}
                    />
                  </div>
                </td>
              </tr>
              {/* commertial invoice row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
              {formData.eCoFileFlag && (
                <tr>
                  <td>
                    <h6>
                      <div className="d-flex align-items-center">
                        <h6>Commercial Invoice</h6>
                        <div>
                          <PopUpModal body={<ComInvoiceTip />} />
                        </div>
                      </div>
                    </h6>
                  </td>
                  <td>
                    {formData.comInvoiceFlag
                      ? `Rs. ${priceControl?.comInvoiceEco}/-`
                      : "Rs.0/-"}
                  </td>

                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-inline ms-3 align-middle mb-3">
                        <Form.Check
                          inline
                          label="Yes"
                          name="comInvoice"
                          type="radio"
                          checked={formData.comInvoiceFlag}
                          onChange={() => {
                            const updatedFormData = {
                              ...formData,
                              comInvoiceFlag: true,
                            };
                            setFormData(updatedFormData);
                          }}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="comInvoice"
                          type="radio"
                          checked={!formData.comInvoiceFlag}
                          onChange={() => {
                            const updatedFormData = {
                              ...formData,
                              comInvoiceFlag: false,
                            };
                            setFormData(updatedFormData);
                          }}
                        />
                      </div>

                      <div>
                        {/* Hidden file input for commercial invoice */}
                        <input
                          type="file"
                          id="comInvoice"
                          accept=".pdf" // Add accepted file types if needed
                          onChange={(event) =>
                            handleFileChange(event, "comInvoice")
                          }
                          style={{ display: "none" }}
                        />

                        {/* Button and associated label for commercial invoice */}
                        <Button
                          className="setup-bg-color w-100 mb-2"
                          disabled={formData.comInvoice}
                        >
                          <label htmlFor="comInvoice" className="mb-0">
                            Upload
                          </label>
                        </Button>
                      </div>

                      {!formData.comInvoiceFlag && (
                        <span
                          style={{ fontSize: "14px" }}
                          className="text-center"
                        >
                          Upload commercial Invoice for Reference Only
                        </span>
                      )}
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                      <a
                        href="#"
                        onClick={() => viewDocument("comInvoice")}
                        disabled={!formData.comInvoice}
                      >
                        <BsEye className="fs-5 cursor-pointer" />
                      </a>

                      <FaPen
                        className="fs-6 cursor-pointer"
                        onClick={() =>
                          document.getElementById("comInvoice").click()
                        }
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* Packing List row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
              {formData.comInvoice && (
                <tr>
                  <td>
                    <h6>
                      <div className="d-flex align-items-center">
                        <h6>Packing List*</h6>
                        <div>
                          <PopUpModal body={<PackListTip />} />
                        </div>
                      </div>
                    </h6>
                  </td>
                  <td>
                    {formData.packingListFlag
                      ? `Rs. ${priceControl?.packListEco}/-`
                      : "Rs.0/-"}
                  </td>

                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-inline ms-3 align-middle mb-3">
                        <Form.Check
                          inline
                          label="Yes"
                          name="packList"
                          type="radio"
                          checked={formData.packingListFlag}
                          onChange={() => {
                            const updatedFormData = {
                              ...formData,
                              packingListFlag: true,
                            };
                            setFormData(updatedFormData);
                          }}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="packList"
                          type="radio"
                          checked={!formData.packingListFlag}
                          onChange={() => {
                            const updatedFormData = {
                              ...formData,
                              packingListFlag: false,
                            };
                            setFormData(updatedFormData);
                          }}
                        />
                      </div>

                      <div>
                        {/* Hidden file input for commercial invoice */}
                        <input
                          type="file"
                          id="packingList"
                          accept=".pdf" // Add accepted file types if needed
                          onChange={(event) =>
                            handleFileChange(event, "packingList")
                          }
                          style={{ display: "none" }}
                        />

                        {/* Button and associated label for commercial invoice */}
                        <Button
                          className="setup-bg-color w-100 mb-2"
                          disabled={formData.packingList}
                        >
                          <label htmlFor="packingList" className="mb-0">
                            Upload
                          </label>
                        </Button>
                      </div>

                      {!formData.packingListFlag && (
                        <span
                          style={{ fontSize: "14px" }}
                          className="text-center"
                        >
                          Upload Packing List for Reference Only{" "}
                        </span>
                      )}
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                      <a
                        href="#"
                        onClick={() => viewDocument("packingList")}
                        disabled={!formData.packingList}
                      >
                        <BsEye className="fs-5 cursor-pointer" />
                      </a>

                      <FaPen
                        className="fs-6 cursor-pointer"
                        onClick={() => editDocument("packingList")}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* 
                            ---------------------additional Document 1---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
              {formData.addDoc1Flag && (
                <tr>
                  <td>
                    <h6>
                      <div className="d-flex align-items-center">
                        <h6>Additional Document 1</h6>
                        <div>
                          <PopUpModal body={<AddDocTips />} />
                        </div>
                      </div>
                    </h6>
                  </td>
                  <td>Rs. {priceControl?.addDocEco}/-</td>

                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      {/* Hidden file input for commercial invoice */}
                      <input
                        type="file"
                        id="addDoc1"
                        accept=".pdf" // Add accepted file types if needed
                        onChange={(event) => handleFileChange(event, "addDoc1")}
                        style={{ display: "none" }}
                      />

                      {/* Button and associated label for commercial invoice */}
                      <Button
                        className="setup-bg-color w-100 mb-2"
                        disabled={formData.addDoc1}
                      >
                        <label htmlFor="addDoc1" className="mb-0">
                          Upload
                        </label>
                      </Button>
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                      <a
                        href="#"
                        onClick={() => viewDocument("addDoc1")}
                        disabled={!formData.addDoc1}
                      >
                        <BsEye className="fs-5 cursor-pointer" />
                      </a>

                      <FaPen
                        className="fs-6 cursor-pointer"
                        onClick={() => editDocument("addDoc1")}
                      />

                      {!formData.addDoc2Flag && (
                        <MdDelete
                          className="fs-5 cursor-pointer"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              addDoc1: null,
                              addDoc1Flag: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {/* 
                            ---------------------additional Document 2---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
              {formData.addDoc2Flag && (
                <tr>
                  <td>
                    <h6>
                      <div className="d-flex align-items-center">
                        <h6>Additional Document 2</h6>
                        <div>
                          <PopUpModal body={<AddDocTips />} />
                        </div>
                      </div>
                    </h6>
                  </td>
                  <td>Rs. {priceControl?.addDocEco}/-</td>

                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      {/* Hidden file input for commercial invoice */}
                      <input
                        type="file"
                        id="addDoc2"
                        accept=".pdf" // Add accepted file types if needed
                        onChange={(event) => handleFileChange(event, "addDoc2")}
                        style={{ display: "none" }}
                      />

                      {/* Button and associated label for commercial invoice */}
                      <Button
                        className="setup-bg-color w-100 mb-2"
                        disabled={formData.addDoc2}
                      >
                        <label htmlFor="addDoc2" className="mb-0">
                          Upload
                        </label>
                      </Button>
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                      <a
                        href="#"
                        onClick={() => viewDocument("addDoc2")}
                        disabled={!formData.addDoc2}
                      >
                        <BsEye className="fs-5 cursor-pointer" />
                      </a>
                      <FaPen
                        className="fs-6 cursor-pointer"
                        onClick={() => editDocument("addDoc2")}
                      />

                      <MdDelete
                        className="fs-5 cursor-pointer"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            addDoc2: null,
                            addDoc2Flag: false,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* 
                            ---------------------Refrence document ---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
              {formData.refDocFlag && (
                <tr>
                  <td>
                    <h6>
                      <div className="d-flex align-items-center">
                        <h6>Reference Document*</h6>
                        <div>
                          <PopUpModal body={<RefDocTips />} />
                        </div>
                      </div>
                    </h6>
                  </td>
                  <td>Rs.0/-</td>

                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      {/* Hidden file input for commercial invoice */}
                      <input
                        type="file"
                        id="refDoc"
                        accept=".pdf" // Add accepted file types if needed
                        onChange={(event) => handleFileChange(event, "refDoc")}
                        style={{ display: "none" }}
                      />

                      {/* Button and associated label for commercial invoice */}
                      <Button
                        className="setup-bg-color w-100 mb-2"
                        disabled={formData.refDoc}
                      >
                        <label htmlFor="refDoc" className="mb-0">
                          Upload
                        </label>
                      </Button>
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                      <a
                        href="#"
                        onClick={() => viewDocument("refDoc")}
                        disabled={!formData.refDoc}
                      >
                        <BsEye className="fs-5 cursor-pointer" />
                      </a>
                      <FaPen
                        className="fs-6 cursor-pointer"
                        onClick={() => editDocument("refDoc")}
                      />

                      <MdDelete
                        className="fs-5 cursor-pointer"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            refDoc: null,
                            refDocFlag: false,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {/* ---------------------Documents end here ---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}

              <tr>
                <td colSpan={4}>
                  <div className="d-flex justify-content-evenly w-100">
                    {!formData.addDoc2Flag && (
                      <Button
                        className="small-font my-1 fw-semibold px-3 setup-bg-color"
                        onClick={handleAddFileClick}
                      >
                        SUBMIT ADDITIONAL DOCUMENT?
                      </Button>
                    )}

                    {!formData.refDocFlag && (
                      <Button
                        className="small-font my-1 fw-semibold px-3"
                        variant="info"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            refDocFlag: true,
                          })
                        }
                      >
                        {" "}
                        SUBMIT REFRENCE DOCUMENT?
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h6>
                    <div className="d-flex align-items-center">
                      <h6>Processing Fee</h6>
                    </div>
                  </h6>
                </td>
                <td>Rs. {priceControl?.ecoPc}/-</td>

                <td></td>

                <td></td>
              </tr>
              <tr>
                <td>
                  <h6>Total</h6>
                </td>

                <td>
                  <span>Rs.{formData.totalAmt}/-</span>
                </td>
                <td colSpan={2} className="text-end">
                  <PreviewReceipt formData={formData} />
                  <ProcessedPayment
                    formData={formData}
                    setIsSubmit={setIsSubmit}
                    isTrial={
                      (getAuthUserlocal()?.isTrial
                        ? getAuthUserlocal()?.isTrial
                        : false) &&
                      getAuthUserlocal()?.balance -
                        formData.totalAmt <
                        500
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="d-flex justify-content-between gap-3">
            {/* <Button variant="secondary" onClick={handleBackToTable}>
              Back To Drafts
            </Button> */}
            <div className="d-flex gap-4">
              <Button
                variant="success"
                disabled={apiSubmitEcoDraft}
                onClick={handleUpdateEcoDraft}
              >
                {apiSubmitEcoDraft ? "Processing..." : "Save Draft"}
              </Button>

              {/* <Button variant="primary" onClick={() => handleUpdateEcoDraft(draftName)}>Save Draft</Button> */}
              <Button variant="danger" onClick={resetFormData}>
                RESET
              </Button>
            </div>
            <Button
              variant="success"
              disabled={!isSubmit || apiSubmitEco}
              onClick={handleSubmitEcoForm}
            >
              {apiSubmitEco ? "Processing..." : "SUBMIT"}
            </Button>
          </div>

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            formData={formData}
            setFormData={setFormData}
            handlePreviewSave={handlePreviewSave}
            eCoCreated={eCoCreated}
            setECoCreated={setECoCreated}
            handleSubmitEco={handleSubmitEco}
            handleAddEcoForm={handleAddEcoForm}
          />
        </div>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Date & Time</th>
                <th>Document Type</th>
                <th>Draft Name</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {draftData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{formatDateTime(data.createdAt)}</td>
                    <td>{data.documentType}</td>
                    <td>{data.draftName}</td>
                    <td>
                      <div className="d-flex gap-2 justify-content-center">
                        <Button
                          variant="primary"
                          onClick={() => handleEditDraft(data)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteDraft(data)}
                        >
                          <MdDelete />
                        </Button>
                      </div>
                      <pre className="mt-3 text-center text-wrap">
                        Expiry:{" "}
                        {expiryInfo[index] &&
                          `${expiryInfo[index].expiresInDays}day ${expiryInfo[index].expiresInHours}hrs ${expiryInfo[index].expiresInMinutes}min`}
                      </pre>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default DraftTrack;

function MyVerticallyCenteredModal(props) {
  const { formData } = props;
  const { setFormData } = props;
  const { handlePreviewSave } = props;
  const { eCoCreated } = props;
  const { setECoCreated } = props;
  const { handleSubmitEco } = props;
  const { handleAddEcoForm } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ backgroundColor: "#0b3b5d", color: "white" }}
        className="text-white"
      >
        <div className="d-flex justify-content-center w-100">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <h4>CERTIFICATE OF ORIGIN (NON PREFERENTIAL)</h4>
            <h6>(COMBINED DECLARATION AND CERTIFICATE ISSUE-O IN INDIA)</h6>
          </Modal.Title>
        </div>
        <CloseButton variant="white" onClick={props.onHide} />
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
        {!eCoCreated ? (
          <>
            <Row>
              <Col md={7}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    1. Goods consigned from (Exporter's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="mb-1"
                    rows={4}
                    style={{ resize: "none" }}
                    maxLength={250}
                    placeholder="Enter Exporter business name, address, country"
                    value={formData.eCoDetails.consignedFrom}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedFrom: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 6) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedFrom.length}/250 Max
                  </h6>
                  <hr className="mt-4" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    2. Goods consigned to (Consignee's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    type="text"
                    className="mb-1"
                    maxLength={345}
                    placeholder="Enter Consignee business name, address, country"
                    value={formData.eCoDetails.consignedTo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedTo: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 10) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedTo.length}/345 Max
                  </h6>
                  <hr className="mt-4" />{" "}
                </Form.Group>{" "}
                <Form.Group className="mb-3">
                  <Form.Label>
                    3. Means of transport & route (as far as known)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Enter Means of transport & route"
                    className="mb-1"
                    rows={2}
                    style={{ resize: "none" }}
                    maxLength={97}
                    value={formData.eCoDetails.meanOfTran}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          meanOfTran: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 2) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.meanOfTran.length}/97 Max
                  </h6>
                </Form.Group>
              </Col>
              <Col
                md={5}
                className="d-flex flex-column align-items-stretch text-center"
                style={{
                  borderLeft: "1px solid lightgrey",
                  paddingLeft: "10px",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Logo}
                    width="40%"
                    height="auto"
                    className="img-fluid mb-2"
                    alt="logo"
                    style={{ backgroundColor: "transparent" }}
                  />

                  <h5>Asian Exporters' Chamber of Commerce and Industry</h5>
                  <h6>
                    (Recognized by Ministry of Commerce & Industry, Govt. of
                    India)
                  </h6>
                  <span style={{ fontSize: ".8rem" }}>
                    Regd, Office, 604, 6th Floor, Hilton Center, Plot No, 66,
                    Sector 11, CBD Belapur. Navi Mumbai-400614 (INDIA) Tel:
                    +91-22-4127 1145/46, Fax: +91-22-4127 1147 Email:
                    info@aecci.org.in | Website: www.aecci.org.in CIN:
                    U91900MH2015NPL265816
                  </span>
                </div>
                <div className="mt-auto">
                  <Form.Group className="mb-3 w-100 text-start">
                    <Form.Label>4. For Office use</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ resize: "none" }}
                      className="bg-white"
                      rows={2}
                      type="text"
                      placeholder="Enter Exporter business name, address, country"
                      readOnly
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td style={{ width: "10%" }}>5. item Number</td>
                  <td style={{ width: "15%" }}>
                    6. Marks and number of packages
                  </td>
                  <td style={{ width: "30%" }}>
                    7. Number and kind of packages, description of goods
                  </td>
                  <td style={{ width: "20%" }}>8. Origin Criteria</td>
                  <td style={{ width: "12%" }}>
                    9. Gross weight or order quantity
                  </td>
                  <td style={{ width: "12%" }}>
                    10. Number and date of invoices
                  </td>
                </tr>
              </thead>
              <tbody>
                {formData.eCoDetails.extendedData.map((data, index) => (
                  <>
                    <tr>
                      <td colSpan={6}>Page No. {index + 1}</td>
                    </tr>
                    <tr key={index}>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.itemNo}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              itemNo: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.itemNo.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={240}
                          value={data.noOfPack}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noOfPack: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noOfPack.length}/240 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={1100}
                          value={data.desOfGoods}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              desOfGoods: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.originCriteria}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              originCriteria: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.originCriteria.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={100}
                          value={data.weightAndQuantity}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              weightAndQuantity: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.weightAndQuantity.length}/100 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={200}
                          value={data.noAndDateInvoice}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noAndDateInvoice: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noAndDateInvoice.length}/200 Max
                        </h6>
                      </td>
                    </tr>
                  </>
                ))}
                {/* 
//-------------delete page function is here-------------------
--------------------------------------------------------------
-------------------------------------------------------------- */}

                <tr>
                  <td colSpan={6}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <span>Do You Want To Extend Page?</span>
                        <Button
                          variant="info"
                          className="ms-3 py-0 my-0"
                          onClick={handleAddEcoForm}
                        >
                          Yes
                        </Button>
                      </div>

                      {formData.eCoDetails.extendedData.length > 1 && (
                        <>
                          <div
                            className="d-flex align-items-center text-danger cursor-pointer max-content"
                            onClick={() => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                eCoDetails: {
                                  ...prevFormData.eCoDetails,
                                  extendedData:
                                    prevFormData.eCoDetails.extendedData.slice(
                                      0,
                                      -1
                                    ), // Remove last element
                                },
                              }));
                            }}
                          >
                            <MdDelete className="fs-3" />

                            <h6 className="mb-0 ms-1">Delete Page</h6>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />
            <div className="d-flex">
              <div
                style={{
                  width: "50%",
                  position: "relative",
                }}
                className="d-flex flex-column justify-content-between mr-1"
              >
                <Form.Label className="d-flex ">
                  <h6 className="text-nowrap me-2">
                    11. CERTIFICATION: {"  "}
                  </h6>
                  <span className="text-small">
                    {" "}
                    It is hereby certified on the basis of Control carried out,
                    that the declaration by the exporter is correct.
                  </span>
                </Form.Label>
                <div>
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(55deg)",
                      width: "100%",
                    }}
                  />
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(-55deg)",
                      width: "100%",
                    }}
                  />
                </div>

                <Form.Label className="mb-0">
                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  <span className="text-small">
                    Authorised Signatory - ASIAN EXPORTERS' CHAMBER OF COMMERCE
                    AND INDUSTRY{" "}
                  </span>
                </Form.Label>
              </div>
              <div style={{ width: "50%", borderLeft: "1px solid grey" }}>
                <div className="ms-2">
                  <span className="text-secondary">
                    Israeli declaration required:
                  </span>
                  <div className="d-inline ms-3 align-middle">
                    <Form.Check
                      inline
                      label="Yes"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "true"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "true",
                          },
                        })
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "false"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "false",
                          },
                        })
                      }
                    />
                  </div>

                  <h6 className="my-3">12. DECLARATION BY THE EXPORTERS:</h6>

                  <span>
                    The Undersigned hereby declares that the above details and
                    statements are correct that all the goods were produced in{" "}
                    {"  "}
                    <div className="d-inline-block">
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        label="INDIA"
                        type="radio"
                        checked={formData.eCoDetails.orginOfCom === "INDIA"} // Bind value from form data
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "INDIA",
                            },
                          })
                        }
                      />{" "}
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        type="radio"
                        label="FOREIGN ORIGIN"
                        checked={
                          formData.eCoDetails.orginOfCom === "FOREIGN ORIGIN"
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "FOREIGN ORIGIN",
                            },
                          })
                        }
                      />
                    </div>{" "}
                    and that they comply with the origin requirements
                  </span>

                  <span className="d-block mt-2 mb-3">for exports to</span>

                  <Form.Group className="w-50">
                    <Form.Label className="mb-0">
                      (Importing Country)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      value={formData.eCoDetails.impCountry}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            impCountry: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.impCountry.length}/30 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">FOR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Company Name here"
                      maxLength={40}
                      value={formData.eCoDetails.forComRegion}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            forComRegion: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.forComRegion.length}/40 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">
                      Authorised Signatory
                    </Form.Label>
                    <Form.Select
                      value={formData.eCoDetails.designation}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            designation: e.target.value,
                          },
                        });
                      }}
                    >
                      <option value="">Select Role</option>
                      {[
                        "Authorised Signatory",
                        "Chairman",
                        "CEO",
                        "Managing Director",
                        "Director",
                        "Partner",
                        "Proprietor",
                        "President",
                        "Vice President",
                        "General Manager (GM)",
                        "Executive Director",
                      ].map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">Place</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Place here"
                      maxLength={20}
                      value={formData.eCoDetails.place}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            place: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.place.length}/20 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label className="mb-0">Date</Form.Label>
                    <Form.Control
                      as="input"
                      type="date"
                      className="w-50"
                      placeholder="Enter Place here"
                      value={formData.eCoDetails.date}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            date: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Control
                      as="input"
                      type="file"
                      className="w-50 mb-2"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0]; // Get the first file selected
                        const updatedFormData = {
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            stamp: file, // Update the stamp field with the selected file
                          },
                        };
                        setFormData(updatedFormData); // Update the formData state
                      }}
                    />
                  </Form.Group>

                  {formData.eCoDetails.stamp && (
                    <div>
                      <img
                        width="15%"
                        height="auto"
                        src={URL.createObjectURL(formData.eCoDetails.stamp)}
                        alt="Stamp"
                      />
                    </div>
                  )}

                  <span className="d-block">Authorized Stamp & Signature</span>

                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  {/* border-bottom: 1px dotted #000; */}
                  <span>
                    Place & date, stamp & signature of authorized signatory
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <iframe
              title="eCO File"
              src={URL.createObjectURL(formData.eCoFile)}
              width="100%"
              height="500px"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {!eCoCreated ? (
          <>
            <Button className="setup-bg-color" onClick={handlePreviewSave}>
              PREVIEW & SAVE
            </Button>
            <Button onClick={props.onHide} variant="secondary">
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setECoCreated(false);
              }}
            >
              EDIT
            </Button>
            <Button
              onClick={() => {
                props.onHide();
                handleSubmitEco();
              }}
              variant="success"
            >
              SUBMIT
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
