import React from "react";
import chiefimg from "../../assets/chief.png";
import bfimglock from "../../assets/bgImglock.avif";
// import bgImg from "../../assets/Headerimg.png";
import bgImg from "../../assets/WelcomeBanner.avif";

const WelcomeBanner = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        height: "17rem",
      }}
    >
      <div className="d-flex justify-content-between align-items-center text-light main-width">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ width: "25%" }}
        >
          <div style={{ height: "10rem" }}>
            <img src={chiefimg} height="100%" width="100%" alt="" srcset="" />
          </div>
          <h6>Shri. Jaheer Bukhari</h6>
          <b>-AECCI Chairman</b>
        </div>
        <div style={{ width: "70%" }}>
          <h2 className="font1">Welcome to the AECCI e-Platform!</h2>
          <span>
            Step into a realm of innovation shaped by the visionary ideas of our
            Hon’ble Chairman. Under his guidance, this platform has evolved with
            advanced features and an enhanced version, providing you with an
            unparalleled experience. Explore, connect, and thrive with the
            digital excellence inspired by our esteemed Chairman&#39;s
            insightful vision.
          </span>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;
