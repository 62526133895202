import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getAuthUserlocal } from "../../store/services";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { RiAccountCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const UserAccount = () => {
  const navigate = useNavigate();

  const [showPopover, setShowPopover] = useState(false);
  const handleAccountIconClick = () => {
    setShowPopover(!showPopover);
  };
  const handlePopoverClose = () => {
    setShowPopover(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("auth");

    navigate("/login");
  };

  return (
    <div>
      <div className="header-user">
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          show={showPopover}
          overlay={
            <Popover
              id="popover-account-details"
              className="rounded-4 border-none"
            >
              <Popover.Header
                className="ps-3"
                style={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
              >
                {getAuthUserlocal() ? (
                  <>
                    <h6 className="fw-bold mb-0">
                      Hi, {getAuthUserlocal().firstName}{" "}
                      {getAuthUserlocal().surName}
                    </h6>
                    <span className="fw-semibold text-secondary">
                      {getAuthUserlocal().email}
                    </span>
                    <br />
                    <span className="fw-semibold text-secondary">
                      Membership No. : {getAuthUserlocal().memberShipNo}
                    </span>
                  </>
                ) : (
                  "Account"
                )}
              </Popover.Header>
              <Popover.Body className="p-0 w-100" style={{ minWidth: "8rem" }}>
                {getAuthUserlocal() ? (
                  <div className="w-100">
                    <Dropdown.Item
                      href="#"
                      className="text-start ps-3 py-2"
                      onClick={() => {
                        navigate("/User-dashboard");
                      }}
                    >
                      <h6>Dashboard</h6>
                    </Dropdown.Item>
                    <hr className="my-0" />

                    <Dropdown.Item
                      href="#"
                      className="text-start ps-3 py-2"
                      onClick={() => {
                        navigate("/trade-document");
                      }}
                    >
                      <h6>Trade Documentation</h6>
                    </Dropdown.Item>
                    <hr className="my-0" />

                    <Dropdown.Item
                      href="#"
                      className="text-start ps-3 py-2"
                      onClick={() => {
                        window.location.href =
                          "https://www.aecci.org.in/contact-us/";
                      }}
                    >
                      <h6>Support</h6>
                    </Dropdown.Item>
                    <hr className="my-0" />
                    <Dropdown.Item
                      href="#"
                      className="text-danger ps-3 py-2"
                      onClick={handleLogout}
                    >
                      <h6>Log Out</h6>
                    </Dropdown.Item>
                  </div>
                ) : (
                  <Dropdown.Item
                    href="#"
                    className="text-start ps-3 py-2"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <h6>Log In</h6>
                  </Dropdown.Item>
                )}
              </Popover.Body>
            </Popover>
          }
          rootClose
          onHide={handlePopoverClose}
        >
          <div onClick={handleAccountIconClick} className="ml-2">
            <RiAccountCircleFill className="icon-size" />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default UserAccount;
