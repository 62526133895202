import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { getAuthUserlocal } from "../../store/services";

const ProtectedRoute = ({ Component, path }) => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isHaveAccess, setIsHaveAccess] = useState(true);

  //access control setup
  const accessControlData = getAuthUserlocal()?.approveUserData || false;
  const accessControlDataProfile = accessControlData.Profile || false;
  const accessControlDataServices = accessControlData.Services || false;
  const accessControlDataWings = accessControlData["The Wings"] || false;
  const accessControlDataArbitration =accessControlData["Arbitration Center"] || false;
  const accessControlDataEvents = accessControlData.Events || false;
  const accessControlDataPublications = accessControlData.Publications || false;

  const accessControl = {
    //profile access
    "/personal-details": accessControlDataProfile,
    "/company-details": accessControlDataProfile,
    "/marketing-information": accessControlDataProfile,
    "/change-password": accessControlDataProfile,

    //Services access
    "/e-co": accessControlDataServices["e-CO"],
    "/membership-services": accessControlDataServices["Membership Services"],
    "/recommendation-letters": accessControlDataServices["Recommendation Letters"],
    "/collaboration-portal": accessControlDataServices["Collaboration Portal"],
    "/commercial-directory": accessControlDataServices["Commercial Directory"],
    "/wallet": accessControlDataServices["Wallet"],

    //wings
    "/export-wing": accessControlDataWings["Export Wing"],
    "/legal-wing": accessControlDataWings["Legal Wing"],
    "/hr-support-wing": accessControlDataWings["HR Support Wings"],
    "/buissness-advice-wing": accessControlDataWings["Business Advice Wing"],
    "/professional-wing": accessControlDataWings["Professional Wing"],
    "/event-seminar-wing": accessControlDataWings["Event & Seminar Wing"],
    "/export-wing-token": accessControlDataWings["Export Wing"],

    //arbitration center
    "/rules-policies": accessControlDataArbitration,
    "/panel-name": accessControlDataArbitration,
    "/raise-your-dispute": accessControlDataArbitration,
    "/list-of-agreement": accessControlDataArbitration,

    //Events
    "/chember-event-booking": accessControlDataEvents,
    "/b2b-connect": accessControlDataEvents,
    "/aecci-virtual-b2b-forum": accessControlDataEvents,
    "/b2b-events": accessControlDataEvents,
    "/sponsorships": accessControlDataEvents,
    "/interested-participate": accessControlDataEvents,
    "/lobby": accessControlDataEvents,
    "/b2bSummery": accessControlDataEvents,
    "/room/:roomId": accessControlDataEvents,
    // //publications
    // sponsorships: accessControlDataEvents,
  };

  // const routesJson = {
  //   PersonalDetails: "/personal-details",
  //   CompanyDetails: "/company-details",
  //   MarketingInformation: "/marketing-information",
  //   ChangePassword: "/change-password",
  //   ECO: "/e-co",
  //   MembershipServices: "/membership-services",
  //   RecommendationLetter: "/recommendation-letters",
  //   CommercialDirectory: "/commercial-directory",
  //   Wallet: "/wallet",
  //   ComingSoon: "/export-wing",
  //   // "B2BRegistrations": "/b2b-registrations",
  //   // "ExportWing": "/export-wing",
  //   // "LegalWing": "/legal-wing",
  //   // "HrSupportWing": "/hr-support-wing",
  //   // "BuissnessAdviceWing": "/buissness-advice-wing",
  //   // "ProfessionalWing": "/professional-wing",
  //   // "EventSeminarWing": "/event-seminar-wing",
  //   // "ExportWingToken": "/export-wing-token",
  //   // "RulesPolicies": "/rules-policies",
  //   // "PanelName": "/panel-name",
  //   // "RaiseYourDispute": "/raise-your-dispute",
  //   // "ListOfAgreement": "/list-of-agreement",
  //   // "ChamberEventBooking": "/chember-event-booking",
  //   // "B2BEvents": "/b2b-events",
  //   // "Sponsorships": "/sponsorships",
  // };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("auth"));

    if (!userDetails || !userDetails.userId || !userDetails.token) {
      setIsAuthenticated(false);
      navigate("/login");
      return;
    }

    if (path && accessControl[path]) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate("/User-dashboard");
    }
  }, [auth]);

  return isAuthenticated ? <Component /> : null;
};

export default ProtectedRoute;
