import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Card,
  Container,
  CloseButton,
} from "react-bootstrap";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import panelImg1 from "../../../assets/PanelImages/PanelImg1.png";
import panelImg2 from "../../../assets/PanelImages/PanelImg2.png";
import panelImg3 from "../../../assets/PanelImages/PanelImg3.png";
import panelImg4 from "../../../assets/PanelImages/PanelImg4.png";
import panelImg5 from "../../../assets/PanelImages/PanelImg5.png";
import panelImg6 from "../../../assets/PanelImages/PanelImg6.png";
import panelImg7 from "../../../assets/PanelImages/PanelImg7.png";
import panelImg8 from "../../../assets/PanelImages/Anilkumar.png";
import panelImg9 from "../../../assets/PanelImages/PanelImg9.png";
import panelImg10 from "../../../assets/PanelImages/PanelImg10.png";
import panelImg11 from "../../../assets/PanelImages/PanelImg11.jpg";
import panelImg12 from "../../../assets/PanelImages/RaghvendraChauhan.png";
import panelImg13 from "../../../assets/PanelImages/SivaRao.png";
import panelImg14 from "../../../assets/PanelImages/PanelImg14.jpg";
import panelImg15 from "../../../assets/PanelImages/GeetaLuthra.png";
import panelImg16 from "../../../assets/PanelImages/LSreerangaraju.png";
import panelImg17 from "../../../assets/PanelImages/SubhashGupta.png";
import AnupWadhawan from "../../../assets/PanelImages/AnupWadhawan.jpg";
import shahikantmDeshpande from "../../../assets/PanelImages/shahikantmDeshpande.jpg";
import alokVerma from "../../../assets/PanelImages/JusticeAlokVerma.jpg";
import swatanterKumar from "../../../assets/PanelImages/JusticeSwatanterKumar(Retd).jpg";
import ramMurthy from "../../../assets/PanelImages/Mr.RamMurthyKommera.jpg";
import vInbavijayan from "../../../assets/PanelImages/V_INBAVIJAYAN.jpg";

import QuickLinks from "../../../components/basic/QuickLinks";

const PanelName = () => {
  const panelData = [
    {
      id: 1,
      panelTitle: "Mr. Anil Kumar Jain",
      Qualifications:
        "B.Sc. from Allahabad University; Bachelor of Engineering (Electrical) with 1st class Honours from University of Roorkee (now IIT Roorkee); Masters’ Diploma in Public Administration from Indian Institute of Public Administration, Govt. of India, New Delhi.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "37 years with CPWD, Govt. of India; post-retirement, advised IITs and universities on major projects including PMSSY hospitals and Commonwealth Games stadia.",
      Expertise:
        "Expert in DPR preparation, hilly terrain construction, contract management, project monitoring, and energy efficiency in infrastructure projects.",

      imageSrc: panelImg8,
    },
    {
      id: 2,
      panelTitle: "Justice  Raghvendra Singh Chauhan",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg12,
    },
    {
      id: 3,
      panelTitle: "Justice B. Siva Sankara Rao",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg13,
    },
    {
      id: 4,
      panelTitle: "Dr. Subhash C Gupta",
      Qualifications: "Ph D (Law); Ph D (Management)",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",
      imageSrc: panelImg17,
    },

    {
      id: 5,
      panelTitle: "Adv. Geeta Luthra",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Independent arbitrator with 42 years' experience in various law fields. Additional Advocate General for Haryana, with extensive arbitration expertise.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg15,
    },
    {
      id: 6,
      panelTitle: "Mr. L V Sreerangaraju",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg16,
    },
    {
      id: 7,
      panelTitle: "CA Rajendra Karnmal Bhuta",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg1,
    },
    {
      id: 8,
      panelTitle: "Dr. Sudharanjan Sahu",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg2,
    },
    {
      id: 9,
      panelTitle: "CA Pawan Kumar Agarwal",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg3,
    },
    {
      id: 10,
      panelTitle: "Adv. Kushnuma Khan",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg4,
    },
    {
      id: 11,
      panelTitle: "Adv. Pradeep Vital Sawant",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg5,
    },
    {
      id: 12,
      panelTitle: "Adv. Anant Chawre",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg6,
    },
    {
      id: 13,
      panelTitle: "Adv. Rupali Akolkar",
      Qualifications: "A qualified Chartered Accountant.",
      Empanelment:
        "A senior Finance Professional with 47 years of expertise. Empanelment: Arbitrator empaneled with National Stock Exchange (NSE) and Bombay Stock Exchange (BSE).",
      Experience:
        "Led numerous Merger and Acquisition deals for large corporate clients on both national and international levels.",
      Expertise:
        "Extensive practice in finance markets with a strong understanding of Taxation laws, Corporate laws, Financial Management, and accounting.",

      imageSrc: panelImg7,
    },
    {
      id: 14,
      panelTitle: "IAS Anup Wadhawan ",
      Qualifications: "PhD in Economics from Duke University, North Carolina, USA, M.A. in Economics from Delhi School of Economics, B.A. Hons. (Economics) from Hindu College, University of Delhi.",
      Empanelment:
        "Member of Arbitration Panel in 4 National Highways Authority of India (NHAI), Sole Arbitrator for Government of Uttarakhand, Conciliation work undertaken as a Member in 7 contractual disputes of PSUs, On board of several companies in Insurance, Finance and NGO Sectors.",
      Experience:
        "Executive Magistrate under CrPC, Conciliation, Arbitration, Commerce Secretary to Govt. of India and Handled India’s foreign trade matters",
      Expertise:
        "Expert in Trade & Finance, Banking, Insurance, Capital Markets, Power Sector, Urban Development & Infrastructure, Public Policy & Administration.",

      imageSrc: AnupWadhawan,
    },
    {
      id: 15,
      panelTitle: "Dr. Shashikant Murlidhar Deshpande",
      Qualifications: "B.V.Sc. & A.H., & M.V.Sc. from Bombay Veterinary College, A.I.I. from Insurance Institute of India, F.I.C.A. (Forum for International Conciliation & Arbitration)",
      Empanelment:
        "Arbitrator and Mediator for ICA, SEBI, NSE, BSE, ADR Panel of Hon’ble Bombay High Court, Mediation Cell of NCDRC, Insurance Domain Arbitrator with National Insurance Company Ltd., Gujarat International Maritime Arbitration Centre (GIMAC).",
      Experience:
        "35+ years of experience in National Insurance Company Limited, Retired as Deputy General Manager, Worked as a Consultant in MD India Healthcare NetworX Pvt. Ltd., Handled 20 Arbitration matters (11 cases as Sole Arbitrator, 8 cases as Co-Arbitrator and 1 case as Sole Arbitrator for major Commercial Arbitration)",
      Expertise:
        "Expert in sectors like Construction, Engineering, Transportation, Infrastructure, Commercial Contracts, Shipping & Logistics, Insurance, ADR.",

      imageSrc: shahikantmDeshpande,
    },
    {
      id: 16,
      panelTitle: "Adv. Ram Murthy Kommera ",
      Qualifications: "P.G. Diploma in ADR from NALSAR University, LLB from Kakatiya University, B.A. from Osmania University",
      Empanelment:
        "Arbitrator and Conciliator – empaneled under ICA, NSE, BSE, NCDEX, MSE, CWC, Telangana State Consumer Disputes Redressal Commission, Certified Expert Mediator, Insolvency Professional at Insolvency & Bankruptcy Board of India, Qualified and Life Time Member of Independent Director’s Data Bank of Indian Institute of Corporate Affairs under Ministry of Corporate Affairs.",
      Experience:
        "36 years of Practice as an Advocate, worked as Special Public Prosecutor for Government of India for more than 13 years, handled more than 350 cases as Sole Arbitrator,",
      Expertise:
        "Expert in sectors like Agriculture, Commodities Trading, Real Estate, Infrastructure, Oil & Gas, Media & Entertainment, Construction & Engineering, Pharmaceutical, Energy & Power.",

      imageSrc: ramMurthy,
    },
    {
      id: 17,
      panelTitle: "Justice Alok Verma",
      Qualifications: "LLM from Barkatullah University, Bhopal, LLB from Hamidia College, Bhopal, B.Sc. from Bhopal University.",
      Empanelment:
        "Judge of Hon’ble High Court of Madhya Pradesh for 3 years, Arbitrator in various cases assigned by the Hon’ble High Court of Madhya Pradesh and Delhi.",
      Experience:
        "36 years as JMFC, CJM of different courts in civil and criminal side. Elevated to Hon’ble High Court of Madhya Pradesh from the post of District and Sessions Judge, Handled various cases of Arbitration assigned by the Hon’ble High Court of Madhya Pradesh and Delhi.",
      Expertise:
        "Expert in sectors like Energy & Power, Construction & Engineering, Automotive & Heavy Industry, Pharmaceuticals & Biotech, Infrastructure, Logistics & Shipping, Banking & Finance and ADR.",

      imageSrc: alokVerma,
    },
    {
      id: 18,
      panelTitle: "Adv. V. Inbavijayan",
      Qualifications: "Law Degree from Dr. Ambedkar Government Law College, University of Madras.",
      Empanelment:
        "Accredited as Arbitrator in HKIAC (Hong Kong), CIETAC, BAC & SHIAC (China), AIAC & BICAM (Malaysia), DIAC & EMAC (Dubai), CRCICA (Egypt), SCCA (Saudi Arabia), MIAC (Maldives), KFCRI, ICADR, IIAC, ICA & GIMAC (India), CIDRA & NYIAC (USA), SCIAHK, BAC/BIAC, AALCO-HKRAC and various other institutions.",
      Experience:
        "Sole Arbitrator and Co-Arbitrator in more than 400 Domestic Ad Hoc and Institutional Arbitration and several thousands of small claims commercial/financial arbitration cases, Sole and Co-Arbitrator in 17 International Arbitrations, Emergency Arbitrator in 1 Domestic Institutional Arbitration (ICA).",
      Expertise:
        "International Commercial Arbitration, Maritime Arbitration, International Trade and Contracts, IPR, Construction Disputes, Finance and Banking Disputes, Securities Market, JVs, L/Cs, ADR, Sale of goods, Investment Disputes.",

      imageSrc: vInbavijayan,
    },
    {
      id: 19,
      panelTitle: "Justice Swatanter Kumar",
      Qualifications: "LLB from Punjab University, Chandigarh, B.A. from Government College, Chandigarh",
      Empanelment:
        "Member of Governing Body of Global Institute for Environment under aegis of UNEP, Panel of Arbitrators at different organizations and Institution of Arbitrators which amongst others are ICA, DIAC, LCIA, RAC, IAAC, etc. ",
      Experience:
        "Advocate & Senior Advocate for nearly 23 years of practice, Served as Judge of Hon’ble High Court of Delhi, Judge of Hon’ble High Court of Punjab & Haryana, Chief Justice of Hon’ble High Court of Bombay, Judge of Hon’ble Supreme Court of India, Chairman of NGT, Passed Awards in various Arbitration cases as an Arbitrator, Sole Arbitrator and Presiding Arbitrator.",
      Expertise:
        " Expert in sectors like Energy & Power, Agriculture, Construction & Engineering, Real Estate, Pharma, Transportation, Oil & Gas, Infrastructure, Logistics & Shipping.",

      imageSrc: swatanterKumar,
    },
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (id) => {
    const selectedCard = panelData.find((card) => card.id === id);
    setSelectedItem(selectedCard);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  return (
    <Layout>
      <Layout2>
        <DashboardHeader />

        <div>
          <h4 className="pt-4 mb-3">PANEL</h4>
          <hr />
          <h5>
            <i>
              Meet our highly experienced empaneled arbitrators at AECCI-IAC who
              are dedicated to providing efficient and effective resolution of
              disputes.
            </i>
          </h5>

          <CircleImage panelData={panelData} openModal={openModal} />

          {/* Modal */}
          <Modal show={!!selectedItem} onHide={closeModal} size="ls" centered>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body className="d-flex justify-content-center position-relative">
              <CloseButton
                variant="danger"
                className="position-absolute top-0 end-0 mt-4 me-4"
                onClick={closeModal}
              />
              {selectedItem && (
                <div className="d-flex flex-column align-items-center modal-card display-card">
                  <img
                    src={selectedItem.imageSrc}
                    alt={selectedItem.panelTitle}
                    className="img-fluid border"
                    style={{
                      maxWidth: "300px",
                      borderRadius: "8px",
                      width: "120px",
                      borderColor: "#ccc", // Customize border color
                      borderWidth: "2px", // Customize border width
                      borderStyle: "solid",
                    }}
                  />
                  <div className="ml-1 d-flex flex-column">
                    <h3 className="text-center p-2 fs-4">
                      {selectedItem.panelTitle}
                    </h3>
                    <p>
                      <strong>Qualifications:</strong>{" "}
                      {selectedItem.Qualifications}
                    </p>
                    <p>
                      <strong>Empanelment:</strong> {selectedItem.Empanelment}
                    </p>
                    <p>
                      <strong>Experience:</strong> {selectedItem.Experience}
                    </p>
                    <p>
                      <strong>Expertise:</strong> {selectedItem.Expertise}
                    </p>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
        <QuickLinks></QuickLinks>
      </Layout2>

      {/* Inline CSS */}
    </Layout>
  );
};

const CircleImage = ({ panelData, openModal }) => {
  return (
    <Container
      className="d-flex flex-wrap justify-content-around gap-3 pt-2 pb-2"
      style={{ backgroundColor: "#ebeff5" }}
    >
      {panelData.map((panel) => (
        <Card
          className="text-center shadow-sm"
          style={{ width: "180px", cursor: "pointer" }}
          key={panel.id}
          onClick={() => openModal(panel.id)}
        >
          <div
            className="d-flex justify-content-center align-items-center mb-2"
            style={{ marginBottom: "8px" }} // Replicates margin-bottom: 8px
          >
            <Card.Img
              variant="top"
              src={panel.imageSrc}
              alt={panel.panelTitle}
              className="img-responsive p-2"
              style={{
                width: "120px", // Sets the width of the image
                borderRadius: "50%", // Makes the image circular
                objectFit: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "120px", // Maintains aspect ratio
                filter: "grayscale(100%)",
              }}
            />
          </div>
          <Card.Body
            style={{
              height: "60px", // Adjust this value to reduce the height as needed
              overflow: "hidden", // Optional: hides overflow if content exceeds height
              paddingTop: "0",
              paddingBottom: "0",
            }}
          >
            <Card.Text
              className="font-weight-bold"
              style={{ fontSize: "12px" }}
            >
              {panel.panelTitle}
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default PanelName;
