import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateRoom = () => {
  const [username, setUserName] = useState("");
  const [roomId, setRoomId] = useState("");
  const navigate = useNavigate();

  const joinRoom = useCallback(() => {
    // Pass roomId and username as query parameters
    navigate(`/room/${roomId}?username=${encodeURIComponent(username)}`);
  }, [navigate, roomId, username]);

  return (
    <div className="createRoomWrapper">
      <input
        type="text"
        placeholder="Room ID"
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      />
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUserName(e.target.value)}
      />
      <button onClick={joinRoom}>Join Room</button>
    </div>
  );
};

export default CreateRoom;
