import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";
import toast from "react-hot-toast";
import { countriesList } from "../../../store/AllListData"
import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaEnvelope, FaExternalLinkAlt, FaWhatsapp } from "react-icons/fa";
import "../../auth/Register/Register.css";


const LegalWingRequest = () => {
  const [registerFormSuccess, setRegisterFormSuccess] = useState(false);
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  const captcha_length = 6;
  const initialFormData = {
    companyName: "",
    name: "",
    email: "",
    country: "",
    city: "",
    state: "",
    phoneNo: "",
    contactTimeFrom: "",
    contactTimeTo: "",
    query: selectedType,
    topic: selectedTopic,
    userMessage: "",
    desiredOutcome: "",
    additionalInfo: "",
  };

  const [formData, setFormData] = useState(initialFormData);




  const typesWithTopic = [
    {
      name: "Alternative Dispute Resolution (ADR)",
      types: [
        "Domestic arbitration",
        "International arbitration",
        "International commercial arbitration",
        "Ad-hoc Arbitration",
        "Fast track Arbitration",
        "Institutional Arbitration",
        "Mediation",
        "Conciliation",
      ],
    },
    {
      name: "Common types/causes of global trade disputes",
      types: [
        "Payment terms",
        "Letter of guarantee",
        "Foreign exchange rates",
        "Documentation errors",
      ],
    },
    {
      name: "Commercial/Business law",
      types: [
        "Contract Law",
        "Employment Law",
        "Labour Law",
        "Intellectual Property Law",
        "Securities Law",
        "Tax Law",
      ],
    },
    {
      name: "Company law",
      types: [
        "private limited company",
        "public limited company",
        "sole Proprietorship",
        "one person company",
        "Partnership",
        "limited liability partnership",
      ],
    },
    {
      name: "Director’s disputes",
      types: [
        "Negotiate and Mediate",
        "Shareholder Solutions",
        "Selling or Splitting the Business",
        "Negotiate a Share Sale and Resignation",
        "Company Buy-Back of Shares",
        "Resign or Sell Up",
      ],
    },
    {
      name: "Data protection",
      types: ["Data security", "Data availability", "Access Control"],
    },
  ];

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSelectedTopic("");
  };

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "contactTimeFrom" || name === "contactTimeTo") {
      const timeValue = new Date(`1970-01-01T${value}:00`);
      const minTime = new Date('1970-01-01T10:00:00');
      const maxTime = new Date('1970-01-01T18:00:00');

      if (timeValue >= minTime && timeValue <= maxTime) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        alert('Please select a time between 10:00 AM and 6:00 PM');
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const handleCaptchaChange = (evt) => {
    setCaptchaValue(evt.target.value);
    if (validateCaptcha(evt.target.value, false)) {
      setIsCaptchaCorrect(true);
      return;
    }
    setIsCaptchaCorrect(false);
  };



  const resetForm = () => {
    setFormData(initialFormData);
    setCaptchaValue("");
    setSelectedTopic("")
    setSelectedType("")
    setIsCaptchaCorrect(false);
    setIsInputValid(true);
    setRegisterFormSuccess(false);
    setFormSuccessful(false);
  };

  const handleRegiFormSubmit = async (e) => {
    e.preventDefault();
    setRegisterFormSuccess(true);
    try {
      formData.phoneNo = parseInt(formData.phoneNo);
      if (isFormValid) {
        // Proceed
      } else {
        alert("CAPTCHA is invalid");
        return false;
      }

      const validateField = (field, fieldName, regex) => {
        if (
          field === undefined ||
          (typeof field === "string" && field.trim() === "")
        ) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} can not be empty`);
          return false;
        }
        if (regex && !regex.test(field)) {
          console.log("Invalid Form, field:", field);
          alert(`Invalid Form, ${fieldName} is not in the correct format`);
          return false;
        }

        return true;
      };

      const validateNonZeroNumber = (value, fieldName) => {
        if (parseInt(value) === 0) {
          console.log(`Invalid Form, ${fieldName} cannot be 0`);
          alert(`Invalid Form, ${fieldName} cannot be 0`);
          return false;
        }
        return true;
      };
      // Validate common fields
      if (!validateField(formData.companyName, "Company Name")) return;
      if (!validateField(formData.name, "Name")) return;
      if (!validateField(formData.email, "Email")) return;
      if (!validateField(formData.country, "Country")) return;
      if (!validateField(formData.city, "City")) return;
      if (!validateField(formData.state, "State")) return;
      if (!validateField(formData.query, "query")) return;
      if (!validateField(formData.topic, "topic")) return;
      if (!validateField(formData.phoneNo.toString(), "Phone No.", /^(?:\d{10,15})$/)) return;
      if (!validateNonZeroNumber(formData.phoneNo, "Phone No.")) return;



      const res = await axios.post(getURLbyEndPointV2("createLegalWingRequest"), {
        ...formData,
      });

      if (res && res.data && res.data.status) {
        toast.success(res.data.message);
        setFormSuccessful(true);
        setRegisterFormSuccess(false);
        setFormData(initialFormData);
      } else {
        console.log("Response data:", res);
        toast.error(res?.data?.message || "An error occurred");
      }
    } catch (e) {
      console.log("Error caught:", e);
      toast.error(e?.response?.data?.message || "An error occurred");
    } finally {
      setRegisterFormSuccess(false);
    }
  };


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      query: selectedType,
      topic: selectedTopic,
    }));
  }, [selectedType, selectedTopic]);

  useEffect(() => {
    const isValid =
      isCaptchaCorrect
    setIsFormValid(isValid);
  }, [isCaptchaCorrect]);

  useEffect(() => {
    loadCaptchaEnginge(captcha_length);
  }, []);
  return (
    <>
      <Layout>
        <EPlatformHeader
          heading="Inquiry form-Legal Wing"
          text="AECCI LEGAL WING: Innovative Legal Solutions for Modern International Trade Challenges"
        />
        <div className="main-width mx-auto my-4">
          <Row className="mt-2">
            <Col>
              <h3 className="montserratAlternatesFont fw-bold">
                Unlock Seamless Global Trade: Expert Legal Solutions and Swift Resolutions with AECCI-Legal Wing.
              </h3>
              {/* <h5>AECCI B2B Connect brings the globe to your doorstep.</h5> */}

              {/* <h6 className="my-4 fw-semibold">
              AECCI-Legal Wing is dedicated to providing expert legal guidance to ensure a fair and timely resolution process. Our services include trade dispute resolution, regulatory advocacy, and strategic legal advice
              </h6> */}

              <h6 className="my-4 fw-semibold text-justify">
                Achieving Business Excellence with Expert Legal
                Counsel: Addressing the common challenges faced by exporters and importers due to
                a lack of awareness about necessary legal documentation, trade procedures, and
                policies. Often, deals are made on trust without proper documentation, leading
                to disputes and business disagreements.
              </h6>

              <div style={{ marginTop: '40px' }}>
                <h5 style={{ fontWeight: 'bold' }}>Why Choose AECCI Legal Wing?</h5>
                <div style={{ marginTop: '20px', textAlign: 'left' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Cost-effective Solutions</h6>
                    <p style={{ fontWeight: '500', color: '#555' }}>
                      Get top-quality legal support without breaking the bank. Our affordable services and flexible payment options make expert legal assistance accessible to all businesses.
                    </p>
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Swift Resolutions</h6>
                    <p style={{ fontWeight: '500', color: '#555' }}>
                      Resolve disputes quickly and efficiently with our proactive approach. We minimize disruptions, ensuring your trade operations run smoothly.
                    </p>
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Expert Legal Guidance</h6>
                    <p style={{ fontWeight: '500', color: '#555' }}>
                      Benefit from the expertise of our seasoned legal professionals specializing in international trade laws. We provide reliable advice and strategic solutions tailored to your needs.
                    </p>
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Tailored Services</h6>
                    <p style={{ fontWeight: '500', color: '#555' }}>
                      Receive personalized legal services designed to meet your unique business requirements. From trade dispute resolution to data privacy protection, we’ve got you covered.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '40px' }}>
                <h5 className="text-justify" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                  By choosing AECCI-Legal Wing, you are partnering with a dedicated legal team committed to protecting and advancing your business interests in the global marketplace.
                </h5>
                <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
                  <h4 style={{ fontWeight: '600', color: '#333', marginBottom: '20px' }}>
                    Register to inquire more
                  </h4>
                  <h5 style={{ fontWeight: '600', color: '#333', marginBottom: '20px' }}>
                    For any assistance or queries, please don't hesitate to contact us:
                  </h5>
                  <div className="my-3">
                    <p><FaWhatsapp /> <strong>Mobile/WhatsApp:</strong> <a href="https://api.whatsapp.com/send?phone=918433720996" target="_blank" rel="noopener noreferrer">+91-8433720996</a></p>
                    <p><FaEnvelope /> <strong>Email ID:</strong> legal@aecci.org.in </p>
                    <p><FaExternalLinkAlt /> <strong>Website:</strong> <a href="http://www.aecci.org.in" className="text-primary" target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">www.aecci.org.in</a></p>
                  </div>

                  <p style={{ fontStyle: 'italic', color: '#555', marginBottom: '0' }}>
                    ***Form is to be filled in by the interested businesses, so that we can understand your needs and preferences.
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              {!formSuccessful ? (
                <div
                  className="text-white montserratFont"
                  style={{
                    background: "#021C43",
                    paddingBlock: "30px",
                    paddingInline: "50px",
                  }}
                >

                  Have a legal query or need assistance? Submit your queries, and our expert team will provide the

                  support and guidance you need.
                  <form onSubmit={handleRegiFormSubmit}>
                    <p className="mt-4" style={{ fontSize: "0.9em" }}>
                      * indicates required fields
                    </p>

                    <div className="my-4">
                      <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                        Company Name *
                      </label>
                      <div className="d-flex flex-column">
                        <input
                          style={{ width: "auto", height: "auto" }}
                          className="form-control rounded-0"
                          onChange={handleInputChange}
                          value={formData.companyName}
                          name="companyName"
                          type="text"
                          maxLength={50}
                          required
                        />
                      </div>
                    </div>
                    <div className="my-4">
                      <Row>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            Contact Person Name *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.name}
                              name="name"
                              type="text"
                              maxLength={50}
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            {formData.entity === "Business"
                              ? "Company Email Address *"
                              : "Email Address *"}
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.email}
                              name="email"
                              type="email"
                              maxLength={40}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            Country *
                          </label>
                          <div className="d-flex flex-column">
                            <select
                              className="form-control rounded-0"
                              required
                              value={formData.country}
                              onChange={handleInputChange}
                              name="country"
                            >
                              <option value="">Select a country...</option>
                              {countriesList.map((data, index) => (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            City *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.city}
                              name="city"
                              type="text"
                              maxLength={50}
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            State *
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              style={{ width: "auto", height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.state}
                              name="state"
                              type="text"
                              maxLength={50}
                              required
                            />
                          </div>
                        </Col>

                      </Row>
                    </div>

                    <div className="my-4">
                      <Row>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            Phone Number *
                          </label>
                          <input
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value) || 0
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            className="form-control rounded-0"
                            onChange={handleInputChange}
                            value={formData.phoneNo}
                            name="phoneNo"
                            type="text"
                            required
                          />
                        </Col>
                        <Col>
                          <label className="fw-bold text-nowrap" style={{ fontSize: "0.9rem" }}>
                            Contact Prefer time *
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              style={{ height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.contactTimeFrom}
                              name="contactTimeFrom"
                              type="time"
                              required
                            />

                            <input
                              style={{ height: "auto" }}
                              className="form-control rounded-0"
                              onChange={handleInputChange}
                              value={formData.contactTimeTo}
                              name="contactTimeTo"
                              type="time"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>



                    <div className="my-4">
                      <Row>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            Purpose of Query *
                          </label>
                          <div className="d-flex flex-column">
                            <select
                              className="form-control rounded-0"
                              name="query"
                              onChange={handleTypeChange}
                              value={selectedType}
                              required
                            >
                              <option value="">Select Type</option>
                              {typesWithTopic.map((type, index) => (
                                <option key={index} value={type.name}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col>
                          <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                            Type of Topic *
                          </label>
                          <div className="d-flex flex-column">
                            <select
                              className="form-control rounded-0"
                              name="query"
                              onChange={handleTopicChange}
                              value={selectedTopic}
                              disabled={!selectedType}
                              required
                            >
                              <option value="">Select Topic</option>
                              {selectedType &&
                                typesWithTopic
                                  .find((type) => type.name === selectedType)
                                  .types.map((subType, subIndex) => (
                                    <option key={subIndex} value={subType}>
                                      {subType}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </div>



                    <div className="my-4">
                      <label className="fw-bold" style={{ fontSize: "0.9rem" }}>
                        Describe Your Query *
                      </label>
                      <Form.Control
                        as="textarea"
                        style={{
                          height: "auto",
                          resize: "none",
                        }}
                        className="form-control rounded-0"
                        onChange={handleInputChange}
                        value={formData.userMessage}
                        name="userMessage"
                        maxLength={1000}
                        rows={4}
                        required
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-between align-items-start mb-3">
                      <div className="d-flex align-items-center text-white">
                        <LoadCanvasTemplate className="text-white" />
                        {isCaptchaCorrect && (
                          <AiFillCheckCircle className="icon-size text-white" />
                        )}{" "}
                      </div>

                      <div className="w-50">
                        <input
                          className="w-100 px-2 py-1 border-1"
                          placeholder="Type the given captcha word"
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                          maxLength={captcha_length}
                          onChange={handleCaptchaChange}
                          value={captchaValue}
                        />
                        {isCaptchaCorrect ? (
                          <p className="text-success">CAPTCHA is valid</p>
                        ) : (
                          <p className="text-danger">CAPTCHA is invalid</p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <Button
                          variant="danger"
                          className="m-1 px-5 py-2 rounded-1 border-0 fw-medium"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          className="m-1 px-5 py-2 rounded-1 border-0 fw-medium "
                          disabled={registerFormSuccess}
                        >
                          {!registerFormSuccess ? "Submit" : "Processing..."}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    background: "#021C43",
                    paddingInline: "50px",
                    paddingBlock: "50px",
                  }}
                >
                  <div style={{ marginTop: "10px", color: "#fff" }}>
                    <h2>Thank You for Your Request!</h2>
                    <p>
                      We have received your request and will contact you soon.
                    </p>
                    <p>
                      Meanwhile, feel free to explore our{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                          href="https://www.aecci.org.in/aecci-arbitrationcenter/"
                        rel="noreferrer"
                      >
                        International Arbitration Center
                      </a>{" "}
                      and{" "}
                      <a
                        style={{ color: "#35afd3" }}
                        target="_blank"
                        href="https://www.aecci.org.in/our-services/"
                        rel="noreferrer"
                      >
                        services
                      </a>{" "}
                      to discover more about what we offer.
                    </p>
                    <div className="mt-5 mb-3">
                        <h2 className="mb-2">
                          AECCI-International Arbitration Center</h2>
                      <p>
                          AECCI-IAC has been protecting members from costly and long proceedings taken by commercial disputes, since 2015. AECCI works as an alternative dispute resolution center that simulates the best international practices within the framework of privacy and confidentiality.
                      </p>
                    </div>


                      <div className="text-white my-4">
                        <div className="text-white mb-2">
                          <h4 className="card-title">AECCI B2B Connect 2024</h4>
                          <p className="card-text">
                            Join our big event in October 2024 to connect with 25+
                            foreign partners, addressing your business needs
                            through consultations, market studies, business
                            matches, and more.
                          </p>
                          <p className="card-text">
                            Get ready to meet foreign experts and receive support
                            from the comfort of your business.
                          </p>
                        </div>


                        <div className="text-white mb-2 mt-3">
                          <h4 className="card-title">AECCI teams up with Renowned International Law Firms and Companies to Drive Global Growth</h4>
                          <p className="card-text">
                            Be a part of our international exhibition efforts and strategic partnerships with renowned international law firms, as we cultivate global business opportunities. Our commitment is to empower exporters, helping them to harness these platforms for achieving their business goals. Explore how partnering with AECCI can lead to success in the global commerce sphere. Together, we create pathways for advancement and prosperity.
                          </p>
                          <p className="card-text">
                            Get ready to meet foreign experts and receive support
                            from the comfort of your business.
                          </p>
                        </div>

                      <div className="text-white my-4">
                        <div className="text-white mb-2">
                          <h4 className="card-title">Our Wings</h4>
                          <p className="card-text">
                            Combinations of highly educated and experienced professionals who are committed to providing expert consultations to our global business partners through the various stages of business – all under one roof.
                          </p>
                          <div className="my-1">
                            <h6>EXPORT WING</h6>
                           
                          </div>
                          <div className="my-1">
                            <h6>LEGAL WING</h6>
                           
                          </div>
                          <div className="my-1">
                            <h6>HR SUPPORT WING</h6>
                            
                          </div>
                          <div className="my-1">
                            <h6>BUSINESS ADVICE WING</h6>
                          
                          </div>
                          <div className="my-1">
                            <h6>PROFESSIONAL WING</h6>
                           
                          </div>
                          <div className="my-1">
                            <h6>WOMEN WING</h6>
                            
                          </div>
                          <p className="card-text">
                            Meet our Wing Experts <a href="https://www.aecci.org.in/meet-our-wings-experts/" className="text-decoration-underline text-white">here</a>.
                          </p>
                        </div>
                      </div>


                 

                      <div className="text-white my-4">
                        <div className="text-white mb-2">
                          <h4>OUR Publications</h4>
                          <p>
                            Access all chamber publications in our Digital
                            Library:
                          </p>
                          <ul style={{ color: "#35afd3" }}>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/aecci-viewpoints/"
                                rel="noreferrer"
                              >
                                Daily Viewpoints
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/e-newsletters/"
                                rel="noreferrer"
                              >
                                Newsletters
                              </a>
                            </li>
                            <li>
                              <a
                                style={{ color: "#35afd3" }}
                                target="_blank"
                                href="https://www.aecci.org.in/media/publications/"
                                rel="noreferrer"
                              >
                                Annual Magazine
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default LegalWingRequest;
