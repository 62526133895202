import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { getAuthUserlocal } from "../../store/services";

const PrivateRoute = ({ Component }) => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("auth"));

    if (userDetails && userDetails.userId && userDetails.token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [auth, navigate]);

  return isAuthenticated ? <Component /> : navigate("/login");
};

export default PrivateRoute;
