import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../../components/basic/DashboardHeader";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  FormControl,
  CloseButton,
} from "react-bootstrap";
import { getAuthUserlocal } from "../../../../store/services";
import axios from "axios";
import { IoSend } from "react-icons/io5";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import {
  domain,
  getURLbyEndPointV2,
} from "../../../../store/api";
import { TruncatedTextArea } from "../../../../components/basic/textCustomized";
import { io } from "socket.io-client";

const socket = io(domain, {
  withCredentials: true,
  transports: ['websocket'],
  autoConnect: false,
});

const BuissnessAdviceToken = () => {
  // const navigate = useNavigate();
  const [wingData, setWingData] = useState([]);
  useEffect(() => {
    // Make the GET API call here
    const fetchData = async () => {
      const userId = getAuthUserlocal()._id;
      const wing = "Business";
      try {
        const res = await axios.get(
          getURLbyEndPointV2("getWingDataUser") + userId,
          {
            params: { wing }, // Pass wing as a query parameter
          }
        );
        const data = res.data.data;

        if (res.data.status) {
          setWingData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();


    // Connect socket
    if (!socket.connected) {
      socket.connect();
    }

    // Cleanup function
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Layout>
      <Layout2>
        <DashboardHeader/>
        <hr className="my-4" />
        <h5 className="mb-4">
        BUSINESS ADVICE WING-Online Consultation / Query Notes</h5>
        <div>
          <Row>
            {wingData.map((item, index) => (
              <Col key={index} md={6} className="mb-4">
                <Card className="h-100">
                  <Card.Header className="h5">
                    Token No: {item.tokenNo ? item.tokenNo : "(In Process)"}
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Card.Title className="h6">
                        Query:{" "}
                        <span className="text-secondary">{item.query}</span>
                      </Card.Title>
                    </div>
                    <div>
                      <Card.Title className="h6">
                        Topic:{" "}
                        <span className="text-secondary">{item.topic}</span>
                      </Card.Title>
                    </div>
                    {item.refDoc && (
                      <div>
                        <Card.Title className="h6">
                          {" "}
                          Reference Document:{"  "}
                          <a href={item.refDoc} className="fs-6">
                            VIEW
                          </a>
                        </Card.Title>
                      </div>
                    )}

<Card.Text>
                      {" "}
                      <TruncatedTextArea text={item.summary} maxChars={50} />
                    </Card.Text>
                    <TrackTokenModal
                      wingId={item._id}
                      token={item.tokenNo}
                      isApproved={item.isApproved}
                      isClosed={item.isClosed}
                      isPaused={item.isPaused}
                      rating={item.rating}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
            
          </Row>
        </div>
      </Layout2>
    </Layout>
  );
};

export default BuissnessAdviceToken;

const TrackTokenModal = ({
  wingId,
  token,
  isApproved,
  isPaused,
  isClosed,
  rating,
}) => {
  const [show, setShow] = useState(false);
  const [paymentOfId, setPaymentOfId] = useState("");
  const [msgList, setMsgList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [proceedPayment, setProceedPayment] = useState(false);
  const [file, setFile] = useState(null);
  const messageEndRef = useRef(null);

  const handleClose = () => {
    setShow(false);
    socket.emit("leaveRoom", wingId); // Leave the room when closing the modal
  };

  const handleShow = () => {
    setShow(true);
    socket.emit("joinRoom", wingId); // Join the room when opening the modal
    socket.emit("fetchMessages", wingId);
  };


  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const messageData = {
      client: getAuthUserlocal()._id,
      wingId,
      message: newMessage,
      receiver: "admin",
      sender: "user",
      createdAt: new Date().toISOString(),
    };

    // Optimistically add the new message to the msgList
    setMsgList((prevMsgList) => [...prevMsgList, messageData]);
    socket.emit("sendMessage", messageData);
    setNewMessage("");
  };

  const handleUploadFile = (selectedFile, msgId) => {
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result.split(",")[1]; // Get base64 part

      const formData = {
        wingId,
        msgId,
        file: {
          name: selectedFile.name,
          type: selectedFile.type,
          data: fileData,
        },
      };

      console.log("FormData to be sent:", formData); // Debugging statement

      // Emit the formData to the server
      socket.emit("uploadFile", formData);
    };
    reader.readAsDataURL(selectedFile);
  };

  const findPaymentMessage = (msgId) => {
    return msgList.find((msg) => msg._id === msgId);
  };
  const paymentMessage = findPaymentMessage(paymentOfId);

  useEffect(() => {
    socket.on("allMessages", (messages) => {
      setMsgList(messages);
    });

    socket.on("paymentSuccess", (messages) => {
      console.log("paymentSuccess event received with messages:", messages);
      setMsgList(messages);
      setProceedPayment(false);
      alert("Payment successful!");
    });

    socket.on("accessDenied", (messages) => {
      console.log("paymentSuccess event received with messages:", messages);
      alert("You Don't Have Access.");
      window.location.reload();
    });

    socket.on("paidPayment", (messages) => {
      alert("Already Paid!");
    });

    socket.on("paymentFailed", ({ message }) => {
      console.log("paymentFailed event received with message:", message);
      alert(message);
    });

    return () => {
      socket.off("allMessages");
      socket.off("paymentSuccess");
      socket.off("paymentFailed");
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom when msgList changes
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [msgList]);

  const handleSendPayment = (msgId, wingId) => {
    const result = socket.emit("sendPayment", msgId, wingId);
    console.log("Result is here", result);
  };

  //RATING SERVICES AND CHANGES
  const [selRating, setSelRating] = useState(null);
  const [hover, setHover] = useState(null);

  const handleSubmitReview = async () => { 
    try {
      const res = await axios.put(getURLbyEndPointV2("submitReview") + wingId, {
        selRating,
      });

      if (res.status === 200) {
        alert("Rating submitted successfully");
        window.location.reload();
      } else {
        console.log("Failed to submit selRating");
      }
    } catch (error) {
      console.error("Error in submitting review", error);
    }
  };
  return (
    <>
    <Button
        variant="success"
        className="me-2"
        onClick={handleShow}
        disabled={!(isApproved === "Approved")}
      >
        Track your token
      </Button>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header style={{ backgroundColor: "#0b3b5d", color: "white" }}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="h5">
              Chat-Box: ({token ? token : "(In Process)"})
            </Modal.Title>
            <CloseButton
              variant="white"
              onClick={() => {
                handleClose();
                setProceedPayment(false);
              }}
            />
          </div>
        </Modal.Header>
        {proceedPayment ? (
          <Card>
            <Card.Header>
              <Row className="d-flex">
                <Col md={7}>
                  <h6 className="text-medium">
                    {getAuthUserlocal().companyName}
                  </h6>
                  <h6 className="text-medium">
                    Membership No: {getAuthUserlocal().memberShipNo}
                  </h6>
                </Col>
                <Col md={5}>
                  <h6 className="text-medium">
                    Wallet Balance: ₹{getAuthUserlocal().balance}
                  </h6>
                  <h6 className="text-medium">
                    Valid Upto: {getAuthUserlocal().validUpTo}
                  </h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <div className="ms-5 mb-2">
                <h6 className="text-medium">
                  Debit Amount: ₹{paymentMessage ? paymentMessage.message : ""}
                </h6>{" "}
                <h6 className="text-medium">Payment Towards: Export Wing</h6>
              </div>

              <Card className="p-2 mb-3">
                <Card.Text className="fs-5 mb-0">Informative Note:</Card.Text>

                <ul>
                  <li>
                    Maintain a minimum balance of Rs. 3000/- in your wallet.
                  </li>
                  <li>
                    Initiate wallet payments only from the company&#39;s
                    registered and authorized bank account.
                  </li>
                  <li>
                    The beneficiary Account displayed on this platform is unique
                    to you only and is not be shared with anyone else.
                  </li>
                  <li>
                    <Card.Text className="text-medium">
                      In Case of Rejection, Amount will be Credited to your
                      wallet.
                    </Card.Text>
                  </li>
                  <li>
                    <Card.Text className="text-medium">
                      You can monitor Transaction entries in your wallet.
                    </Card.Text>
                  </li>
                  <li>
                    <Card.Text className="text-medium">
                      For Any Assistance, contact Chamber Desk.
                    </Card.Text>
                  </li>
                </ul>
              </Card>
            </Card.Body>
            <Card.Footer className="text-center gap-3 d-flex">
              {" "}
              <Button
                variant="info"
                className="rounded-5 px-5 text-medium w-50"
                onClick={() => setProceedPayment(false)}
              >
                BACK
              </Button>
              <Button
                variant="success"
                className="rounded-5 px-5 text-medium w-50"
                onClick={() => handleSendPayment(paymentMessage?._id, wingId)}
              >
                SEND PAYMENT
              </Button>
            </Card.Footer>
          </Card>
        ) : (
          <>
            <Modal.Body style={{ height: "70vh", overflowY: "auto" }}>
              <Row>
                <Col md={12} className="d-flex flex-column h-100 w-100">
                  {msgList.map((msg, index) => (
                    <React.Fragment key={index}>
                      {msg.type === "aecciMsg" ? (
                        <AecciMessage text={msg.message} />
                      ) : msg.sender === "admin" ? (
                        msg.type === "msg" ? (
                          <Message
                            align="Received"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        ) : msg.type === "file" ? (
                          <File
                            align="Received"
                            text={msg.message}
                            time={new Date(msg.createdAt).toLocaleString()}
                            handleUploadFile={handleUploadFile}
                            msgId={msg._id} // Pass msgId here
                            fileUrl={msg.fileUrl ? msg.fileUrl : null}
                          />
                        ) : msg.type === "payment" ? (
                          <Payment
                            align="Received"
                            id={msg._id}
                            text={msg.message}
                            paymentStatus={msg.paymentStatus}
                            setProceedPayment={setProceedPayment}
                            setPaymentOfId={setPaymentOfId}
                            time={new Date(msg.createdAt).toLocaleString()}
                          />
                        ) : null
                      ) : msg.sender === "user" && msg.type === "msg" ? (
                        <Message
                          align="sent"
                          text={msg.message}
                          time={new Date(msg.createdAt).toLocaleString()}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
                  <div ref={messageEndRef} /> {/* Ref to scroll to */}
                </Col>
              </Row>

              {isClosed && (
                <>
                  <hr />
                  <Col className="text-center">
                    {rating ? (
                      <>
                        <h6>You Rated Us:</h6>
                        <div>
                          {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                              <FaStar
                                key={index}
                                size={30}
                                color={
                                  ratingValue <= rating ? "#ffc107" : "#e4e5e9"
                                }
                              />
                            );
                          })}
                        </div>
                        <p>Your Rating: {rating}</p>
                      </>
                    ) : (
                      <>
                        <h6>Please Review Our Services:</h6>
                        <div>
                          {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                              <label key={index}>
                                <input
                                  type="radio"
                                  name="selRating"
                                  value={ratingValue}
                                  onClick={() => setSelRating(ratingValue)}
                                  style={{ display: "none" }}
                                />
                                <FaStar
                                  size={30}
                                  color={
                                    ratingValue <= (hover || selRating)
                                      ? "#ffc107"
                                      : "#e4e5e9"
                                  }
                                  onMouseEnter={() => setHover(ratingValue)}
                                  onMouseLeave={() => setHover(null)}
                                  style={{
                                    cursor: "pointer",
                                    transition: "color 200ms",
                                  }}
                                />
                              </label>
                            );
                          })}
                        </div>
                        <p>Your Rating: {selRating ? selRating : "None"}</p>
                        <Button
                          onClick={handleSubmitReview}
                          disabled={!selRating}
                        >
                          Submit Rating
                        </Button>
                      </>
                    )}
                  </Col>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              {isClosed ? (
                <p className="text-center w-100">
                  We are pleased to inform you that your query has been
                  successfully resolved.
                </p>
              ) : isPaused ? (
                <p className="text-center w-100">
                  Your chat session has been paused. Please contact the Chamber
                  desk for further assistance.
                </p>
              ) : (
                <Row className="w-100">
                  <Col md={11} className="ms-0 ps-0">
                    <FormControl
                      placeholder="Type your message"
                      aria-label="Type your message"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      onClick={handleSendMessage}
                      variant="success"
                      disabled={!newMessage}
                    >
                      <IoSend className="fs-5 text-light" />
                    </Button>
                  </Col>
                </Row>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

const Message = ({ align, text, time }) => {
  // Determine the message alignment based on the 'align' prop
  const messageStyle = {
    backgroundColor: align === "sent" ? "#ececec" : "#63b190",
    color: align === "sent" ? "black" : "white",

    borderRadius:
      align === "sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "75%",
    alignSelf: align === "sent" ? "flex-end" : "flex-start", // Align self based on prop
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "sent" ? "black" : "white",
    textAlign: "right", // Adjust textAlign based on alignment
    marginTop: "auto", // Pushes the time to the bottom
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <span>{text}</span>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};

const AecciMessage = ({ text }) => {
  return (
    <Card className="mb-2 border-none text-center">
      <Card.Body
        style={{ backgroundColor: "#E8DAF5" }}
        className="text-info py-1 rounded-1"
      >
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const File = ({ align, text, time, handleUploadFile, msgId, fileUrl }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleUploadFile(selectedFile, msgId);
  };

  const messageStyle = {
    backgroundColor: align === "sent" ? "#ececec" : "#63b190",
    color: align === "sent" ? "black" : "white",
    borderRadius:
      align === "sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "20%",
    maxWidth: "75%",
    alignSelf: align === "sent" ? "flex-end" : "flex-start",
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "sent" ? "black" : "white",
    textAlign: "right",
    marginTop: "auto",
  };

  const handleViewClick = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      <div style={messageStyle}>
        <Col
          className="border-5 text-black p-0"
          style={{
            backgroundColor: "#ececec",
            borderRadius: "15px",
            marginBottom: "10px",
            borderLeft: "5px solid #B16379",
          }}
        >
          <h6 className="mx-3 mt-2">{text}</h6>
          <Col className="w-100 px-0 mb-0">
            {fileUrl ? (
              <Button
                style={{ borderRadius: "0px 0px 11px 11px" }}
                variant="secondary"
                className="pe-0 w-100"
                onClick={handleViewClick}
              >
                VIEW
              </Button>
            ) : (
              <>
                <Button
                  variant="secondary"
                  style={{ borderRadius: "0px 0px 11px 11px" }}
                  onClick={handleButtonClick}
                  className="pe-0 w-100"
                >
                  UPLOAD
                </Button>
                <input
                  type="file"
                  className="d-none"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </>
            )}
          </Col>
        </Col>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};

const Payment = ({
  align,
  id,
  text,
  time,
  setProceedPayment,
  paymentStatus,
  setPaymentOfId,
}) => {
  const messageStyle = {
    backgroundColor: align === "sent" ? "#ececec" : "#63b190",
    color: align === "sent" ? "black" : "white",
    borderRadius:
      align === "sent" ? "15px 0px 15px 15px" : "0px 15px 15px 15px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "20%",
    maxWidth: "40%",
    alignSelf: align === "sent" ? "flex-end" : "flex-start",
  };

  const timeStyle = {
    fontSize: "0.8rem",
    color: align === "sent" ? "black" : "white",
    textAlign: "right",
    marginTop: "auto",
  };

  const handlePaymentClick = () => {
    // Call setProceedPayment(true) when the payment is confirmed
    if (paymentStatus === "Processing") {
      setProceedPayment(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: align === "sent" ? "flex-end" : "flex-start",
        width: "100%",
      }}
      onClick={() => {
        handlePaymentClick();
        setPaymentOfId(id);
      }} // You can handle click event here or within the button
    >
      <div style={messageStyle}>
        <Col
          className="border-5 text-black py-3 text-center"
          style={{
            backgroundColor: "#ececec",
            borderRadius: "15px",
            padding: "10px",
            borderLeft: "5px solid #B16379",
          }}
        >
          <h5>₹ {text}</h5>
        </Col>
        <Row className="d-flex align-items-center">
          <Col xs="auto" className="ms-3 pe-1">
            {paymentStatus === "Completed" ? (
              <FaCircleCheck className="fs-6" />
            ) : paymentStatus === "Processing" ? (
              <MdOutlineAccessTimeFilled className="fs-6" />
            ) : null}
          </Col>
          <Col className="ms-0 ps-0">
            <h6 className="mb-0">{paymentStatus}</h6>
          </Col>
        </Row>
        <div style={timeStyle}>{time}</div>
      </div>
    </div>
  );
};
