import React from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import loginimg from "../../../assets/loginimg.jpg";
import "./Register.css";
import WelcomeBanner from "../../../components/basic/WelcomeBanner";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EnquiryComponent from "../../../components/basic/EnquiryComponent";

import EPlatformHeader from "../../../components/basic/EPlatformHeader";
import infoImg from "../../../assets/infoimg.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const Registerpage2 = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  //functions
  const [inputValue, setInputValue] = useState("");
  const [isInputValid, setInputValid] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);

    // Check if the input matches either GST format or IEC format
    // const gstRegex =
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const iecRegex = /^[a-zA-Z0-9]{10}$/;
    const cinRegex = /^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/;
    // const isValid = gstRegex.test(value) || iecRegex.test(value);
    // Test the input value against each regex pattern
    // const isGSTValid = gstRegex.test(value);
    const isIECValid = iecRegex.test(value);
    const isCINValid = cinRegex.test(value);

    // Check if the input matches either GST or IEC format
    const isValid = isIECValid || isCINValid;

    setInputValid(isValid);
  };

  const handleregistersubmit = () => {
    if (isInputValid) {
      console.log(inputValue);
      console.log(state);

      navigate("/register-3", {
        state: {
          email: state.email,
          gst: inputValue,
        },
      });
    } else {
      alert("Please enter a valid IEC code or CIN No.");
    }
  };

  //basic fuctions
  const handleMemberBenClick = () => {
    // Check if the click event originated from the button
    window.open(
      "https://www.aecci.org.in/membership-and-its-benefits/",
      "_blank"
    );
  };

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center">
          <EPlatformHeader
            text="Joining the AECCI Community offers you the chance to network, participate in webinars, attend events, receive daily alerts,
access news, publications, and a variety of other digital services available on this e-platform."
          />
          <div className="d-flex my-4 flex-column justify-content-center align-items-center">
            <div className="align-items-start main-width">
              <h1>JOIN NOW</h1>
              <p>
                You&#39;re just one step away from unlocking a world of
                opportunities. Please complete this form with precision using
                your accurate details. We&#39;re thrilled to see your interest
                in joining the AECCI Community! For Further assistance Contact
                our Chamber desk:
                <br />
                Board Line 022-41271145/46 or 8433720996
              </p>
            </div>
            <div className="align-items-start main-width">
              <div className="d-flex align-items-center">
                {" "}
                <h4> ENTER YOUR COMPANY’S IEC CODE/CIN NO.</h4>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      For Exporters, kindly input the updated IEC Code, and for
                      Shipping Agents, please provide a valid CIN Number
                    </Tooltip>
                  }
                >
                  <img
                    src={infoImg}
                    style={{ height: "6vh" }}
                    alt=""
                    srcset=""
                  />
                </OverlayTrigger>
              </div>

              <form className="mt-4 mb-1 d-flex">
                <input
                  className="p-1 rounded-1"
                  type="text"
                  placeholder="PLEASE ENTER THE FULL IEC CODE/CIN NO."
                  required
                  style={{
                    width: "400px",
                    borderColor: isInputValid ? "green" : "red",
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  maxLength={30}
                />
                <button
                  className={`px-2 py-1 ml-1 rounded-1 ${
                    !isInputValid ? "bg-gray" : "bg-color-2"
                  }`}
                  disabled={!isInputValid}
                  onClick={handleregistersubmit}
                >
                  NEXT
                </button>
              </form>
              <span className="text-secondary my-2">
                *Note: Please fill up the correct IEC Code as per DGFT India, or
                CIN no. as per MCA.
              </span>
            </div>
            <div className="mt-4 mb-2 align-items-start main-width">
              <button
                className="p-2 px-4 bg-color rounded-1 border-0"
                onClick={() => handleMemberBenClick()}
              >
                <span>EXPLORE THE MEMBERSHIP BENEFITS</span>
              </button>
            </div>
          </div>
          <EnquiryComponent />
        </div>
      </Layout>
    </>
  );
};

export default Registerpage2;
